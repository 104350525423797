import {
    Alert,
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Stack,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { Close as CloseIcon, HelpOutlineOutlined as HelpOutlineOutlinedIcon } from '@mui/icons-material';
import { GRID_CHECKBOX_SELECTION_COL_DEF, GridSelectionModel } from '@mui/x-data-grid';
import { Workflow } from '../../types/Workflow';
import { Campaign } from '../../types/Campaign';
import CampaignStatusComponent from '../campaignManagerV2/CampaignStatusComponent';
import Column from '../../components/Column';
import CDGrid from '../../components/CDGrid';
import Row from '../../components/Row';
import ApiService from '../../ApiService';
import Utils from '../../components/Utils';

export interface CRMDataUploadSendToAmcDialogProps {
    open: boolean;
    dealerId: number;
    onClose(workflowId: number | null, campaignIds: number[]): void;
    onCancel(): void;
}

export default function CRMDataUploadSendToAmcDialog(props: CRMDataUploadSendToAmcDialogProps) {
    const [workflows, setWorkflows] = useState<Workflow[]>([]);
    const [workflowId, setWorkflowId] = useState<number | null>(null);
    const [campaigns, setCampaigns] = useState<Campaign[]>([]);
    const [campaignIds, setCampaignIds] = useState<number[]>([]);
    const [bypassCampaignSelection, setBypassCampaignSelection] = useState(false);

    useEffect(() => {
        getWorkflows();
        getCampaigns(props.dealerId);
    }, [props.dealerId]);

    function getCampaigns(dealerId: number) {
        ApiService.getCampaignsForDealer(dealerId)
            .then((response) => {
                setCampaigns(response.data);
            })
            .catch(() => {
                setCampaigns([]);
            });
    }

    function getWorkflows() {
        ApiService.getWorkflows()
            .then((response) => {
                setWorkflows(response.data);
            })
            .catch(() => {
                setWorkflows([]);
            });
    }

    function cancel() {
        props.onCancel();
    }

    function sendToAmc() {
        props.onClose(workflowId, campaignIds);
    }

    function selectWorkflow(workflowId: number) {
        setWorkflowId(workflowId);
    }

    return (
        <Dialog
            fullWidth={true}
            open={props.open}
            onClose={cancel}
            PaperProps={{
                sx: {
                    maxWidth: '1048px',
                },
            }}
        >
            <DialogTitle>Send to AMC</DialogTitle>
            <IconButton
                aria-label="close"
                onClick={cancel}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent dividers sx={{ pt: 3, pb: 5 }}>
                <DialogContentText>
                    <Column spacing={3}>
                        <Column gap={2}>
                            <InputLabel
                                required={true}
                                sx={{
                                    color: (theme) => theme.palette.text.primary,
                                    fontSize: '14px',
                                }}
                            >
                                Select an AMC workflow for this file.
                            </InputLabel>

                            <FormControl style={{ width: '400px' }}>
                                <Row gap={0.75} alignItems="center">
                                    <TextField
                                        label="AMC Workflow Type"
                                        select
                                        required={true}
                                        size="small"
                                        SelectProps={{ native: false }}
                                        variant="outlined"
                                        onChange={(event) => {
                                            selectWorkflow(Number(event.target.value));
                                        }}
                                        sx={{ width: '100%' }}
                                    >
                                        {workflows.map((workflow) => {
                                            return (
                                                <MenuItem key={workflow.id} value={workflow.id}>
                                                    {workflow.workflowId}
                                                </MenuItem>
                                            );
                                        })}
                                    </TextField>

                                    <Tooltip
                                        arrow
                                        title="An AMC Workflow is a query we run to match Amazon users to your CRM data."
                                        placement="right"
                                    >
                                        <HelpOutlineOutlinedIcon fontSize="medium" color="action" />
                                    </Tooltip>
                                </Row>
                            </FormControl>
                        </Column>

                        <Box
                            sx={{
                                '& .row': {
                                    cursor: 'pointer',
                                },
                                mt: '0 !important',
                            }}
                        >
                            {campaigns.length === 0 && (
                                <>
                                    <Alert severity="warning">
                                        No Campaigns found for this Advertiser
                                        <br />
                                        <Checkbox
                                            disableRipple
                                            checked={bypassCampaignSelection}
                                            onChange={() => setBypassCampaignSelection(!bypassCampaignSelection)}
                                        />
                                        Submit for Attribution only with no Budget or Cost
                                    </Alert>
                                </>
                            )}
                            {campaigns.length > 0 && (
                                <div style={{ height: '200px', width: '100%' }}>
                                    <InputLabel
                                        required={true}
                                        sx={{
                                            color: (theme) => theme.palette.text.primary,
                                            fontSize: '14px',
                                            mb: (theme) => theme.spacing(2),
                                        }}
                                    >
                                        <Tooltip title="Campaign selections are used to calculate the Total Budget for our Cost per Sale/Lead metrics.">
                                            <Typography
                                                variant="subtitle2"
                                                sx={{
                                                    display: 'inline-block',
                                                }}
                                            >
                                                Select Campaigns
                                            </Typography>
                                        </Tooltip>
                                    </InputLabel>
                                    <CDGrid
                                        variant="outlined"
                                        autoHeight={true}
                                        headerHeight={75}
                                        rowHeight={75}
                                        rows={campaigns}
                                        pageSize={10}
                                        checkboxSelection={true}
                                        disableSelectionOnClick={true}
                                        onSelectionModelChange={(selectionModel: GridSelectionModel) => {
                                            // @ts-ignore
                                            setCampaignIds(selectionModel);
                                        }}
                                        columns={[
                                            {
                                                width: 100,
                                                ...GRID_CHECKBOX_SELECTION_COL_DEF,
                                                headerClassName: 'grid-header',
                                            },
                                            {
                                                width: 120,
                                                field: 'deliveryStatus',
                                                headerName: 'Status',
                                                headerClassName: 'grid-header',
                                                disableColumnMenu: false,
                                                hideSortIcons: false,
                                                renderCell: (params) => {
                                                    let value = params.value;
                                                    return <CampaignStatusComponent deliveryStatus={value} />;
                                                },
                                            },
                                            {
                                                width: 120,
                                                field: 'startDate',
                                                headerName: 'Start Date',
                                                headerClassName: 'grid-header',
                                                disableColumnMenu: false,
                                                hideSortIcons: false,
                                                valueGetter: (params) => {
                                                    return Utils.formatDate(params.value);
                                                },
                                            },
                                            {
                                                width: 120,
                                                field: 'endDate',
                                                headerName: 'End Date',
                                                headerClassName: 'grid-header',
                                                disableColumnMenu: false,
                                                hideSortIcons: false,
                                                valueGetter: (params) => {
                                                    return Utils.formatDate(params.value);
                                                },
                                            },
                                            {
                                                width: 180,
                                                field: 'poNumber',
                                                headerName: 'PO Number',
                                                headerClassName: 'grid-header',
                                            },
                                            {
                                                width: 200,
                                                field: 'dspCampaignId',
                                                headerName: 'Campaign ID',
                                                headerClassName: 'grid-header',
                                            },
                                            {
                                                width: 170,
                                                field: 'campaignName',
                                                headerName: 'Campaign Name',
                                                headerClassName: 'grid-header',
                                            },
                                        ]}
                                        sx={{
                                            '& .MuiDataGrid-columnHeader[data-field="__check__"]': {
                                                padding: 0,
                                            },
                                        }}
                                    />
                                </div>
                            )}
                        </Box>
                    </Column>
                </DialogContentText>
            </DialogContent>
            <DialogActions sx={{ px: 3, py: 1 }}>
                <Stack direction="row" spacing={1}>
                    <Button variant="outlined" color="primary" onClick={cancel}>
                        Cancel
                    </Button>
                    <Button
                        color="primary"
                        variant="contained"
                        disabled={!workflowId || (!bypassCampaignSelection && campaignIds.length === 0)}
                        onClick={sendToAmc}
                    >
                        Send to AMC
                    </Button>
                </Stack>
            </DialogActions>
        </Dialog>
    );
}
