import { useEffect, useMemo, useState } from 'react';
import { Typography } from '@mui/material';

import { Performance } from '../../../../../types/CampaignPerformance';
import Row from '../../../../../components/Row';
import Utils from '../../../../../components/Utils';

import { useReportingDashboardPageContext } from '../../../../../hooks/useReportingDashboardPage';
import BasicBarChartCard from '../../../../../components/Metrics/BasicBarChartCard';

type PerformanceAverageCPMByStrategyTypeBasicBarChartCardProps = {
    loading?: boolean;
};

export default function PerformanceAverageCPMByStrategyTypeBasicBarChartCard(
    props: PerformanceAverageCPMByStrategyTypeBasicBarChartCardProps
) {
    const { loading = false } = props;
    const { performances } = useReportingDashboardPageContext();

    const [strategyType, setStrategyType] = useState<string>(() => {
        return Utils.localStorage('analytics.reporting.performanceAverageCPMByStrategyTypeBasicBarChart', 'Display');
    });

    const strategyTypes = useMemo((): string[] => {
        return ['Acquisition', 'Awareness', 'Inventory', 'FixedOps', 'Parts'];
    }, []);

    const seriesData = useMemo(() => {
        if (strategyTypes && strategyTypes.length) {
            return strategyTypes
                .map((_strategyType: string) => {
                    const serieDatum = {
                        x: _strategyType,
                        y: 0,
                    };

                    const strategyTypePerformances: Performance[] = performances.filter(
                        (p: Performance) => p.strategyType === _strategyType
                    );

                    if (strategyTypePerformances.length) {
                        serieDatum.y = strategyTypePerformances.reduce(
                            (total: number, p: Performance) => total + p.CPM,
                            0
                        );
                        serieDatum.y /= strategyTypePerformances.length;
                    }

                    return serieDatum;
                })
                .filter((serieDatum) => {
                    return serieDatum.y > 0;
                });
        }

        return [];
    }, [strategyTypes, performances]);

    const chartSeries = useMemo(() => {
        if (performances && performances.length) {
            return [
                {
                    name: 'Impressions',
                    data: seriesData.filter((d) => d.y > 0).map((d) => d.y),
                },
            ];
        }

        return [];
    }, [seriesData, performances]);

    useEffect(() => {
        localStorage.setItem('analytics.reporting.performanceAverageCPMByStrategyTypeBasicBarChart', strategyType);
    }, [strategyType]);

    useEffect(() => {
        if (strategyTypes && strategyTypes.length) {
            if (!strategyTypes.includes(strategyType)) {
                setStrategyType(strategyTypes[0]);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [strategyTypes]);

    // useEffect(() => {
    //     if (strategyType) {
    //         setPerformances(getPerformances(strategyType));
    //     } else {
    //         setPerformances([]);
    //     }
    // }, [getPerformances, strategyType]);

    return (
        <BasicBarChartCard
            loading={loading}
            label={
                <Row gap={1} alignItems="center" sx={{ mr: 0.5 }} width="auto">
                    <Typography variant="body1" color="text.primary">
                        Average CPM by Campaign Type
                    </Typography>
                </Row>
            }
            tooltip="Cost per thousand impressions/exposures per ad type"
            chartOptions={{
                dataLabels: {
                    formatter: (value: number, opts) => {
                        if (typeof value === 'number') {
                            return Utils.formatCurrency(value, 2) as string;
                        }

                        return value;
                    },
                },
                xaxis: {
                    categories: seriesData.map((d) => d.x),
                },
            }}
            chartSeries={chartSeries}
        />
    );
}
