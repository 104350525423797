import { ChangeEvent, useState } from 'react';
import { Button, Checkbox, FormControlLabel, Link, Paper, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Dealer } from '../../types/Dealer';
import ApiService from '../../ApiService';

export default function PolkMatchbackSetup(props: { dealer: Dealer }) {
    const { dealer } = props;
    const [isTermAccepted, setIsTermAccepted] = useState<boolean>(false);
    const navigate = useNavigate();

    function handleIsTermAccepted(event: any | ChangeEvent): void {
        setIsTermAccepted(event?.target?.checked);
    }

    const handleClick = () => {
        ApiService.participatePolkDealerDealers(dealer.id as number).then((response) => {
            navigate(`/advertisers/${dealer.id}#polkSales`);
        });
    };

    return (
        <Paper variant="outlined" sx={{ width: 660, p: 3 }}>
            <Stack gap={3}>
                <Stack
                    direction="column"
                    sx={{
                        px: 2,
                        py: 3,
                        textAlign: 'center',
                    }}
                >
                    <Typography variant="h6" sx={{ fontSize: 20 }}>
                        Assign Your First Advertiser or Competitor
                    </Typography>

                    <Typography variant="body2" align="left">
                        Begin leveraging Polk to gain valuable insights into advertiser and competitor sales.
                    </Typography>
                </Stack>

                <Stack direction="row" justifyContent="center" alignItems="center" sx={{ p: 3 }}>
                    <img
                        alt="Setup for Polk Reported Sales"
                        src="/Mascot_Astronaut_Floating_Laptop.png"
                        style={{ height: 169, width: 'max-content' }}
                    />
                </Stack>

                <FormControlLabel
                    control={<Checkbox onChange={handleIsTermAccepted} />}
                    label={
                        <>
                            By using Cognition's platform you are agreeing to our{' '}
                            <Link href="//www.cognitionads.com/terms-of-service" target="new" color="primary">
                                terms of service
                            </Link>
                            .
                        </>
                    }
                    sx={{ my: 3, mx: 0, px: 2 }}
                />

                <Stack direction="row" justifyContent="center" alignItems="center">
                    <Button type="submit" variant="contained" disabled={isTermAccepted === false} onClick={handleClick}>
                        Assign Advertiser(s)
                    </Button>
                </Stack>
            </Stack>
        </Paper>
    );
}
