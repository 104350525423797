import { useMemo, useState } from 'react';
import { Alert, AlertTitle, Box, Button, Link } from '@mui/material';
import CRMDataUploadRequirementDialog from './CRMDataUploadRequirementDialog';
import Row from '../../components/Row';

export type CRMDataUploadRequirementAlertProps = {
    format?: 'ftp' | 'web';
};

export default function CRMDataUploadRequirementAlert(props: CRMDataUploadRequirementAlertProps) {
    const { format = 'web' } = props;
    const [requirementDialogOpen, setRequirementDialogOpen] = useState<boolean>(false);

    const csvUrl = useMemo((): string => {
        switch (format) {
            case 'ftp':
                return '/resources/Cognition CSV TemplateForFTP.csv';
        }
        return '/resources/Cognition CSV Template.csv';
    }, [format]);

    const handleViewRequirementsClick = () => {
        if (format === 'ftp') {
            window.open(
                'https://cognitiondigital.atlassian.net/wiki/x/AgCiGg',
                '_blank'
            );
        } else {
            setRequirementDialogOpen(true);
        }
    };

    return (
        <>
            <Alert
                severity="info"
                sx={{
                    '& .MuiAlert-message': {
                        width: '100%',
                    },
                }}
            >
                <Row justifyContent="space-between" alignItems="center">
                    <AlertTitle>CSV Data & Format Requirements</AlertTitle>
                </Row>
                Please review these requirements before selecting your file. Both the quality and completeness of the
                data will significantly impact the rate at which events match back to an Amazon account.
                <Box sx={{ mt: 0.5 }}>
                    <Button color="info" variant="text" onClick={handleViewRequirementsClick}>
                        View Requirements
                    </Button>

                    <Button component={Link} color="info" variant="text" href={csvUrl} target="new">
                        Download CSV Template
                    </Button>
                </Box>
            </Alert>

            {requirementDialogOpen ? (
                <CRMDataUploadRequirementDialog
                    format={format}
                    open={requirementDialogOpen}
                    onClose={() => setRequirementDialogOpen(false)}
                />
            ) : (
                ''
            )}
        </>
    );
}
