import { Dealer } from '../../types/Dealer';
import { useContext, useState } from 'react';
import { Box, Tab, Tabs } from '@mui/material';
import AccessGuard, { hasAccess } from '../../components/AccessGuard';
import TabPanel from '../../components/TabPanel';
import PolkDealerMapping from './PolkDealerMapping';
import PolkAttributionStatus from './PolkAttributionStatus';
import { USER_GROUP_ADMIN } from '../../types/User';
import { UserContext } from '../../App';

export default function PolkSalesTab(props: { dealer: Dealer }) {
    const { dealer } = props;
    const [selectedTab, setSelectedTab] = useState(0);
    const { userContext } = useContext(UserContext);

    return (
        <>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={selectedTab} onChange={(event, value) => setSelectedTab(value)}>
                    <Tab value={0} label="Assign Advertisers" />
                    {hasAccess(userContext, USER_GROUP_ADMIN) && <Tab value={1} label="Attribution Results" />}
                </Tabs>
            </Box>

            <TabPanel value={selectedTab} index={0}>
                <PolkDealerMapping dealer={dealer} />
            </TabPanel>

            <AccessGuard accessGroup={USER_GROUP_ADMIN}>
                <TabPanel index={1} value={selectedTab}>
                    <PolkAttributionStatus dealer={dealer} />
                </TabPanel>
            </AccessGuard>
        </>
    );
}
