import { ChangeEvent, useEffect, useState } from 'react';
import { Vertical } from '../../types/Vertical';
import ApiService from '../../ApiService';
import { MenuItem, TextField } from '@mui/material';

export interface VerticalsProps {
    verticalId: number | undefined;
    onSelectVertical(verticalId: number): void;
    label?: string;
    error?: boolean;
    required: boolean;
    disabled?: boolean;

    variant?: 'filled' | 'outlined';

    shrinkLabel?: boolean;
}

export default function VerticalsComponent({
    disabled,
    error,
    label,
    onSelectVertical,
    required,
    variant = 'outlined',
    verticalId,
    shrinkLabel,
}: VerticalsProps) {
    const [verticals, setVerticals] = useState<Vertical[]>([]);

    useEffect(() => {
        ApiService.getVerticals()
            .then((response) => {
                setVerticals(response.data);
            })
            .catch(() => {});
    }, []);

    function handleChange(event: ChangeEvent<HTMLInputElement>) {
        const id = Number(event.target.value);
        onSelectVertical(id);
    }

    return (
        <>
            <div id="selectVertical">
                <TextField
                    variant={variant}
                    fullWidth={true}
                    size="small"
                    label={label ? label : disabled ? 'Vertical' : 'Select a Vertical'}
                    name="verticalId"
                    select
                    required={required}
                    disabled={disabled}
                    value={verticalId != null ? String(verticalId) : ''}
                    error={error}
                    helperText={error ? 'Vertical is required.' : ''}
                    onChange={handleChange}
                    SelectProps={{ native: false }}
                    InputLabelProps={{ shrink: shrinkLabel }}
                >
                    {verticals.map((vertical) => {
                        return (
                            <MenuItem key={String(vertical.id)} value={String(vertical.id)}>
                                {vertical.name}
                            </MenuItem>
                        );
                    })}
                </TextField>
            </div>
        </>
    );
}
