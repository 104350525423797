import { useEffect, useState } from 'react';
import ApiService from '../../../ApiService';
import { AmcDataSet } from '../../../types/AmcDataSet';
import CDGrid from '../../../components/CDGrid';
import { List, ListItem } from '@mui/material';
import Heading from '../../../components/Heading';
import Column from '../../../components/Column';

export default function AmcDataSetsTab(props: { amcInstanceId: number }) {
    const { amcInstanceId } = props;
    const [dataSets, setDataSets] = useState<AmcDataSet[]>([]);
    const [dataSet, setDataSet] = useState<AmcDataSet | null>(null);

    useEffect(() => {
        ApiService.getAmcDataSets(amcInstanceId).then((response) => {
            setDataSets(response.data);
        });
    }, [amcInstanceId]);
    return (
        <>
            <Column>
                <CDGrid
                    columns={[
                        {
                            field: 'dataSetId',
                            headerName: 'Data Set',
                            width: 200,
                        },
                        {
                            field: 'period',
                            headerName: 'Data Period',
                        },
                    ]}
                    onRowClick={(params) => setDataSet(params.row)}
                    getRowId={(params) => {
                        return params.dataSetId;
                    }}
                    rows={dataSets}
                />

                {dataSet && (
                    <>
                        <Heading>{dataSet.dataSetId}</Heading>
                        <List>
                            {dataSet.columns.map((column) => {
                                return (
                                    <ListItem key={column.name}>
                                        {column.name} {column.dataType}
                                    </ListItem>
                                );
                            })}
                        </List>
                    </>
                )}
            </Column>
        </>
    );
}
