import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { styled, tableCellClasses } from '@mui/material';
import {
    Alert,
    Box,
    Button,
    FormControl,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    InputLabelProps,
    MenuItem,
    Stack,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import { DataSetValue } from '../../types/DataSetValue';
import { SalesDataUpload } from '../../types/SalesDataUpload';
import ApiService from '../../ApiService';
import { SalesDataMapping, SalesDataMappingField } from '../../types/SalesDataMapping';
import { CheckCircle as CheckCircleIcon, InfoOutlined as InfoOutlinedIcon } from '@mui/icons-material';
import { MappedDataSummary } from '../../types/MappedDataSummary';
import { StyledTable } from '../../components/StyledTable';
import DateFormats from '../salesData/DateFormats';
import Column from '../../components/Column';
import theme from '../../theme';

import { parseDateFormat } from '../../hooks/useDateFormat';

const StyledInputLabel = styled(InputLabel)<InputLabelProps>(() => ({
    '&.MuiInputLabel-root': {
        fontSize: '14px',
        fontWeight: 500,
    },
}));

const StyledTableHeadCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.root}`]: {
        color: theme.palette.text.primary,
        fontWeight: 500,
    },
}));

export const DATE_FORMAT = 'M/d/yyyy';

export default function CRMDataUploadMapping(props: {
    salesDataUpload: SalesDataUpload;
    saveInProgress?: boolean;
    onSave: (saveProps?: any) => void;
    onValidate: (validated: boolean) => void;
}) {
    const { salesDataUpload, saveInProgress, onSave, onValidate } = props;

    enum Fields {
        EMAIL = 'Email',
        SALES_DATE = 'Sale Date',
        FULL_NAME = 'Full Name',
        FIRST_NAME = 'First Name',
        LAST_NAME = 'Last Name',
        PHONE_NUMBER = 'Primary Phone',
        PHONE_NUMBER_2 = 'Secondary Phone',
        PHONE_NUMBER_3 = 'Alternate Phone',
        MAKE = 'Make',
        MODEL = 'Model',
        INT1 = 'Integer 1',
        INT2 = 'Integer 2',
        INT3 = 'Integer 3',
        DECIMAL1 = 'Decimal 1',
        DECIMAL2 = 'Decimal 2',
        DECIMAL3 = 'Decimal 3',
        STRING1 = 'String 1',
        STRING2 = 'String 2',
        STRING3 = 'String 3',

        MAID = 'MAID',
        SEGMENT_ID = 'Segment ID',
        DEVICE_TYPE = 'Device Type',
        ADDRESS = 'Address',
        CITY = 'City',
        STATE = 'State',
        ZIPCODE = 'Zip Code',
    }

    type FieldsType = typeof Fields;
    type FieldsKeyType = keyof FieldsType;

    const [salesDataMapping, setSalesDataMapping] = useState<SalesDataMapping>({
        dealerId: 0,
        dateFormat: DATE_FORMAT,
        fields: [],
    });
    const [mappedDataSummary, setMappedDataSummary] = useState<MappedDataSummary>();
    const [sampleMappedData, setSampleMappedData] = useState<DataSetValue[]>([]);
    const [uploadedData, setUploadedData] = useState<string[][]>([]);

    const uploadedDataHeaderColumns = useMemo(() => {
        return uploadedData.length > 0 ? uploadedData[0] : [];
    }, [uploadedData]);

    const uploadedDataColumns = useMemo(() => {
        return uploadedData.length > 1 ? uploadedData[1] : [];
    }, [uploadedData]);

    // Data Set data contains Mobile Advertising ID data
    function isMAIDDataSet(): boolean {
        return salesDataUpload.crmDataType.indexOf('MAID') > -1;
    }

    // Only show the appropriate Field types based on the Data Set type
    function includeField(mappingField: SalesDataMappingField): boolean {
        const field = Fields[mappingField.fieldName as FieldsKeyType];
        if (isMAIDDataSet()) {
            return (
                field === Fields.SALES_DATE ||
                field === Fields.MAID ||
                field === Fields.SEGMENT_ID ||
                field === Fields.DEVICE_TYPE
            );
        } else {
            return field !== Fields.MAID && field !== Fields.SEGMENT_ID && field !== Fields.DEVICE_TYPE;
        }
    }

    const getMappedDataSummary = useCallback(
        (mapping: SalesDataMapping) => {
            ApiService.getMappedDataSummary(salesDataUpload.id, mapping)
                .then((response) => {
                    setMappedDataSummary(response.data);

                    let uploadedData = response.data.uploadedData;
                    setUploadedData(uploadedData);

                    let dataSetValues = response.data.dataSetValues;
                    if (dataSetValues.length > 0) {
                        let id = 0;
                        dataSetValues.forEach((r) => (r.id = id++));
                        setSampleMappedData(dataSetValues);
                    }
                })
                .catch(() => {});
        },
        [salesDataUpload.id]
    );

    const loadMapping = useCallback(() => {
        ApiService.getSalesDataMapping(salesDataUpload.id)
            .then((response) => {
                setSalesDataMapping(response.data);
                getMappedDataSummary(response.data);
            })
            .catch(() => {});
    }, [getMappedDataSummary, salesDataUpload.id]);

    useEffect(() => {
        loadMapping();
    }, [loadMapping]);

    function handleFieldPositionChange(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
        let fields = salesDataMapping.fields;
        fields.forEach((field) => {
            if (field.fieldPosition === +event.target.name) {
                field.fieldPosition = null;
            }
            if (field.fieldName === event.target.value) {
                if (event.target.value === '-1') {
                    field.fieldPosition = null;
                } else {
                    field.fieldPosition = +event.target.name;
                }
            }
        });
        let mapping = {
            ...salesDataMapping,
            dealerId: salesDataUpload.dealerId,
            fields: fields,
        };
        setSalesDataMapping(mapping);
        getMappedDataSummary(mapping);
    }

    function resetMapping() {
        salesDataMapping.fields.forEach((f) => (f.fieldPosition = null));

        ApiService.updateSalesDataMapping(salesDataMapping)
            .then(() => {
                loadMapping();
            })
            .catch(() => {});
    }

    // function handleDownload(salesDataUploadId: number) {
    //     ApiService.downloadSalesData(salesDataUploadId)
    //         .then((response) => {
    //             Utils.downloadFile(response, 'text/csv');
    //         })
    //         .catch(() => {});
    // }

    useEffect(() => {
        if (saveInProgress === true) {
            handleSave();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [saveInProgress]);

    function handleSave() {
        ApiService.updateSalesDataMapping(salesDataMapping)
            .then((response) => {
                setSalesDataMapping(response.data);
                loadMapping();
            })
            .catch(() => {});
        ApiService.updateSalesDataUpload(salesDataUpload)
            .then(() => {
                onSave({
                    salesDataMapping: salesDataMapping,
                    sampleMappedData: sampleMappedData,
                    mappedDataSummary: mappedDataSummary,
                });
            })
            .catch();
    }

    // const outOfRange: boolean = useMemo(() => {
    //     let _outOfRange = false;

    //     mappedDataSummary?.validations.dateRanges.forEach((dr) => {
    //         salesDataMapping.fields.forEach((f) => {
    //             if (f.fieldName === 'SALES_DATE') {
    //                 if (dr.fieldPosition === f.fieldPosition) {
    //                     _outOfRange = dr.outOfRange;
    //                 }
    //             }
    //         });
    //     });

    //     return _outOfRange;
    // }, [mappedDataSummary, salesDataMapping.fields]);

    const hasExcludedRows = useMemo((): boolean => {
        if (mappedDataSummary && mappedDataSummary.excludedRows > 0) {
            return mappedDataSummary.excludedRows < mappedDataSummary.totalRows;
        }
        return false;
    }, [mappedDataSummary]);

    const hasNotIncludedRows = useMemo((): boolean => {
        if (mappedDataSummary && mappedDataSummary.totalRows > 0) {
            return mappedDataSummary.excludedRows === mappedDataSummary.totalRows;
        }
        return false;
    }, [mappedDataSummary]);

    useEffect(() => {
        let validated = true;

        if (salesDataMapping && !salesDataMapping?.dateFormat) {
            validated = false;
        }

        if (hasNotIncludedRows) {
            validated = false;
        }

        onValidate(validated);
    }, [onValidate, salesDataMapping, hasNotIncludedRows]);

    useEffect(() => {
        let field: SalesDataMappingField | undefined = salesDataMapping.fields.find(
            (f) => f.fieldName === 'SALES_DATE'
        );

        if (field && field.fieldPosition) {
            let dateString: string | null | undefined = uploadedDataColumns[field.fieldPosition - 1];

            if (dateString) {
                dateString = dateString.split(' ')[0];

                let dateFormat: string | null | undefined = parseDateFormat(dateString);

                if (dateFormat && dateFormat.length) {
                    setSalesDataMapping({
                        ...salesDataMapping,
                        dateFormat: dateFormat,
                    });
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uploadedDataColumns]);

    return (
        <Grid container spacing={1}>
            <Grid item md={12}>
                <Column gap={3}>
                    <Grid container spacing={3}>
                        <Grid item md={4}>
                            <StyledInputLabel
                                required={true}
                                sx={{
                                    color: theme.palette.text.primary,
                                    marginBottom: theme.spacing(2),
                                }}
                            >
                                What format of date is used in the CSV file?
                            </StyledInputLabel>

                            <FormControl style={{ width: '250px' }}>
                                <TextField
                                    label="Date Format"
                                    required={true}
                                    size="small"
                                    value={salesDataMapping.dateFormat}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Tooltip placement="right" title={<DateFormats />}>
                                                    <IconButton>
                                                        <InfoOutlinedIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </InputAdornment>
                                        ),
                                    }}
                                    onChange={(event) => {
                                        if (salesDataMapping) {
                                            setSalesDataMapping({
                                                ...salesDataMapping,
                                                ...{
                                                    dateFormat: event.target.value,
                                                },
                                            });

                                            getMappedDataSummary({
                                                ...salesDataMapping,
                                                ...{
                                                    dateFormat: event.target.value,
                                                },
                                            });
                                        }
                                    }}
                                    sx={{
                                        '& .MuiInputLabel-asterisk': {
                                            display: 'none',
                                        },
                                    }}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item md={8} />
                    </Grid>

                    <Column gap={1}>
                        <Typography variant="subtitle2">
                            Map your CSV fields to AMC fields. It will improve the accuracy of your matchback results
                        </Typography>

                        {mappedDataSummary && hasExcludedRows && (
                            <Alert severity="warning">
                                {mappedDataSummary.excludedRows} out of {mappedDataSummary.totalRows} entries will be
                                excluded due to missing identifier(s) or activity date(s) being outside of the selected
                                date range. You can continue to upload but results may be limited.
                            </Alert>
                        )}
                        {mappedDataSummary && hasNotIncludedRows && (
                            <Alert severity="error">
                                No entries included due to missing identifier or activity date(s) are outside of the
                                selected date range. You cannot proceed to upload.
                            </Alert>
                        )}

                        <TableContainer>
                            <StyledTable
                                sx={{
                                    borderCollapse: 'inherit',
                                    borderColor: (theme) => theme.palette.divider,
                                    borderStyle: 'solid',
                                    borderWidth: 1,
                                    borderRadius: '8px',
                                    overflow: 'hidden',
                                }}
                            >
                                <TableHead>
                                    <TableRow>
                                        <StyledTableHeadCell>CSV Header Name</StyledTableHeadCell>
                                        <StyledTableHeadCell>Sample Data</StyledTableHeadCell>
                                        <StyledTableHeadCell>
                                            <Stack direction="row" spacing={0.5} alignItems="center">
                                                <span>AMC Field Name</span>
                                                <Tooltip
                                                    arrow
                                                    title="Assign the AMC Data Field that matches the CSV Header Name to map the data."
                                                    placement="right"
                                                >
                                                    <InfoOutlinedIcon fontSize="small" color="action" />
                                                </Tooltip>
                                            </Stack>
                                        </StyledTableHeadCell>
                                        <StyledTableHeadCell align="center">Mapping Status</StyledTableHeadCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {uploadedDataHeaderColumns.map((headerColumnName, index) => {
                                        let columnName: string = uploadedDataColumns[index];
                                        let value = salesDataMapping.fields.find((f) => f.fieldPosition === index);

                                        return (
                                            <TableRow key={`row-${index}`}>
                                                <TableCell>{headerColumnName}</TableCell>
                                                <TableCell>{columnName}</TableCell>
                                                <TableCell>
                                                    <TextField
                                                        variant="outlined"
                                                        fullWidth
                                                        size="small"
                                                        select
                                                        name={'' + index}
                                                        onChange={handleFieldPositionChange}
                                                        disabled={false}
                                                        value={value?.fieldName ? value?.fieldName : '-1'}
                                                        SelectProps={{
                                                            native: false,
                                                        }}
                                                    >
                                                        <MenuItem key={'-1'} value={'-1'}>
                                                            - Ignore -
                                                        </MenuItem>
                                                        {salesDataMapping.fields
                                                            .filter((f) => includeField(f))
                                                            .map((field, col) => {
                                                                return (
                                                                    <MenuItem key={col + ''} value={field.fieldName}>
                                                                        {Fields[field.fieldName as FieldsKeyType]}
                                                                    </MenuItem>
                                                                );
                                                            })}
                                                    </TextField>
                                                </TableCell>
                                                <TableCell align="center">
                                                    <CheckCircleIcon
                                                        color={value?.fieldName ? 'success' : 'disabled'}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </StyledTable>
                        </TableContainer>
                    </Column>

                    <Box>
                        <Button variant="contained" onClick={resetMapping} disabled={saveInProgress === true}>
                            Reset Mapping
                        </Button>
                    </Box>
                </Column>
            </Grid>
        </Grid>
    );
}
