import { SyntheticEvent, useEffect, useState } from 'react';
import { styled } from '@mui/material';
import { Box, Tab, Tabs } from '@mui/material';

import { SectionHeading } from '../../components/SectionHeading';
import { TabPanelProps } from '../../components/TabPanel';
import BaseTabPanel from '../../components/TabPanel';
import Printable from '../../components/Printable';
import Utils from '../../components/Utils';

import { usePrintContext } from '../../hooks/usePrint';
import AdvertiserMatchbackViews from './AdvertiserMatchbackViews';
import PolkMatchbackViews from './PolkMatchbackViews';

export const TABS = {
    SALES_ANALYSIS: 0,
    CAMPAIGN_PERFORMANCE: 1,
};

const TabPanel = styled((props: TabPanelProps) => {
    const { print } = usePrintContext();

    return (
        <BaseTabPanel
            BoxProps={{
                // Negative top margin offset to be inline with view button group
                sx: { mt: print ? 0 : -6.5 },
            }}
            {...props}
        />
    );
})(({ theme }) => ({}));

export default function PolkMatchbackDataTabs() {
    const [tab, setTab] = useState<number>(() => {
        return parseInt(Utils.localStorage('attribution.polkDataTab', TABS.SALES_ANALYSIS as any as string));
    });

    useEffect(() => {
        localStorage.setItem('attribution.polkDataTab', tab as any as string);
    }, [tab]);

    function handleChangeTab(_event: SyntheticEvent, value: any) {
        switch (value) {
            case TABS.CAMPAIGN_PERFORMANCE:
                localStorage.setItem('attribution.advertiserView', 'chart');
                break;

            case TABS.SALES_ANALYSIS:
                localStorage.setItem('attribution.polkView', 'chart');
                break;
        }

        setTab(value);
    }

    return (
        <>
            <Box
                sx={{
                    // borderBottom: 1,
                    // borderColor: 'divider',
                    mt: 2,
                }}
            >
                <Printable show={false}>
                    <Tabs value={tab} onChange={handleChangeTab}>
                        <Tab value={TABS.SALES_ANALYSIS} label="Sales Analysis" />
                        <Tab value={TABS.CAMPAIGN_PERFORMANCE} label="Campaign Performance" />
                    </Tabs>
                </Printable>
                <Printable show={true}>
                    <>
                        {tab === TABS.SALES_ANALYSIS && <SectionHeading divider={false}>Sales Analysis</SectionHeading>}
                        {tab === TABS.CAMPAIGN_PERFORMANCE && (
                            <SectionHeading divider={false}>Campaign Performance</SectionHeading>
                        )}
                    </>
                </Printable>
            </Box>

            <Box>
                <TabPanel index={TABS.SALES_ANALYSIS} value={tab}>
                    <PolkMatchbackViews heading={undefined} />
                </TabPanel>

                <TabPanel index={TABS.CAMPAIGN_PERFORMANCE} value={tab}>
                    <AdvertiserMatchbackViews heading={undefined} />
                </TabPanel>
            </Box>
        </>
    );
}
