import Column from '../../components/Column';

import PolkMatchbackChartMetrics from './PolkMatchbackChartMetrics';

export default function PolkMatchbackChartView() {
    return (
        <Column gap={3}>
            <PolkMatchbackChartMetrics />
        </Column>
    );
}
