import { createContext, useContext, useState } from 'react';

export class PrintContextValue {
    print: boolean = false;
    setPrint: Function = (print: boolean) => {};
}

export const PrintContext = createContext<PrintContextValue>(new PrintContextValue());

export function usePrintContext() {
    return useContext(PrintContext);
}

export default function usePrint() {
    const [print, setPrint] = useState<boolean>(false);

    return {
        print,
        setPrint,
    };
}
