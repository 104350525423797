import { useCallback, useEffect, useState } from 'react';
import { DealerImportSite } from '../../types/DealerImportSite';
import ApiService from '../../ApiService';
import CDGrid from '../../components/CDGrid';
import { GridColumns, GridRowParams } from '@mui/x-data-grid';
import { GridToolbarContainer } from '../../components/DataGrid';
import DealerImportSiteEditDialog from '../importSite/DealerImportSiteEditDialog';
import { Button, Tooltip } from '@mui/material';
import { AddOutlined, DoNotDisturbOutlined } from '@mui/icons-material';
import { Dealer } from '../../types/Dealer';

export interface DealerImportSitesProps {
    dealer?: Dealer;
}

export default function DealerImportSites(props: DealerImportSitesProps) {
    const { dealer } = props;

    const [dealerImportSites, setDealerImportSites] = useState<DealerImportSite[]>([]);
    const [dealerImportSite, setDealerImportSite] = useState<DealerImportSite | null>(null);
    const pageSize = 15;

    const getImportSites = useCallback(() => {
        if (dealer?.id) {
            ApiService.getImportSitesForDealer(dealer.id).then((response) => {
                loadDealerImportSites(response.data);
            });
        } else {
            ApiService.getDealerImportSites().then((response) => {
                loadDealerImportSites(response.data);
            });
        }
    }, [dealer]);

    useEffect(() => {
        getImportSites();
    }, [getImportSites]);

    function loadDealerImportSites(dealerImportSites: DealerImportSite[]) {
        let id = 0;
        const importSites = dealerImportSites;
        importSites.forEach((i) => (i.id = id++));
        setDealerImportSites(importSites);
    }

    function addImport() {
        let dealerImportSite = new DealerImportSite();
        if (dealer) {
            dealerImportSite.dealer = dealer;
            dealerImportSite.dealerId = dealer.id;
        }
        setDealerImportSite(dealerImportSite);
    }

    function handleEdit(params: GridRowParams) {
        setDealerImportSite(params.row as DealerImportSite);
    }

    function closeDialog(saved: boolean) {
        setDealerImportSite(null);
        if (saved) {
            getImportSites();
        }
    }

    const columns: GridColumns = [
        {
            width: 120,
            field: 'isActive',
            headerName: 'Active',
            renderCell: (params) => {
                if (params.row.isActive) {
                    return <></>;
                } else {
                    return (
                        <Tooltip title="Not Active">
                            <DoNotDisturbOutlined />
                        </Tooltip>
                    );
                }
            },
        },
        {
            width: 330,
            field: 'advertiserName',
            headerName: 'Advertiser',
            valueGetter: (params) => {
                return params.row.dealer.dealerName;
            },
        },
        {
            width: 250,
            field: 'importName',
            headerName: 'Import Site',
            valueGetter: (params) => {
                return params.row.importSite.importName;
            },
            renderCell: (params) => {
                return (
                    <Button
                        sx={{ textDecoration: 'underline' }}
                        onClick={() => {
                            handleEdit(params.row);
                        }}
                    >
                        {params.row.importSite.importName}
                    </Button>
                );
            },
        },
        {
            width: 230,
            field: 'importDealerKey',
            headerName: 'Import Key/Filename',
            filterable: false,
            sortable: false,
            disableColumnMenu: true,
            flex: 1,
        },
    ];

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <div style={{ flexGrow: 1 }} />
                <Tooltip title="Add a new Import Site">
                    <Button
                        sx={{ margin: '5px' }}
                        onClick={addImport}
                        variant="contained"
                        color="primary"
                        startIcon={<AddOutlined />}
                    >
                        Add
                    </Button>
                </Tooltip>
            </GridToolbarContainer>
        );
    }

    return (
        <>
            {dealerImportSite != null && (
                <DealerImportSiteEditDialog dealerImportSite={dealerImportSite} onClose={closeDialog} />
            )}
            <CDGrid
                columns={columns}
                rows={dealerImportSites}
                components={{ Toolbar: CustomToolbar }}
                pageSize={pageSize}
                rowsPerPage={[15, 25, 50]}
                onRowClick={handleEdit}
            />
        </>
    );
}
