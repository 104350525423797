import { useCallback, useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import { Box, Chip } from '@mui/material';
import { styled } from '@mui/material/styles';
import ApiService from '../ApiService';
import Masonry from '@mui/lab/Masonry';
import Paper from '@mui/material/Paper';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import { AdCreative, AssetFileMetadata } from '../types/AmazonAds';

export interface AdvertiserAdComponentProps {
    dealerId?: number;
    disabled?: boolean;
}

export default function AdvertiserAdComponent(props: AdvertiserAdComponentProps) {
    const [selectedDate, setSelectedDate] = useState<Dayjs>(dayjs());
    const [adCreativeList, setAdCreativeList] = useState<AdCreative[]>([]);

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(0.5),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }));

    const fetchAdCreatives = useCallback(
        (dealerId: number) => {
            ApiService.getDealerDspAdCreatives(dealerId)
                .then((response) => {
                    const creatives: AdCreative[] = [];
                    response.data.forEach((creative: AdCreative) => {
                        if (!selectedDate || selectedDate.isBefore(creative.createdDateTime)) {
                            creative.assets.forEach((asset) => {
                                const parsedMetadata: AssetFileMetadata = JSON.parse(asset.fileMetadata);
                                asset.fileMetadataObject = parsedMetadata;
                            });
                            creatives.push(creative);
                        }
                    });
                    setAdCreativeList(creatives);
                })
                .catch(() => {});
        },
        [selectedDate]
    );

    useEffect(() => {
        if (props.dealerId) {
            fetchAdCreatives(props.dealerId);
        }
    }, [props.dealerId, fetchAdCreatives]);

    return (
        <Box>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                    label={'Campaign Month'}
                    views={['month', 'year']}
                    onChange={function (value: unknown, keyboardInputValue?: string): void {
                        setSelectedDate(value as Dayjs);
                    }}
                    value={selectedDate}
                    renderInput={(props) => <TextField {...props} />}
                />
            </LocalizationProvider>
            <Box sx={{ m: 5 }} />
            <Masonry columns={3} spacing={2} defaultSpacing={1}>
                {adCreativeList.map((adCreative, index) => (
                    <Item key={index}>
                        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
                            <Chip
                                sx={{ backgroundColor: 'green', color: 'white', marginRight: 2, fontWeight: 'bold' }}
                                label={adCreative.assets[0].assetStatus}
                            />
                            <div style={{ fontWeight: 'bold' }}>
                                {adCreative.assets[0].fileMetadataObject.contentType === 'video/mp4'
                                    ? `${adCreative.assets[0].fileMetadataObject.resolutionWidth}x${adCreative.assets[0].fileMetadataObject.resolutionHeight}`
                                    : `${adCreative.assets[0].fileMetadataObject.width}x${adCreative.assets[0].fileMetadataObject.height}`}
                            </div>
                        </Box>
                        {adCreative.assets[0].fileMetadataObject.contentType === 'video/mp4' ? (
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    width: '100%',
                                    maxHeight: '320px',
                                    overflow: 'hidden',
                                }}
                            >
                                <video
                                    style={{
                                        maxWidth: '100%',
                                        maxHeight: '100%',
                                    }}
                                    controls
                                >
                                    <source
                                        src={adCreative.assets[0].url}
                                        type={adCreative.assets[0].fileMetadataObject.contentType}
                                    />
                                    Your browser does not support the video tag.
                                </video>
                            </Box>
                        ) : (
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    width: adCreative.assets[0].fileMetadataObject.width,
                                    height: adCreative.assets[0].fileMetadataObject.height,
                                    overflow: 'hidden',
                                }}
                            >
                                <img
                                    src={adCreative.assets[0].url}
                                    alt="Creative asset"
                                    style={{
                                        width: adCreative.assets[0].fileMetadataObject.width,
                                        height: adCreative.assets[0].fileMetadataObject.height,
                                    }}
                                />
                            </Box>
                        )}
                    </Item>
                ))}
            </Masonry>
        </Box>
    );
}
