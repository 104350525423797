import { useContext } from 'react';
import { FormControl } from '@mui/material';

import { Agency } from '../../../../types/Agency';
import { Dealer } from '../../../../types/Dealer';
import { UserContext } from '../../../../App';
import { ReportingCriteria } from '../../../../types/Analytics/Reporting/ReportingCriteria';
import { useReportingDashboardPageContext } from '../../../../hooks/useReportingDashboardPage';
import AgencyAutocomplete from '../../../../components/AgencyAutocomplete';
import DealerAutocomplete from '../../../../components/DealerAutocomplete';
import Row from '../../../../components/Row';
import ReportingCriteriaDataRangePicker from './ReportingCriteriaDataRangePicker';

type ReportingCriteriaFormProps = {
    reportingCriteria: ReportingCriteria;
    onChangeReportingCriteria: (reportingCriteria: ReportingCriteria) => void;
    onFetchAgencies?: (agencies: Agency[]) => void;
    onFetchingAgencies?: () => void;
    onFetchDealers?: (dealers: Dealer[]) => void;
    onFetchingDealers?: () => void;
};

export default function ReportingCriteriaForm(props: ReportingCriteriaFormProps) {
    const {
        reportingCriteria,
        onChangeReportingCriteria,
        onFetchAgencies,
        onFetchingAgencies,
        onFetchDealers,
        onFetchingDealers,
    } = props;

    const { userContext } = useContext(UserContext);
    const { defaultMinDate, defaultMaxDate } = useReportingDashboardPageContext();

    return (
        <Row>
            {userContext.isAdmin() && (
                <FormControl
                    sx={{
                        '&': {
                            width: '100%',
                        },
                        '& .MuiAutocomplete-root': {
                            width: '100%',
                        },
                    }}
                >
                    <AgencyAutocomplete
                        multiple={true}
                        label="Agency"
                        required={false}
                        openOnFocus={true}
                        value={reportingCriteria.agencyIds}
                        onChange={(event, options, reason) => {
                            onChangeReportingCriteria({
                                ...reportingCriteria,
                                agencyIds: options.map((agency: Agency) => agency.id as number),
                            });
                        }}
                        onFetch={onFetchAgencies}
                        onFetching={onFetchingAgencies}
                    />
                </FormControl>
            )}

            <FormControl
                sx={{
                    '&': {
                        width: '100%',
                    },
                    '& .MuiAutocomplete-root': {
                        width: '100%',
                    },
                }}
            >
                <DealerAutocomplete
                    multiple={true}
                    label="Advertiser"
                    required={false}
                    openOnFocus={true}
                    agencies={reportingCriteria.agencyIds}
                    value={reportingCriteria.dealerIds}
                    onChange={(event, options, reason) => {
                        onChangeReportingCriteria({
                            ...reportingCriteria,
                            dealerIds: options.map((dealer: Dealer) => dealer.id as number),
                        });
                    }}
                    onFetch={onFetchDealers}
                    onFetching={onFetchingDealers}
                />
            </FormControl>

            <FormControl fullWidth>
                <ReportingCriteriaDataRangePicker
                    minDate={defaultMinDate}
                    maxDate={defaultMaxDate}
                    startDate={reportingCriteria.startDate}
                    endDate={reportingCriteria.endDate}
                    onChange={(startDate?: Date, endDate?: Date): void => {
                        const isFilled: boolean = startDate !== undefined && endDate !== undefined;
                        if (isFilled) {
                            onChangeReportingCriteria({
                                ...reportingCriteria,
                                startDate: startDate ? startDate : reportingCriteria.startDate,
                                endDate: endDate ? endDate : reportingCriteria.endDate,
                            });
                        }
                    }}
                />
            </FormControl>
        </Row>
    );
}
