import { alpha, autocompleteClasses } from '@mui/material';
import { navBackground } from '../theme';
import theme from '../theme';

export function getStyles(isHeader?: boolean) {
    return isHeader
        ? {
              backgroundColor: navBackground,
              '& .MuiSvgIcon-root': {
                  color: alpha(theme.palette.common.white, 0.7),
              },
              '& .MuiOutlinedInput-notchedOutline': {
                  borderWidth: '1px',
                  borderStyle: 'solid',
                  borderColor: alpha(theme.palette.common.white, 0.7),
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: `${theme.palette.common.white} !important`,
              },
              '& .MuiFormLabel-root, .MuiInputLabel-root': {
                  color: alpha(theme.palette.common.white, 0.7),
              },
              [`&.${autocompleteClasses.hasClearIcon}`]: {
                  '& .MuiSvgIcon-root': {
                      color: alpha(theme.palette.common.white, 0.7),
                  },
                  '& .MuiInputBase-root': {
                      color: theme.palette.common.white,
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: alpha(theme.palette.common.white, 0.7),
                  },
              },
              [`& .${autocompleteClasses.focused}`]: {
                  border: 'none',
                  color: theme.palette.common.white,

                  '& .MuiSvgIcon-root': {
                      color: theme.palette.common.white,
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                      borderWidth: '2px !important',
                  },
              },
              color: theme.palette.common.white,
              borderRadius: '8px',
          }
        : {
              // backgroundColor: '#ffffff',
              color: '#000000',
              '& .MuiSvgIcon-root': {
                  color: '#000000',
              },
              width: '300px',
              borderRadius: '8px',
          };
}
