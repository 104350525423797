import { ChangeEvent, FormEvent, useEffect, useMemo, useState } from 'react';
import { AlertColor, Box, Button, Chip, Divider, FormControl, Grid, MenuItem, Stack, TextField } from '@mui/material';
import ApiService from '../../ApiService';
import { AmcAttribution } from '../../types/AmcAttribution';
import { InfoMessage, InfoMessageProps } from '../../components/InfoMessage';
import DealerSelector from '../../components/DealerSelector';
import { DataSet } from '../../types/DataSet';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { CloseOutlined, DeleteOutlined, PublishOutlined, SaveOutlined } from '@mui/icons-material';
import ConfirmDialog, { ConfirmDialogProps } from '../../components/ConfirmDialog';
import { useNavigate, useParams } from 'react-router-dom';

type AmcAttributionFormProps = {
    save?: boolean;
    size?: 'lg' | 'md' | 'sm';
    hideActions?: boolean;
    amcAttribution: AmcAttribution;
    onValidate?: (validated: boolean) => void;
    onSave: (amcAttribution: AmcAttribution) => void;
};

export default function AmcAttributionForm(props: AmcAttributionFormProps) {
    const { onValidate } = props;

    const navigate = useNavigate();
    const params = useParams();
    const id = params.id ? +params.id : null;
    const [amcAttribution, setAmcAttribution] = useState<AmcAttribution>(new AmcAttribution());
    const [dataSets, setDataSets] = useState<DataSet[]>([]);
    const [infoMessage, setInfoMessage] = useState<InfoMessageProps>({
        message: null,
    });
    const [confirmDialogProps, setConfirmDialogProps] = useState<ConfirmDialogProps | null>(null);

    const validated: boolean | undefined = useMemo(() => {
        if (amcAttribution) {
            if (!amcAttribution.dataSetName) {
                return false;
            }

            if (!amcAttribution.recordsSubmitted) {
                return false;
            }

            if (!amcAttribution.matchedAmazonRecords) {
                return false;
            }

            if (!amcAttribution.attributedUserRecords) {
                return false;
            }

            if (!amcAttribution.adBudget) {
                return false;
            }

            if (!amcAttribution.adSpend) {
                return false;
            }

            if (!amcAttribution.gross) {
                return false;
            }

            return true;
        }

        return false;
    }, [amcAttribution]);

    useEffect(() => {
        if (props.save === true) {
            submit();
        }
    }, [props.save]);

    useEffect(() => {
        if (onValidate) {
            onValidate(validated);
        }
    }, [onValidate, validated]);

    const showActions: boolean | undefined = useMemo(() => {
        return props.hideActions ? false : true;
    }, [props.hideActions]);

    // const isEditing: boolean | undefined = useMemo(() => {
    //     return amcAttribution.id !== undefined && amcAttribution.id > 0;
    // }, [amcAttribution]);

    function submit() {
        ApiService.updateAmcAttribution(amcAttribution)
            .then((response) => {
                showInfoMessage('success', 'Attribution data has been updated.');
                setAmcAttribution(response.data);

                props.onSave({ ...response.data });

                setTimeout(() => {
                    navigate(-1);
                }, 1500);
            })
            .catch((error) => {
                showInfoMessage('error', error.response.data);
            });
    }

    function showInfoMessage(alertColor: AlertColor, message: string) {
        setInfoMessage({
            message: message,
            severity: alertColor,
            onClose: () => {
                setInfoMessage({ message: null });
            },
        });
    }

    function handleSubmit(event: FormEvent<HTMLFormElement>) {
        event.preventDefault();
        submit();
    }

    function setStatus(status: string) {
        let amcAttrib = { ...amcAttribution, status: status };
        setAmcAttribution(amcAttrib);
        ApiService.updateAmcAttribution(amcAttrib)
            .then((response) => {
                setAmcAttribution(response.data);
            })
            .catch(() => {});
    }

    function handleChange(event: ChangeEvent<HTMLInputElement>) {
        const field = event.target.name;
        setAmcAttribution({ ...amcAttribution, [field]: event.target.value });
    }

    function selectDealer(dealerId: number | null) {
        setAmcAttribution({ ...amcAttribution, dealerId: dealerId });
    }

    function showDeleteConfirm() {
        setConfirmDialogProps({
            title: 'Delete this Attribution record ?',
            text: '',
            onOk: () => deleteAttribution(),
            okText: 'Delete',
            okColor: 'error',
            okIcon: <DeleteOutlined />,
            onCancel: () => hideConfirm(),
        });
    }

    function hideConfirm() {
        setConfirmDialogProps(null);
    }

    function deleteAttribution() {
        hideConfirm();
        if (amcAttribution.id) {
            ApiService.deleteAmcAttribution(amcAttribution.id)
                .then(() => {
                    showInfoMessage('success', 'Attribution record has been deleted.');
                    setTimeout(() => {
                        navigate(-1);
                    }, 1500);
                })
                .catch(() => {});
        }
    }

    useEffect(() => {
        if (id) {
            ApiService.getAmcAttribution(id).then((response) => {
                setAmcAttribution(response.data);
            });
        }
        ApiService.getDataSets()
            .then((response) => {
                setDataSets(response.data);
            })
            .catch(() => {});
    }, [id]);

    return (
        <Box
            sx={{
                '& .row': {
                    cursor: 'pointer',
                },
            }}
        >
            <InfoMessage {...infoMessage} />

            {confirmDialogProps && (
                <>
                    <ConfirmDialog
                        title={confirmDialogProps.title}
                        text={confirmDialogProps.text}
                        okText={confirmDialogProps.okText}
                        okIcon={confirmDialogProps.okIcon}
                        okColor={confirmDialogProps.okColor}
                        onCancel={confirmDialogProps.onCancel}
                        onOk={confirmDialogProps.onOk}
                    />
                </>
            )}

            <Box sx={{ minHeight: '100%', p: 1 }}>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    label="Date"
                                    value={amcAttribution.date}
                                    onChange={(newValue) => {
                                        if (newValue !== null) {
                                            setAmcAttribution({
                                                ...amcAttribution,
                                                date: newValue,
                                            });
                                        }
                                    }}
                                    renderInput={(params) => <TextField size="small" {...params} />}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={2}>
                            <Chip
                                label={amcAttribution.status}
                                size="small"
                                variant={amcAttribution.status === 'Published' ? 'filled' : 'outlined'}
                                color={amcAttribution.status === 'Published' ? 'success' : 'warning'}
                            />
                        </Grid>
                        <Grid item xs={6} />

                        <Grid item xs={4}>
                            <DealerSelector dealerId={amcAttribution.dealerId} dealerSelected={selectDealer} />
                        </Grid>
                        <Grid item xs={8} />

                        <Grid item xs={4}>
                            <TextField
                                name="campaignName"
                                label="Campaign Name"
                                value={amcAttribution.campaignName}
                                required={false}
                                fullWidth={true}
                                size="small"
                                variant="outlined"
                                onChange={handleChange}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                        <Grid item xs={8} />

                        <Grid item md={2} sx={{ marginBottom: '10px' }}>
                            <FormControl style={{ width: '320px' }}>
                                <TextField
                                    label="Data Type"
                                    select
                                    required={true}
                                    size="small"
                                    SelectProps={{ native: false }}
                                    variant="outlined"
                                    value={amcAttribution.dataSetName}
                                    onChange={(event) => {
                                        setAmcAttribution({
                                            ...amcAttribution,
                                            dataSetName: event.target.value,
                                        });
                                    }}
                                >
                                    {dataSets.map((dataSet) => {
                                        return (
                                            <MenuItem key={dataSet.name} value={dataSet.name}>
                                                {dataSet.name}
                                            </MenuItem>
                                        );
                                    })}
                                </TextField>
                            </FormControl>
                        </Grid>
                        <Grid item md={10} />

                        <Grid item xs={3}>
                            <TextField
                                name="recordsSubmitted"
                                label="Records Submitted"
                                value={amcAttribution.recordsSubmitted}
                                required={true}
                                fullWidth={true}
                                size="small"
                                type="number"
                                variant="outlined"
                                onChange={handleChange}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>

                        <Grid item xs={3}>
                            <TextField
                                name="matchedAmazonRecords"
                                label="Matched Amazon Records"
                                value={amcAttribution.matchedAmazonRecords}
                                required={true}
                                fullWidth={true}
                                size="small"
                                type="number"
                                variant="outlined"
                                onChange={handleChange}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>

                        <Grid item xs={3}>
                            <TextField
                                name="attributedUserRecords"
                                label="Attributed User Records"
                                value={amcAttribution.attributedUserRecords}
                                required={true}
                                fullWidth={true}
                                size="small"
                                type="number"
                                variant="outlined"
                                onChange={handleChange}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                        <Grid item xs={3} />

                        <Grid item xs={3}>
                            <TextField
                                name="adBudget"
                                label="Ad Budget"
                                value={amcAttribution.adBudget}
                                required={true}
                                fullWidth={true}
                                size="small"
                                type="number"
                                variant="outlined"
                                onChange={handleChange}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>

                        <Grid item xs={3}>
                            <TextField
                                name="adSpend"
                                label="Ad Spend"
                                value={amcAttribution.adSpend}
                                required={true}
                                fullWidth={true}
                                size="small"
                                type="number"
                                variant="outlined"
                                onChange={handleChange}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>

                        <Grid item xs={3}>
                            <TextField
                                name="gross"
                                label="Gross Revenue"
                                value={amcAttribution.gross}
                                required={true}
                                fullWidth={true}
                                size="small"
                                type="number"
                                variant="outlined"
                                onChange={handleChange}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                    </Grid>

                    {showActions && (
                        <>
                            <Divider />

                            <Grid container spacing={2}>
                                <Grid item md={12} style={{ marginTop: '30px' }}></Grid>
                                <Grid item xs={12}>
                                    <Stack direction="row" spacing={2}>
                                        <Button
                                            color="primary"
                                            type="submit"
                                            variant="contained"
                                            startIcon={<SaveOutlined />}
                                        >
                                            Save
                                        </Button>
                                        <Button
                                            variant="outlined"
                                            onClick={() => navigate(-1)}
                                            color="error"
                                            startIcon={<CloseOutlined />}
                                        >
                                            Cancel
                                        </Button>
                                        <div style={{ marginLeft: '40px' }} />
                                        {amcAttribution.status !== 'Published' && (
                                            <Button
                                                variant="outlined"
                                                onClick={() => setStatus('Published')}
                                                color="primary"
                                                startIcon={<PublishOutlined />}
                                            >
                                                Publish
                                            </Button>
                                        )}
                                        {amcAttribution.status === 'Published' && (
                                            <Button
                                                variant="outlined"
                                                onClick={() => setStatus('Pending')}
                                                color="error"
                                                startIcon={<PublishOutlined />}
                                            >
                                                Un-Publish
                                            </Button>
                                        )}
                                        <div style={{ flex: 1 }} />
                                        <Button
                                            startIcon={<CloseOutlined />}
                                            onClick={showDeleteConfirm}
                                            variant="contained"
                                            disabled={!amcAttribution.id}
                                            color="error"
                                        >
                                            Delete
                                        </Button>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </>
                    )}
                </form>
            </Box>
        </Box>
    );
}
