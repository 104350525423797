import { Skeleton, Tooltip, Typography } from '@mui/material';
import { HelpOutlineOutlined as HelpOutlineOutlinedIcon } from '@mui/icons-material';

import Printable from '../../../../components/Printable';
import Row from '../../../../components/Row';

interface ReportingActiveAdvertiserLabelProps {
    activeAdvertiserCount: number;
    totalAdvertiserCount: number;
    loading?: boolean;
}

export default function ReportingAdvertiserCountLabel(props: ReportingActiveAdvertiserLabelProps) {
    const { activeAdvertiserCount = 0, totalAdvertiserCount = 0, loading = false } = props;

    return (
        <Row gap={0.5} alignItems="center" width="auto">
            <Typography component="span" variant="body1" color="text.secondary">
                Active Advertisers
            </Typography>

            {loading ? (
                <Row gap={0.5} width="auto">
                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={40} />

                    <Typography component="span" variant="body1" color="text.primary">
                        {`/`}
                    </Typography>

                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={40} />
                </Row>
            ) : (
                <Typography component="span" variant="body1" color="text.primary">
                    {`${activeAdvertiserCount}/${totalAdvertiserCount}`}
                </Typography>
            )}

            <Printable show={false}>
                <Tooltip title="The report shows data only for active Advertisers — those with available data. Advertisers not included have no data for various reasons">
                    <HelpOutlineOutlinedIcon color="action" sx={{ fontSize: 16 }} />
                </Tooltip>
            </Printable>
        </Row>
    );
}
