import { StepButton, StepLabel } from '@mui/material';
import { CheckCircle, WarningOutlined } from '@mui/icons-material';
import theme from '../../../theme';

export default function StepHeader(props: {
    id: number | undefined;
    label: string;
    stepNumber: number;
    activeStep: number;
    stepError: number;
    stepWarning: boolean;
    setActiveStep(step: number): void;
}) {
    const { id, label, stepNumber, activeStep, stepError, stepWarning, setActiveStep } = props;

    return (
        <>
            {id ? (
                <StepButton onClick={() => setActiveStep(stepNumber)}>{label}</StepButton>
            ) : (
                <StepLabel
                    className={stepWarning ? 'stepWarning' : ''}
                    sx={{
                        '& .Mui-error .MuiBadge-badge': {
                            backgroundColor: 'error.main.light',
                        },
                        '& .Mui-completed .MuiBadge-badge': {
                            backgroundColor: 'success.main',
                            color: theme.palette.success.contrastText,
                        },
                        '& .Mui-active .MuiBadge-badge': {
                            backgroundColor: 'primary.main',
                            color: theme.palette.success.contrastText,
                        },
                        '& .MuiBadge-badge': {
                            backgroundColor: 'action.disabled',
                            color: theme.palette.success.contrastText,
                        },
                        '& .MuiStepLabel-label.Mui-completed .stepWarning ': {
                            color: 'warning.main',
                        },
                        '& .MuiStepLabel-label.Mui-completed': {
                            color: 'success.main',
                        },
                        '& .MuiSvgIcon-root.Mui-completed': {
                            color: 'success.main',
                        },
                    }}
                    error={stepError === stepNumber}
                    icon={
                        stepError === stepNumber || stepWarning ? (
                            <WarningOutlined fontSize="small" color={stepWarning ? 'warning' : 'error'} />
                        ) : activeStep > stepNumber ? (
                            <CheckCircle color="success" />
                        ) : (
                            <span
                                className="MuiBadge-badge"
                                style={{
                                    height: '24px',
                                    width: '24px',
                                    paddingTop: '3px',
                                    paddingLeft: '8px',
                                    marginRight: '2px',
                                    fontSize: '0.8em',
                                    borderRadius: '50%',
                                }}
                            >
                                {stepNumber + 1}
                            </span>
                        )
                    }
                >
                    <span className={stepWarning ? 'stepWarning' : ''}>{label}</span>
                </StepLabel>
            )}
        </>
    );
}
