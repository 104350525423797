import Heading from '../../components/Heading';
import { useEffect, useState } from 'react';
import ApiService from '../../ApiService';
import { OemOffer, OemOfferStatusCount } from '../../types/OemOffer';
import { Chip, Stack, ToggleButton, ToggleButtonGroup } from '@mui/material';
import theme from '../../theme';
import Column from '../../components/Column';
import OemOffersGrid from './OemOffersGrid';
import OemOfferDetails from './OemOfferDetails';

export default function OemOfferPage() {
    const [oemOffers, setOemOffers] = useState<OemOffer[]>([]);
    const [oemOffer, setOemOffer] = useState<OemOffer | null>(null);
    const [oemOfferStatusCounts, setOemOfferStatusCounts] = useState<OemOfferStatusCount[]>([]);
    const [offerStatus, setOfferStatus] = useState('New');

    useEffect(() => {
        getStatusCounts();
    }, []);

    useEffect(() => {
        getOffers();
    }, [offerStatus]);

    function getStatusCounts() {
        ApiService.getOemOfferStatusCounts().then((response) => {
            setOemOfferStatusCounts(response.data);
        });
    }

    function getOffers() {
        setOemOffer(null);
        ApiService.getOemOffers(offerStatus).then((response) => {
            setOemOffers(response.data);
        });
    }

    function refreshOffers() {
        getStatusCounts();
        getOffers();
    }

    return (
        <>
            <Heading>OEM Offers</Heading>
            <Column>
                <ToggleButtonGroup
                    size="small"
                    value={offerStatus}
                    exclusive
                    onChange={(event, value) => {
                        setOfferStatus(value);
                    }}
                >
                    {oemOfferStatusCounts.map((statusCount, index) => {
                        return (
                            <ToggleButton
                                key={index}
                                sx={{
                                    textTransform: 'none',
                                    padding: '10px',
                                    minWidth: '110px',
                                }}
                                disableRipple
                                size="small"
                                selected={statusCount.offerStatus === offerStatus}
                                color={'primary'}
                                value={statusCount.offerStatus}
                            >
                                <Stack direction="column" spacing={1}>
                                    <span
                                        style={{
                                            color: theme.palette.text.secondary,
                                            fontWeight: theme.typography.fontWeightMedium,
                                        }}
                                    >
                                        {statusCount.offerStatus}
                                    </span>
                                    <span style={{}}>
                                        <Chip
                                            sx={{
                                                fontWeight: theme.typography.fontWeightMedium,
                                            }}
                                            size="medium"
                                            variant="filled"
                                            color={statusCount.count > 0 ? 'primary' : 'default'}
                                            label={statusCount.count}
                                        />
                                    </span>
                                </Stack>
                            </ToggleButton>
                        );
                    })}
                </ToggleButtonGroup>

                <OemOffersGrid
                    oemOffers={oemOffers}
                    offerStatus={offerStatus}
                    onClick={(oemOffer: OemOffer) => {
                        setOemOffer(oemOffer);
                    }}
                    refresh={() => {
                        refreshOffers();
                    }}
                />
                {oemOffer && <OemOfferDetails oemOffer={oemOffer} onClose={() => setOemOffer(null)} />}
            </Column>
        </>
    );
}
