import { forwardRef, useCallback, useEffect, useMemo, useState } from 'react';
import {
    Backdrop,
    Box,
    CircularProgress,
    Table,
    TableBody,
    TableContainer,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    Paper,
} from '@mui/material';
import { TableVirtuoso, TableComponents } from 'react-virtuoso';
import axios, { CancelTokenSource } from 'axios';

import { AppBySpendBySiteNameSummary } from '../../../../../types/Analytics/AppBySpendSummary';
import { AppBySpendSummaryListCriteria } from '../../../../../types/Analytics/AppBySpendSummaryListCriteria';
import { ReportingCriteria } from '../../../../../types/Analytics/Reporting/ReportingCriteria';
import ApiService from '../../../../../ApiService';
import Column from '../../../../../components/Column';
import Utils from '../../../../../components/Utils';

const VirtuosoTableComponents: TableComponents<AppBySpendBySiteNameSummary> = {
    Scroller: forwardRef<HTMLDivElement>((props, ref) => <TableContainer component={Box} {...props} ref={ref} />),
    Table: (props) => <Table {...props} sx={{ borderCollapse: 'separate', tableLayout: 'fixed' }} />,
    TableHead: forwardRef<HTMLTableSectionElement>((props, ref) => <TableHead {...props} ref={ref} />),
    TableRow,
    TableBody: forwardRef<HTMLTableSectionElement>((props, ref) => <TableBody {...props} ref={ref} />),
};

function fixedHeaderContent() {
    return (
        <TableRow>
            {columns.map((column) => (
                <TableCell
                    key={column.dataKey}
                    variant="head"
                    align={column.numeric || false ? 'right' : 'left'}
                    style={{ width: column.width }}
                    sx={{ backgroundColor: 'background.paper' }}
                >
                    {column.label}
                </TableCell>
            ))}
        </TableRow>
    );
}

function rowContent(_index: number, row: AppBySpendBySiteNameSummary) {
    return (
        <>
            {columns.map((column) => (
                <TableCell key={column.dataKey} align={column.numeric || false ? 'right' : 'left'}>
                    {column?.render ? column.render(column, row) : row[column.dataKey]}
                </TableCell>
            ))}
        </>
    );
}

interface ColumnData {
    dataKey: keyof AppBySpendBySiteNameSummary;
    label: string;
    numeric?: boolean;
    width: number;
    render?: (column: ColumnData, row: AppBySpendBySiteNameSummary) => any;
}

const columns: ColumnData[] = [
    {
        width: 180,
        label: 'Property Name',
        dataKey: 'siteName',
    },
    {
        width: 160,
        label: 'Total Spend by Property',
        dataKey: 'totalCost',
        numeric: true,
        render: (column: ColumnData, row: AppBySpendBySiteNameSummary) => {
            return <>{Utils.formatValue(row.totalCost, 'dollar')}</>;
        },
    },
    {
        width: 100,
        label: 'Exposure',
        dataKey: 'impressions',
        numeric: true,
        render: (column: ColumnData, row: AppBySpendBySiteNameSummary) => {
            return <>{Utils.formatValue(row.impressions)}</>;
        },
    },
];

type ReportingPerformanceSiteImpressionTableProps = {
    reportingCriteria: ReportingCriteria;
};

export default function ReportingPerformanceSiteImpressionTable(props: ReportingPerformanceSiteImpressionTableProps) {
    const { reportingCriteria } = props;
    const [criteria, setCriteria] = useState<AppBySpendSummaryListCriteria>({
        agencyIds: [],
        dealerIds: [],
        startDate: new Date(),
        endDate: new Date(),
        mediaType: '',
        strategyType: '',

        page: 0,
        pageSize: 10,
        sortBy: 'impressions',
        sort: 'DESC',
    });

    const [data, setData] = useState<AppBySpendBySiteNameSummary[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [cancelTokenSource, setCancelTokenSource] = useState<CancelTokenSource | null>(null);

    const loadMoreData = useCallback(async (updatedCriteria: AppBySpendSummaryListCriteria): Promise<void> => {
        if (cancelTokenSource) {
            cancelTokenSource.cancel('Operation canceled due to new request.');
        }

        const newCancelTokenSource: CancelTokenSource = axios.CancelToken.source();
        setCancelTokenSource(newCancelTokenSource);

        setIsLoading(true);
        setCriteria(updatedCriteria);

        try {
            const response = await ApiService.getAppBySpendBySiteNameSummaryList(updatedCriteria, {
                cancelToken: newCancelTokenSource.token,
                headers: { XBusy: 'false' },
            });

            const newData = response?.data?.content ? response.data.content : [];

            setData((prevData) => {
                if (updatedCriteria.page === 0) {
                    return [...newData];
                }
                return [...prevData, ...newData];
            });
        } catch (error) {
            if (axios.isCancel(error)) {
                console.log('Request canceled:', error);
            } else {
                // Handle other types of errors here
            }
        } finally {
            setIsLoading(false);
        }
    }, []);

    const handleEndReached = useCallback((): void => {
        if (!isLoading) {
            const updatedCriteria = { ...criteria, page: criteria.page + 1 };
            loadMoreData(updatedCriteria);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading, loadMoreData]);

    const hasData = useMemo((): boolean => {
        return data && data.length > 0 ? true : false;
    }, [data]);

    useEffect(() => {
        if (cancelTokenSource) {
            cancelTokenSource.cancel('Operation canceled due to new request.');
        }

        const updatedCriteria = { ...criteria, ...reportingCriteria, page: 0 };

        loadMoreData(updatedCriteria);

        return () => {
            if (cancelTokenSource) {
                cancelTokenSource.cancel('Component unmounted or criteria changed.');
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reportingCriteria, loadMoreData]);

    return (
        <Paper style={{ height: 400, width: '100%', position: 'relative' }} elevation={0}>
            <TableVirtuoso
                data={data}
                components={VirtuosoTableComponents}
                fixedHeaderContent={fixedHeaderContent}
                itemContent={rowContent}
                endReached={handleEndReached}
                overscan={10}
            />

            {isLoading === true && (
                <Backdrop
                    sx={{
                        position: 'absolute',
                        zIndex: (theme) => theme.zIndex.drawer + 1,
                    }}
                    open
                >
                    <Box sx={{ display: 'flex' }}>
                        <CircularProgress />
                    </Box>
                </Backdrop>
            )}

            {hasData === false && isLoading === false && (
                <Column direction="column" alignItems="center" justifyContent="center" sx={{ height: 400 }}>
                    <Typography variant="body2">No data available</Typography>
                </Column>
            )}
        </Paper>
    );
}
