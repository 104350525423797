import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { useCallback, useState } from 'react';
import type { ControlPosition } from 'react-map-gl';
import { useControl } from 'react-map-gl';

type DrawControlProps = ConstructorParameters<typeof MapboxDraw>[0] & {
    position: ControlPosition;
    onCreate: (evt: { draw: MapboxDraw; features: GeoJSON.Feature[] }) => void;
    onModeChange: (evt: { mode: string }) => void;
};

const DrawControl = (props: DrawControlProps) => {
    const [draw] = useState<MapboxDraw>(() => new MapboxDraw(props));
    const { onCreate: onCreateProp } = props;

    const onCreate = useCallback(
        (event: { features: GeoJSON.Feature[] }) => {
            onCreateProp({
                ...event,
                draw,
            });
        },
        [draw, onCreateProp]
    );

    useControl(
        () => draw,
        ({ map }) => {
            map.on('draw.create', onCreate);
            map.on('draw.modechange', props.onModeChange);
        },
        ({ map }) => {
            map.off('draw.create', onCreate);
            map.off('draw.modechange', props.onModeChange);
        },
        {
            position: props.position,
        }
    );

    return null;
};

export default DrawControl;
