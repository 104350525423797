import { useMemo } from 'react';
import { deepmerge } from '@mui/utils';
import { Box, Card, CardContent, CardHeader, Tooltip, Typography } from '@mui/material';
import { HelpOutlineOutlined as HelpOutlineOutlinedIcon } from '@mui/icons-material';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import Column from '../Column';
import Printable from '../Printable';
import Row from '../Row';
import SkeletonPieChart from './SkeletonPieChart';
import theme from '../../theme';

export default function PieChartCard(props: {
    label: string | null | undefined | React.ReactElement;
    tooltip?: string;
    chartOptions?: ApexOptions;
    chartSeries?: ApexAxisChartSeries | number[];
    noDataOverlay?: React.ReactElement;
    loading?: boolean;
}) {
    const { label, tooltip, chartOptions, chartSeries, noDataOverlay, loading = false } = props;

    const defaultChartOptions: ApexOptions = {
        colors: [
            '#0e59e7',
            '#6771dc',
            '#8067dc',
            '#a367dc',
            '#c767dc',
            '#dc67ab',
            '#dc6788',
            '#dc6967',
            '#dc8c67',
            '#dcaf67',
            '#dcd267',
            '#c3dc67',
            '#a0dc67',
            '#7ddc67',
            '#67dc75',
            '#67dc98',
            '#67dcbb',
            '#67dadc',
        ],
        chart: {
            type: 'donut',
            // height: 300,
            offsetX: 0,
        },
        legend: {
            position: 'right',
            offsetY: 0,
            height: 300,
            width: 350,
            fontSize: '12px',
            fontFamily: theme.typography.fontFamily,
            fontWeight: 400,
            markers: {
                width: 14,
                height: 14,
                radius: 4,
                offsetX: -4,
                offsetY: 4,
            },
            itemMargin: {
                horizontal: 4,
                vertical: 4,
            },
        },
        plotOptions: {
            pie: {
                donut: {
                    size: '60%',
                    labels: {
                        show: true,
                        name: {
                            show: true,
                            offsetY: 20,
                            formatter: function (value) {
                                return `${value}`;
                            },
                        },
                        value: {
                            show: true,
                            color: theme.palette.text.primary,
                            fontFamily: theme.typography.fontFamily,
                            fontSize: '20px',
                            offsetY: -20,
                            formatter: function (value) {
                                return `${value}%`;
                            },
                        },
                        total: {
                            show: true,
                            showAlways: true,
                            label: 'Total',
                            color: theme.palette.text.secondary,
                            fontFamily: theme.typography.fontFamily,
                            fontSize: '12px',
                            formatter: function (value) {
                                return chartSeries && chartSeries.length > 0 ? `${chartSeries[0]}%` : '';
                            },
                        },
                    },
                },
            },
        },
        tooltip: {
            enabled: true,
            y: {
                formatter: function (value) {
                    return `${Math.round(value)}%`;
                },
                title: {
                    formatter: (seriesName) => seriesName,
                },
            },
        },
        dataLabels: {
            enabled: true,
            dropShadow: {
                enabled: false,
            },
            formatter: function (value: any, opts: any) {
                return `${Math.round(value)}%`;
            },
        },
        responsive: [
            {
                breakpoint: 300,
                options: {
                    chart: {
                        width: 200,
                    },
                    legend: {
                        position: 'bottom',
                    },
                },
            },
        ],
    };

    const hasData: boolean | undefined = useMemo(() => {
        return chartSeries && chartSeries.length > 0;
    }, [chartSeries]);

    const hasNoDataOverlay: boolean | undefined = useMemo(() => {
        return noDataOverlay !== null && noDataOverlay !== undefined;
    }, [noDataOverlay]);

    const boxHeight: number = 300;

    return (
        <Card
            variant="outlined"
            sx={{
                overflow: 'visible',
            }}
        >
            <CardHeader
                title={
                    <Row gap={0.5} alignItems="center">
                        <Typography variant="body1" color="text.primary">
                            {label}
                        </Typography>

                        <Printable show={false}>
                            {tooltip ? (
                                <Tooltip title={tooltip}>
                                    <HelpOutlineOutlinedIcon color="action" sx={{ fontSize: 16 }} />
                                </Tooltip>
                            ) : (
                                ''
                            )}
                        </Printable>
                    </Row>
                }
                sx={{
                    borderBottom: 1,
                    borderColor: 'divider',
                }}
            />
            <CardContent sx={{ height: 325 }}>
                {loading ? (
                    <SkeletonPieChart />
                ) : (
                    // <Column alignItems="center" justifyContent="center" sx={{ height: boxHeight + 2 }}>
                    //     <CircularProgress />
                    // </Column>
                    <>
                        {hasData === true && (
                            <Box
                                sx={
                                    {
                                        // display: hasData === true ? 'block' : 'none',
                                    }
                                }
                            >
                                <ReactApexChart
                                    type="donut"
                                    options={deepmerge(defaultChartOptions, chartOptions)}
                                    series={chartSeries}
                                    height={boxHeight}
                                    // width={500}
                                />
                            </Box>
                        )}

                        {hasData === false && (
                            <>
                                {hasNoDataOverlay === true && <>{noDataOverlay}</>}

                                {hasNoDataOverlay === false && (
                                    <Column
                                        direction="column"
                                        alignItems="center"
                                        justifyContent="center"
                                        sx={{ height: boxHeight + 2 }}
                                    >
                                        <Typography variant="body2">No data available</Typography>
                                    </Column>
                                )}
                            </>
                        )}
                    </>
                )}
            </CardContent>
        </Card>
    );
}
