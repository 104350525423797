import { Campaign, CampaignFlight } from '../../../types/Campaign';
import CDGrid, { CustomPagination } from '../../../components/CDGrid';
import Utils from '../../../components/Utils';
import {
    GridColumns,
    GridFooterContainer,
    GridRowClassNameParams,
    GridRowModel,
    GridValueGetterParams,
} from '@mui/x-data-grid';
import { Box } from '@mui/material';
import { useContext } from 'react';
import { UserContext } from '../../../App';
import ApiService from '../../../ApiService';
import AddNewFlight from './AddNewFlight';

interface FlightGridFooterProps {
    reloadCampaign(): void;
}

function FlightGridFooter(props: FlightGridFooterProps) {
    const { reloadCampaign } = props;

    return (
        <GridFooterContainer>
            <AddNewFlight reloadCampaign={reloadCampaign} />

            <CustomPagination />
        </GridFooterContainer>
    );
}

export default function FlightsTab(props: { campaign: Campaign; reloadCampaign(): void }) {
    const { campaign, reloadCampaign } = props;
    const { userContext } = useContext(UserContext);

    function getCellClass(params: GridRowClassNameParams) {
        const now = new Date();
        if (now > params.row.startDateTime && now < params.row.endDateTime) {
            return 'currentFlight';
        } else {
            return '';
        }
    }

    const columns: GridColumns = [
        {
            field: 'startDateTime',
            headerName: 'Start Date',
            minWidth: 125,
            flex: 1,
            valueFormatter: (params) => Utils.formatESTDateLong(params.value),
        },
        {
            field: 'endDateTime',
            headerName: 'End Date',
            minWidth: 125,
            flex: 1,
            valueFormatter: (params) => Utils.formatESTDateLong(params.value),
        },
        {
            field: 'totalImpressions',
            headerName: 'Impressions',
            headerAlign: 'right',
            align: 'right',
            minWidth: 115,
            flex: 1,
            valueGetter: (params: GridValueGetterParams) => Utils.formatNumber(params.value),
        },
        {
            field: 'budgetAmount',
            headerName: 'Budget',
            headerAlign: 'right',
            align: 'right',
            minWidth: 115,
            editable: true,
            flex: 1,
            renderCell: (params) => (
                <>
                    <span>{Utils.formatCurrency(params.value, 2)}</span>
                </>
            ),
        },
        {
            field: 'totalCost',
            headerName: 'Spend',
            headerAlign: 'right',
            align: 'right',
            minWidth: 115,
            flex: 1,
            valueGetter: (params: GridValueGetterParams) => Utils.formatCurrency(params.value, 2),
        },
    ];

    if (userContext.isAdmin()) {
        columns.push(
            {
                field: 'dspBudget',
                headerName: 'DSP Budget',
                headerAlign: 'right',
                align: 'right',
                minWidth: 115,
                flex: 1,
                valueGetter: (params: GridValueGetterParams) => Utils.formatCurrency(params.value, 2),
            },
            {
                field: 'dspCost',
                headerName: 'DSP Cost',
                headerAlign: 'right',
                align: 'right',
                minWidth: 115,
                flex: 1,
                valueGetter: (params: GridValueGetterParams) => Utils.formatCurrency(params.value, 2),
            },
            {
                field: 'updateDate',
                headerName: 'Last Updated',
                minWidth: 155,
                flex: 1,
                valueFormatter: (params) => Utils.formatDateTime(params.value),
            }
        );
    }

    return (
        <>
            <Box
                sx={{
                    '& .currentFlight': {
                        fontWeight: 600,
                    },
                    width: '100%',
                }}
            >
                <CDGrid
                    columns={columns}
                    rows={campaign.flights}
                    getRowClassName={getCellClass}
                    processRowUpdate={(row: GridRowModel) => {
                        ApiService.updateFlight(row as CampaignFlight);
                        return row;
                    }}
                    onProcessRowUpdateError={() => {}}
                    experimentalFeatures={{ newEditingApi: true }}
                    components={{
                        Footer: FlightGridFooter,
                    }}
                    componentsProps={{
                        footer: { reloadCampaign },
                    }}
                />
            </Box>
        </>
    );
}
