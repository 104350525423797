import moment from 'moment-timezone';

export const PlatfromUsers = [
    {
        id: 'aws',
        label: 'Amazon User',
    },
    {
        id: 'no_aws',
        label: 'Non-Amazon User',
    },
];

export const PurchaseType = [
    {
        id: 'N',
        label: 'New Sales',
    },
    {
        id: 'U',
        label: 'Used Sales',
    },
];

export const States = [
    { label: 'Alabama', id: 'AL' },
    { label: 'Alaska', id: 'AK' },
    { label: 'Arizona', id: 'AZ' },
    { label: 'Arkansas', id: 'AR' },
    { label: 'California', id: 'CA' },
    { label: 'Colorado', id: 'CO' },
    { label: 'Connecticut', id: 'CT' },
    { label: 'Delaware', id: 'DE' },
    { label: 'District of Columbia', id: 'DC' },
    { label: 'Florida', id: 'FL' },
    { label: 'Georgia', id: 'GA' },
    { label: 'Hawaii', id: 'HI' },
    { label: 'Idaho', id: 'ID' },
    { label: 'Illinois', id: 'IL' },
    { label: 'Indiana', id: 'IN' },
    { label: 'Iowa', id: 'IA' },
    { label: 'Kansas', id: 'KS' },
    { label: 'Kentucky', id: 'KY' },
    { label: 'Louisiana', id: 'LA' },
    { label: 'Maine', id: 'ME' },
    { label: 'Maryland', id: 'MD' },
    { label: 'Massachusetts', id: 'MA' },
    { label: 'Michigan', id: 'MI' },
    { label: 'Minnesota', id: 'MN' },
    { label: 'Mississippi', id: 'MS' },
    { label: 'Missouri', id: 'MO' },
    { label: 'Montana', id: 'MT' },
    { label: 'Nebraska', id: 'NE' },
    { label: 'Nevada', id: 'NV' },
    { label: 'New Hampshire', id: 'NH' },
    { label: 'New Jersey', id: 'NJ' },
    { label: 'New Mexico', id: 'NM' },
    { label: 'New York', id: 'NY' },
    { label: 'North Carolina', id: 'NC' },
    { label: 'North Dakota', id: 'ND' },
    { label: 'Ohio', id: 'OH' },
    { label: 'Oklahoma', id: 'OK' },
    { label: 'Oregon', id: 'OR' },
    { label: 'Pennsylvania', id: 'PA' },
    { label: 'Puerto Rico', id: 'PR' },
    { label: 'Rhode Island', id: 'RI' },
    { label: 'South Carolina', id: 'SC' },
    { label: 'South Dakota', id: 'SD' },
    { label: 'Tennessee', id: 'TN' },
    { label: 'Texas', id: 'TX' },
    { label: 'Utah', id: 'UT' },
    { label: 'Vermont', id: 'VT' },
    { label: 'Virginia', id: 'VA' },
    { label: 'Washington', id: 'WA' },
    { label: 'West Virginia', id: 'WV' },
    { label: 'Wisconsin', id: 'WI' },
    { label: 'Wyoming', id: 'WY' },
];

export const removeItemAtIndex = (arr: any[], indices: number[]) => {
    const newData = JSON.parse(JSON.stringify(arr));
    let currentArray = newData;
    let lastIndex = indices.length - 1;

    for (let i = 0; i < lastIndex; i++) {
        currentArray = currentArray[indices[i]].children;
    }

    // Remove the item at the specified index
    currentArray.splice(indices[lastIndex], 1);

    // Return the new array
    return newData;
};

export const adjustDateRange = (startDate: Date | undefined, endDate: Date | undefined) => {
    const today = moment();
    let adjustedStartDate = startDate ? moment(startDate) : null;
    let adjustedEndDate = endDate ? moment(endDate) : null;

    if (adjustedStartDate) {
        if (adjustedStartDate.day() === 0) {
            // Snap Sunday to the previous week's Monday to Sunday
            adjustedEndDate = adjustedStartDate.clone();
            adjustedStartDate = adjustedEndDate.clone().subtract(6, 'days');
        } else if (adjustedStartDate.day() !== 1) {
            // Snap startDate to the nearest Monday
            adjustedStartDate = adjustedStartDate.day(1);
        }
    }

    if (adjustedEndDate) {
        if (adjustedEndDate.day() !== 0) {
            // Snap endDate to the nearest Sunday
            adjustedEndDate = adjustedEndDate.day(7);
        }
    }

    // If only startDate is provided
    if (adjustedStartDate && !adjustedEndDate) {
        adjustedEndDate = adjustedStartDate.clone().add(6, 'days'); // Default to a single week range
    }

    // If only endDate is provided
    if (!adjustedStartDate && adjustedEndDate) {
        adjustedStartDate = adjustedEndDate.clone().subtract(6, 'days'); // Default to a single week range
    }

    // Ensure the range does not extend into the future
    if (adjustedEndDate && adjustedEndDate.isAfter(today)) {
        adjustedEndDate = today.clone().day(0); // Last Sunday
        adjustedStartDate = adjustedEndDate.clone().subtract(6, 'days'); // Previous Monday
    }

    return {
        startDate: (adjustedStartDate ?? today.clone()).toDate(),
        endDate: (adjustedEndDate ?? today.clone()).toDate(),
    };
};

type AdjustDateRangeInPastProps = {
    startDate?: Date;
    endDate?: Date;
    weeksInPast: number;
};

export const adjustDateRangeInPast = (props: AdjustDateRangeInPastProps) => {
    const { weeksInPast = 1 } = props;

    let { startDate, endDate } = adjustDateRange(props.startDate, props.endDate);

    if (startDate) {
        startDate = moment(startDate)
            .subtract((weeksInPast - 1) * 7, 'days')
            .toDate();
        // endDate = moment(startDate).clone().add(6, 'days').toDate();
    }

    return {
        startDate: startDate,
        endDate: endDate,
    };
};

export const getDefaultDates = () => {
    let minDate = new Date(2024, 0, 1);
    let maxDate: Date = new Date();
    let startDate: Date = new Date();
    let endDate: Date = new Date();

    const today = moment();

    let adjustedStartDate, adjustedEndDate;

    adjustedStartDate = today.clone();

    // Calculate endDate as 6 days after startDate
    adjustedEndDate = adjustedStartDate.clone().add(6, 'days');

    // If the range extends into the future, default to the last completed weekly range
    if (adjustedEndDate.isAfter(today)) {
        adjustedEndDate = today.clone().day(0); // Last Sunday
        adjustedStartDate = adjustedEndDate.clone().subtract(6, 'days'); // Previous Monday
    }

    startDate = adjustedStartDate.toDate();
    endDate = adjustedEndDate.toDate();
    maxDate = endDate;

    return {
        minDate,
        maxDate,
        startDate,
        endDate,
    };
};
