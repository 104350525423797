import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useState } from 'react';
import { CloseOutlined } from '@mui/icons-material';
import { ReleaseNote } from '../../types/ReleaseNote';
import Utils from '../../components/Utils';
import { releaseNotesStyles } from './releaseNotesStyles';

export function ReleaseNotesDialog(props: { onClose: () => void; releaseNote?: ReleaseNote }) {
    const { onClose } = props;
    const [releaseNote, setReleaseNote] = useState<ReleaseNote | null>(props.releaseNote ? props.releaseNote : null);

    if (releaseNote) {
        return (
            <Dialog
                maxWidth="lg"
                fullWidth={true}
                open={true}
                sx={{
                    '& .MuiDialogTitle-root': {
                        padding: '5px 24px',
                    },
                    '& .MuiPaper-root': {
                        margin: '0px',
                    },
                    '& .MuiDialogTitle-root .date': {
                        fontStyle: 'italic',
                    },
                    ...releaseNotesStyles,
                }}
                scroll="paper"
                onClose={() => {
                    setReleaseNote(null);
                    onClose();
                }}
            >
                <DialogTitle>
                    <h2>{releaseNote.description}</h2>
                    <span className="date">{Utils.formatESTDateLong(releaseNote.releaseDate)}</span>
                </DialogTitle>

                <DialogContent dividers={true}>
                    <div
                        className="releaseNoteView"
                        style={{ margin: '0px', padding: '5px' }}
                        dangerouslySetInnerHTML={{
                            __html: releaseNote.content,
                        }}
                    ></div>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="primary" startIcon={<CloseOutlined />} onClick={onClose}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        );
    } else {
        return null;
    }
}
