import { Alert, Button } from '@mui/material';
import Heading from '../components/Heading';
import { useNavigate } from 'react-router-dom';
import { ArrowBackOutlined } from '@mui/icons-material';

export default function NotFound() {
    const navigate = useNavigate();

    return (
        <>
            <Heading>Not Found</Heading>
            <Alert severity="error">The specified page could not be found.</Alert>
            <br />
            <Button
                variant="outlined"
                sx={{ marginTop: '15px' }}
                color="info"
                startIcon={<ArrowBackOutlined />}
                onClick={() => navigate('/')}
            >
                Back
            </Button>
        </>
    );
}
