import { Backdrop, Box, CircularProgress } from '@mui/material';

type AppLoaderProps = {
    loading: boolean;
};

export default function AppLoader({ loading }: AppLoaderProps) {
    return (
        <>
            {loading === true && (
                <Backdrop
                    sx={{
                        color: '#fff',
                        zIndex: (theme) => theme.zIndex.drawer + 1,
                    }}
                    open
                >
                    <Box sx={{ display: 'flex' }}>
                        <CircularProgress />
                    </Box>
                </Backdrop>
            )}
        </>
    );
}
