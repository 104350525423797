import { createBrowserRouter, createRoutesFromElements, Navigate, Outlet, Route } from 'react-router-dom';
import App from './App';
import ApiService from './ApiService';
import SalesDataUploadPage from './pages/salesData/SalesDataUploadPage';
import SalesDataUploadStatusPage from './pages/salesData/SalesDataUploadStatusPage';
import SalesDataUploadMapping from './pages/salesData/SalesDataUploadMapping';
import CRMDataUploadPage from './pages/CRMDataUpload';
import UserManagement from './pages/userManagement/UserManagement';
import UserEdit from './pages/userEdit/UserEdit';
import AmcWorkflowsPage from './pages/amc/workflows/AmcWorkflowsPage';
import AmcWorkflowEditPage from './pages/amc/workflows/AmcWorkflowEditPage';
import AmcInstanceListPage from './pages/amc/instances/AmcInstanceListPage';
import AmcInstanceEditPage from './pages/amc/instances/AmcInstanceEditPage';
import AttributionPage from './pages/attribution/AttributionPage';

import WorkflowsPage from './pages/amc/workflows/WorkflowsPage';
import WorkflowEditPage from './pages/amc/workflows/WorkflowEditPage';
import DealerListPage from './pages/dealers/DealerListPage';
import DealerEditPage from './pages/dealers/DealerEditPage';
import OfferListPage from './pages/offers/OfferListPage';
import QuickSightDashboardPage from './pages/analytics/QuickSightDashboardPage';
import DefaultDashboardPage from './pages/analytics/DefaultDashboardPage';
import ReportingDashboardPage from './pages/analytics/ReportingDashboardPage';
import AmcAttributionEdit from './pages/amcAttribution/AmcAttributionEdit';
import AgencyEditPage from './pages/agency/AgencyEditPage';
import JobStatusListPage from './pages/jobStatus/JobStatusListPage';
import AnalyticsApiPage from './pages/analytics/AnalyticsApiPage';
import ImportListPage from './pages/importexport/ImportListPage';
import AmcAttributionList from './pages/amcAttribution/AmcAttributionList';
import AgencyListPage from './pages/agency/AgencyListPage';
import { AccessRoute } from './components/AccessGuard';
import NotFound from './components/NotFound';
import {
    AGENCY_ADVERTISERS,
    AGENCY_API_DOCS,
    AGENCY_ATTRIBUTION,
    AGENCY_CAMPAIGNS,
    AGENCY_CRM_UPLOAD,
    AGENCY_DATA,
    AGENCY_INSIGHTS,
    AGENCY_REPORTING,
    USER_GROUP_ADMIN,
    USER_GROUP_ADVERTISER,
    USER_GROUP_AGENCY,
} from './types/User';
import { ReleaseNoteEditorPage } from './pages/releaseNotes/ReleaseNoteEditorPage';
import { ReleaseNoteListPage } from './pages/releaseNotes/ReleaseNoteListPage';
import DataSetPage from './pages/amc/datasets/DataSetPage';
import OperationsDashboardPage from './pages/operationsDashboard/OperationsDashboardPage';
import DspAudiencePage from './pages/dspAudience/DspAudiencePage';
import StyleGuide from './pages/styleguide/StyleGuide';
import CampaignManagerPageV2 from './pages/campaignManagerV2/CampaignManagerPageV2';
import OemOfferPage from './pages/oemoffer/OemOfferPage';
import CampaignEditPage from './pages/campaignManagerV2/campaignEdit/CampaignEditPage';
import CampaignDetailsPage from './pages/campaignManagerV2/campaignDetailView/CampaignDetailsPage';
import PolkAudiencesPage from './pages/polkAudiences/PolkAudiencesPage';
import PolkAudiencesQueuePage from './pages/polkAudiences/PolkAudienceQueuePage';
import DataMappingPage from './pages/dataMapping/dataMappingPage';
import AdTagPreviewPage from './pages/adTagPreview/adTagPreviewPage';
import SalesInsights from './pages/insights/SalesInsights';

function AppRoutes() {
    return createBrowserRouter(
        createRoutesFromElements(
            <Route path="/" element={<App />} errorElement={<App />}>
                <Route index element={<Navigate to="campaignManager" replace />} />

                <Route path="salesDataUploads" element={<Outlet />}>
                    <Route path="" element={<SalesDataUploadStatusPage />} />
                    <Route
                        path="uploadMapping/:salesDataUploadId"
                        element={<SalesDataUploadMapping />}
                        loader={async ({ request, params }) => {
                            try {
                                const response = await ApiService.getSalesDataUpload(
                                    parseInt(params.salesDataUploadId as string)
                                );
                                return response.data;
                            } catch (error) {}

                            return undefined;
                        }}
                        errorElement={<NotFound />}
                    />
                </Route>

                <Route path="crmDataUpload" element={<CRMDataUploadPage />} />

                <Route
                    path="crmUpload"
                    element={
                        <AccessRoute
                            accessGroup={`${USER_GROUP_ADMIN},${USER_GROUP_AGENCY}`}
                            accessAgency={AGENCY_CRM_UPLOAD}
                        >
                            <SalesDataUploadPage />
                        </AccessRoute>
                    }
                />

                <Route path="users" element={<Outlet />}>
                    <Route
                        path=""
                        element={
                            <AccessRoute accessGroup={USER_GROUP_ADMIN}>
                                <UserManagement />
                            </AccessRoute>
                        }
                    />
                    <Route
                        path=":id"
                        element={
                            <AccessRoute accessGroup={USER_GROUP_ADMIN}>
                                <UserEdit />
                            </AccessRoute>
                        }
                    />
                </Route>

                <Route path="amcworkflows" element={<Outlet />}>
                    <Route
                        index
                        element={
                            <AccessRoute accessGroup={USER_GROUP_ADMIN}>
                                <AmcWorkflowsPage />
                            </AccessRoute>
                        }
                    />
                    <Route
                        path=":workflowId"
                        element={
                            <AccessRoute accessGroup={USER_GROUP_ADMIN}>
                                <AmcWorkflowEditPage />
                            </AccessRoute>
                        }
                    />
                    <Route
                        path="new"
                        element={
                            <AccessRoute accessGroup={USER_GROUP_ADMIN}>
                                <AmcWorkflowEditPage />
                            </AccessRoute>
                        }
                    />
                </Route>

                <Route path="amcInstances" element={<Outlet />}>
                    <Route
                        index
                        element={
                            <AccessRoute
                                accessGroup={`${USER_GROUP_ADMIN},${USER_GROUP_AGENCY}`}
                                accessAgency={AGENCY_DATA}
                            >
                                <AmcInstanceListPage />
                            </AccessRoute>
                        }
                    />
                    <Route
                        path=":id"
                        element={
                            <AccessRoute
                                accessGroup={`${USER_GROUP_ADMIN},${USER_GROUP_AGENCY}`}
                                accessAgency={AGENCY_DATA}
                            >
                                <AmcInstanceEditPage />
                            </AccessRoute>
                        }
                    />
                </Route>

                <Route path="attribution" element={<Outlet />}>
                    <Route
                        index
                        element={
                            <AccessRoute accessAgency={AGENCY_ATTRIBUTION}>
                                <AttributionPage />
                            </AccessRoute>
                        }
                    />
                </Route>

                <Route path="amcDataSets" element={<DataSetPage />} />

                <Route path="workflows" element={<Outlet />}>
                    <Route
                        index
                        element={
                            <AccessRoute accessGroup={USER_GROUP_ADMIN}>
                                <WorkflowsPage />
                            </AccessRoute>
                        }
                    />
                    <Route
                        path=":workflowId"
                        element={
                            <AccessRoute accessGroup={USER_GROUP_ADMIN}>
                                <WorkflowEditPage />
                            </AccessRoute>
                        }
                    />
                </Route>

                <Route path="/audiences" element={<DataSetPage />} />

                <Route path="/dspAudiences" element={<DspAudiencePage />} />

                <Route
                    path="advertisers"
                    element={
                        <AccessRoute
                            accessGroup={`${USER_GROUP_ADMIN},${USER_GROUP_ADVERTISER},${USER_GROUP_AGENCY}`}
                            accessAgency={AGENCY_ADVERTISERS}
                        >
                            <Outlet />
                        </AccessRoute>
                    }
                >
                    <Route index element={<DealerListPage />} />
                    <Route path=":id?" element={<DealerEditPage />} />
                </Route>

                <Route
                    path="/opsDashboard"
                    element={
                        <AccessRoute accessGroup={USER_GROUP_ADMIN}>
                            <OperationsDashboardPage />
                        </AccessRoute>
                    }
                />

                <Route
                    path="/adTagPreview"
                    element={
                        <AccessRoute accessGroup={USER_GROUP_ADMIN}>
                            <AdTagPreviewPage />
                        </AccessRoute>
                    }
                />

                <Route
                    path="/polkAudiences"
                    element={
                        <AccessRoute accessGroup={USER_GROUP_ADMIN}>
                            <PolkAudiencesPage />
                        </AccessRoute>
                    }
                />

                <Route
                    path="/polkAudiences/queue"
                    element={
                        <AccessRoute accessGroup={USER_GROUP_ADMIN}>
                            <PolkAudiencesQueuePage />
                        </AccessRoute>
                    }
                />

                <Route
                    path="tradeInOffers/:dealerId"
                    element={
                        <AccessRoute accessGroup={USER_GROUP_ADMIN}>
                            <OfferListPage />
                        </AccessRoute>
                    }
                />
                <Route
                    path="quicksight"
                    element={
                        <AccessRoute
                            accessGroup={`${USER_GROUP_ADMIN},${USER_GROUP_ADVERTISER},${USER_GROUP_AGENCY}`}
                            accessAgency={AGENCY_REPORTING}
                        >
                            <QuickSightDashboardPage />
                        </AccessRoute>
                    }
                />
                <Route
                    path="analyticsSettings"
                    element={
                        <AccessRoute accessGroup={USER_GROUP_ADMIN}>
                            <DefaultDashboardPage />
                        </AccessRoute>
                    }
                />
                <Route
                    path="analyticsapi"
                    element={
                        <AccessRoute
                            accessGroup={`${USER_GROUP_ADMIN},${USER_GROUP_AGENCY}`}
                            accessAgency={AGENCY_API_DOCS}
                        >
                            <AnalyticsApiPage />
                        </AccessRoute>
                    }
                />

                <Route
                    path="reporting"
                    element={
                        <AccessRoute
                            accessGroup={`${USER_GROUP_ADMIN},${USER_GROUP_ADVERTISER},${USER_GROUP_AGENCY}`}
                            accessAgency={AGENCY_REPORTING}
                        >
                            <ReportingDashboardPage />
                        </AccessRoute>
                    }
                />

                <Route
                    path="jobStatus"
                    element={
                        <AccessRoute accessGroup={USER_GROUP_ADMIN}>
                            <JobStatusListPage />
                        </AccessRoute>
                    }
                />

                <Route path="campaignManager" element={<Outlet />}>
                    <Route
                        index
                        element={
                            <AccessRoute
                                accessGroup={`${USER_GROUP_ADMIN},${USER_GROUP_ADVERTISER},${USER_GROUP_AGENCY}`}
                                accessAgency={AGENCY_CAMPAIGNS}
                            >
                                <CampaignManagerPageV2 />
                            </AccessRoute>
                        }
                    />
                    <Route
                        path=":id?"
                        element={
                            <AccessRoute
                                accessGroup={`${USER_GROUP_ADMIN},${USER_GROUP_ADVERTISER},${USER_GROUP_AGENCY}`}
                                accessAgency={AGENCY_CAMPAIGNS}
                            >
                                <CampaignDetailsPage />
                            </AccessRoute>
                        }
                    />
                </Route>

                <Route path="campaign" element={<Outlet />}>
                    <Route path="create" element={<CampaignEditPage />} />
                    <Route path=":id?" element={<CampaignEditPage />} />
                </Route>

                <Route
                    path="importManager"
                    element={
                        <AccessRoute accessGroup={USER_GROUP_ADMIN}>
                            <ImportListPage />
                        </AccessRoute>
                    }
                />

                <Route path="agencies" element={<Outlet />}>
                    <Route
                        index
                        element={
                            <AccessRoute accessGroup={USER_GROUP_ADMIN}>
                                <AgencyListPage />
                            </AccessRoute>
                        }
                    />
                    <Route
                        path=":id?"
                        element={
                            <AccessRoute accessGroup={USER_GROUP_ADMIN}>
                                <AgencyEditPage />
                            </AccessRoute>
                        }
                    />
                </Route>

                <Route path="amcAttributionList" element={<Outlet />}>
                    <Route index element={<AmcAttributionList />} />
                    <Route path=":id?" element={<AmcAttributionEdit />} />
                </Route>

                <Route path="releaseNotes" element={<Outlet />}>
                    <Route
                        index
                        element={
                            <AccessRoute accessGroup={USER_GROUP_ADMIN}>
                                <ReleaseNoteListPage />
                            </AccessRoute>
                        }
                    />
                    <Route
                        path=":id?"
                        element={
                            <AccessRoute accessGroup={USER_GROUP_ADMIN}>
                                <ReleaseNoteEditorPage />
                            </AccessRoute>
                        }
                    />
                </Route>

                <Route
                    path="/styleguide"
                    element={
                        <AccessRoute accessGroup={USER_GROUP_ADMIN}>
                            <StyleGuide />
                        </AccessRoute>
                    }
                />

                <Route
                    path="oemoffers"
                    element={
                        <AccessRoute accessGroup={USER_GROUP_ADMIN}>
                            <OemOfferPage />
                        </AccessRoute>
                    }
                />

                <Route
                    path="insights"
                    element={
                        <AccessRoute
                            accessGroup={`${USER_GROUP_ADMIN},${USER_GROUP_AGENCY}`}
                            accessAgency={AGENCY_INSIGHTS}
                        >
                            <SalesInsights />
                        </AccessRoute>
                    }
                />

                <Route path="/dataMapping" element={<DataMappingPage />} />

                <Route path="/notFound" element={<NotFound />} />
                <Route path="*" element={<NotFound />} />
            </Route>
        )
    );
}

export default AppRoutes;
