import AppBreadcrumbs from '../../../components/AppBreadcrumbs';
import Column from '../../../components/Column';
import Heading from '../../../components/Heading';
import { useEffect, useState } from 'react';
import 'ace-builds/src-noconflict/mode-sql';
import 'ace-builds/src-noconflict/theme-sqlserver';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { AmcInstance } from '../../../types/AmcInstance';
import { Workflow } from '../../../types/Workflow';
import { useLocation } from 'react-router-dom';
import WorkflowForm from './WorkflowForm';

export interface WorkflowEditPageProps {
    workflowId: string;
    sqlQuery: string;
    isNew: boolean;
    amcInstance: AmcInstance;
}

export default function WorkflowEditPage() {
    const location = useLocation();
    const [workflow, setWorkflow] = useState<Workflow>(location.state);

    useEffect(() => {
        setWorkflow(location.state);
    }, [location.state]);

    return (
        <Column sx={{ height: '100%' }}>
            <Column gap={1}>
                <AppBreadcrumbs items={[{ label: 'AMC Global Workflows', href: '/workflows' }]} />

                <Heading>AMC Global Workflow</Heading>
            </Column>

            <Column gap={2}>
                <WorkflowForm size="md" workflow={workflow} onSave={(_workflow: Workflow) => {}} />
            </Column>
        </Column>
    );
}
