import { useContext, useEffect, useState } from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Alert,
    Box,
    Button,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Paper,
    Stack,
    Step,
    StepLabel,
    Stepper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from '@mui/material';
import ApiService from '../ApiService';
import Heading from '../components/Heading';
import { Dealer } from '../types/Dealer';
import { Link, useNavigate } from 'react-router-dom';
import {
    ArrowDropDown,
    AttachFileOutlined,
    CloseOutlined,
    CloudUploadOutlined,
    Info as InfoIcon,
    NavigateBeforeOutlined,
    NavigateNextOutlined,
} from '@mui/icons-material';
import { SalesDataValidations } from '../types/UploadResponse';
import Utils from '../components/Utils';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DataSet } from '../types/DataSet';
import DealerSelector from '../components/DealerSelector';
import { AdvertiserContext } from '../AdvertiserContext';

export default function CRMDataUploadPage() {
    const navigate = useNavigate();
    const { advertiserContext } = useContext(AdvertiserContext);
    const [uploadResult, setUploadResult] = useState<any>(false);
    const [dataSets, setDataSets] = useState<DataSet[]>([]);
    const [dealers, setDealers] = useState<Dealer[]>([]);
    const [dealerId, setDealerId] = useState<any>(advertiserContext.dealer?.id);
    const [crmDataType, setCrmDataType] = useState('');

    const [beginDate, setBeginDate] = useState(Utils.getLastMonthStart());
    const [endDate, setEndDate] = useState(Utils.getLastMonthEnd());
    const [dealersLoaded, setDealersLoaded] = useState(false);
    const [file, setFile] = useState<File | null>(null);
    const [activeStep, setActiveStep] = useState(0);
    const [validationResponse, setValidationResponse] = useState<SalesDataValidations | null>();
    const [canUpload, setCanUpload] = useState(false);

    const STEP_SELECT_DEALER = 0;
    const STEP_UPLOAD_FILE = dealers.length > 1 ? 1 : 0;
    const STEP_SUMMARY = dealers.length > 1 ? 2 : 1;

    useEffect(() => {
        if (!dealersLoaded) {
            ApiService.getAssignedDealers()
                .then((response) => {
                    setDealersLoaded(true);
                    let dealers = response.data;
                    if (dealers.length > 0 && !advertiserContext.dealer?.id) {
                        setDealerId(dealers[0].id);
                    }
                    setDealers(dealers);
                })
                .catch(() => {});
            ApiService.getDataSets()
                .then((response) => {
                    setDataSets(response.data);
                })
                .catch(() => {});
        }
    }, [dealers, dealerId, dealersLoaded, file]);

    const handleNext = () => {
        if (!dealerId) {
            return;
        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        if (activeStep === STEP_UPLOAD_FILE) {
            validateCRMData();
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    function onFileUpload() {
        const formData = getFormData();
        ApiService.uploadSalesData(formData)
            .then(() => {
                setUploadResult(true);
                setFile(null);
                setTimeout(() => {
                    navigate('/');
                }, 4000);
            })
            .catch(function () {});
    }

    function getFormData(): FormData {
        const formData = new FormData();
        formData.append('dealerId', dealerId);
        formData.append('crmDataType', crmDataType);
        if (beginDate != null && endDate != null) {
            formData.append('beginDate', beginDate.toISOString());
            formData.append('endDate', endDate.toISOString());
        }
        if (file !== null) {
            formData.append('file', file, file.name);
        }
        return formData;
    }

    function onFileChange(event: any) {
        setFile(event.target.files[0]);
    }

    function validateCRMData() {
        setValidationResponse(null);
        const formData = getFormData();
        formData.append('validateOnly', 'true');
        ApiService.uploadSalesData(formData)
            .then((response) => {
                const validationResponse = response.data;
                setValidationResponse(validationResponse);
                setCanUpload(
                    validationResponse.validCsv &&
                        !validationResponse.duplicate &&
                        validationResponse.dateRanges.length > 0
                );
            })
            .catch(function () {});
    }

    function handleClose() {
        setUploadResult(false);
    }

    const boxHeight = '400px';

    let dealerSelector;
    if (dealers.length === 1) {
        dealerSelector = <></>;
    } else {
        dealerSelector = (
            <Box sx={{ minHeight: boxHeight }}>
                <Grid container>
                    <Grid item md={3} />
                    <Grid item md={4}>
                        <FormControl
                            style={{
                                width: '350px',
                                marginTop: '85px',
                                marginBottom: '30px',
                            }}
                        >
                            <DealerSelector dealerId={dealerId} dealerSelected={(dealerId) => setDealerId(dealerId)} />
                        </FormControl>
                    </Grid>
                </Grid>
            </Box>
        );
    }

    return dealers.length > 0 ? (
        <Box>
            <Heading>CRM Data Upload Attribution</Heading>

            <Box component="form">
                <Stepper alternativeLabel activeStep={activeStep}>
                    {dealers.length > 1 && (
                        <Step key="step1">
                            <StepLabel>Select an Advertiser</StepLabel>
                        </Step>
                    )}
                    <Step key="step2">
                        <StepLabel>Select File & Data Type</StepLabel>
                    </Step>
                    <Step key="step3">
                        <StepLabel>Data Summary & Validations</StepLabel>
                    </Step>
                </Stepper>

                {activeStep === STEP_SELECT_DEALER && <>{dealerSelector}</>}

                {activeStep === STEP_UPLOAD_FILE && (
                    <Box sx={{ minHeight: boxHeight }}>
                        <Grid container spacing={1}>
                            <Grid item md={1} />

                            <Grid item md={9}>
                                <Stack
                                    spacing={4}
                                    sx={{
                                        marginTop: '30px',
                                        marginBottom: '60px',
                                    }}
                                >
                                    <Typography variant="h5">
                                        Upload your monthly CRM data in '.csv' format using the requirements and
                                        recommendations below. Additional fields may be provided.
                                    </Typography>

                                    <Paper elevation={3}>
                                        <Accordion elevation={0}>
                                            <AccordionSummary
                                                expandIcon={<ArrowDropDown />}
                                                aria-controls="crm-data-format-requirements-panel-content"
                                                id="crm-data-format-requirements-panel-header"
                                            >
                                                <Stack direction="row" spacing={2}>
                                                    <InfoIcon />
                                                    <Typography>CSV Data & Format Requirements</Typography>
                                                </Stack>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <TableContainer>
                                                    <Table size="medium">
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell>Field/Column</TableCell>
                                                                <TableCell>Required/Recommended</TableCell>
                                                                <TableCell>Formatting</TableCell>
                                                                <TableCell>Notes</TableCell>
                                                            </TableRow>
                                                        </TableHead>

                                                        <TableBody>
                                                            <TableRow>
                                                                <TableCell>Sale/Activity Date</TableCell>
                                                                <TableCell>Required</TableCell>
                                                                <TableCell>mm/dd/yy</TableCell>
                                                                <TableCell>
                                                                    <div>Numeric values separated by / or - only.</div>
                                                                    <div>
                                                                        Attribution is performed on a 60-day lookback
                                                                        from this date.
                                                                    </div>
                                                                </TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell>First Name</TableCell>
                                                                <TableCell>
                                                                    Required if last name or email or phone not
                                                                    provided.
                                                                </TableCell>
                                                                <TableCell>John</TableCell>
                                                                <TableCell>
                                                                    can be combined into one Name column (Format: John
                                                                    Doe)
                                                                </TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell>Last Name</TableCell>
                                                                <TableCell>
                                                                    Required if first name or email or phone not
                                                                    provided.
                                                                </TableCell>
                                                                <TableCell>John</TableCell>
                                                                <TableCell>
                                                                    can be combined into one Name column (Format: John
                                                                    Doe)
                                                                </TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell>Email</TableCell>
                                                                <TableCell>
                                                                    Required if first name or last name or phone not
                                                                    provided.
                                                                </TableCell>
                                                                <TableCell>email@domain.com</TableCell>
                                                                <TableCell>one value per record</TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell>Phone</TableCell>
                                                                <TableCell>
                                                                    Required if first name or last name or email not
                                                                    provided.
                                                                </TableCell>
                                                                <TableCell>000-000-0000</TableCell>
                                                                <TableCell>
                                                                    one value per cell, multiple phone columns can be
                                                                    added (Home, Cell, etc.)
                                                                </TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell>Street Address</TableCell>
                                                                <TableCell>Recommended</TableCell>
                                                                <TableCell>123 Main St</TableCell>
                                                                <TableCell>one value per record</TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell>City</TableCell>
                                                                <TableCell>Recommended</TableCell>
                                                                <TableCell>Ponte Vedra</TableCell>
                                                                <TableCell></TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell>State</TableCell>
                                                                <TableCell>Recommended</TableCell>
                                                                <TableCell>FL</TableCell>
                                                                <TableCell></TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell>Zip</TableCell>
                                                                <TableCell>Recommended</TableCell>
                                                                <TableCell>12345</TableCell>
                                                                <TableCell>Numeric only 5 digits.</TableCell>
                                                            </TableRow>
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Paper>
                                </Stack>
                            </Grid>
                            <Grid item md={2} />

                            <Grid item md={1} />
                            <Grid item md={4} sx={{ marginTop: '10px' }}>
                                <label>Choose a type of data:</label>
                            </Grid>
                            <Grid item md={2} sx={{ marginBottom: '10px' }}>
                                <FormControl style={{ width: '250px' }}>
                                    <TextField
                                        label="Data Type"
                                        select
                                        required={true}
                                        size="small"
                                        SelectProps={{ native: false }}
                                        value={crmDataType}
                                        onChange={(event) => {
                                            setCrmDataType(event.target.value);
                                        }}
                                    >
                                        {dataSets.map((dataSet) => {
                                            return <MenuItem value={dataSet.name}>{dataSet.name}</MenuItem>;
                                        })}
                                    </TextField>
                                </FormControl>
                            </Grid>
                            <Grid item md={5} />

                            <Grid item xs={1} />
                            <Grid item md={4} sx={{ marginTop: '15px' }}>
                                Choose a date range for the data:
                            </Grid>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <Grid item xs={2} sx={{ marginTop: '10px' }}>
                                    <DatePicker
                                        label="Start Date"
                                        value={beginDate}
                                        disableFuture={true}
                                        onChange={(newValue) => {
                                            if (newValue !== null) {
                                                setBeginDate(newValue);
                                            }
                                        }}
                                        renderInput={(params) => <TextField size="small" {...params} />}
                                    />
                                </Grid>
                                <Grid item xs={2} sx={{ marginTop: '10px' }}>
                                    <DatePicker
                                        label="End Date"
                                        value={endDate}
                                        disableFuture={true}
                                        onChange={(newValue) => {
                                            if (newValue !== null) {
                                                setEndDate(newValue);
                                            }
                                        }}
                                        renderInput={(params) => <TextField size="small" {...params} />}
                                    />
                                </Grid>
                            </LocalizationProvider>
                            <Grid item md={3} />

                            <Grid item md={1} />
                            <Grid item md={4} sx={{ marginTop: '15px' }}>
                                Select a File:
                            </Grid>
                            <Grid item md={2} sx={{ marginTop: '15px' }}>
                                <InputLabel htmlFor="btn-uploadRequest">
                                    <input
                                        id="btn-uploadRequest"
                                        name="btn-uploadRequest"
                                        style={{ display: 'none' }}
                                        type="file"
                                        accept=".csv"
                                        onChange={onFileChange}
                                    />
                                    <Button variant="outlined" component="span" startIcon={<AttachFileOutlined />}>
                                        Select File
                                    </Button>
                                </InputLabel>
                            </Grid>
                            <Grid item md={5} sx={{ marginTop: '25px' }}>
                                {file ? file.name : ''}
                            </Grid>

                            <Grid item md={1} />
                            <Grid item md={9} sx={{ marginTop: '25px' }}>
                                <Alert severity="warning">
                                    <Typography variant="h5">
                                        Both the quality and completeness of the data will significantly impact the rate
                                        at which events match back to an Amazon account.
                                    </Typography>
                                </Alert>
                            </Grid>
                            <Grid item md={2} />
                        </Grid>
                    </Box>
                )}

                {activeStep === STEP_SUMMARY && (
                    <Box sx={{ minHeight: boxHeight }}>
                        <div style={{ paddingBottom: '40px' }} />
                        {validationResponse && (
                            <Grid container>
                                {validationResponse?.lastUpload && (
                                    <Grid item md={9}>
                                        <Alert severity="info" sx={{ padding: '2px' }}>
                                            {validationResponse.lastUpload.uploadedByName} last uploaded{' '}
                                            <b>{crmDataType}</b> data on{' '}
                                            <b>{Utils.formatDate(validationResponse.lastUpload.dateUploaded)}</b> for
                                            the date range{' '}
                                            <b>
                                                {Utils.formatDate(validationResponse.lastUploadDateRange?.startDate)} -{' '}
                                                {Utils.formatDate(validationResponse.lastUploadDateRange?.endDate)}
                                            </b>
                                        </Alert>
                                    </Grid>
                                )}

                                {!validationResponse?.validCsv && (
                                    <Grid item md={9}>
                                        <Alert severity="error" sx={{ padding: '2px' }}>
                                            {file?.name} does not appear to be a valid CSV file. Please go back and
                                            select another file.
                                        </Alert>
                                    </Grid>
                                )}

                                {validationResponse?.duplicate && (
                                    <>
                                        <Grid item md={1} />
                                        <Grid item md={9}>
                                            <Alert severity="error" sx={{ padding: '2px' }}>
                                                {file?.name} has already been uploaded and processed. Please go back and
                                                select another file.
                                            </Alert>
                                        </Grid>
                                    </>
                                )}

                                {validationResponse?.validCsv && validationResponse?.dateRanges.length === 0 && (
                                    <Grid item md={9}>
                                        <Alert severity="error" sx={{ padding: '2px' }}>
                                            {file?.name} is missing the Sales or Activity Dates. Please go back and
                                            select another file.
                                        </Alert>
                                    </Grid>
                                )}

                                {canUpload && (
                                    <>
                                        <Grid item md={2} />
                                        <Grid item md={8}>
                                            <Table size="small">
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell
                                                            sx={{
                                                                fontWeight: 500,
                                                            }}
                                                        >
                                                            Data Type
                                                        </TableCell>
                                                        <TableCell>{crmDataType}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell
                                                            sx={{
                                                                fontWeight: 500,
                                                            }}
                                                        >
                                                            File name
                                                        </TableCell>
                                                        <TableCell>{file?.name}</TableCell>
                                                    </TableRow>

                                                    <TableRow>
                                                        <TableCell
                                                            sx={{
                                                                fontWeight: 500,
                                                            }}
                                                        >
                                                            Date Range
                                                        </TableCell>
                                                        <TableCell>
                                                            {Utils.formatDate(beginDate)} - {Utils.formatDate(endDate)}
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell colSpan={2}>File Contents</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell
                                                            sx={{
                                                                fontWeight: 500,
                                                            }}
                                                        >
                                                            Number of Records
                                                        </TableCell>
                                                        <TableCell>
                                                            {validationResponse?.numberOfRecords.toLocaleString()}
                                                        </TableCell>
                                                    </TableRow>

                                                    {validationResponse?.dateRanges.map((dateRange) => {
                                                        return (
                                                            <>
                                                                <TableRow>
                                                                    <TableCell
                                                                        sx={{
                                                                            fontWeight: 500,
                                                                        }}
                                                                    >
                                                                        {dateRange.headerName}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {Utils.formatDate(dateRange.startDate)} -{' '}
                                                                        {Utils.formatDate(dateRange.endDate)}
                                                                    </TableCell>
                                                                </TableRow>
                                                            </>
                                                        );
                                                    })}
                                                </TableBody>
                                            </Table>
                                        </Grid>
                                        <Grid item md={2} />

                                        <Grid item md={12} sx={{ paddingTop: '20px' }} />
                                        <Grid item md={2} />
                                        <Grid item md={6}>
                                            {file && (
                                                <Alert
                                                    severity="info"
                                                    sx={{
                                                        padding: '8px',
                                                        marginBottom: '20px',
                                                    }}
                                                >
                                                    Click <b>Upload</b> to send for processing.
                                                </Alert>
                                            )}
                                            {uploadResult && (
                                                <Alert severity="success" onClose={handleClose}>
                                                    Your sales data was uploaded successfully. <br />
                                                    You will receive an Email notification once processing is complete.
                                                </Alert>
                                            )}
                                        </Grid>
                                        <Grid item md={2} />

                                        <Grid item md={4} />
                                        <Grid item md={4}>
                                            {file && (
                                                <Button
                                                    color="primary"
                                                    startIcon={<CloudUploadOutlined />}
                                                    onClick={onFileUpload}
                                                    variant="contained"
                                                    component="span"
                                                >
                                                    Upload
                                                </Button>
                                            )}
                                        </Grid>
                                        <Grid item md={4} />
                                    </>
                                )}

                                <Grid item md={12}>
                                    <div style={{ paddingBottom: '60px' }} />
                                </Grid>
                            </Grid>
                        )}
                    </Box>
                )}

                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 25, pb: 5 }}>
                    <Button
                        variant="outlined"
                        color="primary"
                        startIcon={<NavigateBeforeOutlined />}
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        sx={{ mr: 1 }}
                    >
                        Back
                    </Button>

                    <Button
                        variant="contained"
                        endIcon={<NavigateNextOutlined />}
                        color="primary"
                        disabled={
                            activeStep === STEP_SUMMARY || (activeStep === STEP_UPLOAD_FILE && (!file || !crmDataType))
                        }
                        onClick={handleNext}
                        sx={{ mr: 1 }}
                    >
                        Next
                    </Button>
                    <div style={{ flex: 1 }} />
                    <Link to="/salesDataUploads">
                        <Button variant="outlined" color="error" startIcon={<CloseOutlined />}>
                            Cancel
                        </Button>
                    </Link>
                </Box>
            </Box>
        </Box>
    ) : dealersLoaded ? (
        <div>You are not associated with a Dealership</div>
    ) : (
        <></>
    );
}
