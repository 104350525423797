import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    InputAdornment,
    InputProps,
    Stack,
    SxProps,
    TextField,
} from '@mui/material';
import Utils from './Utils';
import { ArrowDropDownOutlined, DateRangeOutlined } from '@mui/icons-material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateRangePicker, Range, RangeKeyDict, StaticRange } from 'react-date-range';
import { useEffect, useMemo, useState } from 'react';
import theme from '../theme';
import { isSameDay } from 'date-fns';

export default function CDDateRangePicker(props: {
    sx?: SxProps;
    InputProps?: InputProps;
    label?: string;
    startDate: Date;
    endDate: Date;
    disableFuture?: boolean;
    minDate?: Date;
    maxDate?: Date;
    ranges?: StaticRange[];
    onChange(startDate?: Date, endDate?: Date): void;
    onSelect?: (startDate?: Date, endDate?: Date) => void;
}) {
    const { label, startDate, endDate, ranges, minDate, maxDate, onChange, onSelect } = props;

    const defaultRanges: StaticRange[] = [
        {
            label: 'Last Month',
            range() {
                return {
                    startDate: Utils.getMonthStart(-1),
                    endDate: Utils.getMonthEnd(-1),
                };
            },
            isSelected: (range: Range) => {
                if (range.startDate && range.endDate) {
                    return (
                        isSameDay(range.startDate, Utils.getMonthStart(-1)) &&
                        isSameDay(range.endDate, Utils.getMonthEnd(-1))
                    );
                } else {
                    return false;
                }
            },
        },
        {
            label: 'This Month',
            range() {
                return {
                    startDate: Utils.getMonthStart(0),
                    endDate: Utils.getMonthEnd(0),
                };
            },
            isSelected: (range: Range) => {
                if (range.startDate && range.endDate) {
                    return (
                        isSameDay(range.startDate, Utils.getMonthStart(0)) &&
                        isSameDay(range.endDate, Utils.getMonthEnd(0))
                    );
                } else {
                    return false;
                }
            },
        },
    ];

    // if (!disableFuture) {
    //     defaultRanges.push({
    //         label: 'Next Month',
    //         range() {
    //             return {
    //                 startDate: Utils.getMonthStart(1),
    //                 endDate: Utils.getMonthEnd(1),
    //             };
    //         },
    //         isSelected: (range: Range) => range.startDate === Utils.getMonthStart(1),
    //     });
    // }

    const staticRanges = useMemo((): StaticRange[] => {
        return ranges ? ranges : defaultRanges;
    }, [ranges]);

    const [dateRange, setDateRange] = useState<Range>({
        startDate: startDate,
        endDate: endDate,
        key: 'selection',
    });

    useEffect(() => {
        setDateRange({
            startDate: startDate,
            endDate: endDate,
            key: 'selection',
        });
    }, [startDate, endDate]);

    const [open, setOpen] = useState(false);

    function handleDateChange(range: RangeKeyDict) {
        const current = range['selection'] as Range;
        setDateRange(current);

        staticRanges.forEach((range) => {
            const startDate: Date = range.range().startDate as Date;
            const endDate = range.range().endDate as Date;

            if (isSameDay(startDate, current.startDate as Date) && isSameDay(endDate, current.endDate as Date)) {
                onChange(current.startDate, current.endDate);
                setOpen(false);
            }
        });

        if (onSelect) {
            onSelect(current.startDate, current.endDate);
        }
    }

    function handleClose(dateRange: Range | null) {
        onChange(dateRange?.startDate, dateRange?.endDate);
        setOpen(false);
    }

    return (
        <>
            <TextField
                size="small"
                label={label}
                sx={{ width: '310px', ...props.sx }}
                value={Utils.formatESTDateLong(startDate) + ' - ' + Utils.formatESTDateLong(endDate)}
                onClick={() => setOpen(true)}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <DateRangeOutlined fontSize="small" color="action" />
                        </InputAdornment>
                    ),
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton edge="end">
                                <ArrowDropDownOutlined fontSize="small" color="action" />
                            </IconButton>
                        </InputAdornment>
                    ),
                    ...props.InputProps,
                }}
                InputLabelProps={{ shrink: true }}
            />
            <Dialog open={open}>
                <DialogTitle>{label}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <Box
                                sx={{
                                    '& .rdrInRange': {
                                        color: theme.palette.primary.light + ' !important',
                                    },
                                    '& .rdrStartEdge, .rdrEndEdge': {
                                        color: theme.palette.primary.dark + ' !important',
                                    },
                                    '& .rdrDateDisplayItemActive': {
                                        borderColor: 'transparent',
                                    },
                                    '& .rdrDateDisplayItem': {},
                                    '& .rdrDateDisplayItem input': {
                                        cursor: 'default',
                                    },
                                }}
                            >
                                <DateRangePicker
                                    onChange={handleDateChange}
                                    moveRangeOnFirstSelection={true}
                                    months={1}
                                    staticRanges={staticRanges}
                                    inputRanges={[]}
                                    ranges={[dateRange]}
                                    showMonthAndYearPickers={false}
                                    retainEndDateOnFirstSelection={true}
                                    fixedHeight={true}
                                    minDate={minDate}
                                    maxDate={maxDate}
                                    direction="horizontal"
                                />
                            </Box>
                        </LocalizationProvider>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Stack direction="row" spacing={2}>
                        <Button
                            onClick={() => setOpen(false)}
                            variant="outlined"
                            size="small"
                            color="inherit"
                            sx={{ width: 90 }}
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={() => handleClose(dateRange)}
                            variant="contained"
                            size="small"
                            color="primary"
                            sx={{ width: 90 }}
                        >
                            Apply
                        </Button>
                    </Stack>
                </DialogActions>
            </Dialog>
        </>
    );
}
