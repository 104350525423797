import { Box, IconButton, InputAdornment, TextField } from '@mui/material';
import CDGrid from '../../components/CDGrid';
import { ChangeEvent, useEffect, useState } from 'react';
// import ApiService from '../../ApiService';
import { DealerPolkAudience } from '../../types/DealerPolkAudience';
import Row from '../../components/Row';
import { ClearOutlined, SearchOutlined } from '@mui/icons-material';
import { GridColumns } from '@mui/x-data-grid';
import Utils from '../../components/Utils';

export default function PolkAudienceQueueGrid(props: { queues: DealerPolkAudience[] | null }) {
    const { queues } = props;

    const [rows, setRows] = useState<DealerPolkAudience[] | null>(null);
    const [allRows, setAllRows] = useState<DealerPolkAudience[]>([]);
    const [page, setPage] = useState(
        // Use a callback function so the local storage call only runs once.
        () => Number(localStorage.getItem('polkAudienceGridPage'))
    );
    const [searchText, setSearchText] = useState<string>(
        // Use a callback function so the local storage call only runs once.
        () => Utils.localStorage('polkAudienceSearch', '')
    );

    useEffect(() => {
        const initialSearchText = Utils.localStorage('polkAudienceSearch', '');

        setRows(queues);
        setAllRows(queues === null ? [] : queues);
        filterRows(initialSearchText, queues === null ? [] : queues);
    }, [queues]);

    function onPageChange(page: number) {
        setPage(page);
        localStorage.setItem('dealerGridPage', String(page));
    }

    function clearSearch() {
        setSearchText('');
        filterRows('', allRows);
        localStorage.setItem('polkAudienceSearch', '');
    }

    function search(event: ChangeEvent<HTMLInputElement>) {
        event.preventDefault();
        setSearchText(event.target.value);
        filterRows(event.target.value, allRows);
        localStorage.setItem('polkAudienceSearch', event.target.value);
    }

    function filterRows(searchText: string, allRows: DealerPolkAudience[]) {
        const filteredRows = allRows.filter((row: DealerPolkAudience) => {
            return (
                row.dealerName.toLowerCase().indexOf(searchText.toLowerCase()) > -1 ||
                row.agencyName.toLowerCase().indexOf(searchText.toLowerCase()) > -1
            );
        });
        setRows(filteredRows);
    }

    function GridToolbar() {
        return (
            <Row justifyContent="space-between" sx={{ mt: 2, mb: 3 }}>
                <TextField
                    inputRef={(input) => input && input.focus()}
                    id="searchText"
                    value={searchText}
                    size="small"
                    label="Search"
                    onChange={search}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchOutlined fontSize="small" />
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={() => clearSearch()} edge="end">
                                    <ClearOutlined fontSize="small" />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </Row>
        );
    }

    const columns: GridColumns = [
        {
            width: 200,
            field: 'agencyName',
            headerName: 'Agency Name',
            flex: 2,
        },
        {
            width: 200,
            field: 'dealerName',
            headerName: 'Advertiser Name',
            flex: 2,
        },
        {
            field: 'totalAudiences',
            headerName: 'Total Audiences',
            description: 'Total Audiences to Upload',
            flex: 1,
        },
        {
            field: 'priorityOrder',
            headerName: 'Priority Order',
            flex: 1,
        },
    ];

    return (
        <Box
            sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <GridToolbar />
            <CDGrid
                rowHeight={70}
                page={page}
                columns={columns}
                rows={rows === null ? [] : rows}
                loading={rows === null}
                autoHeight={false}
                autoPageSize={true}
                onPageChange={onPageChange}
            />
        </Box>
    );
}
