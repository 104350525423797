import { Dealer } from './Dealer';

export class ExportSite {
    id?: number;
    exportName: string = '';
    exportType: string = '';
    defaultCampaignParam: string = '';
    ftpServer: string = '';
    ftpUser: string = '';
    ftpPassword: string = '';
}

export class DealerExportSite {
    id?: number;
    dealerId?: number;
    dealer?: Dealer;
    exportSiteId?: number;
    exportSite?: ExportSite;
    active?: boolean = true;
    fallbackVersion?: boolean = false;
}
