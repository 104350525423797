import { styled, Table, tableCellClasses, tableRowClasses } from '@mui/material';

export const StyledTable = styled(Table)(({ theme }) => ({
    [`& .${tableRowClasses.root}`]: {
        backgroundColor: theme.palette.background.default,
    },
    [`& .${tableCellClasses.root}`]: {
        borderColor: theme.palette.divider,
    },
    [`& .${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.background.tableHeader,
    },
    '& .head': {
        padding: '5px 0px',
    },
}));
