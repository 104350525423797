import { Dialog, DialogContent } from '@mui/material';
import { AdvertiserDataSetSummary } from '../../../types/DataSet';
import { useEffect, useState } from 'react';
import ApiService from '../../../ApiService';
import { CDChart } from '../../../components/CDChart';
import MetricCard from '../../../components/MetricCard';

export default function DataSetDetailsDialog(props: {
    dataSetSummary: AdvertiserDataSetSummary | null;
    onClose: () => void;
}) {
    const [series, setSeries] = useState<ApexAxisChartSeries>([]);

    useEffect(() => {
        if (props.dataSetSummary) {
            ApiService.getDataSetDetails(props.dataSetSummary.dealerId, props.dataSetSummary.dataSetName)
                .then((response) => {
                    setSeries(response.data.series);
                })
                .catch(() => {});
        }
    }, [props.dataSetSummary]);

    return (
        <>
            <Dialog maxWidth="lg" open={props.dataSetSummary != null} onClose={props.onClose}>
                <DialogContent sx={{ padding: 0 }}>
                    <MetricCard
                        metric={
                            <CDChart
                                chartOptions={{
                                    markers: {
                                        strokeWidth: 1,
                                        size: 4,
                                    },
                                }}
                                series={series}
                                categories={['']}
                                width="700"
                                height="300"
                            />
                        }
                        label={
                            props.dataSetSummary?.dealerName +
                            ' : ' +
                            props.dataSetSummary?.dataSetName +
                            ' - Last 6 Months'
                        }
                    />
                </DialogContent>
            </Dialog>
        </>
    );
}
