import Button, { ButtonProps } from '@mui/material/Button';
import { forwardRef } from 'react';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';

export type LinkProps = ButtonProps & {
    linkRouterProps?: Omit<RouterLinkProps, 'to'>;
};

const RouterLinkBehavior = forwardRef<any, LinkProps>((props, ref) => {
    const { href, linkRouterProps, ...buttonProps } = props;
    const restRouterLinkProps = buttonProps as Omit<RouterLinkProps, 'to'>;
    return <RouterLink ref={ref} to={href ?? ''} {...linkRouterProps} {...restRouterLinkProps} />;
});

/**
 * A Button that works as a React Router Link
 */
export function Link(props: LinkProps) {
    return <Button LinkComponent={RouterLinkBehavior} {...props} variant="text" sx={{ p: 0 }} />;
}
