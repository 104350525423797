import { useEffect, useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';

import { Agency } from '../../types/Agency';
import ApiService from '../../ApiService';

const SelectAgency = ({ setAgency }: { setAgency: (agency: Agency | null) => void }) => {
    const [agencies, setAgencies] = useState<Agency[]>([]);

    useEffect(() => {
        ApiService.getAgencies()
            .then((response) => {
                setAgencies(response?.data || []);
            })
            .catch(() => {
                setAgencies([]);
            });
    }, []);

    return (
        <Autocomplete
            sx={{
                width: '400px',
            }}
            size="small"
            options={agencies}
            id="select-agency"
            getOptionLabel={(option) => option.name}
            renderOption={(props, option) => (
                <li {...props} key={option.id}>
                    {option.name}
                </li>
            )}
            isOptionEqualToValue={(option, value) => option?.id === value?.id}
            onChange={(e, value) => {
                setAgency(value ? value : null);
            }}
            renderInput={(params) => <TextField {...params} label="Agency" />}
        />
    );
};

export default SelectAgency;
