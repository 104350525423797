import { useMemo } from 'react';
import { Card, CardContent, Tooltip, Typography } from '@mui/material';
import {
    HelpOutlineOutlined as HelpOutlineOutlinedIcon,
    Moving as MovingIcon,
    TrendingDown as TrendingDownIcon,
} from '@mui/icons-material';

import theme from '../../theme';
import Column from '../../components/Column';
import Printable from '../../components/Printable';
import Row from '../../components/Row';
import SkeletonMetricCard from '../../components/Metrics/SkeletonMetricCard';
import Utils from '../../components/Utils';

export type Metric = {
    format: 'dollar' | 'decimal' | 'percent' | 'number';
    value: number;
    previousValue?: number;
    change?: number;
    changeLabel?: string | null;
    changePeriodLabel?: string | null | undefined | React.ReactElement;
};

export function calculateMetricChange(metric: Metric): number {
    let change: number = 0;

    if (metric.value !== undefined && metric.previousValue !== undefined) {
        if (metric.previousValue !== 0) {
            change = Utils.round((metric.value / metric.previousValue - 1) * 100, 2);
        }
    }

    return change;
}

export function formatMetricChangeLabel(metric: Metric): string {
    let changeLabel: string = '';
    let digits: number = 2;

    if (metric.change !== undefined) {
        if (metric.change > 0) {
            changeLabel = `${Utils.formatNumber(Utils.round(metric.change, digits), digits)}%`;
        } else {
            changeLabel = `${Utils.formatNumber(Utils.round(metric.change, digits), 0)}%`;
        }
    }

    return changeLabel;
}

export function setupMetricChange(metric: Metric): Metric {
    metric.change = calculateMetricChange(metric);
    metric.changeLabel = formatMetricChangeLabel(metric);

    return metric;
}

export default function MetricCard(props: {
    loading?: boolean;
    metric?: Metric;

    label: string | null | undefined | React.ReactElement;
    sublabel?: string | null | undefined | React.ReactElement;
    value?: string | null | undefined | React.ReactElement;
    direction?: 'up' | 'down' | 'center';
    tooltip?: string;
    inverseColor?: boolean;
    hideSublabel?: boolean;
}) {
    let { loading, metric, label, sublabel, value, direction, tooltip, inverseColor, hideSublabel = false } = props;

    function formatValue(metric: Metric, value: any): any {
        switch (metric.format) {
            case 'dollar':
                return Utils.formatCurrency(value, 2);

            case 'decimal':
                return Utils.formatNumber(Utils.round(value, 2));

            case 'percent':
                return Utils.formatNumber(Utils.round(value, 2)) + '%';

            default:
                return Utils.formatNumber(value);
        }
    }

    const metricColor = useMemo((): string => {
        if (metric && metric.change !== undefined) {
            if (inverseColor) {
                return metric.change > 0 ? theme.palette.error.main : theme.palette.success.main;
            }

            return metric.change > 0 ? theme.palette.success.main : theme.palette.error.main;
        }

        return '';
    }, [inverseColor, metric]);

    if (metric) {
        if (!sublabel) {
            sublabel = (
                <>
                    {metric.change !== undefined && (
                        <>
                            <span
                                style={{
                                    color: metricColor,
                                }}
                            >
                                {metric.changeLabel}
                            </span>

                            <span> vs. {metric?.changePeriodLabel ?? 'Previous Month'}</span>
                        </>
                    )}
                </>
            );
        }

        if (!value) {
            value = formatValue(metric, metric.value) as string;
        }

        if (!direction) {
            if (metric.change !== undefined) {
                if (metric.change > 0) {
                    direction = 'up';
                } else if (metric.change < 0) {
                    direction = 'down';
                } else {
                    direction = 'center';
                }
            }
        }
    }

    if (loading) {
        return <SkeletonMetricCard hideSublabel={hideSublabel} />;
    }

    return (
        <Card variant="outlined">
            <CardContent
                sx={{
                    minHeight: 128,
                    '&.MuiCardContent-root:last-child': {
                        pb: theme.spacing(2),
                    },
                }}
            >
                <Column gap={hideSublabel ? 2 : 0} justifyContent="space-between">
                    <Row gap={0.5} alignItems="center" sx={{ mb: 3 }}>
                        <Typography variant="body1" color="text.secondary">
                            {label}
                        </Typography>

                        <Printable show={false}>
                            {tooltip ? (
                                <Tooltip title={tooltip}>
                                    <HelpOutlineOutlinedIcon color="action" sx={{ fontSize: 16 }} />
                                </Tooltip>
                            ) : (
                                ''
                            )}
                        </Printable>
                    </Row>

                    <Column gap={1}>
                        <Typography
                            variant="h5"
                            component="div"
                            color="text.primary"
                            sx={{
                                fontSize: 24,
                            }}
                        >
                            {value}
                        </Typography>

                        {hideSublabel === false && (
                            <Row gap={0.5}>
                                {sublabel ? (
                                    <>
                                        {direction === 'up' && (
                                            <MovingIcon color={inverseColor ? 'error' : 'success'} />
                                        )}
                                        {direction === 'down' && (
                                            <TrendingDownIcon color={inverseColor ? 'success' : 'error'} />
                                        )}

                                        <Typography
                                            variant="body1"
                                            color="text.primary"
                                            sx={{
                                                fontSize: 14,
                                            }}
                                        >
                                            {sublabel}
                                        </Typography>
                                    </>
                                ) : (
                                    <>&nbsp;</>
                                )}
                            </Row>
                        )}
                    </Column>
                </Column>
            </CardContent>
        </Card>
    );
}
