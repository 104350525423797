import { Dealer } from '../../types/Dealer';
import { Box, Drawer, IconButton, InputAdornment, Paper, Stack, Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { PolkDealer, PolkDealerSearch } from '../../types/Polk';
import ApiService from '../../ApiService';
import Row from '../../components/Row';
import { AddCircleOutline, ClearOutlined, RemoveCircleOutline, SearchOutlined } from '@mui/icons-material';
import CDGrid from '../../components/CDGrid';
import CDButton, { CDDefaultButton } from '../../components/CDButton';
import { GridColumns, GridRenderCellParams, GridRowId } from '@mui/x-data-grid';
import { CDTextField } from '../../components/CDTextField';
import theme from '../../theme';
import Utils from '../../components/Utils';

type PolkDealerMappingDrawerProps = {
    mappingType: 'advertiser' | 'competitor';
    dealer: Dealer;
    defaultSelectedDealers: PolkDealer[];
    onClose(items: PolkDealer[], action: 'cancel' | 'save'): void;
    onSearch(items: PolkDealer[], setPolkDealersCallback: Function): void;
    open: boolean;
};

export default function PolkDealerMappingDrawer(props: PolkDealerMappingDrawerProps) {
    const { mappingType, dealer, defaultSelectedDealers, open, onClose, onSearch } = props;

    const [dealerSearch, setDealerSearch] = useState<PolkDealerSearch>({ dealerName: '', state: dealer.state });
    const [polkDealers, setPolkDealers] = useState<PolkDealer[]>([]);
    const [selectedItems, setSelectedItems] = useState<GridRowId[]>([]);
    const [selectionItems, setSelectionItems] = useState<GridRowId[]>([]);
    const [selectedDealers, setSelectedDealers] = useState<PolkDealer[]>([]);
    const [selectedDealersPage, setSelectedDealersPage] = useState<number>(0);
    const selectionDealers = useMemo<PolkDealer[]>(() => {
        return polkDealers.filter((_polkDealer) => {
            return (
                selectedDealers.find((_selectedPolkDealer) => _polkDealer.id === _selectedPolkDealer.id) === undefined
            );
        });
    }, [selectedDealers, polkDealers]);
    const showBulkAddButton = useMemo(() => {
        return selectionItems.length > 1;
    }, [selectionItems]);
    const showBulkRemoveButton = useMemo(() => {
        return selectedItems.length > 1;
    }, [selectedItems]);

    useEffect(() => {
        setSelectedDealers(defaultSelectedDealers);
    }, [defaultSelectedDealers]);

    function search() {
        ApiService.searchPolkDealer(dealerSearch).then((response) => {
            setPolkDealers(response.data);

            onSearch(response.data, setPolkDealers);
        });
    }

    function attachPolkDealer(polkDealer: PolkDealer) {
        setSelectedDealers([...selectedDealers, polkDealer]);
    }

    function attachManyPolkDealer(polkDealers: PolkDealer[]) {
        setSelectedDealers([...selectedDealers, ...polkDealers]);
    }

    function detachPolkDealer(polkDealer: PolkDealer) {
        let filteredPolkDealers = selectedDealers.filter(
            (_selectedPolkDealer) => _selectedPolkDealer.id !== polkDealer.id
        );
        setSelectedDealers(filteredPolkDealers);
    }

    function detachManyPolkDealer(polkDealers: PolkDealer[]) {
        let filteredPolkDealers = selectedDealers.filter(
            (_selectedPolkDealer) => polkDealers.indexOf(_selectedPolkDealer) === -1
        );
        setSelectedDealers(filteredPolkDealers);
    }

    const defaultDealerGridColumns: GridColumns = [
        {
            headerName: 'Dealer',
            field: 'dealerName',
            width: 200,
            flex: 2,
        },
        {
            headerName: 'Address',
            field: 'address',
            width: 200,
            flex: 2,
        },
        {
            headerName: 'City',
            field: 'city',
            width: 200,
            flex: 1,
        },
        {
            headerName: 'State',
            field: 'state',
            width: 50,
            flex: 1,
        },
        {
            headerName: Utils.formatDate(Utils.getMonthStart(-2), 'MMMM'),
            field: 'priorMonthSales',
            align: 'right',
            headerAlign: 'right',
        },
        {
            headerName: Utils.formatDate(Utils.getMonthStart(-1), 'MMMM'),
            field: 'lastMonthSales',
            align: 'right',
            headerAlign: 'right',
        },
    ];

    const selectionDealerGridColumns: GridColumns = [
        {
            headerName: '',
            field: 'actions',
            width: 50,
            hideSortIcons: true,
            disableColumnMenu: true,
            sortable: false,
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <IconButton
                        onClick={() => {
                            attachPolkDealer(params.row);

                            let itemCount: number = selectedDealers.length + 1;
                            let page: number = Math.floor(itemCount / 5);
                            page = (page > 0 ? page : 1) + 1;

                            setSelectedDealersPage(page);
                        }}
                    >
                        <AddCircleOutline fontSize="small" />
                    </IconButton>
                );
            },
        },
        ...defaultDealerGridColumns,
    ];

    const selectedDealerGridColumns: GridColumns = [
        {
            headerName: '',
            field: 'actions',
            width: 50,
            hideSortIcons: true,
            disableColumnMenu: true,
            sortable: false,
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <IconButton
                        onClick={() => {
                            detachPolkDealer(params.row);
                        }}
                    >
                        <RemoveCircleOutline fontSize="small" color="error" />
                    </IconButton>
                );
            },
        },
        ...defaultDealerGridColumns,
    ];

    function SelectionNoRowsOverlay() {
        return (
            <Stack spacing={1} justifyContent="center" alignItems="center" sx={{ height: '70px' }}>
                <Typography variant="subtitle2">Enter value(s) and click 'Search' to see results.</Typography>
            </Stack>
        );
    }

    function SelectedNoRowsOverlay() {
        return (
            <Stack spacing={1} justifyContent="center" alignItems="center" sx={{ height: '70px' }}>
                <Typography variant="subtitle2">Selections will appear here.</Typography>
            </Stack>
        );
    }

    return (
        <>
            <Drawer
                anchor="right"
                open={open}
                onClose={() => onClose([], 'cancel')}
                sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
            >
                <Stack
                    direction="column"
                    justifyContent="space-between"
                    sx={{ minHeight: '480px', height: '100%', width: '1000px' }}
                >
                    <>
                        <div style={{ paddingBottom: '16px' }}>
                            <Box sx={{ paddingX: 3, paddingY: 2, borderBottom: 1, borderColor: 'divider' }}>
                                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                                    <Stack spacing={1}>
                                        <Typography variant="body1">
                                            <span style={{ color: theme.palette.text.secondary }}>
                                                {dealer.dealerName}
                                            </span>
                                            <span> / </span>
                                            {mappingType === 'advertiser' ? 'Add Advertiser(s)' : 'Add Competitor(s)'}
                                        </Typography>
                                        <Typography variant="body2">
                                            {mappingType === 'advertiser'
                                                ? 'To add Advertiser(s), please provide their names or addresses along with the state.'
                                                : 'To add Competitor(s), please provide their names or addresses along with the state.'}
                                        </Typography>
                                    </Stack>

                                    <IconButton onClick={() => onClose([], 'cancel')} edge="end">
                                        <ClearOutlined fontSize="small" color="action" />
                                    </IconButton>
                                </Stack>
                            </Box>
                            <Box sx={{ paddingX: 3, paddingY: 3 }}>
                                <Paper
                                    variant="outlined"
                                    elevation={0}
                                    sx={{
                                        height: '100%',
                                    }}
                                >
                                    <Stack direction="column">
                                        <Stack>
                                            <Row sx={{ padding: 2 }}>
                                                <CDTextField
                                                    sx={{ width: '300px' }}
                                                    id="searchText"
                                                    value={dealerSearch.dealerName}
                                                    autoComplete="off"
                                                    size="small"
                                                    label={
                                                        mappingType === 'advertiser'
                                                            ? 'Advertiser Name / Address'
                                                            : 'Competitor Name / Address'
                                                    }
                                                    placeholder="Advertiser name, street, or city"
                                                    onKeyDown={(event) => {
                                                        if (['Enter', 'NumpadEnter'].includes(event.code) === true) {
                                                            search();
                                                        }
                                                    }}
                                                    onChange={(event) => {
                                                        setDealerSearch((prev) => {
                                                            return { ...prev, dealerName: event.target.value };
                                                        });
                                                    }}
                                                    InputProps={{
                                                        endAdornment: dealerSearch.dealerName ? (
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    onClick={() =>
                                                                        setDealerSearch((prev) => {
                                                                            return { ...prev, dealerName: '' };
                                                                        })
                                                                    }
                                                                    edge="end"
                                                                >
                                                                    <ClearOutlined fontSize="small" color="action" />
                                                                </IconButton>
                                                            </InputAdornment>
                                                        ) : (
                                                            ''
                                                        ),
                                                    }}
                                                />
                                                <CDTextField
                                                    sx={{ width: '120px' }}
                                                    value={dealerSearch.state ?? ''}
                                                    autoComplete="off"
                                                    placeholder="e.g., FL"
                                                    size="small"
                                                    label="State"
                                                    onKeyDown={(event) => {
                                                        if (['Enter', 'NumpadEnter'].includes(event.code) === true) {
                                                            search();
                                                        }
                                                    }}
                                                    onChange={(event) =>
                                                        setDealerSearch({ ...dealerSearch, state: event.target.value })
                                                    }
                                                    InputProps={{
                                                        endAdornment: dealerSearch?.state ? (
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    onClick={() =>
                                                                        setDealerSearch({ ...dealerSearch, state: '' })
                                                                    }
                                                                    edge="end"
                                                                >
                                                                    <ClearOutlined fontSize="small" color="action" />
                                                                </IconButton>
                                                            </InputAdornment>
                                                        ) : (
                                                            ''
                                                        ),
                                                    }}
                                                />
                                                <CDButton
                                                    variant="contained"
                                                    startIcon={<SearchOutlined />}
                                                    onClick={search}
                                                    disabled={!dealerSearch.dealerName}
                                                >
                                                    Search
                                                </CDButton>
                                            </Row>
                                            <Box sx={{ paddingX: 2, paddingBottom: 2 }}>
                                                <Typography variant="subtitle1">
                                                    {mappingType === 'advertiser'
                                                        ? 'Available Advertiser(s)'
                                                        : 'Available Competitor(s)'}
                                                </Typography>
                                                <Typography
                                                    variant="subtitle2"
                                                    sx={{
                                                        visibility: polkDealers.length > 0 ? 'visible' : 'hidden',
                                                    }}
                                                >
                                                    {selectionItems.length}/{selectionDealers.length} selected
                                                </Typography>
                                            </Box>
                                            <Box sx={{ paddingX: 2, paddingBottom: 1 }}>
                                                <CDButton
                                                    variant="text"
                                                    color="primary"
                                                    size="small"
                                                    startIcon={<AddCircleOutline />}
                                                    onClick={() => {
                                                        let selected = polkDealers.filter(
                                                            (d) => selectionItems.indexOf(d.id) > -1
                                                        );
                                                        attachManyPolkDealer(selected);

                                                        let itemCount: number =
                                                            selectedDealers.length + selected.length;
                                                        let page: number = Math.floor(itemCount / 5);
                                                        page = (page > 0 ? page : 1) + 1;

                                                        setSelectedDealersPage(page);
                                                    }}
                                                    sx={{
                                                        visibility: showBulkAddButton === true ? 'visible' : 'hidden',
                                                    }}
                                                >
                                                    Bulk Add
                                                </CDButton>
                                            </Box>
                                        </Stack>
                                        <Box
                                            sx={{
                                                height: selectionDealers.length > 0 ? 'auto' : '200px',
                                                width: '100%',
                                                flexGrow: 1,
                                            }}
                                        >
                                            <CDGrid
                                                variant="elevation"
                                                autoPageSize={selectionDealers.length > 0 ? false : true}
                                                autoHeight={selectionDealers.length > 0 ? true : false}
                                                pageSize={5}
                                                columns={selectionDealerGridColumns}
                                                rows={selectionDealers}
                                                selectionsChanged={(selectionModel) => {
                                                    setSelectionItems(selectionModel);
                                                }}
                                                checkboxSelection={true}
                                                components={{
                                                    NoRowsOverlay: SelectionNoRowsOverlay,
                                                }}
                                                PaperProps={{
                                                    elevation: 0,
                                                }}
                                            />
                                        </Box>
                                    </Stack>
                                </Paper>
                            </Box>
                            <Box sx={{ paddingX: 3, paddingY: 0 }}>
                                <Paper
                                    variant="outlined"
                                    elevation={0}
                                    sx={{
                                        height: '100%',
                                    }}
                                >
                                    <Stack direction="column">
                                        <Stack>
                                            <Box sx={{ padding: 2 }}>
                                                <Typography variant="subtitle1">
                                                    {mappingType === 'advertiser'
                                                        ? 'Selected Advertiser(s)'
                                                        : 'Selected Competitor(s)'}
                                                </Typography>
                                                <Typography
                                                    variant="subtitle2"
                                                    sx={{
                                                        visibility: selectedDealers.length > 0 ? 'visible' : 'hidden',
                                                    }}
                                                >
                                                    {selectedItems.length}/{selectedDealers.length} selected
                                                </Typography>
                                            </Box>
                                            <Box sx={{ paddingX: 2, paddingBottom: 1 }}>
                                                <CDButton
                                                    variant="text"
                                                    color="error"
                                                    size="small"
                                                    startIcon={<RemoveCircleOutline />}
                                                    onClick={() => {
                                                        let selected = selectedDealers.filter(
                                                            (d) => selectedItems.indexOf(d.id) > -1
                                                        );
                                                        detachManyPolkDealer(selected);
                                                    }}
                                                    sx={{
                                                        visibility:
                                                            showBulkRemoveButton === true ? 'visible' : 'hidden',
                                                    }}
                                                >
                                                    Bulk Remove
                                                </CDButton>
                                            </Box>
                                        </Stack>
                                        <Box
                                            sx={{
                                                height: selectedDealers.length > 0 ? 'auto' : '200px',
                                                width: '100%',
                                                flexGrow: 1,
                                            }}
                                        >
                                            <CDGrid
                                                variant="elevation"
                                                autoPageSize={selectedDealers.length > 0 ? false : true}
                                                autoHeight={selectedDealers.length > 0 ? true : false}
                                                pageSize={5}
                                                page={selectedDealersPage}
                                                columns={selectedDealerGridColumns}
                                                rows={selectedDealers}
                                                selectionsChanged={(selectionModel) => {
                                                    setSelectedItems(selectionModel);
                                                }}
                                                checkboxSelection={true}
                                                components={{
                                                    NoRowsOverlay: SelectedNoRowsOverlay,
                                                }}
                                                PaperProps={{
                                                    elevation: 0,
                                                }}
                                                onPageChange={(page: number) => {
                                                    setSelectedDealersPage(page);
                                                }}
                                            />
                                        </Box>
                                    </Stack>
                                </Paper>
                            </Box>
                        </div>
                    </>
                    <Box
                        sx={{
                            backgroundColor: theme.palette.common.white,
                            marginTop: 2,
                            paddingX: 3,
                            paddingY: 2,
                            borderTop: 1,
                            borderColor: 'divider',
                            position: 'sticky',
                            bottom: 0,
                            left: 0,
                            right: 0,
                        }}
                    >
                        <Row justifyContent="flex-end" gap={3}>
                            <CDButton onClick={() => onClose([], 'cancel')}>Cancel</CDButton>
                            <CDDefaultButton
                                // disabled={selectedDealers.length === 0}
                                onClick={() => onClose(selectedDealers, 'save')}
                                color="primary"
                            >
                                Update
                            </CDDefaultButton>
                        </Row>
                    </Box>
                </Stack>
            </Drawer>
        </>
    );
}
