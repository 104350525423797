import { useEffect, useState } from 'react';
import { DateRange } from '@mui/x-date-pickers-pro/models';
import dayjs, { Dayjs } from 'dayjs';
import moment from 'moment-timezone';

import Utils from '../../components/Utils';
import { IFilterItem } from './SalesInsights';
import { getWeeklyStaticRange } from '../../components/DateRangePicker';
import { StaticRange } from '../../components/DateRangePicker';
import DateRangePicker from '../../components/DateRangePicker';
import { adjustDateRange, getDefaultDates } from './utils';

const getDefaultDateRanges = (): StaticRange[] => {
    const dateRanges: StaticRange[] = [];
    const today = new Date();

    dateRanges.push(
        getWeeklyStaticRange('Previous Week', today, 1),
        getWeeklyStaticRange('Last 2 Weeks', today, 2),
        getWeeklyStaticRange('Last 4 Weeks', today, 4),
        getWeeklyStaticRange('Last 8 Weeks', today, 8),
        getWeeklyStaticRange('Last 12 Weeks', today, 12)
    );

    return dateRanges;
};

const dateRanges: StaticRange[] = getDefaultDateRanges();

const SelectMonth = ({
    id,
    setFilters,
    filters,
}: {
    id: number;
    setFilters: (filters: IFilterItem[]) => void;
    filters: IFilterItem[];
}) => {
    const {
        minDate: defaultMinDate,
        maxDate: defaultMaxDate,
        startDate: defaultStartDate,
        endDate: defaultEndDate,
    } = getDefaultDates();

    const [minDate, setMinDate] = useState(new Date());
    const [maxDate, setMaxDate] = useState(new Date());
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());

    const handleDateRangeChange = (newDateRange: DateRange<Dayjs>): void => {
        const startDate: Date | null | undefined = newDateRange[0]?.toDate();
        const endDate: Date | null | undefined = newDateRange[1]?.toDate();

        if (startDate || endDate) {
            const dateRange = adjustDateRange(startDate ?? new Date(), endDate ?? new Date());

            const newFilters = [...filters];
            newFilters[id]?.children?.splice(0, newFilters[id]?.children?.length);
            newFilters[id]?.children?.push({
                id:
                    moment(dateRange.startDate).format('YYYY-MM-DD') +
                    '@' +
                    moment(dateRange.endDate).format('YYYY-MM-DD'),

                label:
                    moment(dateRange.startDate).format('MMMM DD YYYY') +
                    '-' +
                    moment(dateRange.endDate).format('MMMM DD YYYY'),
            });
            setFilters(newFilters);
        }
    };

    useEffect(() => {
        setMinDate(defaultMinDate);
        setMaxDate(defaultMaxDate);
        setStartDate(defaultStartDate);
        setEndDate(defaultEndDate);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        let newStartDate: Date | null = defaultStartDate;
        let newEndDate: Date | null = defaultEndDate;

        if (filters?.[id]?.children?.length === 1) {
            const ids = filters?.[id]?.children?.[0]?.id?.split('@');
            if (ids?.length === 2) {
                newStartDate = Utils.formatStringToDate(ids[0]);
                newEndDate = Utils.formatStringToDate(ids[1]);
            }
        }

        if (newStartDate) {
            setStartDate(newStartDate);
        }
        if (newEndDate) {
            setEndDate(newEndDate);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters, id, maxDate]);

    return (
        <DateRangePicker
            label="Date Range"
            ranges={dateRanges}
            minDate={dayjs(minDate)}
            maxDate={dayjs(maxDate)}
            disableFuture={true}
            value={[dayjs(startDate), dayjs(endDate)]}
            onChange={handleDateRangeChange}
            onSelect={handleDateRangeChange}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
        />
    );
};

export default SelectMonth;
