import { Skeleton } from '@mui/material';

import Column from '../../components/Column';
import Row from '../../components/Row';

export default function SkeletonBasicBarChart() {
    return (
        <Row sx={{ height: 300 }}>
            {false && (
                <Column gap={3}>
                    <Skeleton width={30} height={15} />

                    <Skeleton width={30} height={15} />

                    <Skeleton width={30} height={15} />
                </Column>
            )}

            <Column gap={1} sx={{ flexGrow: 1, px: 3, py: 6 }}>
                <Column
                    gap={3}
                    alignItems="flex-start"
                    justifyContent="center"
                    sx={{
                        borderRightWidth: 0,
                        borderTopWidth: 0,
                        borderLeftWidth: 1,
                        borderBottomWidth: 1,
                        borderStyle: 'solid',
                        borderColor: 'divider',

                        py: 2,
                    }}
                >
                    <Skeleton variant="rectangular" width={600} height={30} />

                    <Skeleton variant="rectangular" width={450} height={30} />

                    <Skeleton variant="rectangular" width={300} height={30} />
                </Column>

                <Row justifyContent="space-between">
                    {Array.from({ length: 16 }).map((_, index) => (
                        <Skeleton key={index} width={30} height={15} />
                    ))}
                </Row>
            </Column>
        </Row>
    );
}
