import { useContext, useMemo } from 'react';
import {
    // Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';

import { isPerformanceMediaType } from '../../../../../hooks/useCampaignPerformance';
import { Performance } from '../../../../../types/CampaignPerformance';
import { ReportingCriteria } from '../../../../../types/Analytics/Reporting/ReportingCriteria';
import { Table, TableCellText, TablePrimaryCell } from '../../../../../components/Metrics/MetricTable';
import { UserContext } from '../../../../../App';
import Utils from '../../../../../components/Utils';

interface MediaPerformanceListItem {
    mediaType: string;
    totalCost: number;
    impressions: number;
    CPM: number;
    dailyReach: number;
    clickthroughs: number;
    offAmazonConversions: number;
}
interface MediaCampaignPerformanceListItem {
    campaignName: string;
    mediaType: string;
    totalCost: number;
    impressions: number;
    CPM: number;
    dailyReach: number;
    clickthroughs: number;
    offAmazonConversions: number;
}

export type MediaPerformanceListTableProps = {
    reportingCriteria: ReportingCriteria;
    performances: Performance[];
};

export function MediaPerformanceListTable(props: MediaPerformanceListTableProps) {
    const { reportingCriteria, performances } = props;

    const { userContext } = useContext(UserContext);

    const mediaTypes = useMemo((): string[] => {
        if (reportingCriteria.mediaType) {
            return [reportingCriteria.mediaType];
        }

        if (userContext.isAdmin()) {
            return ['Display', 'Video', 'Online Video', 'Streaming TV', 'Audio'];
        }

        return ['Display', 'Online Video', 'Streaming TV', 'Audio'];
    }, [reportingCriteria.mediaType]);

    const items = useMemo((): MediaPerformanceListItem[] => {
        return mediaTypes.map((mediaType: string) => {
            let item: MediaPerformanceListItem = {
                mediaType: mediaType,
                totalCost: 0,
                impressions: 0,
                CPM: 0,
                dailyReach: 0,
                clickthroughs: 0,
                offAmazonConversions: 0,
            };

            const mediaTypePerformances: Performance[] = performances.filter((perfomance: Performance): boolean => {
                return isPerformanceMediaType(perfomance, mediaType);
            });

            if (mediaTypePerformances.length > 0) {
                mediaTypePerformances.forEach((perfomance: Performance) => {
                    item.totalCost += perfomance.totalCost;
                    item.impressions += perfomance.impressions;
                    item.CPM += perfomance.CPM;
                    item.dailyReach += perfomance.dailyReach;
                    item.clickthroughs += perfomance.clickthroughs;
                    item.offAmazonConversions += perfomance.offAmazonConversions;
                });

                item.CPM /= mediaTypePerformances.length;
            }

            return item;
        });
    }, [mediaTypes, performances]);

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Media</TableCell>
                    <TableCell>Total Investment</TableCell>
                    <TableCell>Total Exposures</TableCell>
                    <TableCell>Average CPM</TableCell>
                    <TableCell>Household Reach</TableCell>
                    <TableCell>Clicks</TableCell>
                    <TableCell>
                        <TableCellText tooltip="Conversions as reported by the DSP">Conversions</TableCellText>
                    </TableCell>
                </TableRow>
            </TableHead>

            <TableBody>
                {items.map((item: MediaPerformanceListItem, index: number) => (
                    <TableRow key={index}>
                        <TablePrimaryCell>{item.mediaType}</TablePrimaryCell>
                        <TableCell>{Utils.formatValue(item.totalCost, 'dollar')}</TableCell>
                        <TableCell>{Utils.formatValue(item.impressions)}</TableCell>
                        <TableCell>{Utils.formatValue(item.CPM, 'dollar')}</TableCell>
                        <TableCell>{Utils.formatValue(item.dailyReach)}</TableCell>
                        <TableCell>{Utils.formatValue(item.clickthroughs)}</TableCell>
                        <TableCell>{Utils.formatValue(item.offAmazonConversions)}</TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
}

export type MediaCampaignPerformanceListTableProps = {
    mediaType: string;
    performances: Performance[];
};

export function MediaCampaignPerformanceListTable(props: MediaCampaignPerformanceListTableProps) {
    const { mediaType, performances } = props;

    const items = useMemo((): MediaCampaignPerformanceListItem[] => {
        const list: Record<string, MediaCampaignPerformanceListItem> = {};

        performances
            .filter((perfomance: Performance): boolean => {
                return isPerformanceMediaType(perfomance, mediaType);
            })
            .forEach((perfomance: Performance): void => {
                if (perfomance.campaignName in list) {
                    const item: MediaCampaignPerformanceListItem = list[perfomance.campaignName];

                    item.totalCost += perfomance.totalCost;
                    item.impressions += perfomance.impressions;
                    item.CPM += perfomance.CPM;
                    item.dailyReach += perfomance.dailyReach;
                    item.clickthroughs += perfomance.clickthroughs;
                    item.offAmazonConversions += perfomance.offAmazonConversions;

                    list[perfomance.campaignName] = item;
                } else {
                    list[perfomance.campaignName] = perfomance;
                }
            });

        return Object.values(list);
    }, [mediaType, performances]);

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Campaign</TableCell>
                    <TableCell>Total Investment</TableCell>
                    <TableCell>Total Exposures</TableCell>
                    <TableCell>Average CPM</TableCell>
                    <TableCell>Household Reach</TableCell>
                    <TableCell>Clicks</TableCell>
                    <TableCell>
                        <TableCellText tooltip="Conversions as reported by the DSP">Conversions</TableCellText>
                    </TableCell>
                </TableRow>
            </TableHead>

            <TableBody>
                {items.map((item: MediaCampaignPerformanceListItem, index: number) => (
                    <TableRow key={index}>
                        <TablePrimaryCell>{item.campaignName}</TablePrimaryCell>
                        <TableCell>{Utils.formatValue(item.totalCost, 'dollar')}</TableCell>
                        <TableCell>{Utils.formatValue(item.impressions)}</TableCell>
                        <TableCell>{Utils.formatValue(item.CPM, 'dollar')}</TableCell>
                        <TableCell>{Utils.formatValue(item.dailyReach)}</TableCell>
                        <TableCell>{Utils.formatValue(item.clickthroughs)}</TableCell>
                        <TableCell>{Utils.formatValue(item.offAmazonConversions)}</TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
}

export type ReportingPerformanceTableProps = {
    reportingCriteria: ReportingCriteria;
    performances: Performance[];
};

export default function ReportingPerformanceTable(props: ReportingPerformanceTableProps) {
    const { performances, reportingCriteria } = props;

    return (
        <TableContainer
            sx={{
                borderCollapse: 'inherit',
                borderColor: (theme) => theme.palette.grey[300],
                borderStyle: 'solid',
                borderWidth: 1,
                borderRadius: '8px',
                overflow: 'hidden',
            }}
        >
            <MediaPerformanceListTable reportingCriteria={reportingCriteria} performances={performances} />
        </TableContainer>
    );
}
