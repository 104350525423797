import { useState } from 'react';
import { styled } from '@mui/material/styles';
import { Close as CloseIcon } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import AmcInstanceForm from './AmcInstanceForm';
import { AmcInstance } from '../../../types/AmcInstance';

const StyledDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

type AmcInstanceCreateFormDialogProps = {
    open: boolean;
    onClose: () => void;
};

export default function AmcInstanceCreateFormDialog(props: AmcInstanceCreateFormDialogProps) {
    const [open, setOpen] = useState<boolean>(props.open);
    const [save, setSave] = useState<boolean>(false);
    const [validated, setValidated] = useState<boolean>(false);

    const handleClose = () => {
        setOpen(false);
        setSave(false);

        props.onClose();
    };

    const handleSave = (amcInstance: AmcInstance) => {
        handleClose();
    };

    const handleValidate = (_validated: boolean) => {
        setValidated(_validated);
    };

    const handleClickSave = () => {
        setSave(true);
    };

    return (
        <>
            <StyledDialog maxWidth="md" onClose={handleClose} open={open}>
                <DialogTitle sx={{ m: 0, p: 2 }}>Add AMC Instance</DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <AmcInstanceForm
                        size="sm"
                        hideActions={true}
                        amcInstance={{
                            id: 0,
                            name: '',
                            instanceName: '',
                            instanceId: '',
                            awsAccountId: '',
                            owningEntityId: '',
                            isNew: true,
                        }}
                        onSave={handleSave}
                        onValidate={handleValidate}
                        save={save}
                    />
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" color="error" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        autoFocus
                        disabled={validated === false}
                        onClick={handleClickSave}
                    >
                        Create Instance
                    </Button>
                </DialogActions>
            </StyledDialog>
        </>
    );
}
