import { Typography } from '@mui/material';

export default function SummaryText(props: { label: string; value: string | null | undefined | React.ReactElement }) {
    return (
        <>
            <Typography variant="h5">{props.label}</Typography>
            {props.value}
        </>
    );
}
