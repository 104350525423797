import { Box, CardContent, CardHeader, Grid, MenuItem, Typography } from '@mui/material';
import MapComponent, { ZipCode } from './MapComponent';
import { ForecastingParameters } from '../../../types/AdvertiserRequest';
import { useEffect, useState } from 'react';
import { Dealer } from '../../../types/Dealer';
import Column from '../../../components/Column';
import Row from '../../../components/Row';
import { CDTextField } from '../../../components/CDTextField';
import AudienceDefinition from './AudienceDefinition';
import { CampaignCard } from './CampaignCard';
import { Campaign, CampaignChangeRequest } from '../../../types/Campaign';

export default function StepGeoTargeting(props: {
    campaign: CampaignChangeRequest;
    dealer: Dealer | null;
    forecastingParameters: ForecastingParameters;
    onChange(value: Partial<Campaign>): void;
}) {
    const { campaign, dealer, forecastingParameters, onChange } = props;
    const complete = campaign.status === 'Complete';
    const [centerPoint, setCenterPoint] = useState('');
    const [mapCenter, setMapCenter] = useState('');

    useEffect(() => {
        if (dealer) {
            setCenterPoint(getAddress(dealer, centerPoint));
            setMapCenter(getAddress(dealer, centerPoint));
        }
    }, [dealer]);

    function recenterMap() {
        onChange({ zipcodes: '' });
        setMapCenter(centerPoint);
    }

    function getAddress(dealer: Dealer, address: string): string {
        if (!address) {
            if (dealer) {
                if (dealer.address) address = dealer.address;
                if (dealer.city) address += ' ' + dealer.city;
                if (dealer.state) address += ' ' + dealer.state;
                if (dealer.zip) address += ' ' + dealer.zip;
            }
        }
        return address;
    }

    function zipCodesLoaded(results: ZipCode[]) {
        const zipCodeString = results.map(({ code }) => code).join(',');
        const population = results.reduce((acc, r) => acc + r.population, 0);
        const defaultLocationZipCode = '67420';

        if (
            mapCenter === '' ||
            (mapCenter === undefined && results && results.length === 1 && results[0].code === defaultLocationZipCode)
        ) {
            onChange({
                zipcodes: '',
                population: Math.round(0 / 1000) * 1000,
                availableReach: 0,
            });
        } else {
            onChange({
                zipcodes: zipCodeString,
                population: Math.round(population / 1000) * 1000,
            });
        }
    }

    function zipCodeClick(zip: ZipCode) {
        let zips = campaign.zipcodes?.split(',') || [];
        let population = campaign.population ? campaign.population : 0;
        const index = zips.indexOf(zip.code);
        if (index !== -1) {
            zips = [...zips.slice(0, index), ...zips.slice(index + 1)];
            population -= zip.population;
        } else {
            zips = [...zips, zip.code];
            population += zip.population;
        }

        onChange({
            zipcodes: zips.join(),
            population: population,
        });
    }

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={8}>
                    <CampaignCard elevation={4}>
                        <CardHeader title="Geography Targeting" />
                        <CardContent>
                            <Column>
                                <Row>
                                    <CDTextField
                                        label="Target Region / DMA"
                                        variant="outlined"
                                        onBlur={recenterMap}
                                        onChange={(e) => setCenterPoint(e.target.value)}
                                        value={centerPoint ?? ''}
                                        shrinkLabel
                                        disabled={complete}
                                    />
                                    <CDTextField
                                        label="Radius(mi.)"
                                        variant="outlined"
                                        select
                                        shrinkLabel
                                        value={campaign.zipcodeRadius}
                                        onChange={(e) =>
                                            onChange({
                                                zipcodeRadius: Number(e.target.value),
                                                zipcodes: '',
                                            })
                                        }
                                        sx={{ flexBasis: '100px' }}
                                        disabled={complete}
                                    >
                                        <MenuItem value={10}>10</MenuItem>
                                        <MenuItem value={15}>15</MenuItem>
                                        <MenuItem value={20}>20</MenuItem>
                                        <MenuItem value={25}>25</MenuItem>
                                        <MenuItem value={30}>30</MenuItem>
                                        <MenuItem value={35}>35</MenuItem>
                                        <MenuItem value={40}>40</MenuItem>
                                        <MenuItem value={45}>45</MenuItem>
                                        <MenuItem value={50}>50</MenuItem>
                                        <MenuItem value={75}>75</MenuItem>
                                    </CDTextField>
                                    <Typography
                                        sx={{
                                            fontSize: '0.8em',
                                            paddingLeft: '30px',
                                            paddingTop: '15px',
                                        }}
                                    >
                                        {campaign.zipcodes?.split(',').filter((zipcode) => zipcode).length +
                                            ' Zip Codes selected'}
                                    </Typography>
                                </Row>

                                <CDTextField
                                    name="geographyTargeting"
                                    label="Additional Zip Codes"
                                    variant="outlined"
                                    value={campaign.geographyTargeting ?? ''}
                                    required={false}
                                    fullWidth={true}
                                    shrinkLabel
                                    disabled={complete}
                                    multiline
                                    rows={2}
                                    onChange={(event) => {
                                        onChange({
                                            geographyTargeting: event.target.value,
                                        });
                                    }}
                                />

                                {/*{campaign.id && (*/}
                                {/*    <AccessGuard accessGroup={USER_GROUP_ADMIN}>*/}
                                {/*        <CDTextField*/}
                                {/*            name="zipcodes"*/}
                                {/*            label="Map Zipcodes"*/}
                                {/*            value={campaign.zipcodes ?? ''}*/}
                                {/*            required={false}*/}
                                {/*            fullWidth={true}*/}
                                {/*            disabled={true}*/}
                                {/*            shrinkLabel*/}
                                {/*            multiline*/}
                                {/*            rows={3}*/}
                                {/*            onChange={(event) => {*/}
                                {/*                onChange({*/}
                                {/*                    zipcodes: event.target.value,*/}
                                {/*                });*/}
                                {/*            }}*/}
                                {/*        />*/}
                                {/*    </AccessGuard>*/}
                                {/*)}*/}
                                <Box
                                    sx={{
                                        height: '55vh',
                                    }}
                                >
                                    <MapComponent
                                        onZipClick={zipCodeClick}
                                        onZipsLoaded={zipCodesLoaded}
                                        radius={campaign.zipcodeRadius ? campaign.zipcodeRadius : 30}
                                        zipCode={mapCenter}
                                        zipCodes={campaign.zipcodes}
                                    />
                                </Box>
                            </Column>
                        </CardContent>
                    </CampaignCard>
                </Grid>
                <Grid item xs={4}>
                    <AudienceDefinition
                        campaign={campaign}
                        forecastingParameters={forecastingParameters}
                        showError={false}
                        disabled={complete}
                        setTargets={(budget, impressionGoal, impressionBased) => {
                            onChange({
                                budget: budget,
                                impressionGoal: impressionGoal,
                                impressionBased: impressionBased,
                            });
                        }}
                    />
                </Grid>
            </Grid>
        </>
    );
}
