import {
    Alert,
    Box,
    Card,
    CardContent,
    FormControlLabel,
    InputAdornment,
    Slider,
    sliderClasses,
    Stack,
    Switch,
    Tooltip,
    Typography,
} from '@mui/material';
import { AttachMoneyOutlined, InfoOutlined, InsightsOutlined } from '@mui/icons-material';
import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { ForecastingParameters } from '../../../types/AdvertiserRequest';
import { styled } from '@mui/styles';
import { green, orange, red } from '@mui/material/colors';
import { CDTextField } from '../../../components/CDTextField';
import Row from '../../../components/Row';
import StylableTooltip from '../../../components/StylableTooltip';
import { Campaign, CpmValue } from '../../../types/Campaign';
import ApiService from '../../../ApiService';
import { UserContext } from '../../../App';

const HtmlTooltip = styled(StylableTooltip)(() => ({
    padding: '0px',
}));

const narrowColor = red[200];
const balancedColor = green[400];
const broadColor = orange[300];

export default function AudienceDefinition(props: {
    campaign: Campaign;
    forecastingParameters: ForecastingParameters;
    showError: boolean;
    setTargets(budget: number, impressionGoal: number | null, impressionBased: boolean): void;
    disabled?: boolean;
}) {
    const { campaign, forecastingParameters, showError, setTargets, disabled } = props;
    const [maxImpressions, setMaxImpressions] = useState(0);
    const [cpmValues, setCpmValues] = useState<CpmValue[]>([]);
    const [cpmValue, setCpmValue] = useState<CpmValue>({ mediaType: '', cpmValue: 0, fixedCpm: false });
    const [impressionBased, setImpressionBased] = useState(campaign.impressionBased);
    const { userContext } = useContext(UserContext);

    const status =
        campaign.availableReach < forecastingParameters.reachNarrow
            ? 'narrow'
            : campaign.availableReach > forecastingParameters.reachBroad
            ? 'broad'
            : 'balanced';

    const MAX_IMPRESSIONS = 6000000;

    useEffect(() => {
        if (campaign.dealerId) {
            ApiService.getCampaignCPMValues(campaign.dealerId).then((response) => {
                setCpmValues(response.data);
                if (campaign.mediaType) {
                    setCpmValue(getCpmValue(response.data));
                }
            });
        }
    }, [campaign.dealerId]);

    useEffect(() => {
        setCpmValue(getCpmValue(cpmValues));
    }, [campaign.mediaType]);

    useEffect(() => {
        let maxImpressions = MAX_IMPRESSIONS;
        if (campaign.availableImpressions < maxImpressions) {
            maxImpressions = campaign.availableImpressions;
        }
        setMaxImpressions(maxImpressions);
    }, [campaign.availableImpressions]);

    function getCpmValue(cpmValues: CpmValue[]): CpmValue {
        return cpmValues.find((cpmValue) => cpmValue.mediaType === campaign.mediaType) as CpmValue;
    }

    function getCPM(): number {
        return cpmValue ? cpmValue.cpmValue : 0;
    }

    function calculateImpressionChange(event: Event | null, value: number | number[]) {
        let impressionGoal = campaign.impressionGoal;
        if (event != null && typeof value === 'number') {
            impressionGoal = value;
            if (isNaN(impressionGoal)) {
                impressionGoal = 0;
            }
        }
        if (impressionGoal) {
            let budget = (getCPM() * impressionGoal) / 1000;
            budget = parseInt(budget.toFixed(0));

            setTargets(budget, impressionGoal, impressionBased);
        }
    }

    function calculateBudgetChange() {
        let budget = campaign.budget;
        if (budget !== null) {
            let impressionGoal = (budget * 1000) / getCPM();
            impressionGoal = parseInt(impressionGoal.toFixed(0));
            setTargets(budget, impressionGoal, impressionBased);
        }
    }

    function handleChange(event: ChangeEvent<HTMLInputElement>) {
        let budget = campaign.budget;
        let impressionGoal = campaign.impressionGoal;
        let value = parseInt(event.target.value);
        if (isNaN(value)) {
            value = 0;
        }
        let impressionBasedCampaign;
        if (event.target.name === 'budget') {
            budget = value;
            impressionBasedCampaign = false;
        } else {
            impressionBasedCampaign = true;
            if (value <= maxImpressions) {
                impressionGoal = value;
            }
        }
        setImpressionBased(impressionBasedCampaign);
        setTargets(budget, impressionGoal, impressionBasedCampaign);
    }

    return (
        <Card variant="outlined">
            <CardContent>
                <Stack direction="column" alignItems="center" textAlign="center" gap={1}>
                    <Typography variant="subtitle2">Estimated Audience Size</Typography>

                    <Row alignItems="center" justifyContent="center" gap={1}>
                        {campaign.availableReach && (
                            <>
                                {Number((campaign.availableReach * 0.9).toFixed()).toLocaleString() +
                                    ' - ' +
                                    Number((campaign.availableReach * 1.1).toFixed()).toLocaleString()}
                            </>
                        )}
                        <HtmlTooltip
                            title={
                                <Alert severity="info" icon={<InsightsOutlined />}>
                                    Estimates may vary significantly over time based on your targeting selections and
                                    available data.
                                </Alert>
                            }
                        >
                            <InfoOutlined fontSize="small" color="info" />
                        </HtmlTooltip>
                    </Row>

                    <Stack direction="column" justifyContent="center" height="3em">
                        {status === 'narrow' ? (
                            <>
                                <Typography variant="caption">
                                    Your audience selection is a{' '}
                                    <Typography component="span" variant="caption" color={narrowColor}>
                                        bit narrow
                                    </Typography>
                                </Typography>
                                <Typography variant="caption">You may want to expand your filters.</Typography>
                            </>
                        ) : null}
                        {status === 'broad' ? (
                            <>
                                <Typography variant="caption">
                                    Your audience selection is{' '}
                                    <Typography component="span" color={broadColor} variant="caption">
                                        fairly broad
                                    </Typography>
                                </Typography>

                                <Typography variant="caption">
                                    Additional filters may help increase conversion.
                                </Typography>
                            </>
                        ) : null}
                        {status === 'balanced' ? (
                            <Typography variant="caption">
                                Your audience selection is{' '}
                                <Typography component="span" color={balancedColor} variant="caption">
                                    fairly balanced
                                </Typography>
                            </Typography>
                        ) : null}
                    </Stack>

                    <Stack direction="row" width="100%" height="10px" gap={1}>
                        <Box
                            flexGrow={1}
                            borderRadius="10px"
                            sx={{
                                background: status === 'narrow' ? narrowColor : 'none',
                                border: `solid 1px ${narrowColor}`,
                            }}
                        />
                        <Box
                            flexGrow={1}
                            borderRadius="10px"
                            sx={{
                                background: status === 'balanced' ? balancedColor : 'none',
                                border: `solid 1px ${balancedColor}`,
                            }}
                        />
                        <Box
                            flexGrow={1}
                            borderRadius="10px"
                            sx={{
                                background: status === 'broad' ? broadColor : 'none',
                                border: `solid 1px ${broadColor}`,
                            }}
                        />
                    </Stack>

                    <Stack direction="row" width="100%" justifyContent="space-between">
                        <Typography variant="caption" color={narrowColor}>
                            Narrow
                        </Typography>

                        <Typography variant="caption" color={broadColor}>
                            Broad
                        </Typography>
                    </Stack>

                    {campaign.impressionGoal !== null && campaign.availableImpressions > 0 && (
                        <>
                            <Row alignItems="center" justifyContent="center" gap={1}>
                                <Typography variant="subtitle2">Impression Target</Typography>
                                <HtmlTooltip
                                    title={
                                        <Alert severity="info" icon={<InsightsOutlined />}>
                                            Maximum Available Impressions:{' '}
                                            {campaign.availableImpressions?.toLocaleString()}
                                        </Alert>
                                    }
                                >
                                    <InfoOutlined fontSize="small" color="info" />
                                </HtmlTooltip>
                            </Row>
                            <Box width="100%" paddingLeft={1} paddingRight={1}>
                                <Slider
                                    marks={[
                                        { value: 0, label: '0' },
                                        {
                                            value: maxImpressions,
                                            label: maxImpressions
                                                ? (maxImpressions / 1000000).toLocaleString() + 'M'
                                                : 0,
                                        },
                                    ]}
                                    min={0}
                                    value={campaign.impressionGoal}
                                    max={maxImpressions}
                                    onChange={(event, value) => {
                                        setImpressionBased(true);
                                        calculateImpressionChange(event, value);
                                    }}
                                    valueLabelDisplay="auto"
                                    sx={{
                                        // Keep the mark labels even with the slider ends
                                        [`& [style*="left:0%"], & [style*="left: 0%"]`]: {
                                            [`&.${sliderClasses.markLabel}`]: {
                                                transform: 'none',
                                            },
                                        },
                                        [`& [style*="left:100%"], & [style*="left: 100%"]`]: {
                                            [`&.${sliderClasses.markLabel}`]: {
                                                transform: 'translateX(-100%)',
                                            },
                                        },
                                    }}
                                />
                            </Box>
                        </>
                    )}

                    <Row marginTop={1}>
                        <Tooltip title="Switch between a Budget based Campaign or an Impression Goal">
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={!impressionBased}
                                        onClick={() => {
                                            setImpressionBased(!impressionBased);
                                            setTargets(campaign.budget, campaign.impressionGoal, !impressionBased);
                                        }}
                                    />
                                }
                                label={impressionBased ? 'Impressions' : 'Budget'}
                            />
                        </Tooltip>

                        {impressionBased && (
                            <CDTextField
                                name="impressionGoal"
                                label={'Impression Goal'}
                                value={campaign.impressionGoal ?? ''}
                                required={impressionBased}
                                fullWidth={false}
                                error={showError && !campaign.impressionGoal}
                                onChange={handleChange}
                                disabled={disabled}
                                onBlur={() => calculateImpressionChange(null, 0)}
                                InputLabelProps={{ shrink: true }}
                            />
                        )}
                        {!impressionBased && (
                            <CDTextField
                                name="budget"
                                label="Budget"
                                value={campaign.budget ?? ''}
                                required={true}
                                fullWidth={false}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <AttachMoneyOutlined />
                                        </InputAdornment>
                                    ),
                                }}
                                error={showError && !campaign.budget}
                                onChange={handleChange}
                                disabled={disabled}
                                onBlur={calculateBudgetChange}
                                InputLabelProps={{ shrink: true }}
                            />
                        )}
                    </Row>

                    <Row marginTop={2}>
                        {impressionBased && (
                            <CDTextField
                                label="Budget"
                                value={campaign.budget ?? ''}
                                fullWidth={false}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <AttachMoneyOutlined />
                                        </InputAdornment>
                                    ),
                                }}
                                disabled={true}
                                InputLabelProps={{ shrink: true }}
                            />
                        )}
                        {!impressionBased && (
                            <CDTextField
                                name="impressionGoal"
                                label="Estimated Impressions"
                                value={campaign.impressionGoal ?? ''}
                                fullWidth={false}
                                disabled={true}
                                InputLabelProps={{ shrink: true }}
                            />
                        )}
                    </Row>

                    <Row marginTop={2}>
                        {cpmValue && cpmValue.fixedCpm && (userContext.isAdmin() || userContext.isAgency()) && (
                            <CDTextField
                                label="Fixed CPM"
                                fullWidth={false}
                                value={cpmValue.cpmValue}
                                disabled={true}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <AttachMoneyOutlined />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        )}
                    </Row>
                </Stack>
            </CardContent>
        </Card>
    );
}
