import { styled } from '@mui/material/styles';
import { gridClasses } from '@mui/x-data-grid';
import { GridToolbarContainerProps } from '@mui/x-data-grid';
import { GridToolbarContainer as BaseGridToolbarContainer } from '@mui/x-data-grid';

const GridToolbarContainer = styled((props: GridToolbarContainerProps) => (
    <BaseGridToolbarContainer className={gridClasses.toolbarContainer} {...props} />
))(({ theme }) => ({
    padding: theme.spacing(2),
}));

export { GridToolbarContainer };
