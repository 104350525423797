import { Card, CardContent, Skeleton } from '@mui/material';

import Column from '../../components/Column';
import Row from '../../components/Row';

export default function SkeletonMetricCard(props: { hideSublabel?: boolean }) {
    const { hideSublabel = false } = props;

    return (
        <Card variant="outlined">
            <CardContent
                sx={(theme) => ({
                    minHeight: 128,
                    '&.MuiCardContent-root:last-child': {
                        pb: theme.spacing(2),
                    },
                })}
            >
                <Column gap={hideSublabel ? 2 : 0} justifyContent="space-between">
                    <Skeleton
                        variant="text"
                        width="50%"
                        sx={(theme) => ({
                            fontSize: theme.typography.body1.fontSize,
                            mb: theme.spacing(3 - 1),
                        })}
                    />

                    <Column gap={1}>
                        <Skeleton
                            variant="text"
                            width="20%"
                            sx={(theme) => ({ fontSize: 24, pt: hideSublabel === false ? 0 : 0.5 })}
                        />

                        {hideSublabel === false && (
                            <Row gap={0.5}>
                                <Skeleton
                                    variant="text"
                                    width={30}
                                    sx={(theme) => ({
                                        fontSize: theme.typography.body1.fontSize,
                                    })}
                                />

                                <Skeleton
                                    variant="text"
                                    width={20}
                                    sx={(theme) => ({
                                        fontSize: theme.typography.body1.fontSize,
                                    })}
                                />

                                <Skeleton
                                    variant="text"
                                    width="20%"
                                    sx={(theme) => ({
                                        fontSize: theme.typography.body1.fontSize,
                                    })}
                                />
                            </Row>
                        )}
                    </Column>
                </Column>
            </CardContent>
        </Card>
    );
}
