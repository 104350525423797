import { useMemo, useState } from 'react';
import { deepmerge } from '@mui/utils';
import { Card, CardContent, CardHeader, IconButton, Slide, Tooltip, Typography } from '@mui/material';
import {
    ChevronLeft as ChevronLeftIcon,
    ChevronRight as ChevronRightIcon,
    HelpOutlineOutlined as HelpOutlineOutlinedIcon,
} from '@mui/icons-material';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import Column from '../Column';
import Printable from '../Printable';
import Row from '../Row';
import SkeletonColumnChartCard from './SkeletonColumnChartCard';
import theme from '../../theme';

export default function ColumnChartCard(props: {
    label: string | null | undefined | React.ReactElement;
    tooltip?: string;
    chartOptions?: ApexOptions;
    chartSeries?: ApexAxisChartSeries | number[];
    children?: React.ReactElement;
    noDataOverlay?: React.ReactElement;
    loading?: boolean;
}) {
    const { label, tooltip, chartOptions, chartSeries, children, noDataOverlay, loading = false } = props;

    const itemsPerSlide: number = 8;
    const [slide, setSlide] = useState<number>(1);
    const slideTotal: number = useMemo(() => {
        let _slideTotal: number = Math.floor(chartOptions?.xaxis?.categories?.length / itemsPerSlide);

        return _slideTotal > 1 ? _slideTotal : 1;
    }, [chartOptions]);

    function paginate(items: any[], pageSize: number, pageNumber: number) {
        return items.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
    }

    function nextSlide() {
        if (slide >= slideTotal) {
            setSlide(1);
        } else {
            setSlide(slide + 1);
        }
    }

    function previousSlide() {
        if (slide === 1) {
            setSlide(slideTotal);
        } else {
            setSlide(slide - 1);
        }
    }

    const defaultChartOptions: ApexOptions = {
        colors: ['#0e59e7', '#cfdefa', '#3e7aec', '#9fbdf5', '#6e9bf1'],
        chart: {
            type: 'bar',
            height: 350,
            stacked: true,
            toolbar: {
                show: false,
                tools: {
                    download: false,
                    selection: false,
                    zoom: false,
                    zoomin: false,
                    zoomout: false,
                    pan: false,
                    reset: false,
                },
            },
        },
        responsive: [
            {
                breakpoint: 480,
                options: {
                    legend: {
                        position: 'bottom',
                        offsetX: -10,
                        offsetY: 0,
                    },
                },
            },
        ],
        dataLabels: {
            style: {
                colors: [theme.palette.secondary.main, theme.palette.text.secondary],
                fontFamily: theme.typography.fontFamily,
                fontSize: '14px',
                fontWeight: 400,
            },
        },
        plotOptions: {
            bar: {
                columnWidth: '30%',
                horizontal: false,
                borderRadius: 10,
                dataLabels: {
                    total: {
                        enabled: true,
                        style: {
                            color: theme.palette.text.primary,
                            fontFamily: theme.typography.fontFamily,
                            fontSize: '14px',
                            fontWeight: 400,
                        },
                    },
                },
            },
        },
        xaxis: {
            type: 'category',
            tickPlacement: 'on',
            labels: {
                offsetX: -10,
            },
        },
        yaxis: {
            title: {
                style: {
                    fontFamily: theme.typography.fontFamily,
                    fontSize: '16px',
                    fontWeight: 400,
                },
            },
        },
        legend: {
            fontFamily: theme.typography.fontFamily,
            fontSize: '14px',
            fontWeight: 400,
            markers: {
                width: 14,
                height: 14,
                radius: 4,
                offsetX: -4,
                offsetY: 0,
            },
            labels: {
                colors: [theme.palette.text.primary, theme.palette.text.secondary],
            },
            itemMargin: {
                horizontal: 8,
                vertical: 24,
            },
        },
        fill: {
            type: 'solid',
            opacity: 1,
        },
        states: {
            hover: {
                filter: {
                    type: 'none',
                },
            },
        },
    };

    const slides = useMemo(() => {
        const slides = [];

        for (let _slide = 1; _slide <= slideTotal; _slide++) {
            slides.push(
                <Slide key={_slide} direction="right" in={slide === _slide} mountOnEnter unmountOnExit>
                    <div>
                        <ReactApexChart
                            type="bar"
                            options={deepmerge(defaultChartOptions, {
                                ...chartOptions,
                                ...{
                                    xaxis: {
                                        categories: paginate(chartOptions?.xaxis?.categories, itemsPerSlide, _slide),
                                    },
                                },
                            })}
                            series={
                                chartSeries
                                    ? chartSeries.map((_chartSerie: any, index) => {
                                          return {
                                              ..._chartSerie,
                                              ...{
                                                  data: paginate(_chartSerie.data, itemsPerSlide, _slide),
                                              },
                                          };
                                      })
                                    : []
                            }
                            height={320}
                            // width={500}
                        />

                        {children}
                    </div>
                </Slide>
            );
        }

        return slides;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [slide, chartOptions, chartSeries]);

    const hasPreviousSlide: boolean | undefined = useMemo(() => {
        return slide - 1 >= 1;
    }, [slide]);

    const hasNextSlide: boolean | undefined = useMemo(() => {
        return slide + 1 <= slideTotal;
    }, [slide, slideTotal]);

    const hasData: boolean | undefined = useMemo(() => {
        return chartOptions?.xaxis?.categories?.length > 0;
    }, [chartOptions]);

    const hasNoDataOverlay: boolean | undefined = useMemo(() => {
        return noDataOverlay !== null && noDataOverlay !== undefined;
    }, [noDataOverlay]);

    return (
        <Card variant="outlined">
            <CardHeader
                title={
                    <Row gap={0.5} alignItems="center">
                        <Typography variant="body1" color="text.primary">
                            {label}
                        </Typography>

                        <Printable show={false}>
                            {tooltip ? (
                                <Tooltip title={tooltip}>
                                    <HelpOutlineOutlinedIcon color="action" sx={{ fontSize: 16 }} />
                                </Tooltip>
                            ) : (
                                ''
                            )}
                        </Printable>
                    </Row>
                }
                sx={{
                    borderBottom: 1,
                    borderColor: 'divider',
                }}
            />
            <CardContent
                sx={{
                    '& .apexcharts-xaxis-label': {
                        textAnchor: 'start',
                    },
                }}
            >
                {loading ? (
                    <Column alignItems="center" justifyContent="center" sx={{ height: 280 }}>
                        <SkeletonColumnChartCard />
                    </Column>
                ) : (
                    <>
                        {hasData === true && (
                            <>
                                <div style={{ minHeight: 350 }}>{slides}</div>

                                {slideTotal > 1 ? (
                                    <Row justifyContent="flex-end" alignItems="center" spacing={2}>
                                        <IconButton
                                            aria-label="previous"
                                            onClick={() => previousSlide()}
                                            disabled={hasPreviousSlide === false}
                                        >
                                            <ChevronLeftIcon />
                                        </IconButton>

                                        <IconButton
                                            aria-label="next"
                                            onClick={() => nextSlide()}
                                            disabled={hasNextSlide === false}
                                        >
                                            <ChevronRightIcon />
                                        </IconButton>
                                    </Row>
                                ) : (
                                    ''
                                )}
                            </>
                        )}
                        {hasData === false && (
                            <>
                                {hasNoDataOverlay === true && <>{noDataOverlay}</>}

                                {hasNoDataOverlay === false && (
                                    <Column alignItems="center" justifyContent="center" sx={{ height: 280 }}>
                                        <Typography variant="body2">No data available</Typography>
                                    </Column>
                                )}
                            </>
                        )}
                    </>
                )}
            </CardContent>
        </Card>
    );
}
