import { useState } from 'react';
import { ArchiveOutlined as ArchiveOutlinedIcon } from '@mui/icons-material';

import { Campaign } from '../../../types/Campaign';
import ConfirmDialog, { ConfirmDialogProps } from '../../../components/ConfirmDialog';
import AppLoader from '../../../components/AppLoader';
import CDButton from '../../../components/CDButton';
import ApiService from '../../../ApiService';

import { useInfoMessage } from '../../../hooks/useInfoMessage';

type CampaignArchiveButtonProps = {
    campaign: Campaign;
    onCampaignArchive: (campaign: Campaign) => void;
};

export default function CampaignArchiveButton({ campaign, onCampaignArchive }: CampaignArchiveButtonProps) {
    const { showInfoMessage } = useInfoMessage();

    const [loading, setLoading] = useState<boolean>(false);
    const [confirmDialogProps, setConfirmDialogProps] = useState<ConfirmDialogProps | null>(null);

    const archiveCampaign = () => {
        hideConfirm();

        setLoading(true);

        if (campaign && campaign.id) {
            ApiService.archiveCampaign(campaign.id)
                .then(() => {
                    setLoading(false);

                    showInfoMessage('success', 'Campaign has been archived.');

                    onCampaignArchive(campaign);
                })
                .catch((error) => {
                    showInfoMessage('error', error.message);
                });
        }
    };

    const showArchiveConfirm = () => {
        setConfirmDialogProps({
            title: 'Are you sure you want to archive this campaign?',
            onOk: () => archiveCampaign(),
            okText: 'Archive Campaign',
            okColor: 'error',
            okIcon: <ArchiveOutlinedIcon />,
            onCancel: () => hideConfirm(),
        });
    };

    const hideConfirm = () => {
        setConfirmDialogProps(null);
    };

    return (
        <>
            <AppLoader loading={loading} />

            <CDButton
                color="primary"
                tooltip="Archive Campaign"
                startIcon={<ArchiveOutlinedIcon />}
                onClick={() => showArchiveConfirm()}
                disabled={campaign.deliveryStatusId === 'ARCHIVED'}
            >
                Archive
            </CDButton>

            {confirmDialogProps && (
                <>
                    <ConfirmDialog
                        title={confirmDialogProps.title}
                        text={confirmDialogProps.text}
                        okText={confirmDialogProps.okText}
                        okIcon={confirmDialogProps.okIcon}
                        onCancel={confirmDialogProps.onCancel}
                        onOk={confirmDialogProps.onOk}
                    />
                </>
            )}
        </>
    );
}
