import { Chip } from '@mui/material';

export default function ActiveStatus(props: { activeStatus: boolean; activeText?: string; inactiveText?: string }) {
    const { activeStatus, activeText, inactiveText } = props;

    return (
        <Chip
            label={activeStatus ? activeText || 'Active' : inactiveText || 'Inactive'}
            size="small"
            variant={'outlined'}
            color={activeStatus ? 'success' : 'error'}
        />
    );
}
