import { AlertColor, Dialog, DialogContent, DialogTitle } from '@mui/material';
import DealerDetailsEdit, { DealerUpdateCallback } from '../dealers/DealerDetailsEdit';
import { Dealer } from '../../types/Dealer';
import { FormEvent, useContext, useEffect, useState } from 'react';
import ApiService from '../../ApiService';
import { UserContext } from '../../App';
import Row from '../../components/Row';
import Column from '../../components/Column';
import { CDCancelButton, CDDefaultButton } from '../../components/CDButton';

export type NewAdvertiserModalProps = {
    open: boolean;

    onCancel: () => void;

    onSave: (dealer: Dealer) => void;

    onMessage?: (alertLevel: AlertColor, message: string) => void;
};

export default function NewDealerModal({ open, onCancel, onSave, onMessage }: NewAdvertiserModalProps) {
    const [dealer, setDealer] = useState<Dealer>(new Dealer());
    const [hasDefaultDspEntity, setHasDefaultDspEntity] = useState(false);
    const [hasDefaultSasAccount, setHasDefaultSasAccount] = useState(false);
    const { userContext } = useContext(UserContext);

    useEffect(() => {
        ApiService.getDefaultDealer()
            .then((response) => {
                const dealer = response.data;
                setDealer({
                    ...dealer,
                    agencyId: userContext.agencyId,
                });
                setHasDefaultDspEntity(dealer.dspEntityId !== null);
                setHasDefaultSasAccount(dealer.sasAccountId !== null);
            })
            .catch(() => {});
    }, [open, userContext.agencyId]);

    if (dealer == null) {
        return null;
    }

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        onSave(dealer);
    };

    const handleFieldChange: DealerUpdateCallback = (_name, changes) => setDealer({ ...dealer, ...changes });

    return (
        <Dialog open={open}>
            <DialogTitle>New Advertiser</DialogTitle>
            <DialogContent style={{ paddingTop: '10px' }}>
                <form onSubmit={handleSubmit}>
                    <Column>
                        <DealerDetailsEdit
                            dealer={dealer}
                            hasDefaultSasAccount={hasDefaultSasAccount}
                            hasDefaultDspEntity={hasDefaultDspEntity}
                            onDealerUpdate={handleFieldChange}
                            variant="outlined"
                            showImagePreviews={false}
                            onMessage={onMessage}
                        />

                        <Row justifyContent="flex-end">
                            <CDCancelButton onClick={onCancel} />
                            <CDDefaultButton type="submit">Create</CDDefaultButton>
                        </Row>
                    </Column>
                </form>
            </DialogContent>
        </Dialog>
    );
}
