import { AppBar, Stack, Toolbar } from '@mui/material';

export default function CDPageToolbar(props: any) {
    return (
        <AppBar position="sticky" sx={{ top: 'auto', bottom: 0, width: '100%', backgroundColor: 'white' }}>
            <Toolbar>
                <Stack direction="row" spacing={3} sx={{ width: '100%' }}>
                    {props.children}
                </Stack>
            </Toolbar>
        </AppBar>
    );
}
