import { Button, Tooltip } from '@mui/material';
import { ButtonProps } from '@mui/material/Button/Button';
import { CloseOutlined } from '@mui/icons-material';

export interface CDButtonProps extends ButtonProps {
    tooltip?: string;
}
export default function CDButton(props: CDButtonProps) {
    return (
        <>
            <Tooltip title={props.tooltip ? props.tooltip : ''}>
                <span>
                    <Button variant="outlined" color="primary" size="medium" disableFocusRipple {...props}>
                        {props.children}
                    </Button>
                </span>
            </Tooltip>
        </>
    );
}

export function CDDefaultButton(props: CDButtonProps) {
    return (
        <CDButton variant="contained" {...props}>
            {props.children}
        </CDButton>
    );
}

export function CDCancelButton(props: CDButtonProps) {
    return (
        <CDButton color="error" startIcon={<CloseOutlined />} {...props}>
            {props.children ? props.children : 'Cancel'}
        </CDButton>
    );
}
