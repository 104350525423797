import { DataGrid } from '@mui/x-data-grid';
import Utils from '../../components/Utils';
import { DataSetValue } from '../../types/DataSetValue';

export default function FirstPartyDataGrid(props: { rows: DataSetValue[] }) {
    return (
        <DataGrid
            autoHeight
            columns={[
                {
                    width: 110,
                    field: 'firstName',
                    headerName: 'First Name',
                    headerClassName: 'grid-header',
                },
                {
                    width: 120,
                    field: 'lastName',
                    headerName: 'Last Name',
                    headerClassName: 'grid-header',
                },
                {
                    width: 220,
                    field: 'email',
                    headerName: 'Email',
                    headerClassName: 'grid-header',
                },
                {
                    width: 120,
                    field: 'phoneNumber',
                    headerName: 'Phone',
                    headerClassName: 'grid-header',
                },
                {
                    width: 100,
                    field: 'saleDate',
                    headerName: 'Sale Date',
                    headerClassName: 'grid-header',
                    valueGetter: (params) => {
                        return Utils.formatDate(params.value);
                    },
                },
                {
                    width: 150,
                    field: 'address',
                    headerName: 'Address',
                    headerClassName: 'grid-header',
                },
                {
                    width: 150,
                    field: 'city',
                    headerName: 'City',
                    headerClassName: 'grid-header',
                },
                {
                    width: 150,
                    field: 'state',
                    headerName: 'State',
                    headerClassName: 'grid-header',
                },
                {
                    width: 150,
                    field: 'zipcode',
                    headerName: 'Zip Code',
                    headerClassName: 'grid-header',
                },
                {
                    width: 130,
                    field: 'make',
                    headerName: 'Make',
                    headerClassName: 'grid-header',
                },
                {
                    width: 150,
                    field: 'model',
                    headerName: 'Model',
                    headerClassName: 'grid-header',
                },
            ]}
            rows={props.rows}
            hideFooter
        />
    );
}
