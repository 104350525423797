import { deepmerge } from '@mui/utils';
import {
    DataGridPro,
    DataGridProProps,
    GRID_CHECKBOX_SELECTION_COL_DEF,
    gridClasses,
    gridPageCountSelector,
    gridPageSelector,
    GridColDef,
    GridRenderCellParams,
    GridRowSelectionModel,
    GridValidRowModel,
    useGridApiContext,
    useGridSelector,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
} from '@mui/x-data-grid-pro';
import React, { useEffect, useState } from 'react';
import { Pagination, Paper } from '@mui/material';
import { CheckOutlined } from '@mui/icons-material';
import theme from '../theme';

type CDGridPropsType<T extends GridValidRowModel> = DataGridProProps<T> & {
    id?: string;
    disableSelectionOnclick?: boolean;
    selectionEnabled?: boolean;
    selectionsChanged?: (gridSelectionModel: GridRowSelectionModel) => void;
    rowsPerPage?: number[];
    disabled?: boolean;
    PaperProps?: Object;
    variant?: 'contained' | 'outlined' | 'elevation';
    pageSize?: number;
} & React.RefAttributes<HTMLDivElement>;

function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
        </GridToolbarContainer>
    );
}

function CustomPagination() {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    return (
        <Pagination
            showFirstButton
            showLastButton
            color="primary"
            count={pageCount}
            page={page + 1}
            onChange={(event, value) => apiRef.current.setPage(value - 1)}
        />
    );
}

export default function CDGrid<T extends GridValidRowModel>(props: CDGridPropsType<T>) {
    const { variant, pageSize } = props;

    const [columns, setColumns] = useState<GridColDef[]>([]);
    const [paginationModel, setPaginationModel] = React.useState({
        pageSize: props.pageSize ? props.pageSize : 10,
        page: 0,
    });

    useEffect(() => {
        setPaginationModel({
            ...paginationModel,
            pageSize: pageSize || 20,
        });
    }, [pageSize]);
    const [disableSelectionOnclick] = useState(props.disableSelectionOnclick ? props.disableSelectionOnclick : false);

    useEffect(() => {
        for (let i = 0; i < props.columns.length; i++) {
            props.columns[i].headerClassName = 'grid-header';
        }
        if (props.selectionEnabled) {
            if (props.disabled) {
                let columns = [
                    {
                        width: 100,
                        ...GRID_CHECKBOX_SELECTION_COL_DEF,
                        headerClassName: 'grid-header',
                        renderCell: (params: GridRenderCellParams) => {
                            let x: number[] = props.rowSelectionModel ? (props.rowSelectionModel as number[]) : [];
                            if (x.includes(params.id as number)) {
                                return <CheckOutlined />;
                            } else {
                                return <></>;
                            }
                        },
                    },
                    ...props.columns,
                ];
                setColumns(columns);
            } else {
                let columns = [
                    {
                        width: 100,
                        ...GRID_CHECKBOX_SELECTION_COL_DEF,
                        headerClassName: 'grid-header',
                    },
                    ...props.columns,
                ];
                setColumns(columns);
            }
        } else {
            setColumns([...props.columns]);
        }
    }, [props.columns, props.disabled, props.selectionEnabled, props.rowSelectionModel]);

    let PaperPropsProp = props.PaperProps ?? {};
    let sxProp = props.sx ?? {};
    sxProp = {
        border: 'none',
        [`& .${gridClasses.toolbarContainer}`]: {
            padding: 2,
        },
        [`& .${gridClasses.toolbarContainer} .MuiToolbar-root`]: {
            minHeight: 'inherit',
        },
        [`& .${gridClasses.row}`]: {
            cursor: props.onRowClick ? 'pointer' : 'default',
        },
        [`& .odd`]: {
            backgroundColor: theme.palette.grey[200],
        },
        [`& .${gridClasses.columnHeader}:not(.${gridClasses.columnHeaderCheckbox})`]: {
            paddingX: 2,
        },
        [`& .${gridClasses.columnHeaderTitle}`]: {
            color: theme.palette.text.primary,
            fontWeight: theme.typography.fontWeightMedium,
        },
        [`& .${gridClasses.columnHeaderTitleContainerContent} > strong`]: {
            fontWeight: theme.typography.fontWeightMedium,
        },
        [`& .${gridClasses.columnHeaders}`]: {
            color: theme.palette.text.primary,
            overflow: 'hidden',
        },
        [`& .${gridClasses.cell}`]: {
            color: theme.palette.text.secondary,
            paddingX: 2,
        },
        [`& .${gridClasses.cell}:focus-within`]: {
            outline: 'none',
        },
        [`& .${gridClasses.cell}:focus`]: {
            outline: 'none',
        },
        [`& .${gridClasses.columnHeader}:focus-within`]: {
            outline: 'none',
        },
        [`& .${gridClasses.columnHeader}:focus`]: {
            outline: 'none',
        },
        [`& .${gridClasses.columnSeparator}`]: {
            visibility: 'hidden',
        },
        ...sxProp,
    };

    if (variant === 'contained') {
        sxProp = deepmerge(sxProp, {
            [`& .${gridClasses.virtualScrollerContent}`]: {
                borderLeftWidth: 0,
                borderRightWidth: 0,
            },
        });
    }

    if (!columns || columns.length <= 0) {
        return null;
    }

    return (
        <Paper
            id={props.id}
            variant={variant === 'elevation' ? 'elevation' : 'outlined'}
            elevation={0}
            sx={{
                height: '100%',
            }}
            {...PaperPropsProp}
        >
            <DataGridPro
                variant="contained"
                pagination
                autoHeight
                checkboxSelection={props.selectionEnabled}
                onRowSelectionModelChange={(newSelectionModel: GridRowSelectionModel) => {
                    if (props.selectionsChanged) {
                        props.selectionsChanged(newSelectionModel);
                    }
                }}
                hideFooterSelectedRowCount
                disableRowSelectionOnClick={disableSelectionOnclick}
                density={props.density ? props.density : 'standard'}
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
                paginationMode={props.paginationMode ? props.paginationMode : 'client'}
                {...props}
                slots={{
                    pagination: CustomPagination,
                    toolbar: CustomToolbar,
                    ...props.slots,
                }}
                columns={columns}
                sx={sxProp}
            />
        </Paper>
    );
}
