import { useMemo } from 'react';
import { Grid, Stack, Tooltip, Typography } from '@mui/material';
import { HelpOutlineOutlined as HelpOutlineOutlinedIcon } from '@mui/icons-material';

import { PolkSalesWorkflowResult } from '../../types/Polk';
import { Link } from '../../components/Link';
import Printable from '../../components/Printable';
import Row from '../../components/Row';
import theme from '../../theme';

import { TABS, useAttributionPageContext } from '../../hooks/useAttributionPage';
import { calculateExposedSales, calculateNonExposedSales } from '../../hooks/usePolkAttribution';
import usePolkAttribution from '../../hooks/usePolkAttribution';
import ColumnChartCard from '../../components/Metrics/ColumnChartCard';
import PieChartCard from '../../components/Metrics/PieChartCard';

const ColumnChartLegend = (props: { color: string; label: string; tooltip?: string }) => {
    return (
        <Row gap={0.5} justifyContent="center" alignItems="center" width="auto">
            <span
                style={{
                    background: props.color,
                    color: props.color,
                    height: '14px',
                    width: '14px',
                    marginRight: '4px',
                    borderWidth: '0px',
                    borderColor: 'rgb(255, 255, 255)',
                    borderRadius: '4px',
                }}
            ></span>

            <Typography
                variant="body2"
                sx={{
                    color: (theme) => theme.palette.common.black,
                }}
            >
                {props.label}
            </Typography>

            <Printable show={false}>
                {props.tooltip && (
                    <Tooltip title={props.tooltip}>
                        <HelpOutlineOutlinedIcon sx={{ fontSize: 16 }} />
                    </Tooltip>
                )}
            </Printable>
        </Row>
    );
};

export default function PolkMatchbackChartMetrics() {
    const {
        dealer,
        polkSalesWorkflow,
        tab,
        attributionMetrics,
        isAdvertiserPolkDealer,
        polkOpportunitiesWorkflow,
        isFetchingAttributionStatus,
    } = useAttributionPageContext();

    const {
        marketShares,
        marketSharesTotal,

        adShares,
        adSharesTotal,
    } = usePolkAttribution();

    //
    function splitWords(sentence: string, limit: number) {
        let splittedWords = [];
        let words = sentence?.split(' ');
        let phrase = words[0];

        for (let i = 1; i < words.length; i++) {
            let word = words[i];

            if (phrase.length + word.length + 1 <= limit) {
                phrase = phrase + ' ' + word;
            } else {
                splittedWords.push(phrase);
                phrase = word;
            }
        }

        if (phrase.length) {
            splittedWords.push(phrase);
        }

        return splittedWords;
    }

    const chartOptionLabels = useMemo(() => {
        let _chartOptionLabels: string[] = [];

        if (polkSalesWorkflow) {
            _chartOptionLabels.push(
                ...polkSalesWorkflow.workflowResults.map((workflowResult: PolkSalesWorkflowResult) => {
                    return workflowResult.polkDealer.dealerName;
                })
            );
        }

        if (polkOpportunitiesWorkflow) {
            _chartOptionLabels.push(
                ...polkOpportunitiesWorkflow.workflowResults.map((workflowResult: PolkSalesWorkflowResult) => {
                    return workflowResult.polkDealer.dealerName;
                })
            );
        }

        return _chartOptionLabels;
    }, [polkSalesWorkflow, polkOpportunitiesWorkflow]);

    const attributedSalesSeries = useMemo(() => {
        let _chartSeries: number[] = [];

        if (polkSalesWorkflow) {
            _chartSeries.push(
                ...polkSalesWorkflow.workflowResults.map((workflowResult: PolkSalesWorkflowResult) => {
                    if (tab === TABS.ADVERTISER_REPORTED_SALES && isAdvertiserPolkDealer(workflowResult.polkDealer)) {
                        workflowResult.salesCount = attributionMetrics.totalSales?.value ?? (0 as number);
                        workflowResult.distinctUserCount = attributionMetrics.attributedSales?.value ?? (0 as number);
                    }
                    return calculateExposedSales(workflowResult);
                })
            );
        }

        if (polkOpportunitiesWorkflow) {
            _chartSeries.push(
                ...polkOpportunitiesWorkflow.workflowResults.map((workflowResult: PolkSalesWorkflowResult) => {
                    if (tab === TABS.ADVERTISER_REPORTED_SALES && isAdvertiserPolkDealer(workflowResult.polkDealer)) {
                        workflowResult.salesCount = attributionMetrics.totalSales?.value ?? (0 as number);
                        workflowResult.distinctUserCount = attributionMetrics.attributedSales?.value ?? (0 as number);
                    }
                    return calculateExposedSales(workflowResult);
                })
            );
        }

        return _chartSeries;
    }, [polkSalesWorkflow, polkOpportunitiesWorkflow]);

    const nonAttributedSalesSeries = useMemo(() => {
        let _chartSeries: number[] = [];

        if (polkSalesWorkflow) {
            _chartSeries.push(
                ...polkSalesWorkflow.workflowResults.map((workflowResult: PolkSalesWorkflowResult) => {
                    if (tab === TABS.ADVERTISER_REPORTED_SALES && isAdvertiserPolkDealer(workflowResult.polkDealer)) {
                        workflowResult.salesCount = attributionMetrics.totalSales?.value ?? (0 as number);
                        workflowResult.distinctUserCount = attributionMetrics.attributedSales?.value ?? (0 as number);
                    }
                    return calculateNonExposedSales(workflowResult);
                })
            );
        }

        if (polkOpportunitiesWorkflow) {
            _chartSeries.push(
                ...polkOpportunitiesWorkflow.workflowResults.map((workflowResult: PolkSalesWorkflowResult) => {
                    if (tab === TABS.ADVERTISER_REPORTED_SALES && isAdvertiserPolkDealer(workflowResult.polkDealer)) {
                        workflowResult.salesCount = attributionMetrics.totalSales?.value ?? (0 as number);
                        workflowResult.distinctUserCount = attributionMetrics.attributedSales?.value ?? (0 as number);
                    }
                    return calculateNonExposedSales(workflowResult);
                })
            );
        }

        return _chartSeries;
    }, [polkSalesWorkflow, polkOpportunitiesWorkflow]);

    const renderChartLegendTopLabel = (chartContext: any, label: string) => {
        if (chartContext.el) {
            const svg = chartContext.el.querySelector('svg');

            if (svg) {
                const foreignObject = svg.querySelector('foreignObject .apexcharts-legend');

                const div = document.createElement('div');
                div.setAttribute('xmlns', 'http://www.w3.org/1999/xhtml');
                div.innerHTML = label;
                div.style.fontSize = '12px';
                div.style.textTransform = 'uppercase';
                div.style.color = theme.palette.text.primary;

                foreignObject.prepend(div);
            }
        }
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={6}>
                <PieChartCard
                    loading={isFetchingAttributionStatus}
                    label="Advertiser's Market Share"
                    tooltip="Percentage of total reported sales for the advertiser and the advertiser's assigned competitors"
                    chartOptions={{
                        chart: {
                            events: {
                                mounted: function (chartContext, config) {
                                    renderChartLegendTopLabel(chartContext, 'Advertiser & Competitors (Top 10)');
                                },
                                updated: function (chartContext, config) {
                                    renderChartLegendTopLabel(chartContext, 'Advertiser & Competitors (Top 10)');
                                },
                            },
                        },
                        dataLabels: {
                            enabled: false,
                        },
                        plotOptions: {
                            pie: {
                                donut: {
                                    labels: {
                                        total: {
                                            label: 'Market Share',
                                        },
                                    },
                                },
                            },
                        },
                        labels: marketShares.map((_marketShare) => _marketShare.dealerName),
                    }}
                    chartSeries={marketShares.map((_marketShare) => {
                        let value: number = 0;

                        if (marketSharesTotal > 0) {
                            value = Math.round((_marketShare.value / marketSharesTotal) * 100);
                        }

                        return value;
                    })}
                />
            </Grid>

            <Grid item xs={6}>
                <PieChartCard
                    loading={isFetchingAttributionStatus}
                    label="Advertiser's Ad Share"
                    tooltip="Percentage of total reported sales for the advertiser and advertiser's assigned competitors who were exposed/impressed to an advertisement"
                    chartOptions={{
                        chart: {
                            events: {
                                mounted: function (chartContext, config) {
                                    renderChartLegendTopLabel(chartContext, 'Advertiser & Competitors (Top 10)');
                                },
                                updated: function (chartContext, config) {
                                    renderChartLegendTopLabel(chartContext, 'Advertiser & Competitors (Top 10)');
                                },
                            },
                        },
                        dataLabels: {
                            enabled: false,
                        },
                        plotOptions: {
                            pie: {
                                donut: {
                                    labels: {
                                        total: {
                                            label: 'Ad Share',
                                        },
                                    },
                                },
                            },
                        },
                        labels: adShares.map((_adShare) => _adShare.dealerName),
                    }}
                    chartSeries={adShares.map((_adShare) => {
                        let value: number = 0;

                        if (adSharesTotal) {
                            value = Math.round((_adShare.value / adSharesTotal) * 100);
                        }

                        return value;
                    })}
                />
            </Grid>

            <Grid item xs={12} sx={{ mt: theme.spacing(1) }}>
                <ColumnChartCard
                    loading={isFetchingAttributionStatus}
                    label="Competitor Sales"
                    tooltip="Reported sales for competitors as assigned by advertiser"
                    chartOptions={{
                        xaxis: {
                            categories: chartOptionLabels.map((_label) => splitWords(_label, 15)),
                        },
                        yaxis: {
                            title: {
                                text: 'Total Sales',
                            },
                        },
                        legend: {
                            show: false,
                        },
                    }}
                    chartSeries={[
                        {
                            name: 'Ad Exposed Sales',
                            data: attributedSalesSeries,
                        },
                        {
                            name: 'Non-Exposed Sales',
                            data: nonAttributedSalesSeries,
                        },
                    ]}
                    noDataOverlay={
                        <>
                            <Stack direction="column" alignItems="center" justifyContent="center" sx={{ height: 280 }}>
                                <Typography variant="body2">
                                    No Competitor Advertisers assigned.
                                    {dealer && (
                                        <>
                                            {' '}
                                            <Link href={`/advertisers/${dealer.id}/#polkSales`}>Click here</Link> to
                                            setup.
                                        </>
                                    )}
                                </Typography>
                            </Stack>
                        </>
                    }
                >
                    <Stack gap={2} direction="row" justifyContent="center" alignItems="center" sx={{ mt: 1, mb: 3 }}>
                        <ColumnChartLegend
                            color="#0e59e7"
                            label="Ad Exposed Sales"
                            tooltip="Households that were exposed to an advertisement in the last 90 days and made a purchase"
                        />

                        <ColumnChartLegend
                            color="#cfdefa"
                            label="Non-Exposed Sales"
                            tooltip="Households that were not exposed to an advertisement but made a purchase"
                        />
                    </Stack>
                </ColumnChartCard>
            </Grid>
        </Grid>
    );
}
