import { useEffect, useState } from 'react';
import { AmcAudience } from '../../../types/AmcAudiences';
import ApiService from '../../../ApiService';
import { GridColumns, GridRowParams } from '@mui/x-data-grid';
import Utils from '../../../components/Utils';
import { Chip, Grid, TextField, Tooltip } from '@mui/material';
import AceEditor from 'react-ace';
import CDGrid from '../../../components/CDGrid';

export default function AmcAudienceTab(props: { amcInstanceId: number }) {
    const [audiences, setAudiences] = useState<AmcAudience[]>([]);
    const [audience, setAudience] = useState<AmcAudience | null>(null);

    useEffect(() => {
        if (props.amcInstanceId) {
            ApiService.getAmcAudiences(props.amcInstanceId)
                .then((response) => {
                    setAudiences(response.data);
                })
                .catch(() => {});
        }
    }, [props.amcInstanceId]);

    function selectAudience(event: GridRowParams<AmcAudience>) {
        setAudience(event.row);
    }

    const columns: GridColumns = [
        {
            width: 260,
            field: 'audienceName',
            headerName: 'Name',
        },
        {
            width: 110,
            field: 'status',
            headerName: 'Status',
            renderCell: (params) => {
                const value = params.value;
                return (
                    <Chip
                        label={Utils.convertCase(params.value)}
                        size="small"
                        variant="outlined"
                        color={value === 'SUCCEEDED' ? 'success' : value.indexOf('FAILED') > -1 ? 'error' : 'warning'}
                    />
                );
            },
        },
        {
            flex: 1,
            field: 'statusReason',
            headerName: 'Status Description',
            renderCell: (params) => {
                return (
                    <Tooltip title={params.row.statusReason}>
                        <span>{params.value}</span>
                    </Tooltip>
                );
            },
        },
    ];
    return (
        <>
            <div style={{ height: '100%', width: '100%' }}>
                <CDGrid columns={columns} onRowClick={(rowData) => selectAudience(rowData)} rows={audiences} />
            </div>
            {audience && (
                <>
                    <Grid container spacing={2} sx={{ mt: 5 }}>
                        <Grid item xs={5}>
                            <TextField
                                name="name"
                                label="Audience Name"
                                value={audience.audienceName}
                                required={true}
                                fullWidth={true}
                                size="small"
                                variant="filled"
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                        <Grid item xs={5}>
                            <TextField
                                name="name"
                                label="Execution ID"
                                value={audience.audienceExecutionId}
                                required={false}
                                fullWidth={true}
                                size="small"
                                variant="filled"
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>

                        <Grid item xs={2}>
                            <TextField
                                name="name"
                                label="Refresh Days"
                                value={audience.refreshRateDays}
                                required={true}
                                fullWidth={true}
                                size="small"
                                variant="filled"
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                        <Grid item xs={5}></Grid>

                        <Grid item xs={12}>
                            <AceEditor
                                mode="sql"
                                theme="sqlserver"
                                style={{
                                    border: '1px solid rgba(0, 0, 0, 0.08)',
                                }}
                                width="100%"
                                height="100px"
                                value={audience.query}
                                onChange={() => {}}
                            />
                        </Grid>
                    </Grid>
                </>
            )}
        </>
    );
}
