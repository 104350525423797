import { Campaign, GeoLocation } from '../../../types/Campaign';
import { useEffect, useState } from 'react';
import { Dealer } from '../../../types/Dealer';
import { ZipCode } from '../../../components/Map/Map';
import { Box, CircularProgress, List, ListItem } from '@mui/material';
import Row from '../../../components/Row';
import CDCard from '../../../components/CDCard';
import MapComponent from '../campaignEdit/MapComponent';
import ApiService from '../../../ApiService';

export default function GeoTargeting(props: { campaign: Campaign; onChange(value: Partial<Campaign>): void }) {
    const { campaign, onChange } = props;
    const [mapCenter, setMapCenter] = useState('');
    const [zipcodes, setZipcodes] = useState<string | null>(null);
    const [zipsLoading, setZipsLoading] = useState(false);
    const [geoLocations, setGeoLocations] = useState<GeoLocation[]>([]);

    useEffect(() => {
        if (campaign.dealer) {
            setMapCenter(getAddress(campaign.dealer));
        }
        if (campaign.id && campaign.dspCampaignId && !campaign.zipcodes) {
            setZipsLoading(true);
            ApiService.getGeoLocations(campaign.id).then((response) => {
                setGeoLocations(response.data);
                const zipcodes = response.data.map((geo) => geo.zipcode).join(',');
                if (zipcodes.length > 0) {
                    setZipcodes(zipcodes);
                }
                setZipsLoading(false);
            });
        } else {
            setZipcodes(campaign.zipcodes);
        }
    }, [campaign]);

    function getAddress(dealer: Dealer): string {
        let address = '';
        if (dealer) {
            if (dealer.address) address = dealer.address;
            if (dealer.city) address += ' ' + dealer.city;
            if (dealer.state) address += ' ' + dealer.state;
            if (dealer.zip) address += ' ' + dealer.zip;
        }
        if (address === '' && campaign.zipcodes && campaign.zipcodes.length > 0) {
            address = campaign.zipcodes.split(',')[0];
        }
        return address;
    }

    function zipCodesLoaded(results: ZipCode[]) {
        const zipCodeString = results.map(({ code }) => code).join(',');
        const population = results.reduce((acc, r) => acc + r.population, 0);

        onChange({
            zipcodes: zipCodeString,
            population: Math.round(population / 1000) * 1000,
        });
    }

    function zipCodeClick(zip: ZipCode) {
        let zips = campaign.zipcodes?.split(',') || [];
        let population = campaign.population;
        const index = zips.indexOf(zip.code);
        if (index !== -1) {
            zips = [...zips.slice(0, index), ...zips.slice(index + 1)];
            population -= zip.population;
        } else {
            zips = [...zips, zip.code];
            population += zip.population;
        }

        onChange({
            zipcodes: zips.join(),
            population: population,
        });
    }

    // const title = campaign.zipcodes?.split(',').length &&
    //     campaign.zipcodes?.split(',').length + ' Zip Codes selected'
    // const hasZipCodes = zipcodes && zipcodes?.split(',').length > 0;

    const title = zipcodes && zipcodes?.split(',').length && zipcodes?.split(',').length + ' Zip Codes selected';

    return (
        <CDCard
            title={
                <Row>
                    <span>Geography Targeting</span>
                    <span style={{ flex: 1 }}></span>
                    <span>{title}</span>
                </Row>
            }
        >
            {zipcodes && (
                <Box
                    sx={{
                        height: '55vh',
                    }}
                >
                    <MapComponent
                        onZipClick={zipCodeClick}
                        onZipsLoaded={zipCodesLoaded}
                        radius={campaign.zipcodeRadius ? campaign.zipcodeRadius : 30}
                        zipCode={mapCenter}
                        zipCodes={zipcodes}
                    />
                </Box>
            )}

            {!zipcodes && !zipsLoading && geoLocations.length === 0 && <span>Not available</span>}
            {!zipcodes && !zipsLoading && (
                <>
                    <List>
                        {geoLocations.map((geo) => (
                            <ListItem key={geo.id}>{geo.name}</ListItem>
                        ))}
                    </List>
                </>
            )}
            {zipsLoading && <CircularProgress />}
        </CDCard>
    );
}
