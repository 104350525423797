import { MouseEvent, useState } from 'react';
import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material/';
import { ChevronRightOutlined as ChevronRightOutlinedIcon, MoreVert as MoreVertIcon } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

import { Campaign } from '../../types/Campaign';

// import { TABS } from './campaignDetailView/CampaignDetailsPage';

export default function CampaignManagerGridItemActions(props: { campaign: Campaign }) {
    const { campaign } = props;

    const navigate = useNavigate();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    // const actions = [
    //     { name: 'Performance', tab: TABS.PERFORMANCE },
    //     { name: 'Summary', tab: TABS.SUMMARY },
    //     { name: 'Targeting', tab: TABS.TARGETING },
    //     { name: 'Creative', tab: TABS.CREATIVE },
    //     { name: 'Flights', tab: TABS.FLIGHTS },
    //     { name: 'Fees', tab: TABS.FEES },
    //     { name: 'Change Requests', tab: TABS.CHANGE_REQUESTS },
    // ];

    return (
        <div>
            <IconButton
                aria-label="more"
                id="campaign-manager-grid-item-actions-menu__button"
                aria-controls={open ? 'campaign-manager-grid-item-actions-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                id="campaign-manager-grid-item-actions-menu"
                MenuListProps={{
                    'aria-labelledby': 'campaign-manager-grid-item-actions-menu__button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <MenuItem
                    key="ViewDetails"
                    onClick={() => {
                        navigate('' + campaign.id);
                        handleClose();
                    }}
                >
                    <ListItemIcon>
                        <ChevronRightOutlinedIcon fontSize="medium" />
                    </ListItemIcon>
                    <ListItemText>View Details</ListItemText>
                </MenuItem>

                {/*<Divider />*/}

                {/*{actions.map((action) => {
                    return (
                        <MenuItem
                            key={action.tab}
                            onClick={() => {
                                navigate('' + campaign.id, {
                                    state: {
                                        tab: action.tab,
                                    },
                                });
                            }}
                        >
                            <ListItemIcon>
                                <ChevronRightOutlinedIcon fontSize="medium" />
                            </ListItemIcon>
                            <ListItemText>View {action.name}</ListItemText>
                        </MenuItem>
                    );
                })}*/}
            </Menu>
        </div>
    );
}
