import { Box, IconButton, InputAdornment, TextField } from '@mui/material';
import CDGrid from '../../components/CDGrid';
import { ChangeEvent, useEffect, useState } from 'react';
import ApiService from '../../ApiService';
import { DealerPolkAudience } from '../../types/DealerPolkAudience';
import Row from '../../components/Row';
import { ClearOutlined, SearchOutlined } from '@mui/icons-material';
import Utils from '../../components/Utils';
import Heading, { SubHeading } from '../../components/Heading';

export default function PolkAudiencesQueuePage() {
    const [dealers, setDealers] = useState<DealerPolkAudience[] | null>(null);
    const [allDealers, setAllDealers] = useState<DealerPolkAudience[]>([]);
    const [page, setPage] = useState(
        // Use a callback function so the local storage call only runs once.
        () => Number(localStorage.getItem('polkAudienceGridPage'))
    );
    const [searchText, setSearchText] = useState<string>(
        // Use a callback function so the local storage call only runs once.
        () => Utils.localStorage('polkAudienceSearch', '')
    );

    useEffect(() => {
        const initialSearchText = Utils.localStorage('polkAudienceSearch', '');
        ApiService.getDealerPolkAudienceQueueData()
            .then(function (response) {
                setAllDealers(response.data);
                setDealers(response.data);
                filterRows(initialSearchText, response.data);
            })
            .catch(() => {
                setDealers([]);
            });
    }, []);

    function onPageChange(page: number) {
        setPage(page);
        localStorage.setItem('dealerGridPage', String(page));
    }

    function clearSearch() {
        setSearchText('');
        filterRows('', allDealers);
        localStorage.setItem('polkAudienceSearch', '');
    }

    function search(event: ChangeEvent<HTMLInputElement>) {
        event.preventDefault();
        setSearchText(event.target.value);
        filterRows(event.target.value, allDealers);
        localStorage.setItem('polkAudienceSearch', event.target.value);
    }

    function filterRows(searchText: string, allDealers: DealerPolkAudience[]) {
        const filteredRows = allDealers.filter((row: DealerPolkAudience) => {
            return (
                row.dealerName.toLowerCase().indexOf(searchText.toLowerCase()) > -1 ||
                row.agencyName.toLowerCase().indexOf(searchText.toLowerCase()) > -1
            );
        });
        setDealers(filteredRows);
    }

    function CustomToolbar() {
        return (
            <Row justifyContent="space-between" sx={{ marginBottom: '16px' }}>
                <TextField
                    inputRef={(input) => input && input.focus()}
                    id="searchText"
                    value={searchText}
                    size="small"
                    label="Search"
                    onChange={search}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchOutlined fontSize="small" />
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={() => clearSearch()} edge="end">
                                    <ClearOutlined fontSize="small" />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </Row>
        );
    }

    return (
        <Box
            id="box"
            sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <Heading>Polk Audience Queue</Heading>
            <SubHeading>
                View the status of the Polk Audience Queue. Higher priority audiences are loaded first.
            </SubHeading>
            <br />
            <CustomToolbar />
            <CDGrid
                id="advertiserList"
                page={page}
                columns={[
                    {
                        width: 200,
                        field: 'agencyName',
                        headerName: 'Agency Name',
                        flex: 2,
                    },
                    {
                        width: 200,
                        field: 'dealerName',
                        headerName: 'Advertiser Name',
                        flex: 2,
                    },
                    {
                        field: 'totalAudiences',
                        headerName: 'Total Audiences to Upload',
                        flex: 1,
                    },
                    {
                        field: 'priorityOrder',
                        headerName: 'Priority Order',
                        flex: 1,
                    },
                ]}
                rows={dealers === null ? [] : dealers}
                loading={dealers === null}
                autoHeight={false}
                autoPageSize={true}
                onPageChange={onPageChange}
            />
        </Box>
    );
}
