import { SyntheticEvent } from 'react';
import { useCallback, useState } from 'react';

export function useTabs(props?: { defaultActiveTab?: number }) {
    const { defaultActiveTab = 0 } = props || {};

    const [activeTab, setActiveTab] = useState<number>(defaultActiveTab);

    const handleChangeTab = useCallback((event: SyntheticEvent, newActiveTab: number) => {
        setActiveTab(newActiveTab);
    }, []);

    return {
        activeTab,
        setActiveTab,
        defaultActiveTab,
        handleChangeTab,
    };
}
