import { Box, Tab, Tabs } from '@mui/material';
import { SyntheticEvent, useEffect, useMemo, useState } from 'react';
import ApiService from '../../ApiService';
import { DealerPolkAudience } from '../../types/DealerPolkAudience';
import TabPanel from '../../components/TabPanel';
import Heading, { SubHeading } from '../../components/Heading';
import PolkAudienceDealerGrid from './PolkAudienceDealerGrid';
import PolkAudienceQueueGrid from './PolkAudienceQueueGrid';

export default function PolkAudiencesPage() {
    const [dealers, setDealers] = useState<DealerPolkAudience[] | null>(null);
    const [polkAudienceQueues, setPolkAudienceQueues] = useState<DealerPolkAudience[] | null>(null);
    const [selectedTab, setSelectedTab] = useState(0);

    const completedDealers = useMemo(() => {
        return dealers === null
            ? []
            : dealers.filter((_dealer) => {
                  return getDealerPolkAudienceStatus(_dealer) === 'Completed';
              });
    }, [dealers]);
    const queuedDealers = useMemo(() => {
        return dealers === null
            ? []
            : dealers.filter((_dealer) => {
                  return getDealerPolkAudienceStatus(_dealer) === 'Queued';
              });
    }, [dealers]);

    function getDealerPolkAudienceStatus(dealerPolkAudience: DealerPolkAudience) {
        let status: 'Queued' | 'Processing' | 'Completed';

        if (
            dealerPolkAudience?.hasAllNewCars === 'Yes' &&
            dealerPolkAudience?.hasAllUsedCars === 'Yes' &&
            dealerPolkAudience?.hasAllEVCars === 'Yes'
        ) {
            status = 'Completed';
        } else if (
            dealerPolkAudience?.hasAllNewCars === 'No' ||
            dealerPolkAudience?.hasAllUsedCars === 'No' ||
            dealerPolkAudience?.hasAllEVCars === 'No'
        ) {
            status = 'Queued';
        } else {
            status = 'Processing';
        }

        return status;
    }

    useEffect(() => {
        ApiService.getDealerPolkAudienceData()
            .then(function (response) {
                return ApiService.getDealerPolkAudienceQueueData()
                    .then(function (polkAudienceQueueResponse) {
                        polkAudienceQueueResponse.data.forEach((polkAudienceQueue: DealerPolkAudience) => {
                            for (let index: number = 0; index < response.data.length; index++) {
                                const polkAudienceDealer: DealerPolkAudience = response.data[index];

                                if (!('totalAudiences' in polkAudienceDealer)) {
                                    polkAudienceDealer.totalAudiences = undefined;
                                }

                                if (!('priorityOrder' in polkAudienceDealer)) {
                                    polkAudienceDealer.priorityOrder = undefined;
                                }

                                if (polkAudienceQueue.id === polkAudienceDealer.id) {
                                    polkAudienceDealer.totalAudiences = polkAudienceQueue.totalAudiences;
                                    polkAudienceDealer.priorityOrder = polkAudienceQueue.priorityOrder;
                                    break;
                                }

                                response.data[index] = polkAudienceDealer;
                            }
                        });

                        setDealers(response.data);
                        setPolkAudienceQueues(polkAudienceQueueResponse.data);
                    })
                    .catch(() => {
                        setDealers([]);
                        setPolkAudienceQueues([]);
                    });
            })
            .catch(() => {
                setDealers([]);
                setPolkAudienceQueues([]);
            });
    }, []);

    function selectTab(_event: SyntheticEvent, value: any) {
        setSelectedTab(value);
    }

    return (
        <Box
            sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <Heading>Polk Audience Monitor</Heading>
            <SubHeading>
                Audiences in Completed have been loaded into the Amazon DSP. Audiences in Processing are loading based
                on the Priority Order (higher numbers are first). Audiences in Queued are on deck to be processed next.
            </SubHeading>

            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={selectedTab} onChange={selectTab}>
                    <Tab value={0} label="Completed" />
                    <Tab value={1} label="Processing" />
                    <Tab value={2} label="Queued" />
                </Tabs>
            </Box>

            <TabPanel index={0} value={selectedTab}>
                <Box
                    sx={{
                        height: '100vh',
                        width: '100%',
                        flexGrow: 1,
                    }}
                >
                    <PolkAudienceDealerGrid dealers={completedDealers} />
                </Box>
            </TabPanel>

            <TabPanel index={1} value={selectedTab}>
                <Box
                    sx={{
                        height: '100vh',
                        width: '100%',
                        flexGrow: 1,
                    }}
                >
                    <PolkAudienceQueueGrid queues={polkAudienceQueues} />
                </Box>
            </TabPanel>

            <TabPanel index={2} value={selectedTab}>
                <Box
                    sx={{
                        height: '100vh',
                        width: '100%',
                        flexGrow: 1,
                    }}
                >
                    <PolkAudienceDealerGrid dealers={queuedDealers} />
                </Box>
            </TabPanel>
        </Box>
    );
}
