import { Campaign } from '../../../types/Campaign';
import { List, ListItem, Tooltip } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';
import CDCard from '../../../components/CDCard';
import { SubHeading } from '../../../components/Heading';

export default function AudienceTargeting(props: { campaign: Campaign }) {
    const { campaign } = props;

    return (
        <CDCard title="Audience Targeting">
            <SubHeading>
                In-Market Consumer
                <Tooltip title="Automatically optimized">
                    <span style={{ paddingLeft: '10px' }}>
                        <InfoOutlined fontSize="small" />
                    </span>
                </Tooltip>
            </SubHeading>
            <div style={{ paddingLeft: '8px', paddingBottom: '16px', fontSize: 'smaller' }}>
                Automatically optimized
            </div>

            <SubHeading>
                Audience Targeting
                <List sx={{ pt: 0, pb: 0 }} dense>
                    {campaign.dataSets &&
                        campaign.dataSets
                            .filter((ds) => ds.audienceTargeting !== 'X')
                            .map((dataSet) => (
                                <ListItem sx={{ p: 0 }}>
                                    Custom Audience: {dataSet.audienceTargeting === 'I' ? 'Include ' : 'Exclude '}{' '}
                                    {dataSet.dataSetName}
                                </ListItem>
                            ))}
                    {campaign.audienceDemographic && <ListItem sx={{ p: 0 }}>Demographic</ListItem>}
                    {campaign.audienceLifeStageEvents && <ListItem sx={{ p: 0 }}>Life Stage</ListItem>}
                </List>
            </SubHeading>

            <SubHeading style={{ backgroundColor: 'red', marginTop: '16px' }}>
                Custom Audience
                <Tooltip title="Custom Audience targeting based on Advertiser submitted Leads, Sales, Store Visits, etc.">
                    <span style={{ paddingLeft: '10px' }}>
                        <InfoOutlined fontSize="small" />
                    </span>
                </Tooltip>
            </SubHeading>
            <div style={{ paddingLeft: '8px', paddingBottom: '16px', fontSize: 'smaller' }}>Recommended strategy</div>

            {(campaign.audienceDemographic || campaign.audienceLifeStageEvents) && (
                <div>
                    <SubHeading>Additional Targeting</SubHeading>
                    {campaign.audienceDemographic && (
                        <div style={{ paddingLeft: '16px', fontSize: 'smaller' }}>Demographic</div>
                    )}
                    {campaign.audienceLifeStageEvents && (
                        <div>
                            <div style={{ paddingLeft: '16px', fontSize: 'smaller' }}>Life Stage Events</div>
                        </div>
                    )}
                </div>
            )}

            <div style={{ paddingTop: '16px' }}>
                <SubHeading>Targeting Keywords</SubHeading>
                <div style={{ paddingLeft: '16px', fontSize: 'smaller' }}>{campaign.audienceTargeting}</div>
            </div>
        </CDCard>
    );
}
