import { Link, useLocation } from 'react-router-dom';
import { Box, ListItemButton, ListItemIcon, ListItemText, useTheme } from '@mui/material';

export interface NavigationItemProps {
    label: string;
    to: string;
    icon?: JSX.Element;
    hasActiveItem?: boolean;
    accessGroup?: string;
    accessAgency?: string;
    onNavigate(name: string): void;
    onClick?(event: React.MouseEvent): void;
}
export default function NavigationItem(props: NavigationItemProps) {
    const { label, icon, to, hasActiveItem, onNavigate } = props;
    const location = useLocation();
    const theme = useTheme();

    let isSelected;
    if (hasActiveItem !== undefined) {
        isSelected = hasActiveItem;
    } else {
        if (to === '/') {
            isSelected = location.pathname === '/';
        } else {
            isSelected = new RegExp(`^${to}/?`).test(location.pathname);
        }
    }

    // const iconColor = isSelected ? theme.palette.primary.dark : theme.palette.text.primary;

    return (
        <ListItemButton
            sx={{
                width: '88px',
                maxWidth: '88px',
                color: '#ffffff',
                marginBottom: '24px',
                paddingLeft: '10px',
                paddingRight: '10px',
                borderRadius: '6px',
                '&:hover': {
                    backgroundColor: 'inherit',
                },
                '&.Mui-selected': {
                    backgroundColor: 'inherit',
                },
                '&.Mui-selected:hover': {
                    backgroundColor: 'inherit',
                },
                '& .MuiListItemIcon-root': {
                    color: isSelected ? '#ffffff' : 'rgba(255, 255, 255, 0.7)',
                    backgroundColor: isSelected ? theme.palette.primary.main : '',
                    borderRadius: '8px',
                    height: '36px',
                    width: '36px',
                },
                '&:hover .MuiListItemIcon-root': {
                    backgroundColor: isSelected ? theme.palette.primary.main : 'rgba(77, 102, 166, 0.35)',
                },
                '& .MuiTypography-root': {
                    fontSize: '12px',
                    textAlign: 'center',
                },
            }}
            selected={isSelected}
            className="Navigation-link"
            component={Link}
            to={to}
            onClick={(event) => {
                if (props.onClick) {
                    event.preventDefault();
                    props.onClick(event);
                } else {
                    onNavigate(to);
                }
            }}
        >
            {' '}
            {icon && (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignContent: 'center',
                        alignItems: 'center',
                        justifyContent: 'center',
                        minWidth: '0px',
                        height: '64px',
                        width: '64px',
                    }}
                >
                    <ListItemIcon
                        sx={{
                            display: 'flex',
                            alignContent: 'center',
                            alignItems: 'center',
                            justifyContent: 'center',
                            minWidth: '0px',
                        }}
                    >
                        {icon}
                    </ListItemIcon>
                    <ListItemText primary={label} />
                </Box>
            )}
        </ListItemButton>
    );
}
