import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';

import { ReportingCriteria } from '../../../../types/Analytics/Reporting/ReportingCriteria';

interface ReportingCampaignStrategyTypeSelectProps {
    reportingCriteria: ReportingCriteria;
    onChangeReportingCriteria: (reportingCriteria: ReportingCriteria) => void;
    strategyTypes?: Record<string, string>;
}

export default function ReportingCampaignStrategyTypeSelect(props: ReportingCampaignStrategyTypeSelectProps) {
    const { reportingCriteria, onChangeReportingCriteria } = props;

    const strategyTypes: Record<string, string> = {
        '': 'All',
        // Acquisition: 'Acquisition',
        // Awareness: 'Awareness',
        // Inventory: 'Inventory',
        // FixedOps: 'Fixed Ops',
        // Parts: 'Parts',

        ...props.strategyTypes,
    };

    const handleChange = (event: SelectChangeEvent): void => {
        onChangeReportingCriteria({
            ...reportingCriteria,
            strategyType: event.target.value,
        });
    };

    return (
        <FormControl sx={{ m: 1, minWidth: 240 }} size="small">
            <InputLabel id="campaignStrategyTypeLabel" shrink>
                Campaign Strategy
            </InputLabel>
            <Select
                displayEmpty={true}
                labelId="campaignStrategyTypeLabel"
                id="campaignStrategyType"
                value={reportingCriteria.strategyType}
                label="Campaign Strategy"
                onChange={handleChange}
            >
                {Object.keys(strategyTypes).map((strategyType: string) => {
                    return (
                        <MenuItem key={strategyType} value={strategyType}>
                            {strategyTypes[strategyType]}
                        </MenuItem>
                    );
                })}
            </Select>
        </FormControl>
    );
}
