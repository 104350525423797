import { useMemo } from 'react';
import { Box, Card, CardContent, Typography } from '@mui/material';

import Column from '../../components/Column';

import { useAttributionPageContext } from '../../hooks/useAttributionPage';
import AdvertiserMatchbackCampaignTable from './AdvertiserMatchbackCampaignTable';

export default function AdvertiserMatchbackCampaignTableView() {
    const { getPerformanceMediaTypes } = useAttributionPageContext();

    const mediaTypes: string[] = getPerformanceMediaTypes();

    const hasData = useMemo((): boolean => {
        return mediaTypes && mediaTypes.length > 0 ? true : false;
    }, [mediaTypes]);

    const boxHeight: number = 270;

    return (
        <Column gap={3}>
            <Box>
                <Box sx={{ my: 3 }}>
                    <Typography
                        variant="subtitle2"
                        sx={{
                            color: (theme) => theme.palette.text.primary,
                            fontWeight: (theme) => theme.typography.fontWeightMedium,
                        }}
                    >
                        Campaign Metrics by Media Type
                    </Typography>
                </Box>

                {hasData === true && <AdvertiserMatchbackCampaignTable />}
                {hasData === false && (
                    <Card variant="outlined">
                        <CardContent>
                            <Column
                                direction="column"
                                alignItems="center"
                                justifyContent="center"
                                sx={{ height: boxHeight + 2 }}
                            >
                                <Typography variant="body2">No data available</Typography>
                            </Column>
                        </CardContent>
                    </Card>
                )}
            </Box>
        </Column>
    );
}
