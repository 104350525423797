import moment from 'moment-timezone';

export function parseDateFormat(dateString: string): string | null | undefined {
    const formats: Record<string, string> = {
        'MM/dd/yyyy': 'MM/DD/YYYY',
        'MM-dd-yyyy': 'MM-DD-YYYY',
        'MM/dd/yy': 'MM/DD/YY',
        'MM-dd-yy': 'MM-DD-YY',
        'M/d/yyyy': 'M/D/YYYY',
        'M/d/yy': 'M/D/YY',
        'M-d-yy': 'M-D-YY',
        'M-d-yyyy': 'M-D-YYYY',

        'd/M/yyyy': 'D/M/YYYY',
        'd/M/yy': 'D/M/YY',
        'd-M-yy': 'D-M-YY',
        'd-M-yyyy': 'D-M-YYYY',
    };

    return Object.keys(formats).find((df: string): boolean => {
        return moment(dateString, formats[df], true).isValid();
    });
}
