import { useCallback, useEffect, useState } from 'react';
import { GridCallbackDetails, GridColumnVisibilityModel } from '@mui/x-data-grid';

export default function useGridColumnVisibilityModel(params: { id: string }) {
    const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel | undefined>(() => {
        let _columnVisibilityModel: GridColumnVisibilityModel | undefined;

        let data = localStorage.getItem(`${params.id}.columnVisibilityModel`);

        if (data) {
            try {
                _columnVisibilityModel = JSON.parse(data) as GridColumnVisibilityModel;
            } catch (error) {
                _columnVisibilityModel = {};
            }
        } else {
            _columnVisibilityModel = {};
        }

        return _columnVisibilityModel;
    });

    useEffect(() => {
        localStorage.setItem(`${params.id}.columnVisibilityModel`, JSON.stringify(columnVisibilityModel));
    }, [params.id, columnVisibilityModel]);

    const onColumnVisibilityModelChange = useCallback(
        (model: GridColumnVisibilityModel, details: GridCallbackDetails<any>): void => {
            setColumnVisibilityModel(model);
        },
        []
    );

    return {
        columnVisibilityModel,
        setColumnVisibilityModel,
        onColumnVisibilityModelChange,
    };
}
