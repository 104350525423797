import { MouseEvent } from 'react';
import { useEffect, useRef, useState } from 'react';
import { Box, Divider, Typography } from '@mui/material';
import { InputAdornment, TextField } from '@mui/material';
import { Button, IconButton } from '@mui/material';
import { Popover, PopoverProps } from '@mui/material';
import { ListItem, ListItemButton, ListItemText } from '@mui/material';
import { ArrowDropDown as ArrowDropDownIcon } from '@mui/icons-material';
import { AdapterDayjs as AdapterDayjsPro } from '@mui/x-date-pickers-pro/AdapterDayjs';
import {
    LocalizationProvider as LocalizationProProvider,
    SingleInputDateRangeField,
    SingleInputDateRangeFieldProps,
    DateRangeCalendar,
    DateRangeCalendarProps,
} from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { DateRange } from '@mui/x-date-pickers-pro/models';
import dayjs, { Dayjs } from 'dayjs';
import { endOfQuarter, isSameDay, startOfQuarter, sub, subQuarters } from 'date-fns';

import { adjustDateRangeInPast } from '../pages/insights/utils';
import Row from './Row';
import Utils from './Utils';

interface StaticRange {
    label: string;
    range: () => DateRange<Dayjs>;
    isSelected: (range: DateRange<Dayjs>) => boolean;
}

const getStaticRange = (label: string, startDate: Date, endDate: Date): StaticRange => {
    return {
        label: label,
        range() {
            return [dayjs(startDate), dayjs(endDate)];
        },
        isSelected: (range: DateRange<Dayjs>) => {
            if (range[0] && range[1]) {
                return isSameDay(range[0].toDate(), startDate) && isSameDay(range[1].toDate(), endDate);
            } else {
                return false;
            }
        },
    };
};

const getWeeklyStaticRange = (label: string, startDate: Date, weeksInPast: number): StaticRange => {
    const dateRange = adjustDateRangeInPast({
        startDate: startDate,
        weeksInPast: weeksInPast,
    });

    return {
        label: label,
        range() {
            return [dayjs(dateRange.startDate), dayjs(dateRange.endDate)];
        },
        isSelected: (range: DateRange<Dayjs>) => {
            if (range[0] && range[1]) {
                return (
                    isSameDay(range[0].toDate(), dateRange.startDate) && isSameDay(range[1].toDate(), dateRange.endDate)
                );
            } else {
                return false;
            }
        },
    };
};

const getDefaultDateRanges = (): StaticRange[] => {
    const dateRanges: StaticRange[] = [];
    const today = new Date();
    const lastQuarter = subQuarters(today, 1);

    dateRanges.push(
        getStaticRange('This Month', Utils.getMonthStart(0), today),
        getStaticRange('This Quarter', startOfQuarter(today), today),
        getStaticRange('Last Month', Utils.getMonthStart(-1), Utils.getMonthEnd(-1)),
        getStaticRange('Last Quarter', startOfQuarter(lastQuarter), endOfQuarter(lastQuarter)),
        getStaticRange('Last 30 Days', sub(today, { days: 30 }), today),
        getStaticRange('Last 60 Days', sub(today, { days: 60 }), today),
        getStaticRange('Last 90 Days', sub(today, { days: 90 }), today)
    );

    return dateRanges;
};

interface DateRangePickerProps {
    label: string;
    value: DateRange<Dayjs>;
    onChange: (newRange: DateRange<Dayjs>) => void;
    onSelect?: (newRange: DateRange<Dayjs>) => void;
    onClose?: () => void;
    minDate?: Dayjs;
    maxDate?: Dayjs;
    disableFuture?: boolean;
    disablePast?: boolean;
    ranges?: StaticRange[];
    open?: boolean;

    SingleInputDateRangeFieldProps?: SingleInputDateRangeFieldProps<Dayjs>;
    DateRangeCalendarProps?: DateRangeCalendarProps<Dayjs>;

    PopoverProps?: PopoverProps;
    anchorOrigin?: PopoverProps['anchorOrigin'];
    transformOrigin?: PopoverProps['transformOrigin'];
}

const DateRangePicker = (props: DateRangePickerProps) => {
    const { ranges = [] } = props;

    const [open, setOpen] = useState<boolean>(props.open ?? false);
    const [dateRange, setDateRange] = useState<DateRange<Dayjs>>(props.value);
    const $anchorEl = useRef<any>(null);

    useEffect(() => {
        setDateRange(props.value);
    }, [props.value]);

    const triggerOpenPopover = (): void => {
        setOpen(true);
    };

    const triggerClosePopover = (): void => {
        setOpen(false);

        if (props.onClose) {
            props.onClose();
        }
    };

    const resetDateRange = (): void => {
        setDateRange(props.value);
    };

    const selectStaticRange = (selectedRange: StaticRange): void => {
        setDateRange(selectedRange.range());
    };

    // const handleFocusSingleInput = (event: any) => {
    //     triggerOpenPopover();
    // };

    // const handleFocusOutSingleInput = (event: any) => {
    //     triggerClosePopover();
    // };

    const handleClickButton = (event: MouseEvent<HTMLButtonElement>) => {
        triggerOpenPopover();
    };

    const handleClosePopover = (): void => {
        triggerClosePopover();
    };

    const handleDateRangeChange = (newRange: DateRange<Dayjs>): void => {
        setDateRange(newRange);

        if (props.onSelect) {
            props.onSelect(newRange);
        }
    };

    const handleStartDateChange = (newStartDate: Date | null): void => {
        handleDateRangeChange([dayjs(newStartDate), dateRange[1]]);
    };

    const handleEndDateChange = (newEndDate: Date | null): void => {
        handleDateRangeChange([dateRange[0], dayjs(newEndDate)]);
    };

    const handleDeny = (): void => {
        resetDateRange();

        triggerClosePopover();
    };

    const handleConfirm = (): void => {
        props.onChange(dateRange);

        triggerClosePopover();
    };

    return (
        <>
            <LocalizationProProvider dateAdapter={AdapterDayjsPro}>
                <SingleInputDateRangeField
                    size="small"
                    variant="outlined"
                    {...props.SingleInputDateRangeFieldProps}
                    label={props.label}
                    value={props.value}
                    minDate={props.minDate}
                    maxDate={props.maxDate}
                    format="MMM D, YYYY"
                    ref={$anchorEl}
                    readOnly={true}
                    onChange={(newValue) => handleDateRangeChange(newValue)}
                    // onFocus={handleFocusSingleInput}
                    // onBlur={handleFocusOutSingleInput}
                    InputProps={{
                        readOnly: true,
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton size="small" onClick={handleClickButton}>
                                    <ArrowDropDownIcon fontSize="small" />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </LocalizationProProvider>

            <Popover
                open={open}
                {...props.PopoverProps}
                anchorOrigin={
                    props.anchorOrigin ?? {
                        vertical: 'bottom',
                        horizontal: 'right',
                    }
                }
                transformOrigin={
                    props.transformOrigin ?? {
                        vertical: 'top',
                        horizontal: 'right',
                    }
                }
                anchorEl={$anchorEl.current}
                onClose={handleClosePopover}
            >
                <Row gap={0}>
                    {ranges.length > 0 && (
                        <>
                            <Box width={160} sx={{ py: 1 }}>
                                {ranges.map((range: StaticRange, index: number) => {
                                    return (
                                        <ListItem key={index} component="div" disablePadding>
                                            <ListItemButton
                                                selected={range.isSelected(dateRange)}
                                                onClick={(): void => selectStaticRange(range)}
                                            >
                                                <ListItemText primary={range.label} />
                                            </ListItemButton>
                                        </ListItem>
                                    );
                                })}
                            </Box>

                            <Divider orientation="vertical" flexItem />
                        </>
                    )}

                    <Box>
                        <Box sx={{ px: 3, py: 2 }}>
                            <Row gap={2} alignItems="center">
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        minDate={props?.minDate?.toDate()}
                                        maxDate={props?.maxDate?.toDate()}
                                        value={dateRange[0]}
                                        onChange={handleStartDateChange}
                                        renderInput={(props) => (
                                            <TextField size="small" variant="outlined" sx={{ width: 184 }} {...props} />
                                        )}
                                        label="Start Date"
                                        disableOpenPicker={true}
                                        disableFuture={props.disableFuture}
                                        disablePast={props.disablePast}
                                    />

                                    <Typography variant="body1">—</Typography>

                                    <DatePicker
                                        minDate={props?.minDate?.toDate()}
                                        maxDate={props?.maxDate?.toDate()}
                                        value={dateRange[1]}
                                        onChange={handleEndDateChange}
                                        renderInput={(props) => (
                                            <TextField size="small" variant="outlined" sx={{ width: 184 }} {...props} />
                                        )}
                                        label="End Date"
                                        disableOpenPicker={true}
                                        disableFuture={props.disableFuture}
                                        disablePast={props.disablePast}
                                    />
                                </LocalizationProvider>
                            </Row>
                        </Box>

                        <Divider />

                        <LocalizationProProvider dateAdapter={AdapterDayjsPro}>
                            <DateRangeCalendar
                                disableFuture={props.disableFuture}
                                disablePast={props.disablePast}
                                {...props.DateRangeCalendarProps}
                                minDate={props.minDate}
                                maxDate={props.maxDate}
                                value={dateRange}
                                onChange={(newDateRange) => handleDateRangeChange(newDateRange)}
                            />
                        </LocalizationProProvider>

                        <Divider />

                        <Box sx={{ px: 3, py: 2 }}>
                            <Row gap={1} justifyContent="flex-end">
                                <Button color="primary" variant="outlined" onClick={handleDeny}>
                                    Cancel
                                </Button>

                                <Button color="primary" variant="contained" onClick={handleConfirm}>
                                    Apply
                                </Button>
                            </Row>
                        </Box>
                    </Box>
                </Row>
            </Popover>
        </>
    );
};

export type { StaticRange };
export type { DateRangePickerProps };
export { getStaticRange, getWeeklyStaticRange, getDefaultDateRanges };
export default DateRangePicker;
