import AppBreadcrumbs from '../../../components/AppBreadcrumbs';
import Column from '../../../components/Column';
import { AdvertiserDataSetSummary } from '../../../types/DataSet';
import { useCallback, useContext, useEffect, useState } from 'react';
import ApiService from '../../../ApiService';
import { Button, Tooltip, Typography } from '@mui/material';
import { UserContext } from '../../../App';
import DataSetGridView from './DataSetGridView';
import AccessGuard from '../../../components/AccessGuard';
import { USER_GROUP_ADMIN, USER_GROUP_AGENCY } from '../../../types/User';
import DataSetCardView from './DataSetCardView';
import { GridViewOutlined, ViewListOutlined } from '@mui/icons-material';
import { SectionHeading } from '../../../components/SectionHeading';
import Row from '../../../components/Row';
import { AdvertiserContext } from '../../../AdvertiserContext';

export default function DataSetPage() {
    const [dataSetSummaryList, setDataSetSummaryList] = useState<AdvertiserDataSetSummary[]>([]);

    const { userContext } = useContext(UserContext);
    const { advertiserContext } = useContext(AdvertiserContext);

    const [gridView, setGridView] = useState(true);

    const getDataSetSummary = useCallback(() => {
        ApiService.getDataSetSummary(
            advertiserContext.agencyId,
            advertiserContext.dealer ? advertiserContext.dealer.id : null
        )
            .then((response) => {
                setDataSetSummaryList(response.data);
            })
            .catch(() => {});
    }, [advertiserContext]);

    useEffect(() => {
        getDataSetSummary();
    }, [advertiserContext.dealer, getDataSetSummary]);

    return (
        <Column sx={{ height: '100%' }}>
            <Column gap={1}>
                <AppBreadcrumbs items={[]} />

                <SectionHeading subheading={'Advertiser Submitted First Party Data'} divider={false}>
                    <Row gap={1} alignItems="center">
                        <Typography variant="h6">AMC Data Sets</Typography>
                    </Row>
                </SectionHeading>
            </Column>

            <Column gap={2} sx={{ height: '100%' }}>
                <AccessGuard accessGroup={USER_GROUP_ADMIN}>
                    <>
                        <Row gap={1}>
                            <Tooltip title="List View">
                                <Button
                                    sx={{
                                        minWidth: 0,
                                        margin: 1,
                                        mr: 0,
                                        color: gridView ? 'primary.dark' : 'primary.light',
                                    }}
                                    variant="outlined"
                                    size="small"
                                    onClick={() => setGridView(!gridView)}
                                >
                                    <ViewListOutlined />
                                </Button>
                            </Tooltip>

                            <Tooltip title="Grid View">
                                <Button
                                    sx={{
                                        minWidth: 0,
                                        margin: 1,
                                        ml: 0,
                                        color: !gridView ? 'primary.dark' : 'primary.light',
                                    }}
                                    variant="outlined"
                                    size="small"
                                    onClick={() => setGridView(!gridView)}
                                >
                                    <GridViewOutlined />
                                </Button>
                            </Tooltip>
                        </Row>

                        {gridView && (
                            <>
                                <DataSetGridView
                                    dataSetSummaryList={dataSetSummaryList}
                                    agencyId={advertiserContext.agencyId}
                                    userContext={userContext}
                                    onCreateAudience={() => {
                                        getDataSetSummary();
                                    }}
                                />
                            </>
                        )}

                        {!gridView && (
                            <DataSetCardView
                                dataSetSummaryList={dataSetSummaryList}
                                agencyId={advertiserContext.agencyId}
                                userContext={userContext}
                            />
                        )}
                    </>
                </AccessGuard>
                <AccessGuard accessGroup={USER_GROUP_AGENCY}>
                    <DataSetCardView
                        dataSetSummaryList={dataSetSummaryList}
                        agencyId={advertiserContext.agencyId}
                        userContext={userContext}
                    />
                </AccessGuard>
            </Column>
        </Column>
    );
}
