import { useContext, useEffect, useState } from 'react';
import { Box, Button, Tooltip, Typography } from '@mui/material';
import {
    HelpOutlineOutlined as HelpOutlineOutlinedIcon,
    UpdateOutlined as UpdateOutlinedIcon,
} from '@mui/icons-material';
import { Link } from 'react-router-dom';
import moment from 'moment-timezone';

import { SectionHeading } from '../../components/SectionHeading';
import { UserContext } from '../../App';
import Column from '../../components/Column';
import Printable from '../../components/Printable';
import Row from '../../components/Row';
import Utils from '../../components/Utils';

import { useAttributionPageContext } from '../../hooks/useAttributionPage';
import useAdvertiserAttribution from '../../hooks/useAdvertiserAttribution';
import AdvertiserMatchbackMetrics from './AdvertiserMatchbackMetrics';
import SalesDataUploadDialog from '../salesData/SalesDataUploadDialog';
import PolkMatchbackDataTabs from './PolkMatchbackDataTabs';

// type VIEW = 'chart' | 'table';

export default function AdvertiserMatchbackTab() {
    const { userContext } = useContext(UserContext);
    const {
        dealer,
        attributionDate,
        setAttributionDate,
        attributionStatus,
        fetchAttributionStatus,
        advertiserSalesUpdateDateFormatted,
    } = useAttributionPageContext();

    const { fetchPerformances, fetchPreviousPerformances } = useAdvertiserAttribution();

    const [salesDataUploadDialogOpen, setSalesDataUploadDialogOpen] = useState<boolean>(false);

    useEffect(() => {
        fetchAttributionStatus();
    }, [fetchAttributionStatus]);

    useEffect(() => {
        fetchPerformances();
    }, [fetchPerformances]);

    useEffect(() => {
        fetchPreviousPerformances();
    }, [fetchPreviousPerformances]);

    return (
        <Column gap={3} sx={{ mt: (theme) => theme.spacing(1) }}>
            {advertiserSalesUpdateDateFormatted && (
                <Box>
                    <Row gap={0.5}>
                        <UpdateOutlinedIcon color="success" fontSize="small" />
                        <Typography variant="body2" color="text.secondary">
                            Last updated {advertiserSalesUpdateDateFormatted}
                        </Typography>
                    </Row>
                </Box>
            )}

            <Printable show={true}>
                <>
                    {dealer && (
                        <Row justifyContent="center" alignItems="center">
                            {dealer.logoUrl && dealer.logoUrl.length > 0 ? (
                                <img
                                    src={dealer.logoUrl}
                                    alt={`${dealer.dealerName}'s Logo`}
                                    style={{ maxHeight: 128 }}
                                />
                            ) : (
                                <Typography variant="h4">{dealer.dealerName}</Typography>
                            )}
                        </Row>
                    )}
                    <Row justifyContent="center" alignItems="center">
                        <Typography variant="h5">
                            {moment(Utils.getDate(attributionDate)).format('MMMM YYYY')}
                        </Typography>
                    </Row>
                </>
            </Printable>

            <Box>
                <Row justifyContent="space-between" alignItems="center" spacing={2}>
                    <Column gap={0.5}>
                        <Row gap={0.5} alignItems="center">
                            <SectionHeading divider={false}>Overview</SectionHeading>

                            <Printable show={false}>
                                <Tooltip title="Sales data provided by Advertiser">
                                    <HelpOutlineOutlinedIcon color="action" sx={{ fontSize: 16 }} />
                                </Tooltip>
                            </Printable>
                        </Row>
                    </Column>

                    <Printable show={false}>
                        <>
                            {dealer && (
                                <Row sx={{ pr: 2 }} justifyContent="flex-end">
                                    <Button
                                        variant="text"
                                        color="primary"
                                        onClick={() => {
                                            setSalesDataUploadDialogOpen(true);
                                        }}
                                    >
                                        Upload CRM Data
                                    </Button>

                                    <SalesDataUploadDialog
                                        dealer={dealer}
                                        open={salesDataUploadDialogOpen}
                                        onClose={() => {
                                            setSalesDataUploadDialogOpen(false);
                                            setAttributionDate(Utils.getDate(attributionDate));
                                            fetchAttributionStatus();
                                        }}
                                    />

                                    {userContext.isAdvertiser() ? (
                                        <>
                                            <Button
                                                variant="text"
                                                color="primary"
                                                size="medium"
                                                component={Link}
                                                to={`mailto:support@cognitionads.com?subject=Request to Map Polk Data for Advertiser`}
                                            >
                                                Assign Competitors
                                            </Button>
                                        </>
                                    ) : (
                                        <>
                                            {dealer && (
                                                <Button
                                                    variant="text"
                                                    color="primary"
                                                    size="medium"
                                                    component={Link}
                                                    to={`/advertisers/${dealer.id}/#polkSales`}
                                                >
                                                    Assign Competitors
                                                </Button>
                                            )}
                                        </>
                                    )}
                                </Row>
                            )}
                        </>
                    </Printable>
                </Row>
            </Box>

            {attributionStatus && attributionDate && (
                <Box>
                    <AdvertiserMatchbackMetrics />
                </Box>
            )}

            <Box>
                <PolkMatchbackDataTabs />
            </Box>
        </Column>
    );
}
