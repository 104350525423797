import { useCallback, useContext, useEffect } from 'react';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import { UserContext } from '../App';

const parseName = (fullName: string) => {
    const nameParts = fullName.trim().split(' ');

    if (nameParts.length === 1) {
        return {
            firstName: nameParts[0],
            lastName: '',
        };
    }

    return {
        firstName: nameParts[0],
        lastName: nameParts.slice(1).join(' '),
    };
};

interface TrialfireUserData {
    firstName: string;
    lastName: string;
    email: string;
    phone?: string;
    company?: string;
    title?: string;
}

interface TrialfireState {
    getTrackingId: () => string | null;
    hasTrackingId: () => boolean;
    hasIdentifiedUser: () => boolean;
    hasNotIdentifiedUser: () => boolean;
    markIdentifiedUser: () => void;
    markNotIdentifiedUser: () => void;
    identifyUser: (userId: number, userData: TrialfireUserData) => void;
}

export function useTrialfire(): TrialfireState {
    const getTrackingId = (): string | null => {
        return sessionStorage.getItem('trialfireTrackingId');
    };

    const hasTrackingId = (): boolean => {
        return getTrackingId() !== null;
    };

    const hasIdentifiedUser = (): boolean => {
        return localStorage.getItem('X-COGNITION-TRIALFIRE') === null;
    };

    const hasNotIdentifiedUser = (): boolean => {
        return localStorage.getItem('X-COGNITION-TRIALFIRE') === '1';
    };

    const markIdentifiedUser = (): void => {
        localStorage.removeItem('X-COGNITION-TRIALFIRE');
    };

    const markNotIdentifiedUser = (): void => {
        localStorage.setItem('X-COGNITION-TRIALFIRE', '1');
    };

    const identifyUser = useCallback((userId: number, userData: TrialfireUserData) => {
        if (window?.Lassoo) {
            try {
                //
                window.Lassoo.identify(userId, userData);
            } catch (error) {
                //
                console.log(error);
            }

            //
            markIdentifiedUser();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        getTrackingId,
        hasTrackingId,

        identifyUser,
        hasIdentifiedUser,
        hasNotIdentifiedUser,
        markIdentifiedUser,
        markNotIdentifiedUser,
    };
}

export function TrialfireIdentifyUserScript() {
    const { authStatus } = useAuthenticator((context) => [context.authStatus, context.user]);
    const { identifyUser, hasNotIdentifiedUser } = useTrialfire();
    const { userContext } = useContext(UserContext);

    const captureUser = useCallback(() => {
        if (userContext?.userId && userContext?.email) {
            identifyUser(userContext.userId, {
                ...parseName(userContext.name as string),
                phone: '',
                email: userContext.email as string,
                company: userContext.organization as string,
                title: '',
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userContext]);

    useEffect(() => {
        if (authStatus === 'authenticated') {
            if (hasNotIdentifiedUser()) {
                captureUser();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authStatus, captureUser]);

    return (
        <HelmetProvider>
            <Helmet>
                <script type="text/javascript">{/*{`console.log('Trialfire - User Identified');`}*/}</script>
            </Helmet>
        </HelmetProvider>
    );
}

export default function TrialfireScript() {
    const { getTrackingId } = useTrialfire();

    const trackingId: string | null = getTrackingId();

    if (!trackingId) {
        return <></>;
    }

    return (
        <HelmetProvider>
            <Helmet>
                <script type="text/javascript">
                    {`
                        var s=document.createElement("script"),tf={$q:[],do:function(){tf.$q.push(
                        [].slice.call(arguments))}};["init","ready","identify","property","logout",
                        "track","optout"].forEach(function(t){tf[t]=function(){tf.do.apply(null,
                        [t].concat([].slice.call(arguments)))}}),window.Trialfire=tf,
                        s.src="//cdn.trialfire.com/tf.js",document.head.appendChild(s),
                        Trialfire.init("${trackingId}");
                        window.Lassoo=window.Trialfire;
                    `}
                </script>
            </Helmet>
        </HelmetProvider>
    );
}
