import { PerformanceMetric } from '../../../types/CampaignPerformance';
import { ApexOptions } from 'apexcharts';
import theme from '../../../theme';
import { CDChart } from '../../../components/CDChart';
import Utils from '../../../components/Utils';

export default function PerformanceChart(props: { metric: PerformanceMetric }) {
    const { metric } = props;

    function formatValue(metric: PerformanceMetric, value: any): any {
        switch (metric.format) {
            case 'dollar':
                return Utils.formatCurrency(value, 2);

            case 'percent':
                return Utils.formatValue(value, 'percent');

            default:
                return Utils.formatNumber(value);
        }
    }

    let chartOptions: ApexOptions = {
        stroke: {
            curve: 'straight',
            width: 2,
        },
        grid: {
            show: true,
        },
        tooltip: {
            x: {
                show: true,
                format: 'MMMM dd, yyyy',
                formatter: undefined,
            },
            y: {
                formatter: function (value) {
                    if (!value) {
                        return 'No data available';
                    } else {
                        return formatValue(metric, value);
                    }
                },
            },
            fixed: {
                enabled: true,
            },
        },
        colors: metric.colors ? metric.colors : [theme.palette.primary.main, theme.palette.success.dark],
        xaxis: {
            type: 'datetime',
            labels: {
                showDuplicates: false,
                datetimeFormatter: {
                    month: 'MMM',
                    day: 'MMM dd',
                    hour: 'MMM dd',
                },
            },
            tooltip: {
                enabled: false,
            },
        },
        yaxis: {
            labels: {
                formatter: (value: any) => formatValue(metric, value),
            },
            min: 0,
            forceNiceScale: true,
        },
    };

    return (
        <CDChart
            label=""
            chartOptions={chartOptions}
            series={metric.apexSeries}
            categories={['']}
            width="100%"
            height="350px"
        />
    );
}
