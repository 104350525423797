import { Checkbox, Chip, MenuItem, TextField } from '@mui/material';
import { Dealer } from '../types/Dealer';
import { ChangeEvent, ReactNode } from 'react';

export interface MultiAdvertiserSelectorProps {
    dealers: Dealer[];
    dealerIds: number[];
    onDealerChange(values: number[]): void;
    label: string;
    required: boolean;
}

export default function MultiAdvertiserSelector(props: MultiAdvertiserSelectorProps) {
    function renderSelectedDealers(value: unknown): ReactNode {
        let selected: string[] = [];
        let values = value as number[];
        values.forEach((id) => {
            let dealer = props.dealers.find((dealer) => dealer.id === id);
            if (dealer) {
                selected.push(dealer.dealerName);
            }
        });
        //return (<span>{selected.join(', ')}</span>)
        return <></>;
    }

    function deleteAdvertiser(dealerId: number) {
        let dealerIds = props.dealerIds;
        dealerIds = dealerIds.filter((id) => id !== dealerId);
        props.onDealerChange(dealerIds);
    }

    function handleDealerChange(event: ChangeEvent<HTMLInputElement>) {
        if (Array.isArray(event.target.value)) {
            props.onDealerChange(event.target.value);
        }
    }

    return (
        <>
            <TextField
                fullWidth
                label={props.label}
                name="dealers"
                value={props.dealerIds}
                variant="outlined"
                required={props.required}
                size="small"
                select
                SelectProps={{
                    multiple: true,
                    renderValue: renderSelectedDealers,
                }}
                onChange={handleDealerChange}
            >
                {props.dealers
                    .filter((dealer) => dealer.activeStatus)
                    .map((dealer) => {
                        return (
                            <MenuItem key={dealer.id} value={dealer.id}>
                                <Checkbox checked={props.dealerIds.find((d) => d === dealer.id) !== undefined} />
                                {dealer.dealerName}
                            </MenuItem>
                        );
                    })}
            </TextField>
            {props.dealerIds.map((dealerId) => {
                let dealer = props.dealers.find((d) => d.id === dealerId) || new Dealer();
                return (
                    <Chip
                        sx={{ margin: '5px' }}
                        onDelete={() => deleteAdvertiser(dealerId)}
                        key={'chip-' + dealer.id}
                        color="info"
                        label={dealer.dealerName}
                        variant="filled"
                    ></Chip>
                );
            })}
        </>
    );
}
