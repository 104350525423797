import {
    Alert,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    Tooltip,
} from '@mui/material';
import { useState } from 'react';
import { Campaign } from '../../../types/Campaign';
import { CDTextField } from '../../../components/CDTextField';
import ApiService from '../../../ApiService';
import Row from '../../../components/Row';
import { CancelOutlined, SaveOutlined, SearchOutlined } from '@mui/icons-material';
import SummaryText from '../SummaryText';
import Utils from '../../../components/Utils';
import { Link } from 'react-router-dom';

export default function AssignDspCampaignDialog(props: {
    campaign: Campaign;
    onClose(dspCampaignId: string | null): void;
}) {
    const { campaign, onClose } = props;
    const [dspCampaignId, setDspCampaignId] = useState('');
    const [dspCampaign, setDspCampaign] = useState<Campaign | null>(null);
    const [duplicate, setDuplicate] = useState(false);
    const [dspCampaignExists, setDspCampaignExists] = useState(true);

    function validateDspCampaign() {
        if (campaign.dealerId && dspCampaignId) {
            ApiService.validateDspCampaign(campaign.dealerId, dspCampaignId).then((response) => {
                setDuplicate(response.data.duplicate);
                setDspCampaign(response.data.campaign);
                setDspCampaignExists(response.data.campaign !== null);
            });
        }
    }

    return (
        <>
            <Dialog maxWidth="sm" open={true} fullWidth onClose={() => onClose(null)}>
                <DialogTitle>
                    Assign DSP Campaign
                    <Divider />
                </DialogTitle>
                <DialogContent>
                    <Row>
                        <CDTextField
                            label="DSP Campaign ID"
                            value={dspCampaignId}
                            onChange={(event) => setDspCampaignId(event.target.value)}
                            shrinkLabel
                            sx={{ marginTop: '10px' }}
                        />
                        <Tooltip title="DSP Campaign Search">
                            <IconButton color="primary" sx={{ marginTop: '8px' }} onClick={validateDspCampaign}>
                                <SearchOutlined />
                            </IconButton>
                        </Tooltip>
                    </Row>

                    <Grid container spacing={2} sx={{ marginTop: '8px', marginBottom: '16px' }}>
                        {!dspCampaignExists && (
                            <Grid item xs={12}>
                                <Alert severity="error">Invalid DSP Campaign ID</Alert>
                            </Grid>
                        )}

                        {duplicate && (
                            <Grid item xs={12}>
                                <Alert severity="error">
                                    This Campaign ID is already assigned to another Campaign: <br />
                                    <Link to={'/campaignManager/' + dspCampaign?.id} onClick={() => onClose(null)}>
                                        {dspCampaign?.dealer?.dealerName} / {dspCampaign?.campaignName}
                                    </Link>
                                </Alert>
                            </Grid>
                        )}
                        {dspCampaign && (
                            <>
                                <Grid item xs={4}>
                                    <SummaryText label="Campaign Name" value={dspCampaign.campaignName} />
                                </Grid>
                                <Grid item xs={4}>
                                    <SummaryText label="Status" value={dspCampaign.deliveryStatus.internalStatus} />
                                </Grid>
                                <Grid item xs={4}>
                                    <SummaryText label="Budget" value={Utils.formatCurrency(dspCampaign.budget)} />
                                </Grid>
                            </>
                        )}
                    </Grid>
                    <Divider sx={{ marginTop: '8px' }} />
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="outlined"
                        color="error"
                        startIcon={<CancelOutlined />}
                        onClick={() => {
                            onClose(null);
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="outlined"
                        disabled={dspCampaign === null || duplicate}
                        onClick={() => {
                            onClose(dspCampaignId);
                        }}
                        startIcon={<SaveOutlined />}
                    >
                        Save
                    </Button>
                    <div style={{ width: '16px' }} />
                </DialogActions>
            </Dialog>
        </>
    );
}
