import { Box, Chip, Toolbar } from '@mui/material';
import Heading from '../../components/Heading';
import { GridActionsCellItem, GridColumns, GridToolbarExport, GridValueFormatterParams } from '@mui/x-data-grid';
import { useCallback, useContext, useEffect, useState } from 'react';
import { GridToolbarContainer } from '../../components/DataGrid';
import ApiService from '../../ApiService';

import { SalesDataUpload } from '../../types/SalesDataUpload';
import { Link, useNavigate } from 'react-router-dom';
import { CloudUploadOutlined, MapOutlined, RefreshOutlined } from '@mui/icons-material';
import Utils from '../../components/Utils';
import { UserContext } from '../../App';
import Row from '../../components/Row';
import CDGrid from '../../components/CDGrid';
import { AdvertiserContext } from '../../AdvertiserContext';
import CDButton, { CDDefaultButton } from '../../components/CDButton';

export default function SalesDataUploadStatusPage() {
    const navigate = useNavigate();
    const [salesDataUploadList, setSalesDataUploadList] = useState<SalesDataUpload[] | null>(null);
    const { userContext } = useContext(UserContext);
    const isAdmin = userContext.isAdmin();
    const { advertiserContext } = useContext(AdvertiserContext);

    const refresh = useCallback(() => {
        setSalesDataUploadList(null);
        ApiService.getSalesDataUploadsByDealer(
            advertiserContext.agencyId,
            advertiserContext.dealer ? advertiserContext.dealer.id : null
        )
            .then(function (response) {
                setSalesDataUploadList(response.data);
            })
            .catch(() => {
                setSalesDataUploadList([]);
            });
    }, [advertiserContext]);

    useEffect(() => {
        refresh();
    }, [advertiserContext, refresh]);

    const columns: GridColumns = [
        {
            width: 60,
            field: 'actions',
            headerName: '-',
            type: 'actions',
            getActions: (params) => {
                let actions = [];
                if (isAdmin) {
                    if (params.row.status === 'Uploaded') {
                        actions.push(
                            <GridActionsCellItem
                                icon={<MapOutlined color="primary" />}
                                label="Map"
                                onClick={() => {
                                    navigate('uploadMapping/' + params.row.id, {
                                        state: params.row,
                                    });
                                }}
                                showInMenu={undefined}
                            />
                        );
                    }
                }
                return actions;
            },
        },
        {
            width: 120,
            field: 'dateUploaded',
            headerName: 'Date',
            valueFormatter: (params: GridValueFormatterParams) => {
                return new Date(params.value as string).toLocaleDateString();
            },
        },
        {
            width: 150,
            field: 'status',
            headerName: 'Upload Status',
            renderCell: (params) => {
                let color: 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';
                let value = Utils.convertCase(params.value);
                if (value === 'AMC Processing') {
                    color = 'warning';
                } else if (value === 'Failed') {
                    color = 'error';
                } else {
                    color = 'info';
                }
                return (
                    <Chip
                        label={value}
                        size="small"
                        variant={value === 'Completed' || value === 'Failed' ? 'filled' : 'outlined'}
                        color={color}
                    />
                );
            },
        },
        {
            width: 150,
            field: 'workflowExecutionStatus',
            headerName: 'Workflow Status',
            headerClassName: 'grid-header',
            valueFormatter: (params) => Utils.convertCase(params.value as string),
            renderCell: (params) => {
                const value = Utils.convertCase(params.value as string);
                if (!value) return <></>;
                else
                    return (
                        <Chip
                            variant={value === 'Succeeded' ? 'filled' : 'outlined'}
                            label={value}
                            size="small"
                            color={value === 'Succeeded' ? 'success' : value === 'Failed' ? 'error' : 'info'}
                        />
                    );
            },
        },
    ];

    if (isAdmin) {
        columns.push({
            width: 110,
            field: 'uploadedByName',
            headerName: 'Uploaded By',
            headerClassName: 'grid-header',
        });
    }

    columns.push(
        {
            width: 220,
            field: 'dealerName',
            headerName: 'Advertiser',
            headerClassName: 'grid-header',
        },
        {
            field: 'originalFileName',
            headerName: 'File Name',
            headerClassName: 'grid-header',
            flex: 1,
            minWidth: 200,
            renderCell: (params) => {
                return (
                    <Link to={`uploadMapping/${params.row.id}`} state={params.row}>
                        {params.row.originalFileName}
                    </Link>
                );
            },
        }
    );

    if (isAdmin) {
        columns.push();
    }

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <Row justifyContent="flex-end" marginBottom={2}>
                    <Toolbar disableGutters>
                        <Row>
                            <CDButton startIcon={<RefreshOutlined />} onClick={refresh}>
                                Refresh
                            </CDButton>
                            <GridToolbarExport
                                variant="outlined"
                                color="primary"
                                csvOptions={{
                                    fileName: 'PartitionUploadResults',
                                }}
                            />
                            <Link to="/crmDataUpload">
                                <CDDefaultButton startIcon={<CloudUploadOutlined />}>Upload</CDDefaultButton>
                            </Link>
                        </Row>
                    </Toolbar>
                </Row>
            </GridToolbarContainer>
        );
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
            }}
        >
            <Heading>Attribution / CRM Data Upload</Heading>
            <CDGrid
                autoHeight={false}
                autoPageSize={true}
                components={{ Toolbar: CustomToolbar }}
                columns={columns}
                rows={salesDataUploadList === null ? [] : salesDataUploadList}
                onRowClick={(params) => {
                    navigate('uploadMapping/' + params.row.id, {
                        state: params.row,
                    });
                }}
            />
        </Box>
    );
}
