import { Card, CardContent, CardHeader, Divider } from '@mui/material';
import * as React from 'react';

export default function CDCard(props: { title?: React.ReactNode; children: React.ReactNode }) {
    const { title, children } = props;

    return (
        <Card variant="outlined">
            {title && (
                <CardHeader
                    title={title}
                    sx={{
                        paddingBottom: '0px',
                    }}
                />
            )}
            <CardContent sx={{ padding: '16px', paddingTop: '8px' }}>
                {title && <Divider sx={{ marginBottom: '8px' }} />}
                {children}
            </CardContent>
        </Card>
    );
}
