import { Campaign } from '../../../types/Campaign';
import CampaignPerformance from './CampaignPerformance';
import { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Tooltip } from '@mui/material';
import { CloseOutlined } from '@mui/icons-material';
import Row from '../../../components/Row';
import CDButton from '../../../components/CDButton';
import CampaignType from '../../../components/CampaignType';

export default function PerformanceTab(props: { campaign: Campaign }) {
    const { campaign } = props;
    const [expanded, setExpanded] = useState(false);

    if (expanded) {
        return (
            <Dialog open={expanded} maxWidth="xl">
                <DialogTitle>
                    <Row>
                        <span>{campaign.dealer?.dealerName}</span>
                        <CampaignType
                            mediaType={campaign.mediaType}
                            campaignType={campaign.campaignType}
                            strategyType={campaign.strategyType}
                        />
                        <span> {campaign.campaignName}</span>
                        <div style={{ flex: 1 }} />
                        <Tooltip title="Close Dialog">
                            <IconButton onClick={() => setExpanded(false)}>
                                <CloseOutlined fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </Row>
                </DialogTitle>
                <DialogContent>
                    <CampaignPerformance
                        campaign={campaign}
                        expand={() => {
                            setExpanded(false);
                        }}
                        expanded={expanded}
                    />
                </DialogContent>
                <DialogActions>
                    <CDButton
                        tooltip="Close Dialog"
                        startIcon={<CloseOutlined fontSize="small" />}
                        onClick={() => setExpanded(false)}
                    >
                        Close
                    </CDButton>
                </DialogActions>
            </Dialog>
        );
    } else {
        return (
            <CampaignPerformance
                campaign={campaign}
                expanded={expanded}
                expand={() => {
                    setExpanded(true);
                }}
            />
        );
    }
}
