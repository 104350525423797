import { Dealer } from './Dealer';

export class AmcAttribution {
    id?: number | null;
    dealerId?: number | null;
    dealer?: Dealer;
    campaignName: string = '';
    date: Date = new Date();
    status: string = 'Pending';
    dataSetName: string = '';
    recordsSubmitted: number = 0;
    matchedAmazonRecords: number = 0;
    attributedUserRecords: number = 0;
    adBudget: number = 0;
    adSpend: number = 0;
    gross: number = 0;
}
