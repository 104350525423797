import React from 'react';
import { Box, BoxProps } from '@mui/material';

export interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
    BoxProps?: BoxProps;
}

export default function TabPanel(props: TabPanelProps) {
    const { children, value, index, BoxProps, ...other } = props;

    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} {...other}>
            {value === index && (
                <Box sx={{ paddingTop: 3 }} {...BoxProps}>
                    {children}
                </Box>
            )}
        </div>
    );
}
