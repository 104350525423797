import { Dealer } from './Dealer';

export class ImportSite {
    id?: number;
    importName: string = '';
    isBulk: boolean = false;
    isHeader: boolean = false;
    fileName: string = '';
    fileExtension: string = '';
    ftpUser: string = '';
    inventoryType: string = '';
}

export class DealerImportSite {
    id?: number;
    importSiteId?: number;
    importName?: string = '';
    dealer?: Dealer;
    dealerId?: number;
    dealerName?: string;
    isActive?: number = 1;
    isAdd?: number = 0;
    isUpdate?: number = 0;
    isDelete?: number = 0;
    importDealerKey?: string = '';
    importURL?: string | null = null;
    importSite?: ImportSite;
    vdpUrlPattern?: string = '';
    secondaryVdpUrlPattern?: string = '';
    vdpUrlSearch?: string;
    vdpUrlReplace?: string;
    vdpUrlSearchAlt?: string;
    vdpUrlReplaceAlt?: string;
    copyVDPUrlToSecondaryVDPUrl?: boolean;
    vdpUrlParameters?: string;
    overrideAgencyVdpUrlParameters?: boolean;
}
