import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';

export default function DateFormats() {
    return (
        <>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Symbol</TableCell>
                            <TableCell>Meaning</TableCell>
                            <TableCell>Examples</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell>y</TableCell>
                            <TableCell>Year</TableCell>
                            <TableCell>2021; 21</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>M</TableCell>
                            <TableCell>Month</TableCell>
                            <TableCell>7</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>MM</TableCell>
                            <TableCell>Month</TableCell>
                            <TableCell>07</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>d</TableCell>
                            <TableCell>Day of Month</TableCell>
                            <TableCell>2</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>dd</TableCell>
                            <TableCell>Day of Month</TableCell>
                            <TableCell>02</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell>h</TableCell>
                            <TableCell>Hour (12 hour clock)</TableCell>
                            <TableCell>11</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>H</TableCell>
                            <TableCell>Hour (24 hour clock)</TableCell>
                            <TableCell>14</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>m</TableCell>
                            <TableCell>Minute</TableCell>
                            <TableCell>10</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>s</TableCell>
                            <TableCell>Second</TableCell>
                            <TableCell>20</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>a</TableCell>
                            <TableCell>AM / PM</TableCell>
                            <TableCell>AM</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}
