import { endOfMonth, getMonth, isAfter, isBefore } from 'date-fns';
import { useState } from 'react';
import CDButton from '../../../components/CDButton';
import {
    AlertColor,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormHelperText,
    InputAdornment,
    Stack,
} from '@mui/material';
import CDDatePicker from '../../../components/CDDatePicker';
import Row from '../../../components/Row';
import { CDTextField } from '../../../components/CDTextField';
import { useParams } from 'react-router-dom';
import ApiService from '../../../ApiService';
import { CampaignFlight } from '../../../types/Campaign';
import Utils from '../../../components/Utils';
import { InfoMessage, InfoMessageProps } from '../../../components/InfoMessage';

const initialValues = {
    startDate: null,
    endDate: null,
    budget: '',
};
function validate(
    data: typeof initialValues,
    getErrors: (
        value: React.SetStateAction<{
            startDate: string;
            endDate: string;
            budget: string;
        }>
    ) => void
) {
    const newErrors: any = {};

    if (!data.startDate) {
        newErrors.startDate = 'Start date is required';
    }

    if (!data.endDate) {
        newErrors.endDate = 'End date is required';
    }

    if (data.endDate && data.startDate && isAfter(data.startDate, data.endDate) && !newErrors.startDate) {
        newErrors.startDate = "The start date can't be after the end date";
    }

    if (data.endDate && data.startDate && isBefore(data.endDate, data.startDate) && !newErrors.endDate) {
        newErrors.endDate = "The end date can't be before the start date";
    }

    if (data.endDate && data.startDate && getMonth(data.endDate) !== getMonth(data.startDate) && !newErrors.endDate) {
        newErrors.endDate = 'The end date must end in the same month as the start date';
    }

    if (Number(data.budget) <= 0) {
        newErrors.budget = 'Budget must be greater than zero';
    }

    getErrors(newErrors);
    return Object.keys(newErrors).length === 0;
}

type Props = {
    reloadCampaign(): void;
};

export default function AddNewFlight({ reloadCampaign }: Props) {
    const { id } = useParams();
    const [infoMessage, setInfoMessage] = useState<InfoMessageProps>({
        message: null,
    });

    const [isOpen, setIsOpen] = useState(false);
    const [formData, setFormData] = useState(initialValues);

    const [errors, setErrors] = useState({ startDate: '', endDate: '', budget: '' });
    const [isLoading, setIsLoading] = useState(false);

    const handleDateChange = (dateField: string, newValue: Date | null) => {
        if (newValue !== null) {
            setErrors((prev) => ({ ...prev, startDate: '', endDate: '' }));
            const isStartDateChange = dateField === 'startDate';

            setFormData((prev) => {
                return {
                    ...prev,
                    endDate: isStartDateChange ? null : prev.endDate,
                    [dateField]: newValue,
                };
            });
        }
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = event.target;

        const isNumberValue = /^\d*\.?\d*$/.test(value);

        if (name === 'budget' && !isNumberValue) {
            return;
        }

        setErrors((prev) => ({ ...prev, [name]: '' }));

        setFormData((prev) => {
            return {
                ...prev,
                [name]: value,
            };
        });
    };

    const clearFormData = () => setFormData(initialValues);

    const handleDialogToggle = () => {
        setIsOpen(!isOpen);
    };

    const handleDialogClose = () => {
        handleDialogToggle();
        setErrors({ startDate: '', endDate: '', budget: '' });
        clearFormData();
    };

    function showInfoMessage(alertColor: AlertColor, message: string, onClose?: () => void) {
        setInfoMessage({
            message: message,
            severity: alertColor,
            onClose: () => {
                setInfoMessage({ message: null });
                if (onClose) onClose();
            },
        });
    }

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (!validate(formData, setErrors)) {
            return;
        }

        const { startDate, endDate, budget } = formData;

        if (!startDate || !endDate || !id) return;
        const preparedData: Pick<CampaignFlight, 'startDateTime' | 'endDateTime' | 'budgetAmount'> = {
            startDateTime: Utils.getESTStartDate(startDate),
            endDateTime: Utils.getESTEndDate(endDate),
            budgetAmount: Number(budget),
        };

        setIsLoading(true);
        try {
            const response = await ApiService.createFlight(+id, preparedData);
            if (response.statusText === 'OK') {
                handleDialogToggle();
                clearFormData();
                showInfoMessage('success', 'Flight created successfully');
                reloadCampaign();
            } else {
                showInfoMessage('error', 'Error creating flight');
            }
            clearFormData();
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <InfoMessage {...infoMessage} />
            {/* Commenting out the Add Flight button.
                Reason:  It's causing our users to create duplicate flights because it's not capturing the DSP Flight ID when it's created, and then our system just imports the same flight from the DSP creating the duplicate.
            <CDButton sx={{ marginLeft: '10px' }} onClick={handleDialogToggle}>
                Add Flight
            </CDButton> */}
            <span></span> {/* <-- remove span when uncommenting the Add Flight button */}
            <Dialog open={isOpen} maxWidth="sm" fullWidth>
                <DialogTitle>Add New Flight</DialogTitle>

                <form onSubmit={handleSubmit}>
                    <DialogContent sx={{ padding: '10px' }}>
                        <Stack direction="row" gap="8px" padding="10px">
                            <FormControl error={!!errors.startDate}>
                                <CDDatePicker
                                    value={formData.startDate}
                                    onChange={(dateValue) => handleDateChange('startDate', dateValue)}
                                    label={'Start Date'}
                                    showError={!!errors.startDate}
                                    disablePast
                                />

                                <FormHelperText>{errors.startDate || ' '}</FormHelperText>
                            </FormControl>

                            <FormControl error={!!errors.endDate}>
                                <CDDatePicker
                                    value={formData.endDate}
                                    onChange={(dateValue) => handleDateChange('endDate', dateValue)}
                                    label={'End Date'}
                                    showError={!!errors.endDate}
                                    disablePast
                                    maxDate={endOfMonth(formData.startDate ?? new Date())}
                                />

                                <FormHelperText>{errors.endDate || ' '}</FormHelperText>
                            </FormControl>
                        </Stack>

                        <Row sx={{ padding: '10px' }}>
                            <CDTextField
                                sx={{ width: '350px' }}
                                name="budget"
                                label="Budget"
                                variant="outlined"
                                required
                                placeholder="100"
                                fullWidth={true}
                                InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                                value={formData.budget}
                                onChange={handleChange}
                                error={!!errors.budget}
                                helperText={errors.budget}
                            />
                        </Row>
                    </DialogContent>

                    <DialogActions sx={{ padding: '10px 20px' }}>
                        <CDButton color="error" onClick={handleDialogClose}>
                            Cancel
                        </CDButton>

                        <CDButton type="submit" disabled={isLoading}>
                            Add Flight
                        </CDButton>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    );
}
