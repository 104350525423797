import { ChangeEvent, useEffect, useState } from 'react';
import { FormControlLabel, Switch, Tooltip } from '@mui/material';
import { CampaignCriteria } from '../../types/Campaign';

export default function CampaignIncludePendingSwitch(props: {
    campaignCriteria: CampaignCriteria;
    changeSearchCriteria: Function;
}) {
    const { campaignCriteria, changeSearchCriteria } = props;
    const [includePendingCampaigns, setIncludePendingCampaigns] = useState<boolean>(
        campaignCriteria?.includePendingCampaigns as boolean
    );

    useEffect(() => {
        if (includePendingCampaigns === campaignCriteria.includePendingCampaigns) return;
        changeSearchCriteria({
            ...campaignCriteria,
            includePendingCampaigns: includePendingCampaigns,
        });
    }, [includePendingCampaigns]);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setIncludePendingCampaigns(event.target.checked);
    };

    return (
        <FormControlLabel
            label="Show Future Pending Campaigns"
            control={
                <>
                    <Tooltip title="Enable to show Future Pending Campaigns that are outside of the selected Campaign Month.">
                        <Switch checked={includePendingCampaigns} onChange={handleChange} />
                    </Tooltip>
                </>
            }
        />
    );
}
