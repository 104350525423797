import { styled } from '@mui/material';
import { Box } from '@mui/material';

import { TabPanelProps } from '../../../../../components/TabPanel';
import { usePrintContext } from '../../../../..//hooks/usePrint';
import { useReportingDashboardPageContext } from '../../../../../hooks/useReportingDashboardPage';
import ReportingPerformanceMonitorTab from './ReportingPerformanceMonitorTab';
import ReportingPerformanceOtherTab from './ReportingPerformanceOtherTab';
import ReportingPerformanceOverviewTab from './ReportingPerformanceOverviewTab';
import ReportingPerformanceTabs, { TABS as PERFORMANCE_TABS } from './ReportingPerformanceTabs';
import TabPanel from '../../../../../components/TabPanel';

const OffsetTabPanel = styled((props: TabPanelProps) => {
    const { print } = usePrintContext();

    return (
        <TabPanel
            BoxProps={{
                // Negative top margin offset to be inline with view button group
                sx: { mt: print ? 0 : -6.5 },
            }}
            {...props}
        />
    );
})(({ theme }) => ({}));

export default function ReportingPerformanceTabPanels() {
    const { reportingCriteria, performanceTabs } = useReportingDashboardPageContext();

    if (!reportingCriteria) {
        return <></>;
    }

    return (
        <Box>
            <ReportingPerformanceTabs value={performanceTabs.activeTab} onChange={performanceTabs.handleChangeTab} />

            <Box>
                <OffsetTabPanel index={PERFORMANCE_TABS.PERFORMANCE_OVERVIEW} value={performanceTabs.activeTab}>
                    <ReportingPerformanceOverviewTab />
                </OffsetTabPanel>
                <TabPanel index={PERFORMANCE_TABS.PERFORMANCE_MONITOR} value={performanceTabs.activeTab}>
                    <ReportingPerformanceMonitorTab reportingCriteria={reportingCriteria} />
                </TabPanel>
                <TabPanel index={PERFORMANCE_TABS.PERFORMANCE_OTHER} value={performanceTabs.activeTab}>
                    <ReportingPerformanceOtherTab reportingCriteria={reportingCriteria} />
                </TabPanel>
            </Box>
        </Box>
    );
}
