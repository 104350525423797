import { Box, Typography } from '@mui/material';
import theme from '../theme';

export default function Heading(props: any) {
    return (
        <Box
            sx={
                {
                    // py: (theme) => theme.spacing(2),
                }
            }
        >
            <Typography variant={props.variant ? props.variant : 'h6'} color="text.primary">
                {props.children}
            </Typography>
        </Box>
    );
}

export function SubHeading(props: any) {
    return (
        <div
            {...props}
            style={{
                fontSize: '14px',
                fontWeight: theme.typography.fontWeightMedium,
                color: theme.palette.text.secondary,
                paddingTop: '8px',
                paddingBottom: '8px',
            }}
        >
            {props.children}
        </div>
    );
}
