import { Skeleton } from '@mui/material';

import Column from '../../components/Column';
import Row from '../../components/Row';

export default function SkeletonPieChart() {
    return (
        <Row alignItems="center" sx={{ height: 300 }}>
            <Column sx={{ flexGrow: 1, mx: 12 }}>
                <Column sx={{ position: 'relative' }} alignItems="center" justifyContent="center">
                    <Skeleton variant="circular" width={280} height={280} />

                    <Skeleton
                        variant="circular"
                        width={170}
                        height={170}
                        animation={false}
                        sx={{
                            position: 'absolute',
                            bgcolor: 'white',
                        }}
                    />

                    <Column gap={0.5} alignItems="center" justifyContent="center" sx={{ position: 'absolute' }}>
                        <Skeleton
                            variant="text"
                            width={120}
                            sx={{
                                fontSize: '1.50rem',
                            }}
                        />

                        <Skeleton
                            variant="text"
                            width={80}
                            sx={{
                                fontSize: '1rem',
                            }}
                        />
                    </Column>
                </Column>
            </Column>

            <Column gap={0.5} sx={{ flexGrow: 1 }}>
                <Skeleton width={160} />

                <Skeleton width={160} />

                <Skeleton width={160} />
            </Column>
        </Row>
    );
}
