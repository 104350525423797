import { Autocomplete, Paper, TextFieldProps } from '@mui/material';
import { useEffect, useState } from 'react';
import { Dealer } from '../../../types/Dealer';
import { CDTextField, CDTextFieldProps } from '../../../components/CDTextField';
import ApiService from '../../../ApiService';
import { getStyles } from '../../../components/ToolbarStyles';

export interface SelectDealerProps {
    disabled?: boolean;
    dealerId: number | undefined | null;
    agencyId?: number | null;
    error?: boolean;
    dealerSelected(dealerId: number | null, dealer: Dealer | null): void;
    dealerDefaultSelect?: boolean;
    variant?: TextFieldProps['variant'];
    label?: string;
    required?: boolean;
    isHeader?: boolean;
    TextFieldProps?: CDTextFieldProps;
}

export default function FilteredDealerSelector(props: SelectDealerProps) {
    const [dealers, setDealers] = useState<Dealer[] | null>(null);
    const [selectedDealer, setSelectedDealer] = useState<Dealer | null>(null);

    useEffect(() => {
        if (props.dealerId && dealers) {
            let dealer = dealers.find((d) => {
                return d.id === props.dealerId;
            });
            setSelectedDealer(dealer ?? null);
        } else {
            setSelectedDealer(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.dealerId, dealers]);

    useEffect(() => {
        ApiService.getAssignedDealers()
            .then((response) => {
                const activeDealers = response.data.filter(
                    (dealer: Dealer) => dealer.activeStatus && !dealer.amcInstanceId
                );
                setDealers(activeDealers);
            })
            .catch(() => {});

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function setDealerId(id: string, dealer: Dealer | null) {
        if (id === 'undefined') {
            props.dealerSelected(null, null);
            setSelectedDealer(null);
        } else {
            props.dealerSelected(Number(id), dealer);
        }
    }

    function dealerChange(_event: any, newValue: Dealer | null) {
        setDealerId(String(newValue?.id), newValue);
    }

    const sxProps = getStyles(props.isHeader);

    return (
        <>
            <Autocomplete
                sx={sxProps}
                options={dealers ? dealers : []}
                getOptionLabel={(option) => option.dealerName}
                renderOption={(props, option) => (
                    <li {...props} key={option.id}>
                        {option.dealerName}
                    </li>
                )}
                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                size="small"
                fullWidth={false}
                value={selectedDealer}
                disabled={props.disabled || !dealers || (!props.isHeader && dealers.length === 1)}
                onChange={dealerChange}
                renderInput={(params) => (
                    <CDTextField
                        color={props.isHeader ? 'secondary' : 'primary'}
                        error={props.error}
                        shrinkLabel={false}
                        required={props.required !== undefined ? props.required : !props.disabled}
                        variant={props.variant ? props.variant : 'outlined'}
                        {...params}
                        label={
                            selectedDealer
                                ? ''
                                : props.disabled
                                ? 'Advertiser'
                                : props.label !== undefined
                                ? props.label
                                : 'Select an Existing Advertiser'
                        }
                    />
                )}
                PaperComponent={(props) => (
                    <Paper
                        {...props}
                        sx={{
                            boxShadow: 8,
                            borderRadius: '8px',
                        }}
                    />
                )}
            />
        </>
    );
}
