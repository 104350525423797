import { Campaign, CampaignFlight } from '../../../types/Campaign';
import theme from '../../../theme';
import { Alert, CircularProgress, Paper, Stack, Tooltip, Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import ApiService from '../../../ApiService';
import Utils from '../../../components/Utils';
import Row from '../../../components/Row';
import { getDefaultDateRanges } from '../../../components/DateRangePicker';
import { StaticRange } from '../../../components/DateRangePicker';
import DateRangePicker from '../../../components/DateRangePicker';
import { PerformanceMetric } from '../../../types/CampaignPerformance';
import CDCard from '../../../components/CDCard';
import PerformanceChart from './PerformanceChart';
import { sub } from 'date-fns';
import moment from 'moment';
import dayjs from 'dayjs';

const dateRanges: StaticRange[] = getDefaultDateRanges();

const PREFIX = 'campaignPerformance.';

export default function CampaignPerformance(props: { campaign: Campaign; expand(): void; expanded: boolean }) {
    const { campaign } = props;
    const [metrics, setMetrics] = useState<PerformanceMetric[]>([]);
    const [loading, setLoading] = useState(false);

    let dateRange = {
        startDate: sub(new Date(), { days: 30 }),
        endDate: new Date(),
    };
    let criteria = Utils.localStorage(PREFIX + 'dateRange', null);
    if (criteria) {
        let lastDateRange = JSON.parse(criteria);
        dateRange.startDate = new Date(lastDateRange.startDate);
        dateRange.endDate = new Date(lastDateRange.endDate);
    }
    const [startDate, setStartDate] = useState(dateRange.startDate);
    const [endDate, setEndDate] = useState(dateRange.endDate);

    const [selectedIndex, setSelectedIndex] = useState(0);
    const [metric, setMetric] = useState<PerformanceMetric | null>(null);

    useEffect(() => {
        if (campaign.id && campaign.dspCampaignId) {
            setLoading(true);
            ApiService.getCampaignPerformance({
                campaignId: campaign.id,
                dspCampaignId: campaign.dspCampaignId,
                startDate: moment.utc(moment(startDate).format('YYYY-MM-DD HH:mm:ss')).toDate(),
                endDate: moment.utc(moment(endDate).format('YYYY-MM-DD HH:mm:ss')).toDate(),
            }).then((response) => {
                setMetric(null);
                let metrics = response.data;
                metrics.forEach((m) => {
                    m.apexSeries = m.series as unknown as ApexAxisChartSeries;
                });
                setMetrics(metrics);
                setLoading(false);
            });
        }
    }, [campaign, startDate, endDate]);

    useEffect(() => {
        if (metrics.length > selectedIndex) {
            setMetric(metrics[selectedIndex]);
        }
    }, [selectedIndex, metrics]);

    function formatValue(metric: PerformanceMetric, value: any): any {
        switch (metric.format) {
            case 'dollar':
                return Utils.formatCurrency(value, 2);

            case 'decimal':
                return Utils.formatNumber(Utils.round(value, 2));

            case 'percent':
                return Utils.formatNumber(Utils.round(value, 2)) + '%';

            default:
                return Utils.formatNumber(value);
        }
    }

    const activityDateRangeMinDate = useMemo<Date>(() => {
        let minDateTime: Date | number = new Date().getTime();

        if (campaign.flights.length > 0) {
            campaign.flights.forEach((_flight: CampaignFlight) => {
                if (_flight.startDateTime < minDateTime) {
                    minDateTime = _flight.startDateTime;
                }
            });
        }

        return new Date(minDateTime);
    }, [campaign]);

    const activityDateRangeMaxDate = useMemo<Date>(() => {
        let maxDateTime: Date | number = new Date().getTime();

        if (campaign.flights.length > 0) {
            campaign.flights.forEach((_flight: CampaignFlight) => {
                if (_flight.endDateTime > maxDateTime) {
                    maxDateTime = _flight.endDateTime;
                }
            });
        }

        return new Date(maxDateTime);
    }, [campaign]);

    return (
        <>
            <CDCard>
                <Row style={{ paddingTop: '8px' }}>
                    <Typography variant="h5">Overview</Typography>
                    <div style={{ flex: 1 }} />

                    <DateRangePicker
                        label="Activity Date"
                        ranges={dateRanges}
                        minDate={dayjs(activityDateRangeMinDate)}
                        maxDate={dayjs(activityDateRangeMaxDate)}
                        disableFuture={true}
                        value={[dayjs(startDate), dayjs(endDate)]}
                        onChange={(newDateRange: any) => {
                            if (newDateRange[0] && newDateRange[1]) {
                                const newStartDate: Date = newDateRange[0].toDate();
                                const newEndDate: Date = newDateRange[1].toDate();

                                setStartDate(newStartDate);
                                setEndDate(newEndDate);

                                localStorage.setItem(
                                    PREFIX + 'dateRange',
                                    JSON.stringify({ startDate: newStartDate, endDate: newEndDate })
                                );
                            }
                        }}
                    />
                </Row>

                {!loading && metrics.length === 0 && (
                    <Alert severity="info" sx={{ marginTop: '16px' }}>
                        No performance data available for the selected time period.
                    </Alert>
                )}

                {loading && metrics.length === 0 && <CircularProgress />}

                <Row>
                    {metrics.map((metric, index) => {
                        const metricName = metric.series[0].name;
                        const description = metric.series[0].description;
                        const selected = selectedIndex === index;
                        let total = formatValue(
                            metric,
                            metric.series[0].sum ? metric.series[0].total : metric.series[0].average
                        );
                        return (
                            <Tooltip key={metricName} title={'View ' + (description ? description : metricName)}>
                                <Stack
                                    direction="column"
                                    gap={1}
                                    sx={{
                                        cursor: 'pointer',
                                        backgroundColor: selected ? theme.palette.primary.main : 'default',
                                        color: selected ? '#ffffff' : theme.palette.text.primary,
                                        padding: '8px 16px 4px 16px',
                                        minWidth: '100px',
                                        maxWidth: '140px',
                                        marginTop: '24px',
                                        border: 'solid 1px',
                                        borderColor: theme.palette.divider,
                                        borderRadius: '16px',
                                    }}
                                    onClick={() => setSelectedIndex(index)}
                                >
                                    <span
                                        style={{
                                            fontWeight: theme.typography.fontWeightRegular,
                                        }}
                                    >
                                        {metricName}
                                    </span>
                                    <span
                                        style={{
                                            paddingBottom: '8px',
                                            fontWeight: theme.typography.fontWeightMedium,
                                            color: selected ? '#ffffff' : theme.palette.text.secondary,
                                        }}
                                    >
                                        {total}
                                    </span>
                                </Stack>
                            </Tooltip>
                        );
                    })}
                </Row>

                <Paper
                    elevation={1}
                    sx={{
                        marginTop: '16px',
                    }}
                >
                    {metric && <PerformanceChart metric={metric} />}
                </Paper>
            </CDCard>
        </>
    );
}
