import { Alert, Button } from '@mui/material';
import Heading from '../components/Heading';
import { useNavigate } from 'react-router-dom';
import { ArrowBackOutlined } from '@mui/icons-material';

export default function AccessDenied() {
    const navigate = useNavigate();

    return (
        <>
            <Heading>Access Denied</Heading>
            <Alert severity="error">You do not have access to this page or functionality.</Alert>
            <br />
            <Button
                variant="outlined"
                sx={{ marginTop: '15px' }}
                color="info"
                startIcon={<ArrowBackOutlined />}
                onClick={() => navigate(-1)}
            >
                Back
            </Button>
        </>
    );
}
