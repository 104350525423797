import {
    CardContent,
    CardHeader,
    Chip,
    Divider,
    FormControlLabel,
    FormGroup,
    Grid,
    Paper,
    Radio,
    RadioGroup,
    Switch,
    Tooltip,
    Typography,
} from '@mui/material';
import { ForecastingParameters } from '../../../types/AdvertiserRequest';
import { CDTextField } from '../../../components/CDTextField';
import AudienceDefinition from './AudienceDefinition';
import { InfoOutlined } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import ApiService from '../../../ApiService';
import { CampaignCard } from './CampaignCard';
import { Campaign, CampaignDataSet } from '../../../types/Campaign';
import { Link } from 'react-router-dom';
import Row from '../../../components/Row';
import theme from '../../../theme';

function DataSetRadio(props: { label: string; value: string; dataSet: CampaignDataSet; disabled: boolean }) {
    const { label, value, dataSet, disabled } = props;
    return (
        <FormControlLabel
            value={value}
            disabled={disabled}
            disableTypography
            sx={{
                fontSize: '0.9em',
                opacity: dataSet.identifiedRecords < 2000 ? '0.5' : '1',
            }}
            control={<Radio size="small" disabled={disabled || dataSet.identifiedRecords < 2000} />}
            label={label}
        />
    );
}

export default function StepAudienceTargeting(props: {
    campaign: Campaign;
    forecastingParameters: ForecastingParameters;
    showError: boolean;
    onChange(value: Partial<Campaign>): void;
    disabled: boolean;
}) {
    const { campaign, showError, forecastingParameters, onChange, disabled } = props;

    const [availableDataSets, setAvailableDataSets] = useState(true);

    useEffect(() => {
        if (campaign.dealerId && campaign.id === undefined) {
            ApiService.getAdvertiserDataSetSummary(campaign.dealerId).then((response) => {
                const dataSets = response.data;

                let advertiserRequestDataSets: CampaignDataSet[] = [];
                let available = false;
                dataSets.forEach((dataSet) => {
                    advertiserRequestDataSets.push({
                        dataSetName: dataSet.dataSetName,
                        audienceTargeting: 'X',
                        identifiedRecords: dataSet.identifiedRecords,
                    });
                    if (dataSet.identifiedRecords >= 2000) {
                        available = true;
                    }
                });
                setAvailableDataSets(available);
                onChange({ dataSets: advertiserRequestDataSets });
            });
        }
    }, [campaign.dealerId, campaign.id]);

    function customAudienceChange(dataSet: CampaignDataSet, value: string) {
        let advertiserRequestDataSets = [...campaign.dataSets];
        advertiserRequestDataSets.forEach((ds) => {
            if (ds.dataSetName === dataSet.dataSetName) {
                ds.audienceTargeting = value;
            }
        });
        onChange({ dataSets: advertiserRequestDataSets });
    }

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={8}>
                    <CampaignCard elevation={4}>
                        <CardHeader title="Audience Targeting Information" />
                        <CardContent>
                            <Paper variant="elevation" elevation={0} sx={{ p: 2, marginBottom: '16px' }}>
                                <Typography>
                                    In-Market Consumer
                                    <Tooltip title="Automatically optimized">
                                        <span style={{ paddingLeft: '10px' }}>
                                            <InfoOutlined fontSize="small" color="inherit" />
                                        </span>
                                    </Tooltip>
                                </Typography>
                                <Typography variant="caption">Automatically optimized</Typography>
                            </Paper>

                            <Paper variant="elevation" elevation={0} sx={{ p: 2, marginBottom: '16px' }}>
                                <Grid container spacing={1} sx={{ pb: 2 }}>
                                    <Grid item xs={10} sx={{ marginBottom: '10px' }}>
                                        <Typography>
                                            Custom Audience
                                            <Tooltip title="Custom Audience targeting based on Advertiser submitted Leads, Sales, Store Visits, etc.">
                                                <span
                                                    style={{
                                                        paddingLeft: '10px',
                                                    }}
                                                >
                                                    <InfoOutlined fontSize="small" color="inherit" />
                                                </span>
                                            </Tooltip>
                                            <br />
                                            <Typography variant="caption">Recommended strategy</Typography>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2}></Grid>
                                </Grid>

                                <Grid
                                    container
                                    spacing={2}
                                    sx={{
                                        '& .MuiGrid-item': {
                                            paddingTop: '0px',
                                        },
                                    }}
                                >
                                    {campaign.dataSets &&
                                        campaign.dataSets.map((dataSet) => (
                                            <>
                                                <Grid
                                                    item
                                                    xs={3}
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    {dataSet.dataSetName}
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={2}
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'flex-end',
                                                    }}
                                                >
                                                    {dataSet.identifiedRecords && (
                                                        <Tooltip
                                                            title={
                                                                dataSet.identifiedRecords >= 2000
                                                                    ? 'Available for a Custom Audience'
                                                                    : 'Additional data required.'
                                                            }
                                                        >
                                                            <Chip
                                                                key={dataSet.dataSetName}
                                                                label={dataSet.identifiedRecords.toLocaleString()}
                                                                size="small"
                                                                variant={
                                                                    dataSet.identifiedRecords >= 2000
                                                                        ? 'filled'
                                                                        : 'outlined'
                                                                }
                                                                color={
                                                                    dataSet.identifiedRecords >= 2000
                                                                        ? 'success'
                                                                        : 'warning'
                                                                }
                                                            />
                                                        </Tooltip>
                                                    )}
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={7}
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'flex-end',
                                                    }}
                                                >
                                                    <RadioGroup
                                                        row={true}
                                                        value={dataSet.audienceTargeting}
                                                        onChange={(event) =>
                                                            customAudienceChange(dataSet, event.target.value)
                                                        }
                                                    >
                                                        <DataSetRadio
                                                            label="Do Not Use"
                                                            value="X"
                                                            dataSet={dataSet}
                                                            disabled={disabled || dataSet.identifiedRecords < 2000}
                                                        />
                                                        <DataSetRadio
                                                            label="Include"
                                                            value="I"
                                                            dataSet={dataSet}
                                                            disabled={disabled || dataSet.identifiedRecords < 2000}
                                                        />
                                                        <DataSetRadio
                                                            label="Exclude"
                                                            value="E"
                                                            dataSet={dataSet}
                                                            disabled={disabled || dataSet.identifiedRecords < 2000}
                                                        />
                                                    </RadioGroup>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Divider />
                                                </Grid>
                                            </>
                                        ))}

                                    {campaign.id === undefined && !availableDataSets && (
                                        <Grid item xs={12}>
                                            <Row sx={{ backgroundColor: theme.palette.divider, padding: '8px' }}>
                                                <InfoOutlined fontSize="small" sx={{ marginTop: '5px' }} />
                                                <Typography sx={{ color: theme.palette.text.secondary }}>
                                                    In order to use this feature you need to supply additional
                                                    Advertiser Leads, Sales or Store Visits. &nbsp;For more details or
                                                    assistance
                                                    <Link
                                                        style={{ color: theme.palette.primary.main, fontWeight: 600 }}
                                                        to="#"
                                                        onClick={(event) => {
                                                            window.location.href = 'mailto:support@cognitionads.com';
                                                            event.preventDefault();
                                                        }}
                                                    >
                                                        {' '}
                                                        Contact support
                                                    </Link>
                                                </Typography>
                                            </Row>
                                        </Grid>
                                    )}
                                </Grid>
                            </Paper>

                            <Paper variant="elevation" elevation={0} sx={{ p: 2, marginBottom: '16px' }}>
                                <Typography>Additional Targeting</Typography>
                                <Typography variant="caption">Optional</Typography>
                                <FormGroup>
                                    <FormControlLabel
                                        label="Demographic"
                                        control={
                                            <Switch
                                                name="active"
                                                color="primary"
                                                disabled={disabled}
                                                checked={campaign.audienceDemographic}
                                                onChange={(event) => {
                                                    onChange({
                                                        audienceDemographic: event.target.checked,
                                                    });
                                                }}
                                            />
                                        }
                                    />
                                    <FormControlLabel
                                        label="Life Stage Events"
                                        control={
                                            <Switch
                                                name="active"
                                                color="primary"
                                                disabled={disabled}
                                                checked={campaign.audienceLifeStageEvents}
                                                onChange={(event) => {
                                                    onChange({
                                                        audienceLifeStageEvents: event.target.checked,
                                                    });
                                                }}
                                            />
                                        }
                                    />
                                </FormGroup>
                            </Paper>

                            <Paper variant="elevation" elevation={0} sx={{ p: 2 }}>
                                <Typography>Targeting Keywords</Typography>
                                <CDTextField
                                    name="audienceTargeting"
                                    variant="outlined"
                                    helperText="Body styles, models, etc."
                                    value={campaign.audienceTargeting ?? ''}
                                    required={false}
                                    fullWidth={true}
                                    disabled={disabled}
                                    multiline
                                    rows={2}
                                    shrinkLabel
                                    onChange={(event) => {
                                        onChange({
                                            audienceTargeting: event.target.value,
                                        });
                                    }}
                                />
                            </Paper>
                        </CardContent>
                    </CampaignCard>
                </Grid>
                <Grid item xs={4}>
                    <AudienceDefinition
                        campaign={campaign}
                        forecastingParameters={forecastingParameters}
                        showError={showError}
                        disabled={disabled}
                        setTargets={(budget, impressionGoal, impressionBased) => {
                            onChange({
                                budget: budget,
                                impressionGoal: impressionGoal,
                                impressionBased: impressionBased,
                            });
                        }}
                    />
                </Grid>
            </Grid>
        </>
    );
}
