import AppBreadcrumbs from '../../components/AppBreadcrumbs';
import Column from '../../components/Column';
import Heading from '../../components/Heading';
import { useCallback, useContext, useEffect, useState } from 'react';
import ApiService from '../../ApiService';
import {
    Alert,
    Breadcrumbs,
    Button,
    FormControl,
    Grid,
    List,
    ListItem,
    MenuItem,
    Paper,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import { DspAudienceCategory, ListAudienceResult } from '../../types/DspEntity';
import CDGrid from '../../components/CDGrid';
import DealerSelector from '../../components/DealerSelector';
import { GridColumns } from '@mui/x-data-grid';
import { Dealer } from '../../types/Dealer';
import { AdvertiserContext } from '../../AdvertiserContext';

export default function DspAudiencePage() {
    const [audienceCategories, setAudienceCategories] = useState<DspAudienceCategory[]>([]);
    const [categoryPath, setCategoryPath] = useState<string[]>([]);
    const [listAudienceResult, setListAudienceResult] = useState<ListAudienceResult | null>(null);
    const [tokens, setTokens] = useState<(string | null)[]>([]);
    const { advertiserContext, setDealer } = useContext(AdvertiserContext);

    useEffect(() => {
        setTokens([null]);
        const dealerId = advertiserContext?.dealer?.id;
        if (dealerId) {
            ApiService.getDspAudienceCategories(dealerId, categoryPath).then((response) => {
                setAudienceCategories(response.data);
            });
            ApiService.getDspAudiences(dealerId, categoryPath, null).then((response) => {
                setListAudienceResult(response.data);
            });
        }
    }, [categoryPath, advertiserContext?.dealer]);

    useEffect(() => {
        setListAudienceResult(null);
        setAudienceCategories([]);
        setTokens([null]);
        setCategoryPath([]);
    }, [advertiserContext?.dealer]);

    const dealerSelected = useCallback(
        (dealerId, dealer: Dealer | null) => {
            setDealer(dealer === null ? new Dealer() : dealer);
        },
        [setDealer]
    );

    const dspPlatforms = [{ name: 'Amazon DSP' }];

    function getPage(page: number) {
        let token = null;
        if (page <= tokens.length - 1) {
            token = tokens[page];
        } else {
            if (listAudienceResult?.nextToken) {
                let pageTokens = [...tokens];
                token = listAudienceResult?.nextToken;
                pageTokens.push(token);
                setTokens(pageTokens);
            }
        }
        ApiService.getDspAudiences(1, categoryPath, token).then((response) => {
            setListAudienceResult(response.data);
        });
    }

    function formatRange(lower: number, upper: number) {
        if (lower === 1) {
            return 'Less Than ' + format(upper);
        }
        if (!upper) {
            return 'Over ' + format(lower);
        } else {
            return format(lower) + ' - ' + format(upper);
        }
    }

    function formatAmount(val: number) {
        if (val) {
            return '$ ' + val.toFixed(2);
        } else {
            return '';
        }
    }

    function format(val: number) {
        if (!isNaN(val)) {
            if (val >= 1000000) {
                return val / 1000000 + 'M';
            }
            if (val > 1000) {
                return val / 1000 + 'k';
            } else {
                return val;
            }
        }
        return '';
    }

    const columns: GridColumns = [
        {
            field: 'audienceName',
            headerName: 'Audience',
            flex: 1,
            renderCell: (params) => (
                <Tooltip title={params.row.description}>
                    <span>{params.value}</span>
                </Tooltip>
            ),
        },
        {
            field: 'dailyReach',
            headerName: 'Daily Reach',
            width: 130,
            align: 'center',
            headerAlign: 'center',
            valueGetter: (params) => {
                if (params.value) {
                    return formatRange(params.value.lowerBoundInclusive, params.value.upperBoundExclusive);
                } else {
                    return null;
                }
            },
        },
        {
            field: 'dailyImpressions',
            headerName: 'Daily Impressions',
            width: 130,
            align: 'center',
            headerAlign: 'center',
            valueGetter: (params) => {
                if (params.value) {
                    return formatRange(params.value.lowerBoundInclusive, params.value.upperBoundExclusive);
                } else {
                    return null;
                }
            },
        },
        {
            field: 'displayCPM',
            headerName: 'Display CPM',
            align: 'center',
            headerAlign: 'center',
            valueGetter: (params) => formatAmount(params.value),
        },
        {
            field: 'videoCPM',
            headerName: 'Video CPM',
            align: 'center',
            headerAlign: 'center',
            valueGetter: (params) => formatAmount(params.value),
        },
    ];

    function getSubCategories(category: DspAudienceCategory) {
        const newCategoryPath = [...categoryPath, category.category];
        setCategoryPath(newCategoryPath);
        if (listAudienceResult) {
            let audienceResult = { ...listAudienceResult, nextToken: null };
            setListAudienceResult(audienceResult);
        }
    }

    return (
        <Column sx={{ height: '100%' }}>
            <Column gap={1}>
                <AppBreadcrumbs items={[]} />

                <Heading>DSP Audiences</Heading>
            </Column>

            <Column gap={2} sx={{ height: '100%' }}>
                <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={3}>
                        <FormControl style={{ width: '100%' }}>
                            <TextField
                                label="Select DSP Platform"
                                select
                                required={true}
                                size="small"
                                SelectProps={{ native: false }}
                                variant="outlined"
                                value={'Amazon DSP'}
                                onChange={(event) => {
                                    // setAmcAttribution({
                                    //     ...amcAttribution,
                                    //     dataSetName: event.target.value,
                                    // });
                                }}
                            >
                                {dspPlatforms.map((dspPlatform) => {
                                    return (
                                        <MenuItem key={dspPlatform.name} value={dspPlatform.name}>
                                            {dspPlatform.name}
                                        </MenuItem>
                                    );
                                })}
                            </TextField>
                        </FormControl>
                    </Grid>

                    <Grid item xs={3}>
                        <FormControl style={{ width: '100%' }}>
                            <DealerSelector
                                agencyId={advertiserContext.agencyId}
                                required={true}
                                label={'Select Advertiser'}
                                variant="outlined"
                                dealerId={advertiserContext.dealer ? advertiserContext.dealer.id : null}
                                dealerSelected={dealerSelected}
                            />
                        </FormControl>
                    </Grid>
                </Grid>

                {(!advertiserContext?.dealer || !advertiserContext?.dealer?.id) && (
                    <>
                        <Alert severity="info">Select an Advertiser to view the available Audience data.</Alert>
                    </>
                )}
                {advertiserContext?.dealer && advertiserContext.dealer.id && (
                    <>
                        <Breadcrumbs maxItems={5}>
                            <Button
                                sx={{ minWidth: '32px', padding: '1px' }}
                                variant="text"
                                size="small"
                                color="inherit"
                                onClick={() => setCategoryPath([])}
                            >
                                All
                            </Button>
                            {categoryPath.map((cp, index) => (
                                <Button
                                    sx={{ padding: '1px' }}
                                    variant="text"
                                    size="small"
                                    color={index === categoryPath.length - 1 ? 'primary' : 'inherit'}
                                    onClick={() => setCategoryPath(categoryPath.slice(0, index + 1))}
                                >
                                    {cp}
                                </Button>
                            ))}
                        </Breadcrumbs>
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <Paper variant="outlined">
                                    {audienceCategories.length > 0 && (
                                        <List dense>
                                            {audienceCategories.map((c, index) => (
                                                <ListItem key={index} onClick={() => getSubCategories(c)}>
                                                    <Button
                                                        variant="text"
                                                        sx={{
                                                            pt: '2px',
                                                            pb: '2px',
                                                        }}
                                                    >
                                                        {c.category}
                                                        <Typography sx={{ paddingLeft: 1 }} variant="caption">
                                                            ({c.audienceCount})
                                                        </Typography>
                                                    </Button>
                                                </ListItem>
                                            ))}
                                        </List>
                                    )}
                                </Paper>
                            </Grid>
                            <Grid item xs={8}>
                                {listAudienceResult && listAudienceResult.audiences && (
                                    <CDGrid
                                        autoHeight
                                        columns={columns}
                                        rows={listAudienceResult.audiences}
                                        pageSize={25}
                                        rowCount={listAudienceResult?.matchCount}
                                        paginationMode="server"
                                        onPageChange={(page) => getPage(page)}
                                    />
                                )}
                            </Grid>
                        </Grid>
                    </>
                )}
            </Column>
        </Column>
    );
}
