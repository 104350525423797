import {
    Alert,
    AlertColor,
    Button,
    Divider,
    Grid,
    InputAdornment,
    Link as MuiLink,
    MenuItem,
    TextField,
} from '@mui/material';
import VerticalsComponent from './VerticalsComponent';
import { AddOutlined, InsertLinkOutlined } from '@mui/icons-material';
import SasAccountComponent from './SasAccountComponent';
import DspEntityComponent from './DspEntityComponent';
import { Link } from 'react-router-dom';
import { Dealer } from '../../types/Dealer';
import { ChangeEvent, useCallback, useContext } from 'react';
import { UserContext } from '../../App';
import GeographyStateField from '../../components/GeographyStateField';
import Row from '../../components/Row';

export type DealerUpdateCallback = (name: string, changes: Partial<Dealer>) => void;

export type DealerDetailsEditProps = {
    dealer: Dealer;
    hasDefaultSasAccount: boolean;
    hasDefaultDspEntity: boolean;
    duplicateId?: number;
    // Indicates a partial update of a dealer. The changes object
    // contains only the attributes that changed.
    onDealerUpdate: DealerUpdateCallback;
    className?: string;
    variant?: 'filled' | 'outlined';
    showImagePreviews?: boolean;
    shrinkTextFieldLabels?: boolean;
    onMessage?: (alertLevel: AlertColor, message: string) => void;
};

export default function DealerDetailsEdit({
    className,
    dealer,
    duplicateId,
    hasDefaultDspEntity,
    hasDefaultSasAccount,
    onDealerUpdate,
    onMessage,
    variant = 'outlined',
    showImagePreviews = true,
    shrinkTextFieldLabels,
}: DealerDetailsEditProps) {
    const { userContext } = useContext(UserContext);

    const handleFieldChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            const {
                target: { name, value },
            } = event;
            onDealerUpdate(name, { [name]: value });
        },
        [onDealerUpdate]
    );

    const urlFieldGridSpan = showImagePreviews ? 9 : 12;

    return (
        <Grid container spacing={2} className={className}>
            <Grid item xs={12}>
                <TextField
                    name="dealerName"
                    label="Advertiser Name"
                    value={dealer.dealerName ?? ''}
                    required={true}
                    fullWidth={true}
                    size="small"
                    variant={variant}
                    onChange={handleFieldChange}
                    InputLabelProps={{ shrink: shrinkTextFieldLabels }}
                />
            </Grid>

            <Grid item xs={12}>
                <TextField
                    name="address"
                    label="Address"
                    value={dealer.address ?? ''}
                    fullWidth={true}
                    size="small"
                    variant={variant}
                    onChange={handleFieldChange}
                    InputLabelProps={{ shrink: shrinkTextFieldLabels }}
                />
            </Grid>

            <Grid item xs={5}>
                <TextField
                    name="city"
                    label="City"
                    value={dealer.city ?? ''}
                    fullWidth={true}
                    size="small"
                    variant={variant}
                    onChange={handleFieldChange}
                    InputLabelProps={{ shrink: shrinkTextFieldLabels }}
                />
            </Grid>

            <Grid item xs={3}>
                <GeographyStateField
                    countryCode={dealer.countryCode}
                    name="state"
                    label="State"
                    value={dealer.state ?? ''}
                    fullWidth={true}
                    size="small"
                    variant={variant}
                    onChange={(event: any, value: any) => {
                        onDealerUpdate('state', { state: value?.code ?? '' });
                    }}
                    InputLabelProps={{ shrink: shrinkTextFieldLabels }}
                />
            </Grid>
            <Grid item xs={2}>
                <TextField
                    name="zip"
                    label="Zip Code"
                    value={dealer.zip ?? ''}
                    fullWidth={true}
                    size="small"
                    variant={variant}
                    onChange={handleFieldChange}
                    InputLabelProps={{ shrink: shrinkTextFieldLabels }}
                />
            </Grid>
            <Grid item xs={2}>
                <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    label="Country"
                    name="countryCode"
                    select
                    value={dealer.countryCode}
                    onChange={handleFieldChange}
                    SelectProps={{ native: false }}
                >
                    <MenuItem key={'CA'} value={'CA'}>
                        Canada
                    </MenuItem>
                    <MenuItem key={'US'} value={'US'}>
                        US
                    </MenuItem>
                </TextField>
            </Grid>

            <Grid item xs={6}>
                <TextField
                    name="phone"
                    label="Phone"
                    value={dealer.phone ?? ''}
                    fullWidth={true}
                    size="small"
                    variant={variant}
                    onChange={handleFieldChange}
                    InputLabelProps={{ shrink: shrinkTextFieldLabels }}
                />
            </Grid>

            <Grid item xs={12}>
                <Divider />
            </Grid>

            <Grid item xs={urlFieldGridSpan}>
                <VerticalsComponent
                    verticalId={dealer.verticalId}
                    required={true}
                    onSelectVertical={(verticalId: number) => {
                        onDealerUpdate('verticalId', { verticalId });
                    }}
                    variant={variant}
                    shrinkLabel={shrinkTextFieldLabels}
                />
            </Grid>

            {(userContext.isAgency() || userContext.isAdmin()) && (!hasDefaultSasAccount || !hasDefaultDspEntity) && (
                <Grid item xs={urlFieldGridSpan}>
                    <Row>
                        {!hasDefaultSasAccount && (
                            <SasAccountComponent
                                sasAccountId={dealer.sasAccountId}
                                variant={variant}
                                shrinkLabel={shrinkTextFieldLabels}
                                onSelectSasAccount={(sasAccountId: number) => {
                                    if (sasAccountId !== dealer.sasAccountId) {
                                        onDealerUpdate('sasAccountId', {
                                            sasAccountId,
                                        });
                                    }
                                }}
                            />
                        )}

                        {!hasDefaultDspEntity && (
                            <DspEntityComponent
                                dspEntityId={dealer.dspEntityId}
                                variant={variant}
                                shrinkLabel={shrinkTextFieldLabels}
                                onSelectDspEntity={(dspEntityId: number) => {
                                    if (dspEntityId !== dealer.dspEntityId) {
                                        onDealerUpdate('dspEntityId', {
                                            dspEntityId,
                                        });
                                    }
                                }}
                            />
                        )}
                    </Row>
                </Grid>
            )}

            <Grid item xs={urlFieldGridSpan}>
                <TextField
                    name="url"
                    fullWidth={true}
                    size="small"
                    label="Website URL"
                    required={true}
                    onChange={handleFieldChange}
                    variant={variant}
                    value={dealer.url ?? ''}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="start">
                                {dealer.url && dealer.url.toLowerCase().indexOf('https://') > -1 && (
                                    <MuiLink href={dealer.url} target="new">
                                        <InsertLinkOutlined />
                                    </MuiLink>
                                )}
                            </InputAdornment>
                        ),
                    }}
                    InputLabelProps={{ shrink: shrinkTextFieldLabels }}
                />
            </Grid>

            {userContext.isAgency() && duplicateId != null && (
                <>
                    <Grid item xs={10}>
                        <Alert sx={{ marginBottom: '10px' }} severity="error">
                            You already have an Advertiser with the same name. Would you like to launch a new Campaign
                            instead ?
                        </Alert>

                        <Link to="/campaign/create" state={{ dealerId: duplicateId }}>
                            <Button variant="contained" color="primary" startIcon={<AddOutlined />}>
                                New Campaign
                            </Button>
                        </Link>
                    </Grid>
                </>
            )}
        </Grid>
    );
}
