import { useMemo } from 'react';
import { Box, Grid, Paper, Typography } from '@mui/material';
import Column from '../../components/Column';
import SalesDataFirstPartyDataGrid, {
    getSalesDataFirstPartyDataGridColumns,
} from '../salesData/SalesDataFirstPartyDataGrid';
import SalesDataMAIDDataGrid from '../salesData/SalesDataMAIDDataGrid';
import { DataSetValue } from '../../types/DataSetValue';
import { MappedDataSummary } from '../../types/MappedDataSummary';
import { SalesDataUpload } from '../../types/SalesDataUpload';
import { SalesDataMapping } from '../../types/SalesDataMapping';
import Utils from '../../components/Utils';

type CRMDataUploadMappingReviewProps = {
    salesDataUpload: SalesDataUpload;
    salesDataMapping: SalesDataMapping;
    sampleMappedData: DataSetValue[];
    mappedDataSummary?: MappedDataSummary;
};

export default function CRMDataUploadMappingReview(props: CRMDataUploadMappingReviewProps) {
    const { salesDataUpload, salesDataMapping, sampleMappedData, mappedDataSummary } = props;

    const dateRange: string = useMemo(() => {
        const dateRanges: string[] = [];

        if (salesDataUpload?.beginDate) {
            dateRanges.push(Utils.formatDate(salesDataUpload.beginDate));
        }

        if (salesDataUpload?.endDate) {
            dateRanges.push(Utils.formatDate(salesDataUpload.endDate));
        }

        return dateRanges.join(' - ');
    }, [salesDataUpload.beginDate, salesDataUpload.endDate]);

    // Data Set data contains Mobile Advertising ID data
    function isMAIDDataSet(): boolean {
        return salesDataUpload.crmDataType.indexOf('MAID') > -1;
    }

    return (
        <>
            <Column gap={3}>
                <Column gap={1}>
                    <Typography variant="subtitle2">Review CSV file details</Typography>

                    <Paper variant="outlined" sx={{ p: 2 }}>
                        <Grid container spacing={5}>
                            <Grid item xs={3}>
                                <Typography variant="caption">Data Type</Typography>
                                <Typography variant="subtitle2" sx={{ mt: 0.5 }}>
                                    {salesDataUpload.crmDataType}
                                </Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant="caption">Date Range</Typography>
                                <Typography variant="subtitle2" sx={{ mt: 0.5 }}>
                                    {dateRange}
                                </Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant="caption">File Name</Typography>
                                <Typography variant="subtitle2" sx={{ mt: 0.5 }}>
                                    {salesDataUpload.originalFileName}
                                </Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant="caption">Number of Records</Typography>
                                <Typography variant="subtitle2" sx={{ mt: 0.5 }}>
                                    {mappedDataSummary?.totalRows}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Paper>
                </Column>

                <Column gap={1}>
                    <Typography variant="subtitle2">Preview data mapped to AMC dataset</Typography>

                    <Box>
                        {!isMAIDDataSet() && (
                            <SalesDataFirstPartyDataGrid
                                columns={getSalesDataFirstPartyDataGridColumns(salesDataMapping)}
                                rows={sampleMappedData}
                            />
                        )}
                        {isMAIDDataSet() && <SalesDataMAIDDataGrid rows={sampleMappedData} />}
                    </Box>
                </Column>
            </Column>
        </>
    );
}
