export default `<script>
    !(function (w, d, s, t, a) {
        if (w.amzn) return;
        w.amzn = a = function () {
            w.amzn.q.push([arguments, new Date().getTime()]);
        };
        a.q = [];
        a.version = '0.0';
        s = d.createElement('script');
        s.src = 'https://c.amazon-adsystem.com/aat/amzn.js';
        s.id = 'amzn-pixel';
        s.async = true;
        t = d.getElementsByTagName('script')[0];
        t.parentNode.insertBefore(s, t);
    })(window, document);
    amzn('setRegion', 'NA');
    amzn('addTag', '<ADTAGID>');
    amzn('trackEvent', 'PageView');
    document.addEventListener('DOMContentLoaded', function () {
        /* Team Velocity */
        if (window.location.href.indexOf('/') > -1) {
            amzn('trackEvent', '"Home Page View"');
        } else if (window.location.href.indexOf('/inventory/new') > -1) {
            amzn('trackEvent', '"New SRP View"');
        } else if (window.location.href.indexOf('/new-inventory/') > -1) {
            amzn('trackEvent', '"New SRP View"');
        } else if (window.location.href.indexOf('/viewdetails/new') > -1) {
            amzn('trackEvent', '"New VDP View"');
        } else if (window.location.href.indexOf('/inventory/used') > -1) {
            amzn('trackEvent', '"Used SRP View"');
        } else if (window.location.href.indexOf('/viewdetails/used') > -1) {
            amzn('trackEvent', '"Used VDP View"');
        } else if (window.location.href.indexOf('') > -1) {
            amzn('trackEvent', '"Used VDP View"');
        } else if (window.location.href.indexOf('/service') > -1) {
            amzn('trackEvent', '"Service Page View"');
        } else if (window.location.href.indexOf('/ford-service') > -1) {
            amzn('trackEvent', '"Service Page View"');
        } else if (window.location.href.indexOf('/service-department') > -1) {
            amzn('trackEvent', '"Service Page View"');
        } else if (window.location.href.indexOf('/schedule-service') > -1) {
            amzn('trackEvent', '"Schedule Service Page View"');
        } else if (window.location.href.indexOf('/scheduleservice') > -1) {
            amzn('trackEvent', '"Schedule Service Page View"');
        } else if (window.location.href.indexOf('/parts-department') > -1) {
            amzn('trackEvent', '"Parts Page View"');
        } else if (window.location.href.indexOf('/financing') > -1) {
            amzn('trackEvent', '"Finance Page View"');
        } else if (window.location.href.indexOf('/getfinancing') > -1) {
            amzn('trackEvent', '"Finance App Page View"');
        } else if (window.location.href.indexOf('/shopperwizard/mytradevalue') > -1) {
            amzn('trackEvent', '"Trade In Page View"');
        } else if (window.location.href.indexOf('/sell-us-your-car') > -1) {
            amzn('trackEvent', '"Trade In Page View"');
        } else if (window.location.href.indexOf('/service/service-specials') > -1) {
            amzn('trackEvent', '"Service Specials Page View"');
        } else if (window.location.href.indexOf('/specials*') > -1) {
            amzn('trackEvent', '"New Vehicle Specials Page View"');
        } else if (window.location.href.indexOf('') > -1) {
            amzn('trackEvent', '"Used Vehicle Specials Page View"');
        } else if (window.location.href.indexOf('/ev') > -1) {
            amzn('trackEvent', '"EV or Hybrid Page View"');
        } else if (window.location.href.indexOf('/contactus') > -1) {
            amzn('trackEvent', '"Maps and Directions Page View"');
        } else if (window.location.href.indexOf('/get-directions') > -1) {
            amzn('trackEvent', '"Maps and Directions Page View"');
        } else if (window.location.href.indexOf('/contactus') > -1) {
            amzn('trackEvent', '"Hours of Operation Page View"');
        } else if (window.location.href.indexOf('') > -1) {
            amzn('trackEvent', '"Espanol Page View"');
        }
    });
</script>
<noscript>
    <img src="https://s.amazon-adsystem.com/iu3?pid=&event=PageView" width="1" height="1" border="0" />
</noscript>`;
