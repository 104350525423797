import { ChangeEvent, FocusEvent, useEffect, useState } from 'react';
import { DealerImportSite, ImportSite } from '../../types/DealerImportSite';
import {
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    FormControlLabel,
    FormGroup,
    Grid,
    MenuItem,
    Stack,
    Switch,
    TextField,
    Tooltip,
} from '@mui/material';
import { CloseOutlined, SaveOutlined, DeleteOutlined } from '@mui/icons-material';
import ApiService from '../../ApiService';
import { Dealer } from '../../types/Dealer';
import { CDTextField } from '../../components/CDTextField';
import ConfirmDialog, { ConfirmDialogProps } from '../../components/ConfirmDialog';

export interface DealerImportSiteProps {
    dealerImportSite: DealerImportSite;
    onClose(save: boolean): void;
}

export default function DealerImportSiteEditDialog(props: DealerImportSiteProps) {
    const [dealerImportSite, setDealerImportSite] = useState<DealerImportSite | null>(null);
    const [confirmDialogProps, setConfirmDialogProps] = useState<ConfirmDialogProps | null>(null);
    const [dealers, setDealers] = useState<Dealer[]>([]);
    const [importSites, setImportSites] = useState<ImportSite[]>([]);
    const [requiredFieldError, setRequiredFieldError] = useState(false);

    useEffect(() => {
        const importSite: DealerImportSite = props.dealerImportSite;
        setDealerImportSite(importSite);
        if (!importSite?.dealerId) {
            ApiService.getAssignedDealers()
                .then((response) => {
                    const dealerList: Dealer[] = response.data;
                    setDealers(dealerList);
                    if (dealerList.length === 1 && !importSite.dealerId) {
                        // @ts-ignore
                        importSite.dealer = dealerList[0];
                        setDealerImportSite({
                            ...importSite,
                            dealerId: dealerList[0].id,
                        });
                    }
                })
                .catch();
        }
        if (importSite?.dealerId) {
            if (importSite.dealer) {
                setDealers([importSite.dealer]);
            }
            getAvailableImportSites(importSite.dealerId);
        }
    }, [props.dealerImportSite]);

    function getAvailableImportSites(dealerId: number) {
        ApiService.getAvailableImportSites(dealerId)
            .then((response) => {
                setImportSites(response.data);
            })
            .catch();
    }

    function cancel() {
        setDealerImportSite(null);
        props.onClose(false);
    }

    function handleChange(event: ChangeEvent<HTMLInputElement>) {
        const field = event.target.name;

        if (field.startsWith('is') || field.startsWith('copy') || field === 'overrideAgencyVdpUrlParameters') {
            setDealerImportSite({
                ...dealerImportSite,
                [field]: event.target.checked ? 1 : 0,
            });
        } else {
            let updateDealerImportSite = dealerImportSite;
            if (field === 'importSiteId') {
                let importSite = importSites.find((importSite) => importSite.id === Number(event.target.value));
                updateDealerImportSite = {
                    ...updateDealerImportSite,
                    importSite: importSite,
                };
            }
            setDealerImportSite({
                ...updateDealerImportSite,
                [field]: event.target.value,
            });
            // if (field === 'vdpUrlPattern') {
            //     setHasError(event.target.value.length > 0 && event.target.value.indexOf('[VIN]') < 0);
            // }
        }
        if (field === 'dealerId') {
            getAvailableImportSites(Number(event.target.value));
        }
    }

    function handleBlur(event: FocusEvent<HTMLInputElement>) {
        const url = event.target.value;

        if (!url) {
            return;
        }

        let schemeWithDelimiter = '';
        let baseUrlWithParams = url;

        const schemeSeparatorIndex = url.indexOf('://');
        if (schemeSeparatorIndex !== -1) {
            schemeWithDelimiter = url.substring(0, schemeSeparatorIndex + 3);
            baseUrlWithParams = url.substring(schemeSeparatorIndex + 3);
        }

        const [baseUrlPath, queryString] = baseUrlWithParams.split('?');

        const formattedBaseUrl = baseUrlPath
            .split('/')
            .map((segment, index) => {
                if (index === 0) {
                    return encodeURIComponent(decodeURIComponent(segment));
                } else {
                    return encodeURIComponent(decodeURIComponent(segment.trim()));
                }
            })
            .join('/');

        if (!queryString) {
            const formattedUrlWithoutQuery = `${schemeWithDelimiter}${formattedBaseUrl}`;
            if (dealerImportSite) {
                setDealerImportSite((prevState) => ({
                    ...prevState,
                    importURL: formattedUrlWithoutQuery,
                }));
            }
            return formattedUrlWithoutQuery;
        }

        const formattedQuery = queryString
            .split('&')
            .map((param) => {
                const [key, value] = param.split('=');
                if (!value) {
                    return param;
                }

                const trimmedKey = decodeURIComponent(key.trim());
                const trimmedValue = decodeURIComponent(value.trim());
                const encodedKey = encodeURIComponent(trimmedKey);
                const encodedValue = encodeURIComponent(trimmedValue);
                return `${encodedKey}=${encodedValue}`;
            })
            .join('&');

        const formattedUrl = `${schemeWithDelimiter}${formattedBaseUrl}?${formattedQuery}`;

        if (dealerImportSite) {
            setDealerImportSite((prevState) => ({
                ...prevState,
                importURL: formattedUrl,
            }));
        }
    }

    function saveDealerImportSite(): void {
        let importKeyValid =
            dealerImportSite?.importSite?.inventoryType === 'fixedOpsInventory' ||
            (dealerImportSite?.importDealerKey && dealerImportSite.importDealerKey.length > 0);
        if (dealerImportSite != null) {
            if (!importKeyValid || !dealerImportSite.dealerId || !dealerImportSite.importSiteId) {
                setRequiredFieldError(true);
                return;
            }
            ApiService.updateDealerImportSite(dealerImportSite)
                .then(() => {
                    setDealerImportSite(null);
                    props.onClose(true);
                })
                .catch();
        }
    }

    function deleteDealerImportSite(): void {
        hideConfirm();

        if (dealerImportSite != null) {
            if (!dealerImportSite.dealerId || !dealerImportSite.importSiteId) {
                setRequiredFieldError(true);
                return;
            }
            ApiService.deleteDealerImportSite(dealerImportSite.dealerId, dealerImportSite.importSiteId)
                .then(() => {
                    setDealerImportSite(null);
                    props.onClose(true);
                })
                .catch();
        }
    }

    const showDeleteConfirm = () => {
        setConfirmDialogProps({
            title: 'Are you sure you want to delete this import site?',
            onOk: () => deleteDealerImportSite(),
            okText: 'Delete Import Site',
            okColor: 'error',
            okIcon: <DeleteOutlined />,
            onCancel: () => hideConfirm(),
        });
    };

    const hideConfirm = () => {
        setConfirmDialogProps(null);
    };

    let isNew = dealerImportSite?.id === undefined;

    return (
        <>
            <Dialog maxWidth="md" open={dealerImportSite != null} onClose={cancel}>
                <DialogTitle id="alert-dialog-title">
                    Advertiser Import Site
                    <Divider />
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <Box sx={{ paddingTop: '10px' }}>
                            <Grid container spacing={2}>
                                {isNew && dealers.length > 1 && (
                                    <Grid item xs={6}>
                                        <TextField
                                            name="dealerId"
                                            label="Select an Advertiser"
                                            select
                                            fullWidth={true}
                                            size="small"
                                            SelectProps={{ native: false }}
                                            variant="outlined"
                                            error={requiredFieldError && !dealerImportSite?.dealerId}
                                            value={dealerImportSite?.dealerId}
                                            onChange={handleChange}
                                        >
                                            {dealers.map((dealer) => {
                                                return (
                                                    <MenuItem key={dealer.id} value={dealer.id}>
                                                        {dealer.dealerName}
                                                    </MenuItem>
                                                );
                                            })}
                                        </TextField>
                                    </Grid>
                                )}
                                {(!isNew || dealers.length === 1) && (
                                    <Grid item xs={6}>
                                        <TextField
                                            name="dealerName"
                                            label="Advertiser"
                                            value={dealerImportSite?.dealer?.dealerName}
                                            fullWidth={true}
                                            size="small"
                                            variant="outlined"
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    </Grid>
                                )}
                                {isNew && (
                                    <Grid item xs={6}>
                                        <TextField
                                            name="importSiteId"
                                            label="Select an Import Site"
                                            select
                                            SelectProps={{ native: false }}
                                            required
                                            disabled={!dealerImportSite?.dealerId}
                                            fullWidth={true}
                                            error={requiredFieldError && !dealerImportSite?.importSiteId}
                                            size="small"
                                            variant="outlined"
                                            value={dealerImportSite?.importSiteId}
                                            onChange={handleChange}
                                        >
                                            {importSites.map((importSite) => {
                                                return (
                                                    <MenuItem key={importSite.id} value={importSite.id}>
                                                        {importSite.importName}
                                                    </MenuItem>
                                                );
                                            })}
                                        </TextField>
                                    </Grid>
                                )}

                                {!isNew && (
                                    <Grid item xs={6}>
                                        <TextField
                                            name="importName"
                                            label="Import Site"
                                            value={dealerImportSite?.importSite?.importName}
                                            fullWidth={true}
                                            size="small"
                                            variant="outlined"
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    </Grid>
                                )}

                                <Grid item xs={12}>
                                    <Divider />
                                </Grid>

                                {dealerImportSite?.importSite?.inventoryType !== 'fixedOpsInventory' && (
                                    <>
                                        <Grid item xs={5}>
                                            <TextField
                                                name="importDealerKey"
                                                label="Import Key/Filename"
                                                title="Use Import Key values for Bulk CSV files and Filename values for Single CSV files"
                                                value={dealerImportSite?.importDealerKey}
                                                required={true}
                                                fullWidth={true}
                                                size="small"
                                                error={
                                                    requiredFieldError &&
                                                    (!dealerImportSite?.importDealerKey ||
                                                        dealerImportSite?.importDealerKey.length === 0)
                                                }
                                                disabled={dealerImportSite?.isActive === 0}
                                                variant="outlined"
                                                onChange={handleChange}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={7} />
                                    </>
                                )}

                                <Grid item xs={12}>
                                    <TextField
                                        name="importURL"
                                        label="Import URL"
                                        value={dealerImportSite?.importURL}
                                        fullWidth={true}
                                        size="small"
                                        helperText="Data will be retrieved from here at the time the import runs"
                                        disabled={dealerImportSite?.isActive === 0}
                                        variant="outlined"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </Grid>

                                {dealerImportSite?.importSite?.inventoryType !== 'fixedOpsInventory' && (
                                    <>
                                        <Grid item xs={12}>
                                            <Divider />
                                        </Grid>

                                        <Grid item xs={6} sx={{ marginTop: '10px' }}>
                                            <TextField
                                                name="vdpUrlSearch"
                                                label="VDP URL Contains"
                                                value={dealerImportSite?.vdpUrlSearch}
                                                required={false}
                                                fullWidth={true}
                                                size="small"
                                                disabled={dealerImportSite?.isActive === 0}
                                                variant="outlined"
                                                onChange={handleChange}
                                                helperText="If the VDP URL contains this text"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={6} sx={{ marginTop: '10px' }}>
                                            <TextField
                                                name="vdpUrlReplace"
                                                label="VDP URL Replace"
                                                value={dealerImportSite?.vdpUrlReplace}
                                                required={false}
                                                fullWidth={true}
                                                size="small"
                                                disabled={dealerImportSite?.isActive === 0}
                                                variant="outlined"
                                                onChange={handleChange}
                                                helperText="Then replace with this text"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sx={{ marginTop: '10px' }}>
                                            <CDTextField
                                                name="vdpUrlPattern"
                                                label="VDP URL Pattern"
                                                value={dealerImportSite?.vdpUrlPattern}
                                                required={false}
                                                fullWidth={true}
                                                size="small"
                                                disabled={dealerImportSite?.isActive === 0}
                                                variant="outlined"
                                                onChange={handleChange}
                                                helperText="This pattern will provide a value for the VDPURL and will override imported values."
                                                help="Auto specific parameters: [BODY],[CERTIFIED],[COLOREXTERIOR],[COLORINTERIOR],[CUSTOMLABEL],[DESCRIPTION],[DOORCOUNT],[DRIVETRAIN],[ENGINE],[ENGINECYLINDERS],[ENGINEDISPLACEMENT],[FEATURES],[FUEL],[INVENTORYDATE],[MAKE],[MODEL],[MODELCODE],[MPGCITY],[MPGHIGHWAY],[MSRP],[NEWUSED],[PRICE],[SERIES],[SERIESDETAIL],[STOCK],[TRANSMISSION],[VIN],[YEAR]"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Divider />
                                        </Grid>

                                        <Grid item xs={12} sx={{ marginTop: '10px' }}>
                                            <CDTextField
                                                name="vdpUrlParameters"
                                                label="VDP URL Parameters"
                                                value={dealerImportSite?.vdpUrlParameters}
                                                required={false}
                                                fullWidth={true}
                                                size="small"
                                                disabled={dealerImportSite?.isActive === 0}
                                                variant="outlined"
                                                onChange={handleChange}
                                                helperText="These parameters will be appended to the VDP URL with specific values for each Auto replaced."
                                                help="Auto specific parameters: [BODY],[CERTIFIED],[COLOREXTERIOR],[COLORINTERIOR],[CUSTOMLABEL],[DESCRIPTION],[DOORCOUNT],[DRIVETRAIN],[ENGINE],[ENGINECYLINDERS],[ENGINEDISPLACEMENT],[FEATURES],[FUEL],[INVENTORYDATE],[MAKE],[MODEL],[MODELCODE],[MPGCITY],[MPGHIGHWAY],[MSRP],[NEWUSED],[PRICE],[SERIES],[SERIESDETAIL],[STOCK],[TRANSMISSION],[VIN],[YEAR]"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FormControlLabel
                                                label="Override Agency Campaign Parameters"
                                                control={
                                                    <Switch
                                                        name="overrideAgencyVdpUrlParameters"
                                                        checked={dealerImportSite?.overrideAgencyVdpUrlParameters}
                                                        onChange={handleChange}
                                                        disabled={!dealerImportSite?.vdpUrlParameters}
                                                    />
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Divider />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Tooltip title="This will copy the VDP URL into the Secondary VDP URL.">
                                                <FormGroup>
                                                    <FormControlLabel
                                                        componentsProps={{
                                                            typography: {
                                                                variant: 'caption',
                                                            },
                                                        }}
                                                        control={
                                                            <Checkbox
                                                                name="copyVDPUrlToSecondaryVDPUrl"
                                                                onChange={handleChange}
                                                                checked={dealerImportSite?.copyVDPUrlToSecondaryVDPUrl}
                                                                value={dealerImportSite?.copyVDPUrlToSecondaryVDPUrl}
                                                            />
                                                        }
                                                        label="Copy VDP URL"
                                                    />
                                                </FormGroup>
                                            </Tooltip>
                                        </Grid>

                                        <Grid item xs={4} sx={{ marginTop: '10px' }}>
                                            <TextField
                                                name="vdpUrlSearchAlt"
                                                label="VDP URL Alt Contains"
                                                value={dealerImportSite?.vdpUrlSearchAlt}
                                                required={false}
                                                fullWidth={true}
                                                size="small"
                                                disabled={dealerImportSite?.isActive === 0}
                                                variant="outlined"
                                                onChange={handleChange}
                                                helperText="If the VDP URL contains this text"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={6} sx={{ marginTop: '10px' }}>
                                            <TextField
                                                name="vdpUrlReplaceAlt"
                                                label="VDP URL Alt Replace"
                                                value={dealerImportSite?.vdpUrlReplaceAlt}
                                                required={false}
                                                fullWidth={true}
                                                size="small"
                                                disabled={dealerImportSite?.isActive === 0}
                                                variant="outlined"
                                                onChange={handleChange}
                                                helperText="Then replace with this text"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <TextField
                                                name="secondaryVdpUrlPattern"
                                                label="Secondary VDP URL Pattern"
                                                value={dealerImportSite?.secondaryVdpUrlPattern}
                                                required={false}
                                                fullWidth={true}
                                                size="small"
                                                disabled={dealerImportSite?.isActive === 0}
                                                variant="outlined"
                                                onChange={handleChange}
                                                helperText="This pattern will provide a value for the Secondary VDPURL and will override imported values. Replacement parameters: [VIN]"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </Grid>
                                    </>
                                )}

                                <Grid item xs={7}>
                                    <FormControlLabel
                                        label="Active"
                                        labelPlacement="start"
                                        control={
                                            <Switch
                                                name="isActive"
                                                color="success"
                                                checked={dealerImportSite?.isActive === 1}
                                                onChange={handleChange}
                                            />
                                        }
                                    />
                                </Grid>

                                {dealerImportSite?.importSite?.inventoryType !== 'fixedOpsInventory' && (
                                    <>
                                        <Grid item xs={7}>
                                            <FormControlLabel
                                                label="Add"
                                                labelPlacement="start"
                                                control={
                                                    <Switch
                                                        name="isAdd"
                                                        color="success"
                                                        checked={dealerImportSite?.isAdd === 1}
                                                        disabled={dealerImportSite?.isActive === 0}
                                                        onChange={handleChange}
                                                    />
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={5} />
                                        <Grid item xs={7}>
                                            <FormControlLabel
                                                label="Update"
                                                labelPlacement="start"
                                                control={
                                                    <Switch
                                                        name="isUpdate"
                                                        color="success"
                                                        checked={dealerImportSite?.isUpdate === 1}
                                                        disabled={dealerImportSite?.isActive === 0}
                                                        onChange={handleChange}
                                                    />
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={5} />
                                        <Grid item xs={7}>
                                            <FormControlLabel
                                                label="Delete"
                                                labelPlacement="start"
                                                control={
                                                    <Switch
                                                        name="isDelete"
                                                        color="success"
                                                        checked={dealerImportSite?.isDelete === 1}
                                                        disabled={dealerImportSite?.isActive === 0}
                                                        onChange={handleChange}
                                                    />
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={5} />
                                    </>
                                )}
                            </Grid>
                        </Box>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                        <Button
                            variant="outlined"
                            color="error"
                            startIcon={<DeleteOutlined />}
                            onClick={() => showDeleteConfirm()}
                        >
                            Delete
                        </Button>
                        <Stack direction="row" spacing={3}>
                            <Button variant="outlined" startIcon={<CloseOutlined />} color="error" onClick={cancel}>
                                Cancel
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                startIcon={<SaveOutlined />}
                                onClick={saveDealerImportSite}
                            >
                                Save
                            </Button>
                        </Stack>
                    </Box>
                </DialogActions>
            </Dialog>
            {confirmDialogProps && (
                <>
                    <ConfirmDialog
                        title={confirmDialogProps.title}
                        text={confirmDialogProps.text}
                        okText={confirmDialogProps.okText}
                        okIcon={confirmDialogProps.okIcon}
                        okColor={confirmDialogProps.okColor}
                        onCancel={confirmDialogProps.onCancel}
                        onOk={confirmDialogProps.onOk}
                    />
                </>
            )}
        </>
    );
}
