import { Campaign } from '../../../types/Campaign';
import { Grid } from '@mui/material';
import GeoTargeting from './GeoTargeting';
import AudienceTargeting from './AudienceTargeting';

export default function TargetingTab(props: { campaign: Campaign; onChange(value: Partial<Campaign>): void }) {
    const { campaign, onChange } = props;
    return (
        <>
            <Grid container spacing={1} sx={{ height: '100%' }}>
                <Grid item xs={6}>
                    <GeoTargeting campaign={campaign} onChange={onChange} />
                </Grid>
                <Grid item xs={6}>
                    <AudienceTargeting campaign={campaign} />
                </Grid>
            </Grid>
        </>
    );
}
