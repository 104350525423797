import { useState, useEffect, ChangeEvent, SyntheticEvent } from 'react';
import { alpha } from '@mui/material';
import Box from '@mui/material/Box';
import { RichTreeView } from '@mui/x-tree-view/RichTreeView';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { IFilterItem } from './SalesInsights';

export default function CheckRichTree({
    hasSearch = false,
    items,
    id,
    filters,
    setFilters,
}: {
    hasSearch?: boolean;
    items: IFilterItem[];
    id: number;
    setFilters: (filters: IFilterItem[]) => void;
    filters: IFilterItem[];
}) {
    const [search, setSearch] = useState('');
    const [searchItems, setSearchItems] = useState(JSON.parse(JSON.stringify(items)));
    const [selectedItems, setSelectedItems] = useState<string[]>([]);
    const filterItem = filters[id];

    useEffect(() => {
        if (search) {
            setSearchItems(filterBySearchKey(JSON.parse(JSON.stringify(items)), search));
        } else {
            setSearchItems(JSON.parse(JSON.stringify(items)));
        }
    }, [search, items]);

    useEffect(() => {
        const newSelecteditems: string[] = [];

        filters[id]?.children?.forEach((item) => {
            if (
                item?.children?.length !== undefined &&
                item?.children?.length > 0 &&
                !newSelecteditems?.includes(item.id)
            ) {
                newSelecteditems.push(item.id);
            }
            item?.children?.forEach((j) => {
                if (!newSelecteditems?.includes(j.id)) newSelecteditems.push(j.id);
            });
        });
        setSelectedItems(newSelecteditems);
    }, [filters, id]);

    function filterBySearchKey(data: IFilterItem[], searchKey: string) {
        return data
            .map((parent) => {
                // Filter the children based on the search key
                const filteredChildren = parent?.children?.filter((child) =>
                    child.label.toLowerCase().includes(searchKey.toLowerCase())
                );

                // Check if the parent or any of its children match the search key
                if (
                    parent.label.toLowerCase().includes(searchKey.toLowerCase()) ||
                    (filteredChildren && filteredChildren?.length > 0)
                ) {
                    return {
                        ...parent,
                        children: filteredChildren,
                    };
                }
                return null;
            })
            .filter((parent) => parent !== null);
    }

    const handleItemSelectionToggle = (event: SyntheticEvent, itemId: string, isSelected: boolean) => {
        const ids = itemId.split('@');
        const newFilters = [...filters];
        if (isSelected) {
            // if child
            if (ids.length === 2) {
                const newItems = items?.filter((item) => item.id === ids[0])?.[0];
                if (!filterItem?.children?.filter((item) => item.id === ids[0])?.length) {
                    newFilters[id]?.children?.push({
                        ...newItems,
                        children: [],
                    });
                }
                const parentIndex = newFilters[id]?.children?.findIndex((item) => item.id === ids[0]);

                if (parentIndex !== undefined && parentIndex > -1) {
                    const newItem = newItems?.children?.filter((item) => item.id === itemId)?.[0];

                    if (!newItem) return;
                    newFilters[id]?.children?.[parentIndex]?.children?.push({
                        ...newItem,
                    });
                }
            } else {
                const newItems = items?.filter((item) => item.id === itemId)?.[0];
                if (!filterItem?.children?.filter((item) => item.id === itemId)?.length) {
                    newFilters[id]?.children?.push({
                        ...newItems,
                        children: [],
                    });
                }
                const parentIndex = newFilters[id]?.children?.findIndex((item) => item.id === itemId);
                if (parentIndex !== undefined && parentIndex > -1) {
                    newItems?.children?.forEach((item, index) => {
                        newFilters[id]?.children?.[parentIndex]?.children?.push({
                            ...item,
                        });
                    });
                }
            }
        } else {
            // if child
            if (ids.length === 2) {
                const idx = newFilters[id]?.children
                    ?.filter((item) => item.id === ids[0])?.[0]
                    ?.children?.findIndex((j) => j.id === itemId);
                if (idx !== undefined && idx > -1)
                    newFilters[id]?.children?.filter((item) => item.id === ids[0])?.[0]?.children?.splice(idx, 1);
                // if remove the last one, remove the parent as well
                if (!newFilters[id]?.children?.filter((item) => item.id === ids[0])?.[0]?.children?.length) {
                    newFilters[id]?.children?.splice(
                        newFilters[id]?.children?.findIndex((item) => item.id === ids[0]) || 0,
                        1
                    );
                }
            } else {
                newFilters[id]?.children?.splice(
                    newFilters[id]?.children?.findIndex((item) => item.id === itemId) || 0,
                    1
                );
            }
        }

        setFilters(newFilters);
    };

    return (
        <Box>
            {hasSearch && (
                <Box sx={{ px: 2, mb: 1 }}>
                    <TextField
                        variant="outlined"
                        label={'Search ' + filterItem?.label || ''}
                        type="search"
                        fullWidth
                        size="small"
                        value={search}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                            setSearch(event.target.value);
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment
                                    position="start"
                                    sx={{
                                        color: alpha('#000000', 0.56),
                                    }}
                                >
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>
            )}
            {searchItems && searchItems.length > 0 && (
                <RichTreeView
                    multiSelect
                    checkboxSelection
                    items={searchItems}
                    sx={{
                        maxHeight: '200px',
                        overflowY: 'auto',
                        '& .MuiTreeItem-iconContainer': {
                            order: 3,
                        },
                        '& .MuiCheckbox-root .MuiSvgIcon-root': {
                            color: 'unset',
                        },
                        '& .MuiTreeItem-content': {
                            borderRadius: 0,
                            px: 2,
                            py: 0.5,
                        },
                        '& .MuiTreeItem-content.Mui-selected': {
                            backgroundColor: 'unset',
                        },
                        '& .MuiTreeItem-content .MuiTreeItem-label': {
                            color: (theme) => theme.palette.text.secondary,
                            fontSize: (theme) => theme.typography.body2,
                        },
                        '& .MuiTreeItem-content .MuiTreeItem-checkbox': {
                            marginRight: 3,
                        },
                        '& .MuiTreeItem-groupTransition': {
                            paddingLeft: (theme) => theme.spacing(6),
                        },
                        mt: 1,
                    }}
                    slots={{
                        expandIcon: KeyboardArrowDownIcon,
                        collapseIcon: KeyboardArrowUpIcon,
                    }}
                    onItemSelectionToggle={handleItemSelectionToggle}
                    selectedItems={selectedItems}
                />
            )}
        </Box>
    );
}
