import { DealerExportSite, ExportSite } from '../../types/ExportSite';
import { ChangeEvent, useEffect, useState } from 'react';
import { Dealer } from '../../types/Dealer';
import ApiService from '../../ApiService';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    MenuItem,
    Radio,
    RadioGroup,
    Stack,
    Switch,
    TextField,
} from '@mui/material';
import { CloseOutlined, DeleteOutlined, SaveOutlined } from '@mui/icons-material';
import ConfirmDialog, { ConfirmDialogProps } from '../../components/ConfirmDialog';
import Column from '../../components/Column';
import Row from '../../components/Row';
import { green } from '@mui/material/colors';

export interface DealerExportSiteProps {
    dealerExportSite: DealerExportSite;
    onClose(save: boolean): void;
}

export default function DealerExportSiteEditDialog(props: DealerExportSiteProps) {
    const [dealerExportSite, setDealerExportSite] = useState<DealerExportSite | null>(null);
    const [dealers, setDealers] = useState<Dealer[]>([]);
    const [exportSites, setExportSites] = useState<ExportSite[]>([]);
    const [requiredFieldError, setRequiredFieldError] = useState(false);
    const [deleteDealerExportSiteConfirmDialogProps, setDeleteDealerExportSiteConfirmDialogProps] =
        useState<ConfirmDialogProps | null>(null);

    const [showFallbackMethod, setShowFallbackMethod] = useState(
        props.dealerExportSite?.exportSite?.exportType.includes('adgroup') || false
    );

    useEffect(() => {
        const exportSite: DealerExportSite = props.dealerExportSite;
        setDealerExportSite(exportSite);
        if (!exportSite?.dealerId) {
            ApiService.getAssignedDealers()
                .then((response) => {
                    const dealerList: Dealer[] = response.data;
                    setDealers(dealerList);
                    if (dealerList.length === 1 && !exportSite.dealerId) {
                        // @ts-ignore
                        exportSite.dealer = dealerList[0];
                        setDealerExportSite({
                            ...exportSite,
                            dealerId: dealerList[0].id,
                        });
                    }
                })
                .catch();
        }
        if (exportSite?.dealerId) {
            if (exportSite.dealer) {
                setDealers([exportSite.dealer]);
            }
            getAvailableExportSites(exportSite.dealerId);
        }
    }, [props.dealerExportSite]);

    function getAvailableExportSites(dealerId: number) {
        ApiService.getAvailableExportSites(dealerId)
            .then((response) => {
                setExportSites(response.data);
            })
            .catch();
    }

    function cancel() {
        setDealerExportSite(null);
        props.onClose(false);
    }

    function handleChange(event: ChangeEvent<HTMLInputElement>) {
        const field = event.target.name;
        if (field === 'active') {
            setDealerExportSite({
                ...dealerExportSite,
                [field]: event.target.checked,
            });
        } else if (field === 'fallbackVersion') {
            setDealerExportSite({
                ...dealerExportSite,
                [field]: event.target.value === 'true',
            });
        } else if (field === 'exportSiteId') {
            const showMethod = event.target.value.split('_')[1].includes('adgroup');
            setShowFallbackMethod(showMethod);
            setDealerExportSite({
                ...dealerExportSite,
                fallbackVersion: showMethod ? dealerExportSite?.fallbackVersion : false,
                [field]: +event.target.value.split('_')[0],
            });
        } else {
            setDealerExportSite({
                ...dealerExportSite,
                [field]: event.target.value,
            });
        }
        if (field === 'dealerId') {
            getAvailableExportSites(Number(event.target.value));
        }
    }

    function saveDealerExportSite(): void {
        if (dealerExportSite != null) {
            if (!dealerExportSite.dealerId || !dealerExportSite.exportSiteId) {
                setRequiredFieldError(true);
                return;
            }
            ApiService.updateDealerExportSite(dealerExportSite)
                .then(() => {
                    setDealerExportSite(null);
                    props.onClose(true);
                })
                .catch();
        }
    }

    function showDeleteDealerExportSiteConfirmDialog(): void {
        setDeleteDealerExportSiteConfirmDialogProps({
            title: 'Delete this delear export site record?',
            text: '',
            onOk: () => deleteDealerExportSite(),
            okText: 'Delete',
            okColor: 'error',
            okIcon: <DeleteOutlined />,
            onCancel: () => hideDeleteDealerExportSiteConfirmDialog(),
        });
    }

    function hideDeleteDealerExportSiteConfirmDialog(): void {
        setDeleteDealerExportSiteConfirmDialogProps(null);
    }

    function deleteDealerExportSite(): void {
        if (dealerExportSite != null) {
            if (!dealerExportSite.dealerId || !dealerExportSite.exportSiteId) {
                setRequiredFieldError(true);
                return;
            }

            ApiService.deleteExportData(dealerExportSite.dealerId, dealerExportSite.exportSiteId)
                .then(() => {
                    setDealerExportSite(null);
                    props.onClose(true);
                })
                .catch();
        }
    }

    let isNew = dealerExportSite?.id === undefined;

    return (
        <>
            <Dialog maxWidth="md" open={dealerExportSite != null} onClose={cancel}>
                <DialogTitle id="alert-dialog-title">
                    Advertiser Export Site
                    <Divider />
                </DialogTitle>
                <DialogContent sx={{ width: '900px' }}>
                    <DialogContentText id="alert-dialog-description">
                        <Box sx={{ paddingTop: '10px' }}>
                            <Grid container spacing={3}>
                                {isNew && dealers.length > 1 && (
                                    <Grid item xs={6}>
                                        <FormControl
                                            style={{
                                                marginTop: '15px',
                                                marginBottom: '30px',
                                            }}
                                        >
                                            <TextField
                                                name="dealerId"
                                                label="Select an Advertiser"
                                                select
                                                fullWidth={true}
                                                size="small"
                                                SelectProps={{ native: false }}
                                                variant="outlined"
                                                error={requiredFieldError && !dealerExportSite?.dealerId}
                                                value={dealerExportSite?.dealerId}
                                                onChange={handleChange}
                                            >
                                                {dealers.map((dealer) => {
                                                    return (
                                                        <MenuItem key={dealer.id} value={dealer.id}>
                                                            {dealer.dealerName}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </TextField>
                                        </FormControl>
                                    </Grid>
                                )}
                                {(!isNew || dealers.length === 1) && (
                                    <Grid item xs={6}>
                                        <TextField
                                            name="dealerName"
                                            label="Advertiser"
                                            value={dealerExportSite?.dealer?.dealerName}
                                            fullWidth={true}
                                            size="small"
                                            variant="outlined"
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    </Grid>
                                )}
                                {isNew && (
                                    <Grid item xs={6}>
                                        <FormControl
                                            style={{
                                                width: '100%',
                                            }}
                                        >
                                            <TextField
                                                name="exportSiteId"
                                                label={
                                                    !dealerExportSite?.exportSiteId
                                                        ? 'Select an Export Site'
                                                        : 'Export Site'
                                                }
                                                select
                                                SelectProps={{ native: false }}
                                                required
                                                disabled={!dealerExportSite?.dealerId}
                                                fullWidth={true}
                                                error={requiredFieldError && !dealerExportSite?.exportSiteId}
                                                size="small"
                                                variant="outlined"
                                                value={dealerExportSite?.exportSiteId}
                                                onChange={handleChange}
                                            >
                                                {exportSites.map((exportSite) => {
                                                    return (
                                                        <MenuItem
                                                            key={exportSite.id}
                                                            value={`${exportSite.id}_${exportSite.exportType}`}
                                                        >
                                                            {exportSite.exportName}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </TextField>
                                        </FormControl>
                                    </Grid>
                                )}

                                {!isNew && (
                                    <Grid item xs={6}>
                                        <TextField
                                            name="importName"
                                            label="Export Site"
                                            value={dealerExportSite?.exportSite?.exportName}
                                            fullWidth={true}
                                            size="small"
                                            variant="outlined"
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    </Grid>
                                )}

                                {showFallbackMethod && (
                                    <Grid item xs={12}>
                                        <FormLabel id="demo-radio-buttons-group-label">Fallback Method</FormLabel>
                                        <RadioGroup
                                            aria-labelledby="demo-radio-buttons-group-label"
                                            value={!!dealerExportSite?.fallbackVersion}
                                            name="fallbackVersion"
                                            row
                                            onChange={handleChange}
                                        >
                                            <FormControlLabel
                                                value="true"
                                                control={
                                                    <Radio
                                                        sx={{
                                                            color: green[700],
                                                            '&.Mui-checked': {
                                                                color: green[700],
                                                            },
                                                        }}
                                                    />
                                                }
                                                label="Random VIN"
                                            />
                                            <FormControlLabel
                                                value="false"
                                                control={
                                                    <Radio
                                                        sx={{
                                                            color: green[700],
                                                            '&.Mui-checked': {
                                                                color: green[700],
                                                            },
                                                        }}
                                                    />
                                                }
                                                label="Static Image"
                                            />
                                        </RadioGroup>
                                    </Grid>
                                )}
                                <Grid item xs={12}>
                                    <Divider sx={{ paddingTop: 2 }} />
                                </Grid>

                                <Grid item xs={7} spacing={3}>
                                    <Column>
                                        <Row>
                                            <FormControlLabel
                                                label="Active"
                                                labelPlacement="start"
                                                control={
                                                    <Switch
                                                        name="active"
                                                        color="success"
                                                        checked={dealerExportSite?.active}
                                                        onChange={handleChange}
                                                    />
                                                }
                                            />
                                        </Row>

                                        <Row>
                                            {!isNew && (
                                                <>
                                                    <Button
                                                        variant="outlined"
                                                        color="error"
                                                        startIcon={<DeleteOutlined />}
                                                        onClick={showDeleteDealerExportSiteConfirmDialog}
                                                    >
                                                        Delete
                                                    </Button>

                                                    {deleteDealerExportSiteConfirmDialogProps && (
                                                        <>
                                                            <ConfirmDialog
                                                                {...deleteDealerExportSiteConfirmDialogProps}
                                                            />
                                                        </>
                                                    )}
                                                </>
                                            )}
                                        </Row>
                                    </Column>
                                </Grid>
                            </Grid>
                        </Box>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Stack direction="row" spacing={3}>
                        <Button variant="outlined" startIcon={<CloseOutlined />} color="error" onClick={cancel}>
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<SaveOutlined />}
                            onClick={saveDealerExportSite}
                        >
                            Save
                        </Button>
                    </Stack>
                </DialogActions>
            </Dialog>
        </>
    );
}
