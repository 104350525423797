import { styled, tableCellClasses, tableRowClasses } from '@mui/material';
import {
    // Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';

import { PolkSalesAnalysis } from '../../types/Polk';
import { Table as BaseTable } from '../../components/Table';
import Utils from '../../components/Utils';

import { useAttributionPageContext } from '../../hooks/useAttributionPage';
import usePolkAttribution from '../../hooks/usePolkAttribution';

const Table = styled(BaseTable)(({ theme }) => ({
    [`& .${tableRowClasses.root} .${tableCellClasses.root}:nth-of-type(1)`]: {
        textAlign: 'left',
        width: '25%',
    },
    [`& .${tableRowClasses.root} .${tableCellClasses.root}`]: {
        textAlign: 'right',
        width: '15%',
    },
}));

const TablePrimaryCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.root}`]: {
        color: theme.palette.text.primary,
    },
}));

export type SalesAnalysisTableProps = { rows: PolkSalesAnalysis[] };

export function SalesAnalysisTable({ rows }: SalesAnalysisTableProps) {
    const { isAdvertiserPolkDealer } = useAttributionPageContext();

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Advertiser & Competitors (Top 10)</TableCell>
                    <TableCell>Market Share</TableCell>
                    <TableCell>Ad Share</TableCell>
                    <TableCell>Ad Exposed Sales</TableCell>
                    <TableCell>Non-Exposed Sales</TableCell>
                    <TableCell>Total Sales</TableCell>
                </TableRow>
            </TableHead>

            <TableBody>
                {rows.map((row: PolkSalesAnalysis, index: number) => (
                    <TableRow key={index}>
                        <TablePrimaryCell>
                            {row.dealerName}{' '}
                            {isAdvertiserPolkDealer(row.polkDealer) && (
                                <Typography
                                    component="span"
                                    variant="body2"
                                    sx={{
                                        color: (theme) => theme.palette.text.secondary,
                                        textTransform: 'uppercase',
                                    }}
                                >
                                    (Advertiser)
                                </Typography>
                            )}
                        </TablePrimaryCell>
                        <TableCell>{Utils.formatValue(row.marketSharePercentage, 'percent')}</TableCell>
                        <TableCell>{Utils.formatValue(row.adSharePercentage, 'percent')}</TableCell>
                        <TableCell>{Utils.formatValue(row.totalExposedSales)}</TableCell>
                        <TableCell>{Utils.formatValue(row.totalNonExposedSales)}</TableCell>
                        <TableCell>{Utils.formatValue(row.totalSales)}</TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
}

export default function PolkMatchbackTable() {
    const { polkSalesAnalysis } = usePolkAttribution();

    return (
        <TableContainer
            sx={{
                borderCollapse: 'inherit',
                borderColor: (theme) => theme.palette.grey[300],
                borderStyle: 'solid',
                borderWidth: 1,
                borderRadius: '8px',
                overflow: 'hidden',
            }}
        >
            <SalesAnalysisTable rows={polkSalesAnalysis} />
        </TableContainer>
    );
}
