import { Link, Stack, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';

export default function AppFooter() {
    let currentDate = new Date();
    let currentYear = currentDate.getFullYear();
    const location = useLocation();

    const termsOfServiceLink = (
        <Link href={'https://www.cognitionads.com/terms-of-service'} target="_blank" rel="noreferrer">
            terms of service
        </Link>
    );

    let footer: JSX.Element;

    switch (location.pathname) {
        default:
            footer = (
                <div style={{ paddingTop: 15, paddingBottom: 15 }}>
                    <Stack direction="row" spacing={2}>
                        <Typography variant="body2">
                            &copy; {currentYear} CognitionDigital.io, Inc. By using Cognition's platform you are
                            agreeing to our {termsOfServiceLink}.
                        </Typography>
                    </Stack>
                </div>
            );
            break;
    }

    return <>{footer}</>;
}
