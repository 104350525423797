import { MouseEvent, useCallback, useState } from 'react';
import { Divider, IconButton, Menu, MenuItem } from '@mui/material/';
import { MoreVert as MoreVertIcon } from '@mui/icons-material';
import CRMDataUploadViewDialog from './CRMDataUploadViewDialog';
import { SalesDataUpload } from '../../types/SalesDataUpload';
import ConfirmDialog, { ConfirmDialogProps } from '../../components/ConfirmDialog';
import ApiService from '../../ApiService';

export default function CRMDataUploadResultItemActions(props: {
    salesDataUpload: SalesDataUpload;
    refresh: () => void;
    handleOpenSendToAmcDialog(): void;
}) {
    const { salesDataUpload, refresh, handleOpenSendToAmcDialog } = props;

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const [viewDialogOpen, setViewDialogOpen] = useState<boolean>(false);
    const [deleteConfirmDialogProps, setDeleteConfirmDialogProps] = useState<ConfirmDialogProps | null>(null);

    const handleClick = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDelete = () => {
        ApiService.deleteSalesData(salesDataUpload.id).then(() => {
            refresh();
            handleClose();
        });
    };

    const handleCancelDelete = () => {
        setDeleteConfirmDialogProps(null);
    };

    const showDeleteConfirmDialog = useCallback((_salesDataUpload: SalesDataUpload) => {
        setDeleteConfirmDialogProps({
            title: 'Deletion of Sales Data Upload',
            text: 'Are you sure you want to delete this?',
            onOk: handleDelete,
            okColor: 'error',
            okText: 'Delete',
            onCancel: handleCancelDelete,
            cancelColor: 'inherit',
            cancelIcon: null,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            <IconButton
                aria-label="more"
                id="crm-data-upload-result-item-actions-menu__button"
                aria-controls={open ? 'crm-data-upload-result-item-actions-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                id="crm-data-upload-result-item-actions-menu"
                MenuListProps={{
                    'aria-labelledby': 'crm-data-upload-result-item-actions-menu__button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <MenuItem
                    key="ViewDetails"
                    onClick={() => {
                        handleClose();
                        setViewDialogOpen(true);
                    }}
                >
                    View Details
                </MenuItem>

                <Divider />

                <MenuItem
                    key="Delete"
                    onClick={() => {
                        showDeleteConfirmDialog(salesDataUpload);
                    }}
                    sx={{ color: (theme) => theme.palette.error.main }}
                >
                    Delete
                </MenuItem>
            </Menu>

            {viewDialogOpen && salesDataUpload && (
                <CRMDataUploadViewDialog
                    salesDataUpload={salesDataUpload}
                    open={viewDialogOpen}
                    onClose={() => {
                        setViewDialogOpen(false);
                    }}
                    handleOpenSendToAmcDialog={handleOpenSendToAmcDialog}
                />
            )}

            {deleteConfirmDialogProps && <ConfirmDialog {...deleteConfirmDialogProps} />}
        </div>
    );
}
