import { useState, useEffect } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import { alpha } from '@mui/material';

import { IFilterItem } from './SalesInsights';

export default function CheckboxList({
    hasSearch = false,
    items,
    id,
    setFilters,
    filters,
}: {
    hasSearch?: boolean;
    id: number;
    setFilters: (filters: IFilterItem[]) => void;
    items: IFilterItem[];
    filters: IFilterItem[];
}) {
    const [search, setSearch] = useState('');
    const [searchItems, setSearchItems] = useState(items);
    const filterItem = filters[id];

    useEffect(() => {
        if (search) {
            setSearchItems(items?.filter((item) => item?.label?.toLowerCase()?.includes(search?.toLowerCase())));
        } else {
            setSearchItems(items);
        }
    }, [search, items]);

    const handleToggle = (value: IFilterItem) => () => {
        const isChecked = filterItem?.children?.some((item) => item.id === value.id);
        const newFilters = filters?.map((item, key) => {
            const newItem = item;
            if (key === id) {
                // if checked
                if (isChecked) {
                    newItem.children = newItem.children?.filter((child) => child.id !== value.id);
                } else {
                    newItem.children?.push({ ...value });
                }
            }
            return newItem;
        });
        setFilters(newFilters);
    };

    return (
        <Box>
            {hasSearch && (
                <Box sx={{ px: 2, mb: 1 }}>
                    <TextField
                        variant="outlined"
                        label={'Search ' + filterItem?.label || ''}
                        type="search"
                        fullWidth
                        size="small"
                        value={search}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setSearch(event.target.value);
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment
                                    position="start"
                                    sx={{
                                        color: alpha('#000000', 0.56),
                                    }}
                                >
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>
            )}
            {searchItems && searchItems.length > 0 && (
                <List
                    sx={{
                        width: '100%',
                        maxHeight: '200px',
                        overflowY: 'auto',
                        mt: 1,
                    }}
                >
                    {searchItems?.map((value) => {
                        const labelId = `checkbox-list-label-${value.id}`;

                        return (
                            <ListItem key={value.id} disablePadding>
                                <ListItemButton
                                    role={undefined}
                                    onClick={handleToggle(value)}
                                    dense
                                    sx={{ paddingLeft: 3.5 }}
                                >
                                    <ListItemIcon sx={{ minWidth: 40 }}>
                                        <Checkbox
                                            edge="start"
                                            checked={filterItem?.children?.some((item) => item.id === value.id)}
                                            tabIndex={-1}
                                            inputProps={{ 'aria-labelledby': labelId }}
                                            sx={{
                                                '& .MuiSvgIcon-root': {
                                                    color: 'unset',
                                                },
                                                padding: 0,
                                            }}
                                        />
                                    </ListItemIcon>
                                    <ListItemText id={labelId} primary={value.label} sx={{ color: 'text.secondary' }} />
                                </ListItemButton>
                            </ListItem>
                        );
                    })}
                </List>
            )}
        </Box>
    );
}
