import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button } from '@mui/material';
import { CSVLink } from 'react-csv';
import { Campaign, CampaignCriteria } from '../../types/Campaign';
import moment from 'moment-timezone';
import ApiService from '../../ApiService';
import Utils from '../../components/Utils';

import { useCampaignManagerPageContext } from '../../hooks/useCampaignManagerPage';

type CsvHeader = {
    label: string;
    key: string;
};

export default function CampaignManagerDataGridExportCsvButton(props: { campaignCriteria: CampaignCriteria }) {
    const { campaignCriteria } = props;

    const { campaignGridColumnVisibility } = useCampaignManagerPageContext();

    const [campaigns, setCampaigns] = useState<Campaign[]>([]);
    const csvLink = useRef<any | null>(null);

    function getCsvFilename() {
        let filename = `Cognition Campaign Report`;

        if (campaignCriteria?.startDate) {
            filename += '_' + moment(Utils.getDate(campaignCriteria.startDate)).format('MMMM YYYY');
        }

        filename += '_' + moment().format('YYYYMMDD');
        filename += '.csv';

        return filename;
    }

    const csvFilename = getCsvFilename();

    const csvHeaders = useMemo(() => {
        let _csvHeaders: CsvHeader[] = [
            { label: 'Platform', key: 'platform' },
            { label: 'Agency', key: 'agency' },
            { label: 'User', key: 'createdByUser' },
            { label: 'Create Date', key: 'createdDate' },
            { label: 'Advertiser', key: 'dealerName' },
            { label: 'Campaign', key: 'campaignName' },
            { label: 'Status', key: 'deliveryStatus' },
            { label: 'Start Date', key: 'startDate' },
            { label: 'End Date', key: 'endDate' },
            { label: 'Budget', key: 'budget' },
            { label: 'Impression Goal', key: 'impressionGoal' },
            { label: 'Spend', key: 'totalSpend' },
            { label: 'Impressions', key: 'totalImpressions' },
            { label: 'Impression Pacing', key: 'impressionPacing' },
            { label: 'Budget Pacing', key: 'budgetPacing' },
        ];

        if (campaignGridColumnVisibility) {
            _csvHeaders = _csvHeaders.filter((ch: CsvHeader) => {
                if (ch.key in campaignGridColumnVisibility) {
                    return campaignGridColumnVisibility[ch.key];
                }
                return true;
            });
        }

        return _csvHeaders;
    }, [campaignCriteria, campaignGridColumnVisibility]);

    const getCampaigns = useCallback(() => {
        ApiService.getCampaigns({
            ...props.campaignCriteria,
            ...{
                page: 0,
                pageSize: 10000,
            },
        }).then((response) => {
            setCampaigns(response.data);
        });
    }, [props.campaignCriteria]);

    const getCsvRow = (data: Campaign) => {
        const row: any[] = [];

        csvHeaders.forEach((csvHeader) => {
            const csvHeaderKey = csvHeader.key;

            switch (csvHeaderKey) {
                case 'dealerName':
                case 'dealer.dealerName':
                    row.push(data?.dealer?.dealerName);
                    break;

                case 'agency':
                case 'dealer.agency.name':
                    row.push(data?.dealer?.agency?.name);
                    break;

                case 'createdByUser':
                case 'createdByUser.name':
                    row.push(data?.createdByUser?.name);
                    break;

                case 'deliveryStatus':
                case 'deliveryStatus.externalStatus':
                    row.push(data?.deliveryStatus?.externalStatus);
                    break;

                case 'createdDate':
                    row.push(Utils.formatESTDateLong(data?.createdDate));
                    break;

                case 'startDate':
                    row.push(Utils.formatESTDateLong(data?.startDate));
                    break;

                case 'endDate':
                    row.push(Utils.formatESTDateLong(data?.endDate));
                    break;

                case 'totalSpend':
                    row.push(Utils.formatCurrency(data?.totalSpend, 2));
                    break;

                case 'budget':
                    row.push(Utils.formatCurrency(data?.budget, 0));
                    break;

                case 'budgetPacing':
                    row.push(Utils.round((data?.budgetPacing as number) * 100, 0) + '%');
                    break;

                case 'impressionPacing':
                    row.push(Utils.round((data?.impressionPacing as number) * 100, 0) + '%');
                    break;

                default:
                    if (csvHeaderKey in data) {
                        row.push(data[csvHeaderKey as keyof Campaign]);
                    } else {
                        row.push('');
                    }
                    break;
            }
        });

        return row;
    };

    const csvData = useMemo(() => {
        const data: string[][] = [];

        if (campaigns.length) {
            campaigns.forEach((campaign) => {
                data.push(getCsvRow(campaign));
            });
        }

        return data;
    }, [getCsvRow, csvHeaders, campaigns]);

    useEffect(() => {
        if (csvData && csvData.length > 0) {
            if (csvLink && csvLink.current && csvLink.current.link) {
                csvLink.current.link.click();
                setCampaigns([]);
            }
        }
    }, [csvData]);

    return (
        <>
            <Button
                variant="outlined"
                onClick={(event: any) => {
                    getCampaigns();
                }}
            >
                Download Report
            </Button>

            <CSVLink
                ref={csvLink}
                headers={csvHeaders.map((_csvHeader) => _csvHeader.label)}
                data={csvData}
                filename={csvFilename}
                asyncOnClick={true}
            />
        </>
    );
}
