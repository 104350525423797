import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider } from '@mui/material';
import Row from '../../../components/Row';
import { CDTextField } from '../../../components/CDTextField';
import { CancelOutlined, SaveOutlined } from '@mui/icons-material';
import { Campaign } from '../../../types/Campaign';
import { useState } from 'react';
import { CDDefaultButton } from '../../../components/CDButton';

export default function EditImpressionGoalDialog(props: {
    campaign: Campaign;
    onClose(impressionGOal: number | null): void;
}) {
    const { campaign, onClose } = props;
    const [impressionGoal, setImpressionGoal] = useState(campaign.impressionGoal);

    return (
        <Dialog maxWidth="xs" open={true} fullWidth onClose={() => onClose(null)}>
            <DialogTitle>
                Set Impression Goal
                <Divider />
            </DialogTitle>
            <DialogContent>
                <Row>
                    <CDTextField
                        label="Impression Goal"
                        value={impressionGoal}
                        onChange={(event) => {
                            if (!isNaN(Number(event.target.value))) {
                                setImpressionGoal(Number(event.target.value));
                            }
                        }}
                        shrinkLabel
                        sx={{ marginTop: '10px' }}
                    />
                </Row>
            </DialogContent>
            <DialogActions>
                <Row>
                    <div style={{ flex: 1 }} />
                    <Button
                        variant="outlined"
                        color="error"
                        startIcon={<CancelOutlined />}
                        onClick={() => {
                            onClose(null);
                        }}
                    >
                        Cancel
                    </Button>
                    <CDDefaultButton
                        disabled={impressionGoal === null}
                        onClick={() => {
                            onClose(impressionGoal);
                        }}
                        startIcon={<SaveOutlined />}
                    >
                        Save
                    </CDDefaultButton>
                </Row>
            </DialogActions>
        </Dialog>
    );
}
