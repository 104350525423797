import Heading from '../../components/Heading';
import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { FormControlLabel, IconButton, InputAdornment, Radio, RadioGroup, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { AddOutlined, ClearOutlined, SearchOutlined } from '@mui/icons-material';
import { Dealer } from '../../types/Dealer';
import ApiService from '../../ApiService';
import Utils from '../../components/Utils';
import CDGrid from '../../components/CDGrid';
import { UserContext } from '../../App';
import Row from '../../components/Row';
import { LinkButton } from '../../components/LinkButton';
import ActiveStatus from '../../components/ActiveStatus';
import AppBreadcrumbs from '../../components/AppBreadcrumbs';
import Column from '../../components/Column';

export default function DealerListPage() {
    const [dealers, setDealers] = useState<Dealer[] | null>(null);
    const [allDealers, setAllDealers] = useState<Dealer[]>([]);
    const [searchText, setSearchText] = useState<string>(
        // Use a callback function so the local storage call only runs once.
        () => Utils.localStorage('dealerSearch', '')
    );
    const navigate = useNavigate();
    const [page, setPage] = useState(
        // Use a callback function so the local storage call only runs once.
        () => Number(localStorage.getItem('dealerGridPage'))
    );
    const { userContext } = useContext(UserContext);
    const [activeStatusFilter, setActiveStatusFilter] = useState('active');

    useEffect(() => {
        const initialSearchText = Utils.localStorage('dealerSearch', '');
        ApiService.getDealers()
            .then(function (response) {
                setAllDealers(response.data);
                setDealers(response.data);
                filterRows(initialSearchText, 'active', response.data);
            })
            .catch(() => {
                setDealers([]);
            });
    }, []);

    const handleStatusChange = (event: ChangeEvent<HTMLInputElement>) => {
        const activeStatus = event.target.value;
        setActiveStatusFilter(activeStatus);
        filterRows(searchText, activeStatus, allDealers);
    };

    function clearSearch() {
        setSearchText('');
        filterRows('', activeStatusFilter, allDealers);
        localStorage.setItem('dealerSearch', '');
    }

    function search(event: ChangeEvent<HTMLInputElement>) {
        event.preventDefault();
        setSearchText(event.target.value);
        filterRows(event.target.value, activeStatusFilter, allDealers);
        localStorage.setItem('dealerSearch', event.target.value);
    }

    function filterRows(searchText: string, activeStatus: string, allDealers: Dealer[]) {
        const filteredRows = allDealers.filter((row: Dealer) => {
            let filterMatch =
                row.dealerName.toLowerCase().indexOf(searchText.toLowerCase()) > -1 ||
                (row.agency && row.agency.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1) ||
                (row.publicId && row.publicId.toLowerCase().indexOf(searchText.toLowerCase()) > -1);
            filterMatch = filterMatch && (activeStatus === 'active' ? row.activeStatus : true);
            return filterMatch;
        });
        setDealers(filteredRows);
    }

    function onPageChange(page: number) {
        setPage(page);
        localStorage.setItem('dealerGridPage', String(page));
    }

    function CustomToolbar() {
        return (
            <Row justifyContent="space-between" sx={{ marginBottom: '16px' }}>
                <TextField
                    inputRef={(input) => input && input.focus()}
                    id="searchText"
                    value={searchText}
                    size="small"
                    label="Search"
                    onChange={search}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchOutlined fontSize="small" />
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={() => clearSearch()} edge="end">
                                    <ClearOutlined fontSize="small" />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />

                <RadioGroup
                    aria-label="status"
                    name="status"
                    value={activeStatusFilter}
                    onChange={handleStatusChange}
                    row
                >
                    <FormControlLabel value="active" control={<Radio />} label="Active" />
                    <FormControlLabel value="all" control={<Radio />} label="All" />
                </RadioGroup>

                <LinkButton href="new" variant="contained" color="primary" startIcon={<AddOutlined />}>
                    New Advertiser
                </LinkButton>
            </Row>
        );
    }

    return (
        <Column sx={{ height: '100%' }}>
            <Column gap={1}>
                <AppBreadcrumbs items={[]} />

                <Heading>Advertisers</Heading>
            </Column>

            <Column gap={2} sx={{ height: '100%' }}>
                <CustomToolbar />

                <CDGrid
                    id="advertiserList"
                    page={page}
                    columns={[
                        {
                            minWidth: 160,
                            flex: 2,
                            field: 'agency',
                            headerName: 'Agency',
                            hide: !userContext.isAdmin(),
                            valueGetter: (params) => {
                                return params.value?.name;
                            },
                        },
                        {
                            width: 120,
                            field: 'logo',
                            flex: 1,
                            headerName: 'Logo',
                            headerAlign: 'center',
                            align: 'center',
                            renderCell: (params) => (
                                <img alt="" style={{ width: '100px' }} src={params.row.logoUrl}></img>
                            ),
                        },
                        {
                            width: 200,
                            field: 'dealerName',
                            headerName: 'Advertiser Name',
                            flex: 2,
                        },
                        {
                            width: 170,
                            field: 'city',
                            headerName: 'Location',
                            valueGetter: (params) => {
                                return params.value ? params.value + ', ' + params.row.state : '';
                            },
                        },
                        { width: 170, field: 'publicId', headerName: 'Public Id' },
                        {
                            width: 100,
                            field: 'activeStatus',
                            headerName: 'Status',
                            headerClassName: 'grid-header',
                            renderCell: (params) => {
                                return <ActiveStatus activeStatus={params.value} />;
                            },
                        },
                    ]}
                    onRowClick={(params) => {
                        navigate('' + params.row.id);
                    }}
                    rows={dealers === null ? [] : dealers}
                    loading={dealers === null}
                    autoHeight={false}
                    autoPageSize={true}
                    onPageChange={onPageChange}
                />
            </Column>
        </Column>
    );
}
