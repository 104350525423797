import { Grid } from '@mui/material';

import Column from '../../components/Column';
import theme from '../../theme';
import { useAttributionPageContext } from '../../hooks/useAttributionPage';

import AdvertiserAverageCPMBasicBarChartCard from './AdvertiserAverageCPMBasicBarChartCard';
import AdvertiserInvestmentPieChartCard from './AdvertiserInvestmentPieChartCard';
import AdvertiserPerformanceGroupedBarChartCard from './AdvertiserPerformanceGroupedBarChartCard';

export default function AdvertiserMatchbackCampaignChartView() {
    const { isFetchingPerformances } = useAttributionPageContext();

    return (
        <Column gap={3}>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <AdvertiserInvestmentPieChartCard loading={isFetchingPerformances} />
                </Grid>

                <Grid item xs={6}>
                    <AdvertiserAverageCPMBasicBarChartCard loading={isFetchingPerformances} />
                </Grid>
            </Grid>

            <Grid item xs={12} sx={{ mt: theme.spacing(1) }}>
                <AdvertiserPerformanceGroupedBarChartCard loading={isFetchingPerformances} />
            </Grid>
        </Column>
    );
}
