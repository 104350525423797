import { Performance } from '../types/CampaignPerformance';

export function calculatePerformanceValue(performance: Performance, type: string) {
    let value: number = 0;

    switch (type) {
        case 'eCPCV':
            if (performance.videoComplete > 0) {
                value = performance.totalCost / performance.videoComplete;
            }
            break;
    }

    return value;
}

export function isPerformanceMediaType(performance: Performance, mediaType: string): boolean {
    if (performance.mediaType === mediaType) {
        return true;
    }

    switch (mediaType) {
        case 'Streaming TV':
            return performance.campaignName.includes('STV') || performance.campaignName.includes('OTT');

        case 'Online Video':
            return performance.campaignName.includes('OLV');
    }

    return false;
}

export function getUniquePerformanceMediaTypes(performances: Performance[]): string[] {
    const mediaTypes: string[] = [];

    if (performances.some((performance: Performance) => isPerformanceMediaType(performance, 'Display'))) {
        mediaTypes.push('Display');
    }

    if (performances.some((performance: Performance) => isPerformanceMediaType(performance, 'Video'))) {
        mediaTypes.push('Video');
    }

    if (performances.some((performance: Performance) => isPerformanceMediaType(performance, 'Online Video'))) {
        mediaTypes.push('Online Video');
    }

    if (performances.some((performance: Performance) => isPerformanceMediaType(performance, 'Streaming TV'))) {
        mediaTypes.push('Streaming TV');
    }

    if (performances.some((performance: Performance) => isPerformanceMediaType(performance, 'Audio'))) {
        mediaTypes.push('Audio');
    }

    return mediaTypes;
}

export function getUniquePerformanceStrategyTypes(performances: Performance[]): string[] {
    return [...new Set(performances.map((performance: Performance) => performance.strategyType))];
}
