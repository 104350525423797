import { createContext, useContext, useState } from 'react';

import { CampaignChangeRequest } from '../types/Campaign';

export class CampaignDetailsPageContextValue {
    campaignChangeRequest: CampaignChangeRequest | null = null;
    setCampaignChangeRequest: Function = (campaignChangeRequest: CampaignChangeRequest | null) => {};
}

export const CampaignDetailsPageContext = createContext<CampaignDetailsPageContextValue>(
    new CampaignDetailsPageContextValue()
);

export function useCampaignDetailsPageContext() {
    return useContext(CampaignDetailsPageContext);
}

export type CampaignDetailsPageProps = {};

export default function useCampaignDetailsPage() {
    const [campaignChangeRequest, setCampaignChangeRequest] = useState<CampaignChangeRequest | null>(null);

    return {
        campaignChangeRequest,
        setCampaignChangeRequest,
    };
}
