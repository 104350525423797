import { useEffect, useMemo, useState } from 'react';
import { Typography } from '@mui/material';
import { ApexOptions } from 'apexcharts';

import { Performance } from '../../../../../types/CampaignPerformance';
import Row from '../../../../../components/Row';
import Utils from '../../../../../components/Utils';

import { useReportingDashboardPageContext } from '../../../../../hooks/useReportingDashboardPage';
import GroupedBarChartCard from '../../../../../components/Metrics/GroupedBarChartCard';

type PerformanceByStrategyTypeGroupedBarChartCardProps = {
    loading?: boolean;
};

export default function PerformanceByStrategyTypeGroupedBarChartCard(
    props: PerformanceByStrategyTypeGroupedBarChartCardProps
) {
    const { loading = false } = props;
    const { performances } = useReportingDashboardPageContext();

    const [strategyType, setStrategyType] = useState<string>(() => {
        return Utils.localStorage('analytics.reporting.performanceByStrategyTypeGroupedBarChart', 'Acquisition');
    });

    const strategyTypes = useMemo((): string[] => {
        return ['Acquisition', 'Awareness', 'Inventory', 'FixedOps', 'Parts'];
    }, []);

    const filteredStrategyTypes = useMemo(() => {
        return strategyTypes.filter((_strategyType: string, _index: number) => {
            const total: number = performances
                .filter((p: Performance) => p.strategyType === _strategyType)
                .map((p: Performance) => {
                    const total: number = p?.impressions + p?.dailyReach + p?.clickthroughs + p?.offAmazonConversions;
                    return total;
                })
                .reduce((total: number, value: number) => total + value, 0);

            return total > 0;
        });
    }, [performances, strategyTypes]);

    const seriesData = useMemo(() => {
        if (performances && performances.length) {
            return [
                {
                    name: 'Exposures',
                    data: filteredStrategyTypes.map((s: string) => {
                        return performances
                            .filter((p: Performance) => p.strategyType === s)
                            .map((p: Performance) => p?.impressions)
                            .reduce((total: number, value: number) => total + value, 0);
                    }),
                },
                {
                    name: 'Household Reach',
                    data: filteredStrategyTypes.map((s: string) => {
                        return performances
                            .filter((p: Performance) => p.strategyType === s)
                            .map((p: Performance) => p?.dailyReach)
                            .reduce((total: number, value: number) => total + value, 0);
                    }),
                },
                {
                    name: 'Clicks',
                    data: filteredStrategyTypes.map((s: string) => {
                        return performances
                            .filter((p: Performance) => p.strategyType === s)
                            .map((p: Performance) => p?.clickthroughs)
                            .reduce((total: number, value: number) => total + value, 0);
                    }),
                },
                {
                    name: 'Conversions',
                    data: filteredStrategyTypes.map((s: string) => {
                        return performances
                            .filter((p: Performance) => p.strategyType === s)
                            .map((p: Performance) => p?.offAmazonConversions)
                            .reduce((total: number, value: number) => total + value, 0);
                    }),
                },
            ];
        }

        return [];
    }, [performances, filteredStrategyTypes]);

    const seriesDataLabels = useMemo(() => {
        return filteredStrategyTypes;
    }, [filteredStrategyTypes]);

    const seriesOptions = useMemo((): ApexOptions => {
        return {
            legend: {
                offsetY: 12,
                position: 'top',
            },
            xaxis: {
                categories: seriesDataLabels,
            },
        };
    }, [seriesDataLabels]);

    useEffect(() => {
        localStorage.setItem('analytics.reporting.performanceByStrategyTypeGroupedBarChart', strategyType);
    }, [strategyType]);

    useEffect(() => {
        if (strategyTypes && strategyTypes.length) {
            if (!strategyTypes.includes(strategyType)) {
                setStrategyType(strategyTypes[0]);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [strategyTypes]);

    // useEffect(() => {
    //     if (strategyType) {
    //         setPerformances(getPerformances(strategyType));
    //     } else {
    //         setPerformances([]);
    //     }
    // }, [getPerformances, strategyType]);

    return (
        <GroupedBarChartCard
            loading={loading}
            label={
                <Row gap={1} alignItems="center" sx={{ mr: 0.5 }} width="auto">
                    <Typography variant="body1" color="text.primary">
                        Performance Metrics by Campaign Type
                    </Typography>
                </Row>
            }
            tooltip="An event as defined by advertiser, by Campaign Type."
            chartOptions={seriesOptions}
            chartSeries={seriesData}
        />
    );
}
