import { Autocomplete, Paper, TextFieldProps } from '@mui/material';
import { useEffect, useState } from 'react';
import ApiService from '../ApiService';
import { Agency } from '../types/Agency';
import { getStyles } from './ToolbarStyles';
import { CDTextField } from './CDTextField';

export interface AgencySelectorProps {
    agencyId?: number | null | undefined;
    agencySelected(value: number | null): void;
    userMessage?: boolean;
    label?: string;
    isHeader?: boolean;
    required?: boolean;
    variant?: TextFieldProps['variant'];
    placeholder?: TextFieldProps['placeholder'];
}

export default function AgencySelector(props: AgencySelectorProps) {
    const [agencies, setAgencies] = useState<Agency[]>([]);
    const [agency, setAgency] = useState<Agency | null>(null);

    useEffect(() => {
        ApiService.getAgencies()
            .then((response) => {
                const activeAgencies = response.data.filter((agency: { activeStatus: boolean }) => agency.activeStatus);
                setAgencies(activeAgencies);
            })
            .catch();
    }, []);

    useEffect(() => {
        if (props.agencyId) {
            let selectedAgency = agencies.find((d) => {
                return d.id === props.agencyId;
            });
            setAgency(selectedAgency as Agency);
        }
    }, [props.agencyId, agencies]);

    function handleChange(_event: any, newValue: Agency | null) {
        if (newValue) {
            props.agencySelected(Number(newValue.id));
            setAgency(newValue);
        } else {
            props.agencySelected(null);
            setAgency(null);
        }
    }

    const sxProps = getStyles(props.isHeader);

    return (
        <Autocomplete
            sx={sxProps}
            options={agencies}
            getOptionLabel={(option) => option.name}
            size="small"
            fullWidth={false}
            value={agency}
            onChange={(event: any, newValue: Agency | null) => {
                handleChange(event, newValue);
            }}
            renderInput={(params) => (
                <CDTextField
                    required={props.required || props.userMessage}
                    variant={props.variant ? props.variant : 'outlined'}
                    shrinkLabel={false}
                    color={props.isHeader ? 'secondary' : 'primary'}
                    helperText={
                        props.userMessage
                            ? '* User will inherit access to all Advertisers that belong to this Agency'
                            : ''
                    }
                    {...params}
                    label={props.label ? props.label : 'Select an Agency'}
                    placeholder={props.placeholder}
                />
            )}
            PaperComponent={(props) => (
                <Paper
                    {...props}
                    sx={{
                        boxShadow: 8,
                        borderRadius: '8px',
                    }}
                />
            )}
        />
    );
}
