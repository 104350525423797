import { OemOffer } from '../../types/OemOffer';
import { Button, Dialog, DialogActions, DialogContent, Divider, Grid } from '@mui/material';
import Row from '../../components/Row';
import OfferMediaLink from './OfferMediaLink';

export default function OemOfferDetails(props: { oemOffer: OemOffer; onClose(): void }) {
    const { oemOffer, onClose } = props;

    return (
        <>
            <Dialog maxWidth="lg" fullWidth={true} open={oemOffer != null} onClose={onClose}>
                <DialogContent>
                    <Grid container spacing={0}>
                        <Grid item xs={2}>
                            ID
                        </Grid>
                        <Grid item xs={10}>
                            {oemOffer.id}
                        </Grid>
                        <Grid item xs={2}>
                            Cartender ID
                        </Grid>
                        <Grid item xs={10}>
                            {oemOffer.ctId}
                        </Grid>
                        <Grid item xs={2}>
                            PureCars Advertiser
                        </Grid>
                        <Grid item xs={10}>
                            {oemOffer.pcAdvertiserName}
                        </Grid>
                        <Grid item xs={2}>
                            Cognition Advertiser
                        </Grid>
                        <Grid item xs={10}>
                            {oemOffer.dealer?.dealerName}
                        </Grid>

                        <Grid item xs={2}>
                            OutputName
                        </Grid>
                        <Grid item xs={10}>
                            {oemOffer.pcOutputName}
                        </Grid>

                        <Grid item xs={2}>
                            Video
                        </Grid>
                        <Grid item xs={10}>
                            <OfferMediaLink fullName offerMedia={oemOffer.pcVideo} />
                        </Grid>
                        <Grid item xs={2}>
                            Video Length
                        </Grid>
                        <Grid item xs={10}>
                            {oemOffer.pcVideoLength}
                        </Grid>
                        <Grid item xs={2}>
                            End Card
                        </Grid>
                        <Grid item xs={10}>
                            {oemOffer.pcEndcardTimestamp}
                        </Grid>
                        <Grid item xs={2}>
                            Audio
                        </Grid>
                        <Grid item xs={10}>
                            <OfferMediaLink fullName offerMedia={oemOffer.pcAudio} />
                        </Grid>
                        <Grid item xs={2}>
                            Vehicle Image{' '}
                        </Grid>
                        <Grid item xs={10}>
                            <OfferMediaLink fullName offerMedia={oemOffer.pcVehicleImage} />
                        </Grid>
                        <Grid item xs={2}>
                            Dealership Logo
                        </Grid>
                        <Grid item xs={10}>
                            <OfferMediaLink fullName offerMedia={oemOffer.pcDealershipLogo} />
                        </Grid>
                        <Grid item xs={2}>
                            Sales Logo
                        </Grid>
                        <Grid item xs={10}>
                            <OfferMediaLink fullName offerMedia={oemOffer.pcSalesLogo} />
                        </Grid>

                        <Grid item xs={12}>
                            <Divider sx={{ margin: 1 }} />
                        </Grid>

                        <Grid item xs={2}>
                            Offer Type 1
                        </Grid>
                        <Grid item xs={10}>
                            {oemOffer.pcOfferType1}
                        </Grid>
                        <Grid item xs={2}>
                            Offer Header 1
                        </Grid>
                        <Grid item xs={10}>
                            {oemOffer.pcOfferHeader1}
                        </Grid>
                        <Grid item xs={2}>
                            Offer Value Prefix 1
                        </Grid>
                        <Grid item xs={10}>
                            {oemOffer.pcOfferValuePrefix1}
                        </Grid>
                        <Grid item xs={2}>
                            Offer Value 1
                        </Grid>
                        <Grid item xs={10}>
                            {oemOffer.pcOfferValue1}
                        </Grid>
                        <Grid item xs={2}>
                            Offer Terms 1
                        </Grid>
                        <Grid item xs={10}>
                            {oemOffer.pcOfferTerms1}
                        </Grid>
                        <Grid item xs={2}>
                            Conjuntion 1
                        </Grid>
                        <Grid item xs={10}>
                            {oemOffer.pcConjunction1}
                        </Grid>

                        <Grid item xs={12}>
                            <Divider sx={{ margin: 1 }} />
                        </Grid>
                        <Grid item xs={2}>
                            Offer Type 2
                        </Grid>
                        <Grid item xs={10}>
                            {oemOffer.pcOfferType2}
                        </Grid>
                        <Grid item xs={2}>
                            Offer Header 2
                        </Grid>
                        <Grid item xs={10}>
                            {oemOffer.pcOfferHeader2}
                        </Grid>
                        <Grid item xs={2}>
                            Offer Value Prefix 2
                        </Grid>
                        <Grid item xs={10}>
                            {oemOffer.pcOfferValuePrefix2}
                        </Grid>
                        <Grid item xs={2}>
                            Offer Value 2
                        </Grid>
                        <Grid item xs={10}>
                            {oemOffer.pcOfferValue2}
                        </Grid>
                        <Grid item xs={2}>
                            Offer Terms 2
                        </Grid>
                        <Grid item xs={10}>
                            {oemOffer.pcOfferTerms2}
                        </Grid>
                        <Grid item xs={2}>
                            Conjuntion 2
                        </Grid>
                        <Grid item xs={10}>
                            {oemOffer.pcConjunction2}
                        </Grid>

                        <Grid item xs={12}>
                            <Divider sx={{ margin: 1 }} />
                        </Grid>

                        <Grid item xs={2}>
                            Offer Type 3
                        </Grid>
                        <Grid item xs={10}>
                            {oemOffer.pcOfferType3}
                        </Grid>
                        <Grid item xs={2}>
                            Offer Header 3
                        </Grid>
                        <Grid item xs={10}>
                            {oemOffer.pcOfferHeader3}
                        </Grid>
                        <Grid item xs={2}>
                            Offer Value Prefix 3
                        </Grid>
                        <Grid item xs={10}>
                            {oemOffer.pcOfferValuePrefix3}
                        </Grid>
                        <Grid item xs={2}>
                            Offer Value 3
                        </Grid>
                        <Grid item xs={10}>
                            {oemOffer.pcOfferValue3}
                        </Grid>
                        <Grid item xs={2}>
                            Offer Terms 3
                        </Grid>
                        <Grid item xs={10}>
                            {oemOffer.pcOfferTerms3}
                        </Grid>
                        <Grid item xs={12}>
                            <Divider sx={{ margin: 1 }} />
                        </Grid>

                        <Grid item xs={2}>
                            Offer Disclaimer
                        </Grid>
                        <Grid item xs={10}>
                            {oemOffer.pcOfferDisclaimer}
                        </Grid>

                        <Grid item xs={2}>
                            Vehicle Line 1
                        </Grid>
                        <Grid item xs={10}>
                            {oemOffer.pcVehicleLine1}
                        </Grid>
                        <Grid item xs={2}>
                            Vehicle Line 2
                        </Grid>
                        <Grid item xs={10}>
                            {oemOffer.pcVehicleLine2}
                        </Grid>
                        <Grid item xs={2}>
                            Vehicle Line 3
                        </Grid>
                        <Grid item xs={10}>
                            {oemOffer.pcVehicleLine3}
                        </Grid>

                        <Grid item xs={2}>
                            Dealership Address
                        </Grid>
                        <Grid item xs={10}>
                            {oemOffer.pcDealershipAddress}
                        </Grid>
                        <Grid item xs={2}>
                            Dealership Info
                        </Grid>
                        <Grid item xs={10}>
                            {oemOffer.pcDealershipInfo}
                        </Grid>

                        <Grid item xs={2}>
                            No Offer Headline
                        </Grid>
                        <Grid item xs={10}>
                            {oemOffer.pcNoOfferHeadline}
                        </Grid>
                        <Grid item xs={2}>
                            No Offer Text
                        </Grid>
                        <Grid item xs={10}>
                            {oemOffer.pcNoOfferText}
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Row>
                        <div style={{ flex: 1 }} />
                        <Button variant="outlined" color="inherit" onClick={() => onClose()}>
                            Close
                        </Button>
                    </Row>
                </DialogActions>
            </Dialog>
        </>
    );
}
