import { useContext, useEffect, useRef, useState } from 'react';
import { Alert, Skeleton } from '@mui/material';
import ApiService from '../ApiService';
import { createEmbeddingContext } from 'amazon-quicksight-embedding-sdk';
import { DashboardFrame, EmbeddingContext } from 'amazon-quicksight-embedding-sdk/dist/types';
import { AdvertiserContext } from '../AdvertiserContext';

export interface QuickSightDashboardProps {
    dashboardId: string;
}

export default function QuickSightDashboard(props: QuickSightDashboardProps) {
    const [loading, setLoading] = useState(true);
    const [embeddingContext, setEmbeddingContext] = useState<EmbeddingContext>();
    const [key, setKey] = useState(0);
    const [error, setError] = useState<string | null>(null);
    const dashboard = useRef<DashboardFrame>();
    const { advertiserContext } = useContext(AdvertiserContext);

    useEffect(() => {
        createEmbeddingContext().then((context) => {
            setEmbeddingContext(context);
        });
    }, [advertiserContext]);

    useEffect(() => {
        if (!embeddingContext) {
            return;
        }

        setLoading(true);
        setKey((k) => k + 1);
        ApiService.getQuickSightDashboardUrl(props.dashboardId)
            .then((response) => {
                const containerDiv = document.getElementById('dashboardContainer') as HTMLDivElement;

                const advertiserParm =
                    advertiserContext.dealer && advertiserContext.dealer?.dealerName
                        ? advertiserContext.dealer.dealerName
                        : '[ALL]';
                const options = {
                    url: response.data,
                    container: containerDiv,
                    height: '900px',
                    width: '100%',
                };
                return embeddingContext
                    .embedDashboard(options, {
                        parameters: [{ Name: 'DealerName', Values: [advertiserParm] }],
                        toolbarOptions: {
                            export: true,
                            undoRedo: true,
                            reset: true,
                        },
                    })
                    .then((db) => {
                        dashboard.current = db;
                    });
            })
            .catch((error) => {
                console.log(error);
                console.log(error?.response?.data);
                setError('You do not have permission to view the Dashboard');
            })
            .finally(() => {
                setLoading(false);
            });
    }, [embeddingContext, props.dashboardId]);

    let loadMessage;
    if (loading) {
        loadMessage = <Skeleton animation="wave" variant="rectangular" height="85vh" />;
    }
    return (
        <>
            {loadMessage}
            {error != null && <Alert severity="error">{error}</Alert>}
            <div key={key} id="dashboardContainer"></div>
        </>
    );
}
