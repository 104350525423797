import { Alert, IconButton, Table, TableCell, TableRow, Tooltip } from '@mui/material';
import theme from '../../../theme';
import Row from '../../../components/Row';
import CDButton from '../../../components/CDButton';
import { Link, useNavigate } from 'react-router-dom';
import CampaignStatusComponent from '../CampaignStatusComponent';
import Utils from '../../../components/Utils';
import { EditOutlined, RemoveRedEyeOutlined } from '@mui/icons-material';
import { Campaign } from '../../../types/Campaign';
import CampaignSummaryDialog from './CampaignSummaryDialog';
import { useState } from 'react';
import Column from '../../../components/Column';

export default function DuplicateCampaignsComponent(props: {
    duplicateCampaigns: Campaign[];
    clearDuplicates(): void;
    showError: boolean;
    ignoreDuplicates: boolean;
    allowModify: boolean;
}) {
    const { duplicateCampaigns, showError, ignoreDuplicates, clearDuplicates, allowModify } = props;
    const [viewDuplicate, setViewDuplicate] = useState<Campaign | null>(null);

    const navigate = useNavigate();

    function modifyCampaign(campaignId: number) {
        clearDuplicates();
        setViewDuplicate(null);
        navigate('/campaign/' + campaignId, {
            state: {
                isChangeRequest: true,
                campaignId: campaignId,
            },
        });
    }

    return (
        <>
            {viewDuplicate && (
                <CampaignSummaryDialog
                    campaign={viewDuplicate}
                    allowModify={allowModify}
                    onModify={() => {
                        if (viewDuplicate.id) {
                            modifyCampaign(viewDuplicate?.id);
                        }
                    }}
                    onClose={() => {
                        setViewDuplicate(null);
                    }}
                />
            )}

            {duplicateCampaigns.length > 0 && !ignoreDuplicates && (
                <>
                    <Column>
                        <Alert severity={showError ? 'error' : 'warning'}>
                            <b>Duplicate Alert</b>
                            <br /> We've found possible duplicate Campaigns. We recommend a review before proceeding to
                            create a new campaign.
                        </Alert>

                        <Table
                            sx={{
                                '& .MuiTableCell-root': {
                                    paddingTop: '4px',
                                    paddingBottom: '4px',
                                    borderBottom: '1px solid ' + theme.palette.divider,
                                },
                            }}
                        >
                            <TableRow
                                sx={{
                                    '& .MuiTableCell-root': {
                                        paddingBottom: '0px',
                                    },
                                }}
                            >
                                <TableCell colSpan={5} sx={{ padding: '0px' }}>
                                    <Row
                                        gap={1}
                                        alignItems="center"
                                        sx={{
                                            borderTopLeftRadius: '8px',
                                            borderTopRightRadius: '8px',
                                            backgroundColor: theme.palette.primary.main,
                                            color: theme.palette.primary.contrastText,
                                            padding: '8px',
                                            margin: 0,
                                        }}
                                    >
                                        <span style={{ paddingLeft: '8px' }}>Possible Duplicates</span>
                                        <div style={{ flex: 1 }} />
                                        {allowModify && (
                                            <Tooltip title="Continue with New Campaign creation">
                                                <CDButton
                                                    variant="outlined"
                                                    color="secondary"
                                                    sx={{ marginRight: '16px' }}
                                                    onClick={() => {
                                                        clearDuplicates();
                                                    }}
                                                >
                                                    Ignore & Create New
                                                </CDButton>
                                            </Tooltip>
                                        )}
                                    </Row>
                                </TableCell>
                            </TableRow>

                            {duplicateCampaigns.map((duplicate) => (
                                <TableRow
                                    sx={{
                                        border: '1px solid',
                                        borderColor: theme.palette.divider,
                                        borderBottom: '1px solid',
                                    }}
                                >
                                    <TableCell sx={{ paddingTop: '4px', marginLeft: '4px' }}>
                                        <Link
                                            to={'/campaign/' + duplicate.id}
                                            state={{
                                                isChangeRequest: true,
                                                campaignId: duplicate.id,
                                            }}
                                        >
                                            {duplicate.campaignName}
                                        </Link>
                                    </TableCell>
                                    <TableCell>
                                        <CampaignStatusComponent deliveryStatus={duplicate.deliveryStatus} />
                                    </TableCell>
                                    <TableCell>{Utils.formatCurrency(duplicate.budget)}</TableCell>
                                    <TableCell>
                                        {Utils.formatESTDateLong(duplicate.startDate) +
                                            ' - ' +
                                            Utils.formatESTDateLong(duplicate.endDate)}
                                    </TableCell>
                                    <TableCell>
                                        <Tooltip title="View Campaign Summary">
                                            <IconButton
                                                onClick={() => {
                                                    setViewDuplicate(duplicate);
                                                }}
                                            >
                                                <RemoveRedEyeOutlined fontSize="small" />
                                            </IconButton>
                                        </Tooltip>
                                        {allowModify && (
                                            <Tooltip title="Modify this Campaign">
                                                <IconButton
                                                    onClick={() => {
                                                        clearDuplicates();
                                                        navigate('/campaign/' + duplicate.id, {
                                                            state: {
                                                                isChangeRequest: true,
                                                                campaignId: duplicate.id,
                                                            },
                                                        });
                                                    }}
                                                >
                                                    <EditOutlined fontSize="small" />
                                                </IconButton>
                                            </Tooltip>
                                        )}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </Table>
                    </Column>
                </>
            )}
        </>
    );
}
