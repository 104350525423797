import { useEffect, useState } from 'react';
import { Box } from '@mui/material';

import AppBreadcrumbs from '../../components/AppBreadcrumbs';
import Column from '../../components/Column';
import Heading from '../../components/Heading';
import SelectAdvertiser from './SelectAdvertiser';
import SelectAdGroup from './SelectAdGroup';
import SelectSize from './SelectSize';
import AdTagPreviewSection from './adTagPreviewSection';
import SelectMonth from './SelectMonth';
import SelectAgency from './SelectAgency';
import { Dealer } from '../../types/Dealer';
import { CampaignAdGroup } from '../../types/Campaign';
import { Agency } from '../../types/Agency';

const AdTagPreviewPage = () => {
    const [agency, setAgency] = useState<Agency | null>(null);
    const [advertiser, setAdvertiser] = useState<Dealer | null>(null);
    const [yearMonth, setYearMonth] = useState<string>('');
    const [adGroup, setAdGroup] = useState<CampaignAdGroup | null>(null);
    const [size, setSize] = useState<string>('All');

    useEffect(() => {
        if (advertiser && advertiser.ftAdvertiserId && adGroup && adGroup.dspAdGroupId && yearMonth) {
            const advertiserID = advertiser.ftAdvertiserId;
            const lineItemID = adGroup.dspAdGroupId;

            const adTags = document.querySelectorAll('.ad-tag');

            adTags.forEach((adTag) => {
                let src = adTag.getAttribute('data-src');
                let href = adTag.getAttribute('data-href');
                let imgSrc = adTag.getAttribute('data-img-src');
                if (!src || !href || !imgSrc) return;

                src = src
                    .replace('[%tp_AdvertiserID%]', advertiserID)
                    .replace('[%tp_LineID%]', lineItemID)
                    .replace('[%tp_AdvertiserID%]', advertiserID);
                href = href
                    .replace('[%tp_AdvertiserID%]', advertiserID)
                    .replace('[%tp_LineID%]', lineItemID)
                    .replace('[%tp_AdvertiserID%]', advertiserID);
                imgSrc = imgSrc
                    .replace('[%tp_AdvertiserID%]', advertiserID)
                    .replace('[%tp_LineID%]', lineItemID)
                    .replace('[%tp_AdvertiserID%]', advertiserID);

                adTag.innerHTML = `
                        <iframe src="${src}" allowFullScreen="true" webkitallowfullscreen="true" mozallowfullscreen="true" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" topmargin="0" leftmargin="0" allowtransparency="true" width="${adTag.getAttribute(
                    'data-width'
                )}" height="${adTag.getAttribute('data-height')}">
                            <a href="${href}" target="_blank">
                                <img border="0" src="${imgSrc}">
                            </a>
                        </iframe>
                    `;
            });
        }
    }, [advertiser, yearMonth, adGroup]);

    return (
        <Column sx={{ height: '100%' }}>
            <Column gap={1}>
                <AppBreadcrumbs items={[]} />

                <Heading>Flashtalking Ad Tag Preview</Heading>
            </Column>

            <Column gap={2} sx={{ height: '100%' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, flexWrap: 'wrap' }}>
                    <SelectAgency setAgency={setAgency} />
                    <SelectAdvertiser
                        agency={agency}
                        setAdvertiser={setAdvertiser}
                        setAdGroup={setAdGroup}
                        setYearMonth={setYearMonth}
                        setSize={setSize}
                        advertiser={advertiser}
                    />
                    <SelectMonth
                        advertiser={advertiser}
                        yearMonth={yearMonth}
                        setAdGroup={setAdGroup}
                        setYearMonth={setYearMonth}
                        setSize={setSize}
                    />
                    <SelectAdGroup
                        yearMonth={yearMonth}
                        adGroup={adGroup}
                        setAdGroup={setAdGroup}
                        advertiser={advertiser}
                        setSize={setSize}
                    />
                    <SelectSize size={size} setSize={setSize} />
                </Box>
                <Box sx={{ mt: 2 }}>
                    {advertiser && advertiser.ftAdvertiserId && adGroup && adGroup.dspAdGroupId && yearMonth && (
                        <AdTagPreviewSection size={size} />
                    )}
                </Box>
            </Column>
        </Column>
    );
};

export default AdTagPreviewPage;
