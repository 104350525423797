import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { CDTextField } from './CDTextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

type CDDatePickerProps = {
    value: Date | null;
    onChange: (newValue: Date | null) => void;
    showError: boolean;
    disabled?: boolean;
    label: string;
    disableFuture?: boolean;
    disablePast?: boolean;
    minDate?: any;
    maxDate?: any;
};

export default function CDDatePicker(props: CDDatePickerProps) {
    const handleDateChange = (newValue: Date | null) => {
        if (newValue) {
            // Ensure the year value always starts with "20"
            const year = newValue.getFullYear();
            if (year < 2000) {
                newValue.setFullYear(2000 + (year % 100));
            }
        }
        props.onChange(newValue);
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
                label={props.label}
                value={props.value}
                onChange={handleDateChange}
                disabled={props.disabled}
                disableFuture={props.disableFuture}
                disablePast={props.disablePast}
                minDate={props.minDate}
                maxDate={props.maxDate}
                renderInput={(params) => (
                    <CDTextField
                        {...params}
                        variant="outlined"
                        error={props.showError && !props.value}
                        required={true}
                    />
                )}
            />
        </LocalizationProvider>
    );
}
