import { useEffect, useState } from 'react';
import { styled } from '@mui/material';
import { Box, Tab as BaseTab, TabProps, Tabs } from '@mui/material';
import { useLocation } from 'react-router-dom';

import { Dealer } from '../../types/Dealer';
import TabPanel from '../../components/TabPanel';

import CRMDataUpload from './CRMDataUpload';
import CRMDataUploadResult from './CRMDataUploadResult';

export const TABS = {
    UPLOAD_DATA: 0,
    UPLOAD_RESULTS: 1,
};

export default function CRMDataTab(props: {
    agencyId?: number | null;
    dealer?: Dealer | null;
    defaultSelectedTab?: number;
    dealerDefaultSelect?: boolean;
    showDealerSelector?: boolean;
    showHeading?: boolean;
    role?: 'primary' | 'secondary';
}) {
    const { agencyId, dealer, defaultSelectedTab, dealerDefaultSelect, showDealerSelector, showHeading } = props;

    const { hash } = useLocation();
    const [selectedTab, setSelectedTab] = useState(0);

    useEffect(() => {
        if (defaultSelectedTab !== undefined && defaultSelectedTab >= 0) {
            setSelectedTab(defaultSelectedTab);
        }
    }, [defaultSelectedTab]);

    useEffect(() => {
        if (hash) {
            switch (hash) {
                case '#crmUpload':
                    setSelectedTab(TABS.UPLOAD_DATA);
                    break;

                case '#crmUploadResults':
                    setSelectedTab(TABS.UPLOAD_RESULTS);
                    break;
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hash]);

    // const isSetup = useMemo(() => {
    //     return (agencyId && agencyId > 0) || dealer != null;
    // }, [agencyId, dealer]);

    const Tab = styled(BaseTab)<TabProps>(() => ({
        fontSize: props.role === 'primary' ? 'inherit' : '13px',
    }));

    return (
        <>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={selectedTab} onChange={(event, value) => setSelectedTab(value)}>
                    <Tab value={TABS.UPLOAD_DATA} label="Upload Data" />
                    <Tab value={TABS.UPLOAD_RESULTS} label="Upload Results" />
                </Tabs>
            </Box>

            <TabPanel
                index={TABS.UPLOAD_DATA}
                value={selectedTab}
                BoxProps={{
                    sx: { p: 3 },
                }}
            >
                <CRMDataUpload
                    dealerDefaultSelect={dealerDefaultSelect}
                    showDealerSelector={showDealerSelector}
                    showHeading={showHeading}
                    agencyId={agencyId}
                    dealer={dealer}
                />
            </TabPanel>

            <TabPanel index={TABS.UPLOAD_RESULTS} value={selectedTab}>
                <CRMDataUploadResult agencyId={agencyId} dealer={dealer} />
            </TabPanel>
        </>
    );
}
