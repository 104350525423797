import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

import { Dealer } from '../../types/Dealer';
import Column from '../../components/Column';

import CRMDataTab from '../../pages/dealers/CRMDataTab';

export default function SalesDataUploadDialog(props: { dealer: Dealer; onClose(): void; open: boolean }) {
    const { dealer, onClose, open } = props;

    return (
        <Dialog
            fullWidth={true}
            maxWidth="xl"
            open={open}
            onClose={() => onClose()}
            sx={{
                zIndex: 1300,
            }}
        >
            <DialogTitle>CRM Data Upload - {dealer.dealerName}</DialogTitle>
            <IconButton
                aria-label="close"
                onClick={() => onClose()}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent dividers sx={{ pt: 3, pb: 5 }}>
                <Column gap={3}>
                    <CRMDataTab role="primary" dealer={dealer} />
                </Column>
            </DialogContent>
        </Dialog>
    );
}
