import dealer_inspire from './ad_tag_code_templates/dealer_inspire.js';
import dealer_on from './ad_tag_code_templates/dealer_on.js';
import ansira_sincro from './ad_tag_code_templates/ansira_sincro.js';
import dealer_com from './ad_tag_code_templates/dealer_com.js';
import dealere_process from './ad_tag_code_templates/dealere_process.js';
import team_velocity from './ad_tag_code_templates/team_velocity.js';

export default [
    {
        name: 'Dealer Inspire',
        value: 'dealer_inspire',
        codeTemplate: dealer_inspire,
    },
    {
        name: 'DealerOn',
        value: 'dealer_on',
        codeTemplate: dealer_on,
    },
    {
        name: 'Ansira/Sincro',
        value: 'ansira_sincro',
        codeTemplate: ansira_sincro,
    },
    {
        name: 'Dealer.com',
        value: 'dealer_com',
        codeTemplate: dealer_com,
    },
    {
        name: 'DealereProcess',
        value: 'dealere_process',
        codeTemplate: dealere_process,
    },
    {
        name: 'Team Velocity',
        value: 'team_velocity',
        codeTemplate: team_velocity,
    },
];
