import { useEffect, useMemo, useState } from 'react';
import {
    Card,
    CardContent,
    Box,
    Typography,
    Button,
    Accordion,
    AccordionSummary,
    AccordionDetails,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import moment from 'moment';

import CheckboxList from './CheckboxList';
import CheckRichTree from './CheckRichTree';
import GeographyAutocompleteList from './GeographyAutocompleteList';
import ApiService from '../../ApiService';
import { FilterList, IFilterItem } from './SalesInsights';
import { PlatfromUsers, PurchaseType, States } from './utils';
import SelectMonth from './SelectMonth';

const Filters = ({
    setFilters,
    setApplyFilters,
    filters,
}: {
    setFilters: (filters: IFilterItem[]) => void;
    setApplyFilters: (filters: IFilterItem[]) => void;
    filters: IFilterItem[];
}) => {
    const [fuelType, setFuelType] = useState<IFilterItem[]>([]);
    const [segments, setSegments] = useState<IFilterItem[]>([]);
    const [makeModel, setMakeModel] = useState<IFilterItem[]>([]);
    const [locations, setLocations] = useState<IFilterItem[]>([]);
    const [isFilterDirty, setIsFilterDirty] = useState<boolean>(false);

    const hiddenFilterList = ['month'];

    useEffect(() => {
        ApiService.getPolkSalesGeographyCities()
            .then((response) => {
                if (response?.data?.length) {
                    const newLocations: IFilterItem[] = [
                        ...States.map((_state: Record<string, string>) => {
                            return {
                                id: _state.id,
                                label: _state.label,
                                type: 'STATE',
                            };
                        }),
                        ...response.data?.map((data) => {
                            const state: Record<string, string> | undefined = States.find(
                                (_state: Record<string, string>) => _state.id === data.state
                            );

                            return {
                                id: `${data.city} - ${state ? state.id : data.state}`.toUpperCase(),
                                label: `${data.city} - ${state ? state.label : data.state}`.toUpperCase(),
                                type: 'CITY',
                            };
                        }),
                    ];

                    setLocations(newLocations);
                }
            })
            .catch(() => {});
    }, []);

    useEffect(() => {
        ApiService.getSegments()
            .then((response) => {
                if (response?.data?.length) {
                    const newData = response.data?.map((data) => {
                        return {
                            id: data,
                            label: data,
                        };
                    });
                    setSegments(newData);
                }
            })
            .catch(() => {});
    }, []);

    useEffect(() => {
        ApiService.getFuelType()
            .then((response) => {
                if (response?.data?.length) {
                    const newData = response.data?.map((data) => {
                        return {
                            id: data,
                            label: data,
                        };
                    });
                    setFuelType(newData);
                }
            })
            .catch(() => {});
    }, []);

    useEffect(() => {
        ApiService.getMakeAndModel()
            .then((response) => {
                if (response?.data?.length) {
                    setMakeModel(getTreeData(response?.data, 'make', 'model'));
                }
            })
            .catch(() => {});
    }, []);

    const getTreeData = (data: any[], parentField: string, childField: string) => {
        const result: IFilterItem[] = [];

        data.forEach((item) => {
            // Find the parent object in the result array
            let parentObj = result.find((obj) => obj.label === item[parentField]);

            // If the parent object doesn't exist, create a new one
            if (!parentObj) {
                parentObj = { id: item[parentField], label: item[parentField], children: [] };
                result.push(parentObj);
            }

            // Add the child to the children array of the parent object
            parentObj?.children?.push({ id: `${item[parentField]}@${item[childField]}`, label: item[childField] });
        });

        return result;
    };

    const getDataById = (id: string) => {
        switch (id) {
            case 'platform_users':
                return PlatfromUsers;
            case 'purchase_type':
                return PurchaseType;
            case 'fuel_type':
                return fuelType;
            case 'segments':
                return segments;
            case 'make_model':
                return makeModel;
            case 'geography':
                return States;
            default:
                return [];
        }
    };

    const handleClear = () => {
        setFilters(JSON.parse(JSON.stringify(FilterList)));
    };

    const handleSelectAll = () => {
        const newFilter = JSON.parse(JSON.stringify(FilterList));

        const getNewFilterItemIndex = (id: string): number => {
            return newFilter.findIndex((newFilterItem: IFilterItem) => newFilterItem.id === id);
        };

        const platformUsersIndex: number = getNewFilterItemIndex('platform_users');
        if (platformUsersIndex > -1) {
            PlatfromUsers?.forEach((user, index) => {
                newFilter?.[platformUsersIndex]?.children?.push({
                    ...user,
                });
            });
        }

        const purchaseTypeIndex: number = getNewFilterItemIndex('purchase_type');
        if (purchaseTypeIndex > -1) {
            PurchaseType?.forEach((type, index) => {
                newFilter?.[purchaseTypeIndex]?.children?.push({
                    ...type,
                });
            });
        }

        const makeModelIndex: number = getNewFilterItemIndex('make_model');
        if (makeModelIndex > -1) {
            makeModel?.forEach((make, index) => {
                newFilter?.[makeModelIndex]?.children?.push({
                    ...make,
                });
                make?.children?.forEach((model, j) => {
                    newFilter?.[makeModelIndex]?.[index]?.children?.push({
                        ...model,
                    });
                });
            });
        }

        const fuelTypeIndex: number = getNewFilterItemIndex('fuel_type');
        if (fuelTypeIndex > -1) {
            fuelType?.forEach((fuel, index) => {
                newFilter?.[fuelTypeIndex]?.children?.push({
                    ...fuel,
                });
            });
        }

        const segmentsIndex: number = getNewFilterItemIndex('segments');
        if (segmentsIndex > -1) {
            segments?.forEach((seg, index) => {
                newFilter?.[segmentsIndex]?.children?.push({
                    ...seg,
                });
            });
        }

        const geographyIndex: number = getNewFilterItemIndex('geography');
        if (geographyIndex > -1) {
            States?.forEach((state, index) => {
                newFilter?.[geographyIndex]?.children?.push({
                    ...state,
                });
            });
        }

        const monthIndex: number = getNewFilterItemIndex('month');
        if (monthIndex > -1) {
            newFilter?.[monthIndex]?.children?.push({
                id: moment().format('YYYY-MM'),
                label: moment().format('MMMM YYYY'),
            });
        }

        setFilters(newFilter);
    };

    const handleApply = () => {
        setApplyFilters(JSON.parse(JSON.stringify(filters)));
    };

    useEffect(() => {
        if (filters.some((filter: IFilterItem) => (filter?.children?.length ? true : false)) === true) {
            setIsFilterDirty(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters]);

    const isDisabledApplyButton = useMemo((): boolean => {
        if (!isFilterDirty) {
            return true;
        }
        return false;
    }, [isFilterDirty]);

    return (
        <Card variant="outlined" sx={{ minWidth: '350px' }}>
            <CardContent sx={{ p: 0 }}>
                <Box
                    sx={{
                        borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        gap: 2,
                        flexWrap: 'wrap',
                        mb: 2,
                        px: 2,
                        py: 1.5,
                    }}
                >
                    <Typography variant="body2">Filter By</Typography>
                    <Box sx={{ display: 'flex', gap: 2 }}>
                        <Button size="small" onClick={handleClear}>
                            Clear All
                        </Button>
                        <Button size="small" onClick={handleSelectAll}>
                            Select All
                        </Button>
                        <Button
                            variant={isDisabledApplyButton ? 'outlined' : 'contained'}
                            size="small"
                            onClick={handleApply}
                            disabled={isDisabledApplyButton}
                        >
                            Apply
                        </Button>
                    </Box>
                </Box>
                <Box>
                    <Box>
                        {FilterList?.filter((item, index) => {
                            if (hiddenFilterList.includes(item.id)) {
                                return false;
                            }
                            return true;
                        })?.map((item, index) => {
                            return (
                                <Accordion
                                    elevation={0}
                                    key={item.id}
                                    sx={{
                                        '&.Mui-expanded': {
                                            margin: 0,
                                        },
                                        '&.MuiAccordion-root.Mui-expanded::before': {
                                            // opacity: 1,
                                        },
                                        '&:first-of-type .MuiAccordionSummary-root.Mui-expanded': {
                                            borderTop: 'none',
                                        },
                                    }}
                                >
                                    <AccordionSummary
                                        sx={{
                                            '&.Mui-expanded': {
                                                borderTop: (theme) => `1px solid ${theme.palette.divider}`,
                                                minHeight: (theme) => theme.spacing(6),
                                            },
                                            '& .MuiAccordionSummary-content': {
                                                fontSize: (theme) => theme.typography.subtitle2,
                                            },
                                            '& .MuiAccordionSummary-content.Mui-expanded': {
                                                margin: '12px 0',
                                            },
                                        }}
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls={`${item.id}-content`}
                                        id={`${item.id}-header`}
                                    >
                                        {item.label}
                                    </AccordionSummary>
                                    <AccordionDetails
                                        sx={{
                                            borderTop: (theme) => `1px solid ${theme.palette.divider}`,
                                            pt: 2,
                                            pb: 1.5,
                                            px: 0,
                                        }}
                                    >
                                        {item.id === 'geography' ? (
                                            <GeographyAutocompleteList
                                                id={item.id}
                                                setFilters={setFilters}
                                                filters={filters}
                                                items={locations}
                                            />
                                        ) : (
                                            <>
                                                {!item?.isDropdown && item?.hasSubCategory && (
                                                    <CheckRichTree
                                                        items={getDataById(item.id)}
                                                        hasSearch={item?.hasSearchbox ? true : false}
                                                        setFilters={setFilters}
                                                        id={index}
                                                        filters={filters}
                                                    />
                                                )}
                                                {!item?.isDropdown && !item?.hasSubCategory && (
                                                    <CheckboxList
                                                        items={getDataById(item.id)}
                                                        hasSearch={item?.hasSearchbox ? true : false}
                                                        setFilters={setFilters}
                                                        id={index}
                                                        filters={filters}
                                                    />
                                                )}
                                            </>
                                        )}
                                        {false && item?.isDropdown && (
                                            <Box sx={{ px: 2 }}>
                                                <SelectMonth setFilters={setFilters} id={index} filters={filters} />
                                            </Box>
                                        )}
                                    </AccordionDetails>
                                </Accordion>
                            );
                        })}
                    </Box>
                </Box>
            </CardContent>
        </Card>
    );
};

export default Filters;
