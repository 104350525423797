import { useEffect, useMemo, useState } from 'react';
import { DataGridProProps, GridColDef, GridColumnHeaderParams, GridRowsProp } from '@mui/x-data-grid-pro';

import { Performance } from '../../../../types/CampaignPerformance';
import { ReportingCriteria } from '../../../../types/Analytics/Reporting/ReportingCriteria';
import DataGridPro from '../../../../components/CDGridPro';
import Utils from '../../../../components/Utils';

interface PerformanceRow extends Performance {
    id: string;
    path: string;

    rowCount?: number;
}

function makeDefaultDataGridRow(row: Partial<PerformanceRow>): PerformanceRow {
    return {
        id: '',
        type: '',
        path: '',
        date: undefined,
        agencyId: 0,
        agencyName: '',
        dealerName: '',
        dealerId: 0,
        campaignId: 0,
        campaignName: '',
        campaignType: '',
        mediaType: '',
        strategyType: '',
        CTR: 0,
        CPM: 0,
        clickthroughs: 0,
        dailyReach: 0,
        impressions: 0,
        impressionFrequency: 0,
        measurableImpressions: 0,
        offAmazonConversions: 0,
        dspCost: 0,
        totalCost: 0,
        viewabilityRate: 0,
        viewableImpressions: 0,
        videoComplete: 0,
        videoStart: 0,

        ...row,
    };
}

function sumDataGridRow(currentRow: PerformanceRow, row: PerformanceRow): PerformanceRow {
    currentRow.CTR += row.CTR;
    currentRow.CPM += row.CPM;
    currentRow.clickthroughs += row.clickthroughs;
    currentRow.dailyReach += row.dailyReach;
    currentRow.impressions += row.impressions;
    currentRow.impressionFrequency += row.impressionFrequency;
    currentRow.measurableImpressions += row.measurableImpressions;
    currentRow.offAmazonConversions += row.offAmazonConversions;
    currentRow.dspCost += row.dspCost;
    currentRow.totalCost += row.totalCost;
    currentRow.viewabilityRate += row.viewabilityRate;
    currentRow.viewableImpressions += row.viewableImpressions;
    currentRow.videoComplete += row.videoComplete;
    currentRow.videoStart += row.videoStart;

    return currentRow;
}

function calculateDataGridRows(rows: PerformanceRow[]): PerformanceRow[] {
    rows.forEach((row: PerformanceRow) => {
        const rowCount: number = row.rowCount as number;

        if (rowCount > 0) {
            row.CTR /= rowCount;
            row.CPM /= rowCount;
            row.impressionFrequency /= rowCount;
            row.viewabilityRate /= rowCount;
        }

        delete row.rowCount;
    });

    return rows;
}

export type ReportingPerformanceAgencyDataGridProps = {
    reportingCriteria: ReportingCriteria;
    campaignPerformances: Performance[];
    loading?: boolean;
};

export default function ReportingPerformanceAgencyDataGrid(props: ReportingPerformanceAgencyDataGridProps) {
    const { campaignPerformances, reportingCriteria, loading = false } = props;

    const [rows, setRows] = useState<GridRowsProp>([]);

    useEffect(() => {
        let _rows: PerformanceRow[] = campaignPerformances.map((campaignPerformance: Performance, index: number) => {
            const id: string = `${campaignPerformance.agencyName}/${campaignPerformance.dealerName}/${campaignPerformance.mediaType}/${campaignPerformance.campaignName} (#${index})`;

            return {
                ...campaignPerformance,
                id: id,
                path: id,
            };
        });

        const agencyRows = _rows.reduce((acc: PerformanceRow[], _row) => {
            let agencyRow: PerformanceRow | undefined = acc.find(
                (row: PerformanceRow) => row.agencyId === _row.agencyId
            );

            if (!agencyRow) {
                const id: string = `${_row.agencyName}`;

                agencyRow = makeDefaultDataGridRow({
                    type: 'Agency',
                    id: id,
                    path: id,
                    agencyId: _row.agencyId,
                    agencyName: _row.agencyName,

                    rowCount: 0,
                });

                acc.push(agencyRow);
            }

            agencyRow = sumDataGridRow(agencyRow, _row);

            // Internal
            if (agencyRow?.rowCount !== undefined) {
                agencyRow.rowCount += 1;
            }

            return acc;
        }, []);

        calculateDataGridRows(agencyRows);

        const advertiserRows = _rows.reduce((acc: PerformanceRow[], _row) => {
            let advertiserRow: PerformanceRow | undefined = acc.find((row: PerformanceRow) => {
                return row.agencyId === _row.agencyId && row.dealerId === _row.dealerId;
            });

            if (!advertiserRow) {
                const id: string = `${_row.agencyName}/${_row.dealerName}`;

                advertiserRow = makeDefaultDataGridRow({
                    type: 'Advertiser',
                    id: id,
                    path: id,
                    agencyId: _row.agencyId,
                    agencyName: _row.agencyName,
                    dealerId: _row.dealerId,
                    dealerName: _row.dealerName,

                    rowCount: 0,
                });

                acc.push(advertiserRow);
            }

            advertiserRow = sumDataGridRow(advertiserRow, _row);

            // Internal
            if (advertiserRow?.rowCount !== undefined) {
                advertiserRow.rowCount += 1;
            }

            return acc;
        }, []);

        calculateDataGridRows(advertiserRows);

        const mediaRows = _rows.reduce((acc: PerformanceRow[], _row) => {
            let mediaRow: PerformanceRow | undefined = acc.find((row: PerformanceRow) => {
                return (
                    row.agencyId === _row.agencyId && row.dealerId === _row.dealerId && row.mediaType === _row.mediaType
                );
            });

            if (!mediaRow) {
                const id: string = `${_row.agencyName}/${_row.dealerName}/${_row.mediaType}`;

                mediaRow = makeDefaultDataGridRow({
                    type: 'Media',
                    id: id,
                    path: id,
                    agencyId: _row.agencyId,
                    agencyName: _row.agencyName,
                    dealerId: _row.dealerId,
                    dealerName: _row.dealerName,
                    mediaType: _row.mediaType,
                    strategyType: _row.strategyType,

                    rowCount: 0,
                });

                acc.push(mediaRow);
            }

            mediaRow = sumDataGridRow(mediaRow, _row);

            // Internal
            if (mediaRow?.rowCount !== undefined) {
                mediaRow.rowCount += 1;
            }

            return acc;
        }, []);

        calculateDataGridRows(mediaRows);

        _rows.push(...agencyRows);
        _rows.push(...advertiserRows);
        _rows.push(...mediaRows);

        setRows(_rows);
    }, [campaignPerformances]);

    const getTreeDataPath: DataGridProProps['getTreeDataPath'] = (row) => row.path.split('/');

    const renderHeaderByDelimeter = (delimeter: string = '/') => {
        return (params: GridColumnHeaderParams) => {
            const headerName: string = params?.colDef?.headerName as string;
            const names: string[] = headerName ? headerName.split(delimeter) : [];

            if (delimeter && names.length > 1) {
                return (
                    <strong style={{ textAlign: 'right' }}>
                        {names[0]}
                        {delimeter === '' || delimeter === ' ' ? <></> : <span>/</span>}
                        <br />
                        {names[1]}
                    </strong>
                );
            }

            return <strong>{headerName}</strong>;
        };
    };

    const groupingColDef: DataGridProProps['groupingColDef'] = {
        headerName: 'Agency',
        hideDescendantCount: false,
        sortable: true,
        // valueFormatter: () => '',
        width: 400,
        renderHeader: renderHeaderByDelimeter(' '),
    };

    const columns: GridColDef[] = useMemo(() => {
        return [
            {
                field: 'type',
                headerName: 'Type',
                width: 184,
                headerAlign: 'right',
                align: 'right',
                renderHeader: renderHeaderByDelimeter(''),
                renderCell: (params) => {
                    if (params.value) {
                        return params.value;
                    }
                    if (params.row.strategyType) {
                        return `Campaign (${params.row.strategyType})`;
                    }
                    return 'Campaign';
                },
            },
            {
                field: 'totalCost',
                headerName: 'Total Investment',
                width: 144,
                headerAlign: 'right',
                align: 'right',
                renderHeader: renderHeaderByDelimeter(' '),
                renderCell: (params) => {
                    return params.value ? Utils.formatValue(params.value, 'dollar') : params.value;
                },
            },
            {
                field: 'impressions',
                headerName: 'Total Exposure',
                // width: 112,
                width: 144,
                headerAlign: 'right',
                align: 'right',
                renderHeader: renderHeaderByDelimeter(' '),
                renderCell: (params) => {
                    return params.value ? Utils.formatValue(params.value) : params.value;
                },
            },
            {
                field: 'CPM',
                headerName: 'Avg. CPM/CPM',
                // width: 112,
                width: 144,
                headerAlign: 'right',
                align: 'right',
                renderHeader: renderHeaderByDelimeter('/'),
                renderCell: (params) => {
                    return params.value ? Utils.formatValue(params.value, 'dollar') : params.value;
                },
            },
            {
                field: 'dailyReach',
                headerName: 'Household Reach',
                // width: 144,
                width: 144,
                headerAlign: 'right',
                align: 'right',
                renderHeader: renderHeaderByDelimeter(' '),
                renderCell: (params) => {
                    return params.value ? Utils.formatValue(params.value) : params.value;
                },
            },
            {
                field: 'viewabilityRate',
                headerName: 'Avg. Viewability/Viewability',
                // headerName: 'Avg. Viewability',
                width: 144,
                headerAlign: 'right',
                align: 'right',
                renderHeader: renderHeaderByDelimeter('/'),
                renderCell: (params) => {
                    return params.value ? Utils.formatValue(params.value, 'percent') : params.value;
                },
            },
            {
                field: 'clickthroughs',
                headerName: 'Total Clicks',
                // width: 88,
                width: 132,
                headerAlign: 'right',
                align: 'right',
                renderHeader: renderHeaderByDelimeter(' '),
                renderCell: (params) => {
                    return params.value ? Utils.formatValue(params.value) : params.value;
                },
            },
            {
                field: 'CTR',
                headerName:
                    reportingCriteria.mediaType === 'Audio'
                        ? 'Avg. Companion Banner Clicks/Companion Banner Clicks'
                        : 'Avg. CTR/CTR',
                // headerName: 'Avg. CTR',
                // width: reportingCriteria.mediaType === 'Audio' ? 244 : 88,
                width: reportingCriteria.mediaType === 'Audio' ? 244 : 132,
                headerAlign: 'right',
                align: 'right',
                renderHeader: renderHeaderByDelimeter('/'),
                renderCell: (params) => {
                    return params.value ? Utils.formatValue(params.value, 'percent') : params.value;
                },
            },
            {
                field: 'offAmazonConversions',
                headerName: 'Conversions',
                // width: 116,
                width: 148,
                headerAlign: 'right',
                align: 'right',
                renderHeader: renderHeaderByDelimeter(''),
                renderCell: (params) => {
                    return params.value ? Utils.formatValue(params.value) : params.value;
                },
            },
        ];
    }, [reportingCriteria]);

    return (
        <DataGridPro
            disableColumnResize={true}
            loading={loading}
            density="standard"
            treeData
            getTreeDataPath={getTreeDataPath}
            groupingColDef={groupingColDef}
            rows={rows}
            columns={columns}
            slots={{
                toolbar: null,
            }}
            pageSize={10}
        />
    );
}
