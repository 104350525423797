import { LineLayer, FillLayer } from 'react-map-gl';
import { CircleLayer } from 'react-map-gl'; // Import CircleLayer from react-map-gl

// Define the interface for the circle styles
export interface CircleStyles {
    'circle-radius'?: number;
    'circle-color'?: string;
    'circle-opacity'?: number;
}

export interface BoundaryStyles {
    'background-color'?: string;
    opacity?: number;
    'line-color'?: string;
    'line-width'?: number;
    'line-opacity'?: number;
    selected?: {
        'background-color'?: string;
        opacity?: number;
        'line-color'?: string;
        'line-width'?: number;
        'line-opacity'?: number;
    };
}

// Define the circle function to generate the CircleLayer
export const circle = (overrides: CircleStyles = {}): CircleLayer => ({
    id: 'circle', // Unique layer ID
    type: 'circle', // Layer type
    paint: {
        // Define paint properties based on overrides or default values
        'circle-radius': overrides['circle-radius'] ?? 10, // Default radius value is 10 if not provided
        'circle-color': overrides['circle-color'] ?? '#00008B', // Default color is navy if not provided
        'circle-opacity': overrides['circle-opacity'] ?? 0.5, // Default opacity is 0.5 if not provided
    },
});

export const fill = (overrides: BoundaryStyles = {}): FillLayer => ({
    id: 'fill',
    type: 'fill',
    paint: {
        'fill-color': [
            'case',
            ['boolean', ['feature-state', 'disabled'], false],
            overrides.selected?.['background-color'] ? overrides.selected['background-color'] : 'green',
            overrides['background-color'] ? overrides['background-color'] : 'green',
        ],
        'fill-opacity': [
            'case',
            ['boolean', ['feature-state', 'disabled'], false],
            overrides.selected?.opacity ? overrides.opacity : 0.1,
            overrides.opacity ? overrides.opacity : 0.4,
        ],
    },
});

export const line = (overrides: BoundaryStyles = {}): LineLayer => ({
    id: 'line',
    type: 'line',
    paint: {
        'line-color': [
            'case',
            ['boolean', ['feature-state', 'disabled'], false],
            overrides.selected?.['line-color'] ? overrides.selected['line-color'] : 'black',
            overrides['line-color'] ? overrides['line-color'] : 'black',
        ],
        'line-width': [
            'case',
            ['boolean', ['feature-state', 'disabled'], false],
            overrides.selected?.['line-width'] ? overrides.selected['line-width'] : 1,
            overrides['line-width'] ? overrides['line-width'] : 1,
        ],
        'line-opacity': [
            'case',
            ['boolean', ['feature-state', 'disabled'], false],
            overrides.selected?.['line-opacity'] ? overrides.selected['line-opacity'] : 0.8,
            overrides['line-opacity'] ? overrides['line-opacity'] : 0.8,
        ],
    },
});
