import { useCallback, useMemo } from 'react';
import moment from 'moment-timezone';

import { PolkDealer, PolkSalesAnalysis, PolkSalesWorkflow, PolkSalesWorkflowResult } from '../types/Polk';
import Utils from '../components/Utils';

import { TABS, useAttributionPageContext } from './useAttributionPage';

type MarketShare = {
    polkDealer: PolkDealer;
    dealerName: string;
    value: number;
};

type AdShare = {
    polkDealer: PolkDealer;
    dealerName: string;
    value: number;
};

export function calculateExposedSales(workflowResult: PolkSalesWorkflowResult): number {
    return workflowResult.distinctUserCount;
}

export function calculateNonExposedSales(workflowResult: PolkSalesWorkflowResult): number {
    return workflowResult.salesCount - workflowResult.distinctUserCount;
}

export default function usePolkAttribution() {
    const {
        attributionDate,
        attributionStatus,
        attributionMetrics,
        tab,
        isAdvertiserPolkDealer,
        polkSalesWorkflow,
        setPolkSalesWorkflow,
        polkOpportunitiesWorkflow,
        setPolkOpportunitiesWorkflow,
        polkSalesAnalysis,
        setPolkSalesAnalysis,
    } = useAttributionPageContext();

    function formatDate(_date: any): string {
        return moment(_date).format('YYYY-MM-DD');
    }

    const fetchPolkSalesWorkflow = useCallback(() => {
        setPolkSalesWorkflow(
            attributionStatus.salesWorkflows.find((_polkSalesWorkflow: PolkSalesWorkflow) => {
                const _attributionStartDate: string = formatDate(attributionDate);
                const _workflowStartDate: string = formatDate(Utils.getDate(_polkSalesWorkflow.amcUpload.beginDate));

                if (_polkSalesWorkflow.amcUpload.dataSet !== 'polk_sales') {
                    return false;
                }
                return _attributionStartDate === _workflowStartDate;
            })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [attributionDate, attributionStatus]);

    const fetchPolkOpportunitiesWorkflow = useCallback(() => {
        setPolkOpportunitiesWorkflow(
            attributionStatus.salesWorkflows.find((_polkSalesWorkflow: PolkSalesWorkflow) => {
                const _attributionStartDate: string = formatDate(attributionDate);
                const _workflowStartDate: string = formatDate(Utils.getDate(_polkSalesWorkflow.amcUpload.beginDate));

                if (_polkSalesWorkflow.amcUpload.dataSet !== 'polk_opportunities') {
                    return false;
                }
                return _attributionStartDate === _workflowStartDate;
            })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [attributionDate, attributionStatus]);

    const fetchPolkSalesWorkflows = useCallback(() => {
        fetchPolkSalesWorkflow();
        fetchPolkOpportunitiesWorkflow();
    }, [fetchPolkSalesWorkflow, fetchPolkOpportunitiesWorkflow]);

    const getPolkSalesAnalysis = (): PolkSalesAnalysis[] => {
        let polkSalesAnalysis: PolkSalesAnalysis[] = [];

        if (polkSalesWorkflow) {
            polkSalesAnalysis.unshift(
                ...polkSalesWorkflow.workflowResults.map((workflowResult: PolkSalesWorkflowResult) => {
                    return {
                        polkDealer: workflowResult.polkDealer,
                        dealerName: workflowResult.polkDealer.dealerName,
                        marketShare: workflowResult.salesCount,
                        marketSharePercentage: 0,
                        adShare: workflowResult.distinctUserCount,
                        adSharePercentage: 0,
                        totalExposedSales: calculateExposedSales(workflowResult),
                        totalNonExposedSales: calculateNonExposedSales(workflowResult),
                        totalSales: workflowResult.salesCount,
                    };
                })
            );
        }

        if (polkOpportunitiesWorkflow) {
            polkSalesAnalysis.push(
                ...polkOpportunitiesWorkflow.workflowResults.map((workflowResult: PolkSalesWorkflowResult) => {
                    return {
                        polkDealer: workflowResult.polkDealer,
                        dealerName: workflowResult.polkDealer.dealerName,
                        marketShare: workflowResult.salesCount,
                        marketSharePercentage: 0,
                        adShare: workflowResult.distinctUserCount,
                        adSharePercentage: 0,
                        totalExposedSales: calculateExposedSales(workflowResult),
                        totalNonExposedSales: calculateNonExposedSales(workflowResult),
                        totalSales: workflowResult.salesCount,
                    };
                })
            );
        }

        if (tab === TABS.ADVERTISER_REPORTED_SALES) {
            const index = polkSalesAnalysis.findIndex((psa) => isAdvertiserPolkDealer(psa.polkDealer));

            if (index !== -1) {
                polkSalesAnalysis[index] = {
                    ...polkSalesAnalysis[index],
                    adShare: attributionMetrics.totalAdShares?.value ? attributionMetrics.totalAdShares?.value : 0,
                    totalSales: attributionMetrics.totalSales?.value ? attributionMetrics.totalSales?.value : 0,
                    marketShare: attributionMetrics.totalSales?.value ? attributionMetrics.totalSales?.value : 0,
                };
            }
        }

        if (polkSalesAnalysis.length > 0) {
            let totalShare = 0;

            // Calculate market share
            totalShare = polkSalesAnalysis.map((psa: PolkSalesAnalysis) => psa.marketShare).reduce((a, b) => a + b, 0);

            polkSalesAnalysis = polkSalesAnalysis.map((psa: PolkSalesAnalysis) => {
                psa.marketSharePercentage = totalShare ? Math.round((psa.marketShare / totalShare) * 100) : 0;
                return psa;
            });

            // Calculate ad share
            totalShare = polkSalesAnalysis.map((psa: PolkSalesAnalysis) => psa.adShare).reduce((a, b) => a + b, 0);

            polkSalesAnalysis = polkSalesAnalysis.map((psa: PolkSalesAnalysis) => {
                psa.adSharePercentage = totalShare ? Math.round((psa.adShare / totalShare) * 100) : 0;
                return psa;
            });
        }

        return polkSalesAnalysis;
    };

    const fetchPolkSalesAnalysis = useCallback(() => {
        setPolkSalesAnalysis(getPolkSalesAnalysis());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [polkSalesWorkflow, polkOpportunitiesWorkflow]);

    const marketShares = useMemo(() => {
        let _marketShares: MarketShare[] = [];

        if (polkOpportunitiesWorkflow) {
            _marketShares.push(
                ...polkOpportunitiesWorkflow.workflowResults.map((workflowResult: PolkSalesWorkflowResult) => {
                    return {
                        polkDealer: workflowResult.polkDealer,
                        dealerName: workflowResult.polkDealer.dealerName,
                        value: workflowResult.salesCount,
                    };
                })
            );
        }

        _marketShares.sort((msA: MarketShare, msB: MarketShare) => msB.value - msA.value);

        if (polkSalesWorkflow) {
            _marketShares.unshift(
                ...polkSalesWorkflow.workflowResults.map((workflowResult: PolkSalesWorkflowResult) => {
                    return {
                        polkDealer: workflowResult.polkDealer,
                        dealerName: workflowResult.polkDealer.dealerName,
                        value: workflowResult.salesCount,
                    };
                })
            );
        }

        if (tab === TABS.ADVERTISER_REPORTED_SALES) {
            const index = _marketShares.findIndex((marketShare) => isAdvertiserPolkDealer(marketShare.polkDealer));

            if (index !== -1) {
                _marketShares[index] = {
                    ..._marketShares[index],
                    value: attributionMetrics.totalSales?.value ? attributionMetrics.totalSales?.value : 0,
                };
            }
        }
        return _marketShares;
    }, [polkSalesWorkflow, polkOpportunitiesWorkflow]);

    const marketSharesTotal: number = useMemo(() => {
        return marketShares.map((ms: MarketShare) => ms.value).reduce((a: number, b: number) => a + b, 0);
    }, [marketShares]);

    const adShares = useMemo(() => {
        let _adShares: AdShare[] = [];

        if (polkOpportunitiesWorkflow) {
            _adShares.push(
                ...polkOpportunitiesWorkflow.workflowResults.map((workflowResult: PolkSalesWorkflowResult) => {
                    return {
                        polkDealer: workflowResult.polkDealer,
                        dealerName: workflowResult.polkDealer.dealerName,
                        value: workflowResult.distinctUserCount,
                    };
                })
            );
        }

        _adShares.sort((asA: AdShare, asB: AdShare) => asB.value - asA.value);

        if (polkSalesWorkflow) {
            _adShares.unshift(
                ...polkSalesWorkflow.workflowResults.map((workflowResult: PolkSalesWorkflowResult) => {
                    return {
                        polkDealer: workflowResult.polkDealer,
                        dealerName: workflowResult.polkDealer.dealerName,
                        value: workflowResult.distinctUserCount,
                    };
                })
            );
        }

        if (tab === TABS.ADVERTISER_REPORTED_SALES) {
            const index = _adShares.findIndex((adShare) => isAdvertiserPolkDealer(adShare.polkDealer));

            if (index !== -1) {
                _adShares[index] = {
                    ..._adShares[index],
                    value: attributionMetrics.totalAdShares?.value ? attributionMetrics.totalAdShares?.value : 0,
                };
            }
        }
        return _adShares;
    }, [polkSalesWorkflow, polkOpportunitiesWorkflow]);

    const adSharesTotal: number = useMemo(() => {
        return adShares.map((as: AdShare) => as.value).reduce((a: number, b: number) => a + b, 0);
    }, [adShares]);

    return {
        fetchPolkSalesWorkflow,
        fetchPolkOpportunitiesWorkflow,
        fetchPolkSalesWorkflows,

        marketShares,
        marketSharesTotal,

        adShares,
        adSharesTotal,

        polkSalesAnalysis,
        setPolkSalesAnalysis,
        getPolkSalesAnalysis,
        fetchPolkSalesAnalysis,
    };
}
