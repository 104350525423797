import { useMemo, useState } from 'react';
import { DeleteOutlined as DeleteOutlinedIcon } from '@mui/icons-material';

import { Campaign } from '../../../types/Campaign';
import ConfirmDialog, { ConfirmDialogProps } from '../../../components/ConfirmDialog';
import AppLoader from '../../../components/AppLoader';
import CDButton from '../../../components/CDButton';
import ApiService from '../../../ApiService';

import { useInfoMessage } from '../../../hooks/useInfoMessage';

type CampaignDeleteButtonProps = {
    campaign: Campaign;
    onCampaignDelete: (campaign: Campaign) => void;
};

export default function CampaignDeleteButton({ campaign, onCampaignDelete }: CampaignDeleteButtonProps) {
    const { showInfoMessage } = useInfoMessage();

    const [loading, setLoading] = useState<boolean>(false);
    const [confirmDialogProps, setConfirmDialogProps] = useState<ConfirmDialogProps | null>(null);

    const deleteCampaign = () => {
        hideConfirm();

        setLoading(true);

        if (campaign && campaign.id) {
            ApiService.deleteCampaign(campaign.id)
                .then(() => {
                    setLoading(false);

                    showInfoMessage('success', 'Campaign has been deleted.');

                    onCampaignDelete(campaign);
                })
                .catch((error) => {
                    showInfoMessage('error', error.message);
                });
        }
    };

    const showDeleteConfirm = () => {
        setConfirmDialogProps({
            title: 'Are you sure you want to delete this campaign?',
            text: 'This cannot be undone.',
            onOk: () => deleteCampaign(),
            okText: 'Delete Campaign',
            okColor: 'error',
            okIcon: <DeleteOutlinedIcon />,
            onCancel: () => hideConfirm(),
        });
    };

    const hideConfirm = () => {
        setConfirmDialogProps(null);
    };

    const tooltipText = useMemo(() => {
        return campaign.totalSpend > 0 ? 'Campaigns with spend cannot be deleted' : 'Delete Campaign Permanently';
    }, [campaign.totalSpend]);

    return (
        <>
            <AppLoader loading={loading} />

            <CDButton
                color="error"
                tooltip={tooltipText}
                startIcon={<DeleteOutlinedIcon />}
                onClick={() => showDeleteConfirm()}
                disabled={campaign.totalSpend > 0}
            >
                Delete
            </CDButton>

            {confirmDialogProps && (
                <>
                    <ConfirmDialog
                        title={confirmDialogProps.title}
                        text={confirmDialogProps.text}
                        okText={confirmDialogProps.okText}
                        okIcon={confirmDialogProps.okIcon}
                        okColor={confirmDialogProps.okColor}
                        onCancel={confirmDialogProps.onCancel}
                        onOk={confirmDialogProps.onOk}
                    />
                </>
            )}
        </>
    );
}
