import { Authenticator, ThemeProvider, Theme } from '@aws-amplify/ui-react';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { useContext, useEffect } from 'react';
import { AgencyContext } from './App';
import '@aws-amplify/ui-react/styles.css';
import './Login.css';
import appTheme from './theme';
import { navBackground } from './theme';
import { alpha } from '@mui/system';

const components = {
    Header() {
        const agency = useContext(AgencyContext);

        return (
            <div className="Login-logo">
                <img alt="[cognition]" src={agency && agency.logoUrl ? agency?.logoUrl : '/logo.png'} />
            </div>
        );
    },
};

// MUI dark mode colors that aren't in the theme
const textPrimaryColor = '#667085';
const inputBorderColor = 'rgba(255, 255, 255, 0.23)';
const linkButtonBackground = {
    hover: alpha(appTheme.palette.primary.main, appTheme.palette.action.hoverOpacity),
    active: alpha(appTheme.palette.primary.main, appTheme.palette.action.activatedOpacity),
};

export default function Login() {
    const { authStatus } = useAuthenticator((context) => [context.authStatus]);

    useEffect(() => {
        if (authStatus === 'authenticated') {
            localStorage.setItem('X-COGNITION-TRIALFIRE', '1');
        }
    }, [authStatus]);

    const theme: Theme = {
        name: 'Login theme',
        tokens: {
            colors: {
                background: {
                    primary: {
                        value: navBackground,
                    },
                },
                font: {
                    active: {
                        value: textPrimaryColor,
                    },
                    interactive: {
                        value: textPrimaryColor,
                    },
                },
                brand: {
                    primary: {
                        '100': appTheme.palette.primary.main,
                    },
                },
            },
            components: {
                authenticator: {
                    router: {
                        borderWidth: '0px',
                        boxShadow: 'none',
                    },
                    form: {
                        padding: '16px 32px',
                    },
                },
                heading: {
                    color: textPrimaryColor,
                },
                field: {
                    label: {
                        color: textPrimaryColor,
                    },
                },
                fieldcontrol: {
                    borderColor: inputBorderColor,
                },
                textfield: {
                    borderColor: inputBorderColor,
                    color: textPrimaryColor,
                },
                button: {
                    color: textPrimaryColor,
                    primary: {
                        backgroundColor: appTheme.palette.primary.main,
                        color: appTheme.palette.primary.contrastText,
                        _hover: {
                            color: appTheme.palette.primary.contrastText,
                            backgroundColor: appTheme.palette.primary.dark,
                        },
                        _active: {
                            color: appTheme.palette.primary.contrastText,
                            backgroundColor: alpha(appTheme.palette.primary.dark, 0.8),
                        },
                        _focus: {
                            color: appTheme.palette.primary.contrastText,
                            backgroundColor: alpha(appTheme.palette.primary.dark, 0.8),
                        },
                    },
                    link: {
                        _hover: {
                            backgroundColor: linkButtonBackground.hover,
                            color: textPrimaryColor,
                        },
                        _active: {
                            backgroundColor: linkButtonBackground.active,
                            color: textPrimaryColor,
                        },
                        _focus: {
                            backgroundColor: linkButtonBackground.active,
                            color: textPrimaryColor,
                        },
                    },
                    _hover: {
                        backgroundColor: linkButtonBackground.hover,
                        borderColor: appTheme.palette.grey[800],
                        color: textPrimaryColor,
                    },
                },
            },
        },
    };

    return (
        <div
            style={{
                width: '100%',
                height: '100vh',
                backgroundColor: navBackground,
            }}
        >
            <ThemeProvider theme={theme}>
                <Authenticator className="Login" components={components} hideSignUp />
            </ThemeProvider>
        </div>
    );
}
