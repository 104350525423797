import { Alert, Button, Grid, InputAdornment, MenuItem, TextField, Tooltip } from '@mui/material';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { Dealer } from '../../types/Dealer';
import ApiService from '../../ApiService';
import { RefreshOutlined } from '@mui/icons-material';
import DealerExportSitesGrid from './DealerExportSitesGrid';

export interface DealerExportSitesProps {
    dealer: Dealer;
    onChange: (dealer: Dealer) => void;
}

export default function DealerExportSites(props: DealerExportSitesProps) {
    const { dealer, onChange } = props;
    const [autoExportCount, setAutoExportCount] = useState<number | null>(null);

    const getAutoExportCount = useCallback(() => {
        if (dealer.id) {
            ApiService.getAutoExportCount(
                dealer.id,
                dealer?.ageExportFilterOperator,
                dealer.ageExportFilterValue,
                dealer.newUsedFilter,
                dealer.certifiedFilter,
                dealer.customLabelFilter,
                dealer.imageFilter
            ).then((response) => {
                setAutoExportCount(response.data);
            });
        }
    }, [
        dealer.id,
        dealer.ageExportFilterOperator,
        dealer.ageExportFilterValue,
        dealer.certifiedFilter,
        dealer.newUsedFilter,
        dealer.customLabelFilter,
        dealer.imageFilter,
    ]);

    useEffect(() => {
        getAutoExportCount();
    }, [getAutoExportCount]);

    const handleChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            const field = event.target.name;
            const updatedDealer: Dealer = {
                ...dealer,
                [field]: event.target.value,
            };
            onChange(updatedDealer);
        },
        [dealer, onChange]
    );

    const handleRefreshClick = useCallback(() => getAutoExportCount(), [getAutoExportCount]);

    return (
        <Grid container spacing={2}>
            <Grid item xs={4}>
                <TextField
                    name="publicId"
                    label="Public ID (Global 3P ID)"
                    value={dealer.publicId}
                    fullWidth={true}
                    size="small"
                    variant="outlined"
                    onChange={handleChange}
                    InputLabelProps={{ shrink: true }}
                />
            </Grid>
            <Grid item xs={8} />

            <Grid item xs={4}>
                <TextField
                    name="partsId"
                    label="Part Feed ID"
                    size="small"
                    fullWidth={true}
                    variant="outlined"
                    onChange={handleChange}
                    value={dealer.partsId}
                    InputLabelProps={{ shrink: true }}
                />
            </Grid>
            <Grid item xs={8} />

            <Grid item xs={12}>
                <TextField
                    name="legalDisclaimer"
                    label="Legal Disclaimer"
                    value={dealer.legalDisclaimer}
                    fullWidth={true}
                    size="small"
                    variant="outlined"
                    onChange={handleChange}
                    inputProps={{ maxLength: 400 }}
                    multiline
                    rows={3}
                    InputLabelProps={{ shrink: true }}
                />
            </Grid>

            <Grid item xs={4}>
                <TextField
                    fullWidth
                    name="preferredPhotoIndex"
                    label="Preferred Photo"
                    type="number"
                    size="small"
                    variant="outlined"
                    onChange={handleChange}
                    value={dealer.preferredPhotoIndex}
                    InputLabelProps={{ shrink: true }}
                />
            </Grid>

            <Grid item xs={4}>
                <TextField
                    fullWidth
                    name="minPhotoExportCount"
                    label="Minimum Photo Count"
                    type="number"
                    size="small"
                    helperText="Minimum Photo Count Required For Export"
                    variant="outlined"
                    onChange={handleChange}
                    value={dealer.minPhotoExportCount}
                    InputLabelProps={{ shrink: true }}
                />
            </Grid>
            <Grid item xs={3}>
                <Tooltip title="Enter a comma-separated list of values that are contained in the Photo URL to exclude them from export">
                    <TextField
                        fullWidth
                        name="imageFilter"
                        label="Exclude Photo URLs"
                        size="small"
                        helperText=""
                        variant="outlined"
                        onChange={handleChange}
                        value={dealer.imageFilter}
                        InputLabelProps={{ shrink: true }}
                    />
                </Tooltip>
            </Grid>

            <Grid item xs={4}>
                <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    label="Vehicle Age Filter Operator"
                    name="ageExportFilterOperator"
                    select
                    value={dealer.ageExportFilterOperator ?? ''}
                    disabled={false}
                    onChange={handleChange}
                    InputLabelProps={{ shrink: true }}
                    SelectProps={{ native: false }}
                >
                    <MenuItem key={'>='} value={'>='}>
                        &gt;=
                    </MenuItem>
                    <MenuItem key={'<'} value={'<'}>
                        &lt;=
                    </MenuItem>
                </TextField>
            </Grid>
            <Grid item xs={4}>
                <TextField
                    fullWidth
                    name="ageExportFilterValue"
                    label="Age of Vehicles to include in exports"
                    type="number"
                    size="small"
                    helperText=""
                    variant="outlined"
                    onChange={handleChange}
                    required={dealer.ageExportFilterOperator !== null}
                    value={dealer.ageExportFilterValue}
                    InputLabelProps={{ shrink: true }}
                />
            </Grid>
            <Grid item xs={3}>
                <TextField
                    fullWidth
                    name="customLabelFilter"
                    label="Vehicle Custom Label Filter"
                    size="small"
                    helperText=""
                    variant="outlined"
                    onChange={handleChange}
                    value={dealer.customLabelFilter}
                    InputLabelProps={{ shrink: true }}
                />
            </Grid>

            <Grid item xs={4}>
                <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    label="Vehicle New/Used Filter"
                    name="newUsedFilter"
                    select
                    value={dealer.newUsedFilter ?? ''}
                    disabled={false}
                    onChange={handleChange}
                    InputLabelProps={{ shrink: true }}
                    SelectProps={{ native: false }}
                >
                    <MenuItem key={'all'} value={''}>
                        All
                    </MenuItem>
                    <MenuItem key={'new'} value={'new'}>
                        New
                    </MenuItem>
                    <MenuItem key={'used'} value={'used'}>
                        Used
                    </MenuItem>
                </TextField>
            </Grid>
            <Grid item xs={4}>
                <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    label="Vehicle Certified Filter"
                    name="certifiedFilter"
                    select
                    value={dealer.certifiedFilter ?? ''}
                    disabled={false}
                    onChange={handleChange}
                    InputLabelProps={{ shrink: true }}
                    SelectProps={{ native: false }}
                >
                    <MenuItem key={'all'} value={''}>
                        All
                    </MenuItem>
                    <MenuItem key={'certified'} value={'certified'}>
                        Certified
                    </MenuItem>
                </TextField>
            </Grid>

            <Grid item xs={3}>
                <TextField
                    fullWidth
                    label="Current Exportable Records"
                    size="small"
                    focused={dealer.ageExportFilterOperator !== null && dealer.ageExportFilterValue !== null}
                    color="success"
                    variant="outlined"
                    onChange={handleChange}
                    value={autoExportCount ?? ''}
                    InputProps={{
                        inputProps: { tabIndex: -1 },
                        endAdornment: (
                            <InputAdornment position="end">
                                <Tooltip title="Recalculate Count">
                                    <Button onClick={handleRefreshClick} endIcon={<RefreshOutlined />} />
                                </Tooltip>
                            </InputAdornment>
                        ),
                    }}
                    InputLabelProps={{ shrink: true }}
                />
            </Grid>

            <Grid item xs={12}>
                <DealerExportSitesGrid dealer={dealer} />
            </Grid>

            <Grid item xs={12}>
                <Alert severity="info" sx={{ mt: 2 }}>
                    Data is exported daily between 8:00 am - 9:00 am EST.
                </Alert>
            </Grid>
        </Grid>
    );
}
