import { useContext, useEffect, useState } from 'react';
import AppBreadcrumbs from '../../components/AppBreadcrumbs';
import Column from '../../components/Column';
import Heading from '../../components/Heading';
import ApiService from '../../ApiService';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Alert,
    Chip,
    Grid,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
} from '@mui/material';
import { ContentCopyOutlined, ExpandMoreOutlined } from '@mui/icons-material';
import { UserContext } from '../../App';

interface QsDataSet {
    dataSet: string;
    url: string;
    definition: string;
    fields: QsDataSetField[];
}

interface QsDataSetField {
    category: string;
    fieldName: string;
    definition: string;
    dataType: string;
    example: string;
}

export default function AnalyticsApiPage() {
    const [apiKey, setApiKey] = useState<string | null>();
    const [dataSets, setDataSets] = useState<QsDataSet[]>([]);
    const { userContext } = useContext(UserContext);
    const isAdmin = userContext.isAdmin();

    const origin = document.location.origin;

    useEffect(() => {
        ApiService.getAgencyForUser()
            .then((response) => {
                setApiKey(response.data.apiKey);
            })
            .catch(() => {});

        ApiService.getAnalyticsDocs().then((response) => {
            setDataSets(response.data);
        });
    }, []);

    function apiUrl(dataSet: QsDataSet) {
        if (apiKey) {
            return (
                <a target="new" href={'/api/analytics/' + dataSet.dataSet + '?apiKey=' + apiKey}>
                    {origin}/api/analytics/{dataSet.dataSet}
                </a>
            );
        } else {
            return (
                <span>
                    {origin}/api/analytics/{dataSet.dataSet}
                </span>
            );
        }
    }
    return (
        <Column sx={{ height: '100%' }}>
            <Column gap={1}>
                <AppBreadcrumbs items={[]} />

                <Heading>Analytics API</Heading>
            </Column>

            <Column gap={0} sx={{ height: '100%' }}>
                {!apiKey && !isAdmin && (
                    <>
                        <Grid container>
                            <Grid item xs={12} sx={{ marginBottom: '30px' }}>
                                <Alert variant="filled" severity="info">
                                    Activate Analytics API access through your Cognition Digital customer success rep or
                                    by sending a request to{' '}
                                    <a href="mailto:support@cognitiondigital.io?subject=Grant CD Analytics API Access&body=Hi, I would like to be granted access to the Cognition Digital Analytics API">
                                        support@cognitiondigital.io
                                    </a>
                                </Alert>
                            </Grid>
                        </Grid>
                    </>
                )}

                <Grid container spacing={1} sx={{ marginBottom: '20px' }}>
                    {apiKey && (
                        <>
                            <Grid item sm={3} sx={{ fontWeight: 'bold' }}>
                                API Key :
                            </Grid>
                            <Grid item sm={9}>
                                <Tooltip title="Copy to Clipboard">
                                    <IconButton
                                        size="small"
                                        tabIndex={-1}
                                        onClick={() => {
                                            navigator.clipboard.writeText(String(apiKey));
                                        }}
                                    >
                                        <ContentCopyOutlined />
                                    </IconButton>
                                </Tooltip>
                                {apiKey}
                                <br />
                                <Typography sx={{ fontStyle: 'italic' }}>
                                    Required query parameter on every Api call ?apiKey={apiKey}
                                </Typography>
                            </Grid>
                        </>
                    )}
                    <Grid item sm={3} sx={{ fontWeight: 'bold' }}>
                        Optional parameters:
                    </Grid>
                    <Grid item sm={9} />

                    <Grid item sm={3} sx={{ fontWeight: 'bold' }}>
                        Parameter
                    </Grid>
                    <Grid item sm={2} sx={{ fontWeight: 'bold' }}>
                        Format
                    </Grid>
                    <Grid item sm={7} sx={{ fontWeight: 'bold' }}>
                        Description
                    </Grid>

                    <Grid item sm={3}>
                        numDays
                    </Grid>
                    <Grid item sm={2}>
                        1-7
                    </Grid>
                    <Grid item sm={7}>
                        Returns data from the current date minus the number of days
                    </Grid>

                    <Grid item sm={3}>
                        startDate
                    </Grid>
                    <Grid item sm={2}>
                        yyyy-MM-dd
                    </Grid>
                    <Grid item sm={7}>
                        Returns data between the start and end dates
                    </Grid>

                    <Grid item sm={3}>
                        endDate
                    </Grid>
                    <Grid item sm={2}>
                        yyyy-MM-dd
                    </Grid>
                    <Grid item sm={7}>
                        Required when specifying startDate
                    </Grid>

                    <Grid item sm={3}>
                        cognitionAdvertiserId
                    </Grid>
                    <Grid item sm={2}>
                        Numeric
                    </Grid>
                    <Grid item sm={7}>
                        Id of an Advertiser assigned to your Agency
                    </Grid>
                </Grid>

                {dataSets.map((dataSet) => {
                    return (
                        <Accordion sx={{ pb: '10px' }} key={dataSet.dataSet}>
                            <AccordionSummary expandIcon={<ExpandMoreOutlined />}>
                                <Grid container spacing={1}>
                                    <Grid item xs={4}>
                                        {dataSet.dataSet}
                                    </Grid>
                                    <Grid item xs={8}>
                                        {dataSet.definition}
                                    </Grid>
                                </Grid>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid color="info" container spacing={2} sx={{ pb: '10px' }}>
                                    <Grid item xs={1}>
                                        <Chip color="info" label="GET" />
                                    </Grid>
                                    <Grid item xs={11} sx={{ mt: '5px' }}>
                                        {apiUrl(dataSet)}
                                    </Grid>
                                </Grid>

                                <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 500 }} size="small">
                                        <TableHead sx={{ fontWeight: 'bold' }}>
                                            <TableRow>
                                                <TableCell>Category</TableCell>
                                                <TableCell>Field Name</TableCell>
                                                <TableCell>Definition</TableCell>
                                                <TableCell>Example</TableCell>
                                                <TableCell>Data Type</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {dataSet.fields.map((field) => {
                                                return (
                                                    <TableRow key={dataSet.dataSet + field.fieldName}>
                                                        <TableCell>{field.category}</TableCell>
                                                        <TableCell>{field.fieldName}</TableCell>
                                                        <TableCell>{field.definition}</TableCell>
                                                        <TableCell>{field.example}</TableCell>
                                                        <TableCell>{field.dataType}</TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </AccordionDetails>
                        </Accordion>
                    );
                })}
            </Column>
        </Column>
    );
}
