import { useEffect, useMemo, useState } from 'react';
import { Grid } from '@mui/material';
import moment from 'moment';

import { PolkSalesWorkflow } from '../../types/Polk';
import Utils from '../../components/Utils';
import ApiService from '../../ApiService';

import { Metric } from '../../components/Metrics/MetricCard';
import { setupMetricChange } from '../../components/Metrics/MetricCard';
import { useAttributionPageContext } from '../../hooks/useAttributionPage';
import { useAmcReachAndFrequencyList } from '../../hooks/useAmcReachAndFrequencyList';
import MetricCard from '../../components/Metrics/MetricCard';

export default function PolkMatchbackMetrics() {
    const {
        dealer,
        attributionDate,
        attributionStatus,
        isFetchingAttributionStatus,

        attributionMetrics,
        setAttributionMetrics,
    } = useAttributionPageContext();

    const [metrics, setMetrics] = useState<any[]>([]);
    const [previousMetrics, setPreviousMetrics] = useState<any[]>([]);

    const [isFetchingMetrics, setIsFetchingMetrics] = useState<boolean>(false);
    const [isFetchingPreviousMetrics, setisFetchingPreviousMetrics] = useState<boolean>(false);

    const { amcReachAndFrequencyList, fetchAmcReachAndFrequencyList, isFetchingAmcReachAndFrequencyList } =
        useAmcReachAndFrequencyList();

    const {
        amcReachAndFrequencyList: previousAmcReachAndFrequencyList,
        fetchAmcReachAndFrequencyList: fetchPreviousAmcReachAndFrequencyList,
        isFetchingAmcReachAndFrequencyList: isFetchingPreviousAmcReachAndFrequencyList,
    } = useAmcReachAndFrequencyList();

    function formatDate(_date: any): string {
        return moment(_date).format('YYYY-MM-DD');
    }

    function getSumTotalCampaignPerformanceMetric(_metrics: any[], key: string): number {
        return _metrics.map((_metric: any) => _metric?.[key] ?? 0).reduce((_total, _subtotal) => _total + _subtotal, 0);
    }

    function getMetricByWorkflowResult(
        dataSet: 'polk_sales' | 'polk_opportunities',
        target: string,
        format: 'number' | 'dollar' | 'decimal' | 'percent'
    ): Metric {
        let _metric: Metric = {
            format: format,
            value: 0,
        };

        switch (dataSet) {
            case 'polk_sales':
                if (polkSalesWorkflow && polkSalesWorkflow.workflowResults.length > 0) {
                    _metric.value = getSumTotalCampaignPerformanceMetric(polkSalesWorkflow.workflowResults, target);
                }

                if (previousPolkSalesWorkflow && previousPolkSalesWorkflow.workflowResults.length > 0) {
                    _metric.previousValue = getSumTotalCampaignPerformanceMetric(
                        previousPolkSalesWorkflow.workflowResults,
                        target
                    );
                }
                break;

            case 'polk_opportunities':
                if (polkOpportunitiesWorkflow && polkOpportunitiesWorkflow.workflowResults.length > 0) {
                    _metric.value = getSumTotalCampaignPerformanceMetric(
                        polkOpportunitiesWorkflow.workflowResults,
                        target
                    );
                }

                if (previousPolkOpportunitiesWorkflow && previousPolkOpportunitiesWorkflow.workflowResults.length > 0) {
                    _metric.previousValue = getSumTotalCampaignPerformanceMetric(
                        previousPolkOpportunitiesWorkflow.workflowResults,
                        target
                    );
                }
                break;
        }

        _metric = setupMetricChange(_metric);

        return _metric;
    }

    function getMetricByCampaignPerformance(
        target: string,
        format: 'number' | 'dollar' | 'decimal' | 'percent'
    ): Metric {
        let _metric: Metric = {
            format: format,
            value: 0,
        };

        if (metrics.length > 0) {
            _metric.value = getSumTotalCampaignPerformanceMetric(metrics, target);
        }

        if (previousMetrics.length > 0) {
            _metric.previousValue = getSumTotalCampaignPerformanceMetric(previousMetrics, target);
        }

        _metric = setupMetricChange(_metric);

        return _metric;
    }

    const polkSalesWorkflow = useMemo(() => {
        let last = null;
        // get the last workflow that has a status of 'SUCCEEDED' or any other status if there is no 'SUCCEEDED' status
        for (const _polkSalesWorkflow of attributionStatus.salesWorkflows) {
            const _attributionStartDate: string = formatDate(attributionDate);
            const _workflowStartDate: string = formatDate(Utils.getDate(_polkSalesWorkflow.amcUpload.beginDate));
            if (_polkSalesWorkflow.amcUpload.dataSet !== 'polk_sales') continue;
            if (_attributionStartDate !== _workflowStartDate) continue;
            last = last || _polkSalesWorkflow;
            if (last?.status !== 'SUCCEEDED' && _polkSalesWorkflow.status === 'SUCCEEDED') {
                last = _polkSalesWorkflow;
                break;
            }
        }
        return last;
    }, [attributionDate, attributionStatus]);

    const previousPolkSalesWorkflow = useMemo(() => {
        return attributionStatus.salesWorkflows.find((_polkSalesWorkflow: PolkSalesWorkflow) => {
            const _attributionStartDate: string = formatDate(moment(attributionDate).subtract(1, 'months'));
            const _workflowStartDate: string = formatDate(Utils.getDate(_polkSalesWorkflow.amcUpload.beginDate));

            if (_polkSalesWorkflow.amcUpload.dataSet !== 'polk_sales') {
                return false;
            }

            if (_attributionStartDate !== _workflowStartDate) {
                return false;
            }

            if (!(polkSalesWorkflow && _polkSalesWorkflow.updateDate < polkSalesWorkflow.updateDate)) {
                return false;
            }

            return true;
        });
    }, [attributionDate, attributionStatus, polkSalesWorkflow]);

    const polkOpportunitiesWorkflow = useMemo(() => {
        return attributionStatus.salesWorkflows.find((_polkSalesWorkflow: PolkSalesWorkflow) => {
            const _attributionStartDate: string = formatDate(attributionDate);
            const _workflowStartDate: string = formatDate(Utils.getDate(_polkSalesWorkflow.amcUpload.beginDate));

            if (_polkSalesWorkflow.amcUpload.dataSet !== 'polk_opportunities') {
                return false;
            }

            if (_attributionStartDate !== _workflowStartDate) {
                return false;
            }

            return true;
        });
    }, [attributionDate, attributionStatus]);

    const previousPolkOpportunitiesWorkflow = useMemo(() => {
        return attributionStatus.salesWorkflows.find((_polkSalesWorkflow: PolkSalesWorkflow) => {
            const _attributionStartDate: string = formatDate(moment(attributionDate).subtract(1, 'months'));
            const _workflowStartDate: string = formatDate(Utils.getDate(_polkSalesWorkflow.amcUpload.beginDate));

            if (_polkSalesWorkflow.amcUpload.dataSet !== 'polk_opportunities') {
                return false;
            }

            if (_attributionStartDate !== _workflowStartDate) {
                return false;
            }

            if (!(polkSalesWorkflow && _polkSalesWorkflow.updateDate < polkSalesWorkflow.updateDate)) {
                return false;
            }

            return true;
        });
    }, [attributionDate, attributionStatus, polkSalesWorkflow]);

    const totalSales = useMemo(() => {
        return getMetricByWorkflowResult('polk_sales', 'salesCount', 'number');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [polkSalesWorkflow, previousPolkSalesWorkflow]);

    const attributedSales = useMemo(() => {
        return getMetricByWorkflowResult('polk_sales', 'distinctUserCount', 'number');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [polkSalesWorkflow, previousPolkSalesWorkflow]);

    const totalMarketShares = useMemo(() => {
        let _metric: Metric = {
            format: 'number',
            value: 0,
        };

        const salesWorlkflowMetric = getMetricByWorkflowResult('polk_sales', 'salesCount', 'number');

        const opportunityWorlkflowMetric = getMetricByWorkflowResult('polk_opportunities', 'salesCount', 'number');

        _metric.value = (salesWorlkflowMetric.value as number) + (opportunityWorlkflowMetric.value as number);
        _metric.previousValue =
            (salesWorlkflowMetric.previousValue as number) + (opportunityWorlkflowMetric.previousValue as number);

        _metric = setupMetricChange(_metric);

        return _metric;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [polkSalesWorkflow, previousPolkSalesWorkflow, polkOpportunitiesWorkflow, previousPolkOpportunitiesWorkflow]);

    const totalAdShares = useMemo(() => {
        let _metric: Metric = {
            format: 'number',
            value: 0,
        };

        const salesWorlkflowMetric = getMetricByWorkflowResult('polk_sales', 'distinctUserCount', 'number');

        const opportunityWorlkflowMetric = getMetricByWorkflowResult(
            'polk_opportunities',
            'distinctUserCount',
            'number'
        );

        _metric.value = (salesWorlkflowMetric.value as number) + (opportunityWorlkflowMetric.value as number);
        _metric.previousValue =
            (salesWorlkflowMetric.previousValue as number) + (opportunityWorlkflowMetric.previousValue as number);

        _metric = setupMetricChange(_metric);

        return _metric;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [polkSalesWorkflow, previousPolkSalesWorkflow, polkOpportunitiesWorkflow, previousPolkOpportunitiesWorkflow]);

    const totalImpressions: Metric = useMemo(() => {
        return getMetricByCampaignPerformance('impressions', 'number');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [metrics, previousMetrics]);

    const totalClickthroughs: Metric = useMemo(() => {
        return getMetricByCampaignPerformance('clickthroughs', 'number');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [metrics, previousMetrics]);

    const totalDailyReach: Metric = useMemo(() => {
        let _metric: Metric = {
            format: 'number',
            value: 0,
        };

        if (amcReachAndFrequencyList.length > 0) {
            _metric.value = amcReachAndFrequencyList
                .map((amcReachAndFrequency: any) => amcReachAndFrequency?.reach as number)
                .reduce((a: number, b: number) => a + b, 0);
        }

        if (previousAmcReachAndFrequencyList.length > 0) {
            _metric.previousValue = previousAmcReachAndFrequencyList
                .map((amcReachAndFrequency: any) => amcReachAndFrequency?.reach as number)
                .reduce((a: number, b: number) => a + b, 0);
        }

        _metric = setupMetricChange(_metric);

        return _metric;
    }, [amcReachAndFrequencyList, previousAmcReachAndFrequencyList]);

    const totalImpressionFrequency: Metric = useMemo(() => {
        let _metric: Metric = {
            format: 'number',
            value: 0,
        };

        if (amcReachAndFrequencyList.length > 0) {
            _metric.value = amcReachAndFrequencyList
                .map((amcReachAndFrequency: any) => amcReachAndFrequency?.frequency as number)
                .reduce((a: number, b: number) => a + b, 0);
            _metric.value = Math.round(_metric.value / amcReachAndFrequencyList.length);
        }

        if (previousAmcReachAndFrequencyList.length > 0) {
            _metric.previousValue = previousAmcReachAndFrequencyList
                .map((amcReachAndFrequency: any) => amcReachAndFrequency?.frequency as number)
                .reduce((a: number, b: number) => a + b, 0);
            _metric.previousValue = Math.round(_metric.previousValue / previousAmcReachAndFrequencyList.length);
        }

        _metric = setupMetricChange(_metric);

        return _metric;
    }, [amcReachAndFrequencyList, previousAmcReachAndFrequencyList]);

    // const totalSpendMetricLabel: string = useMemo(() => {
    //     if (attributionDate) {
    //         return `${moment(attributionDate).format('MMMM')} Investment`;
    //     }

    //     return 'Total Investment';
    // }, [attributionDate]);

    // const costPerSoldTooltip: string = useMemo(() => {
    //     if (attributionDate) {
    //         return '{month} Investment divided by Total Ad Exposed Sales'.replace(
    //             '{month}',
    //             moment(attributionDate).format('MMMM')
    //         );
    //     }

    //     return 'Total Investment divided by Total Ad Exposed Sales';
    // }, [attributionDate]);

    useEffect(() => {
        const dealerId = dealer?.id ?? 0;

        if (dealerId > 0 && attributionDate) {
            const startDate: Date = moment(attributionDate).startOf('month').toDate();
            const endDate: Date = moment(attributionDate).endOf('month').toDate();

            const previousStartDate: Date = moment(attributionDate).subtract(1, 'month').startOf('month').toDate();
            const previousEndDate: Date = moment(attributionDate).subtract(1, 'month').endOf('month').toDate();

            setIsFetchingMetrics(true);
            setisFetchingPreviousMetrics(true);

            ApiService.getCampaignPerformanceList({
                dealerId: dealerId,
                startDate: startDate,
                endDate: endDate,
            }).then((response) => {
                setIsFetchingMetrics(false);
                setMetrics(response.data);
            });

            ApiService.getCampaignPerformanceList({
                dealerId: dealerId,
                startDate: previousStartDate,
                endDate: previousEndDate,
            }).then((response) => {
                setisFetchingPreviousMetrics(false);
                setPreviousMetrics(response.data);
            });

            fetchAmcReachAndFrequencyList({
                dealerId: dealerId,
                campaignName: 'Global',
                startDate: startDate,
                endDate: endDate,
            });

            fetchPreviousAmcReachAndFrequencyList({
                dealerId: dealerId,
                campaignName: 'Global',
                startDate: previousStartDate,
                endDate: previousEndDate,
            });
        }
    }, [dealer, attributionDate, fetchAmcReachAndFrequencyList, fetchPreviousAmcReachAndFrequencyList]);

    useEffect(() => {
        setAttributionMetrics({
            ...attributionMetrics,
            ...{
                totalSales,
                attributedSales,
                // totalSpend,
                // costPerSold,
                totalImpressions,
                totalClickthroughs,
                totalImpressionFrequency,
                totalDailyReach,
                totalMarketShares,
                totalAdShares,
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        totalSales,
        attributedSales,
        // totalSpend,
        // costPerSold,
        totalImpressions,
        totalClickthroughs,
        totalImpressionFrequency,
        totalDailyReach,
        totalMarketShares,
        totalAdShares,
    ]);

    return (
        <Grid container spacing={2}>
            <Grid item xs={4}>
                <MetricCard
                    loading={isFetchingAttributionStatus}
                    metric={totalSales}
                    label="Total Sales"
                    tooltip="Total advertiser sales as reported by Polk by S&P Mobility"
                />
            </Grid>

            <Grid item xs={4}>
                <MetricCard
                    loading={isFetchingAttributionStatus}
                    metric={attributedSales}
                    label="Total Ad Exposed Sales"
                    tooltip="Total households that were exposed to an advertisement and made a purchase for the selected time period"
                />
            </Grid>

            {/*<Grid item xs={3}>
                <MetricCard
                    metric={totalSpend}
                    label={totalSpendMetricLabel}
                    tooltip="Total advertiser ad spend for the selected time period"
                />
            </Grid>*/}

            {/*<Grid item xs={3}>
                <MetricCard
                    metric={costPerSold}
                    label="Cost Per Sale"
                    tooltip={costPerSoldTooltip}
                />
            </Grid>*/}

            <Grid item xs={4}>
                <MetricCard
                    loading={isFetchingMetrics || isFetchingPreviousMetrics}
                    metric={totalImpressions}
                    label="Total Exposures"
                    tooltip="Total number of ad exposures or impressions for the selected advertiser for the selected time period"
                />
            </Grid>

            <Grid item xs={4}>
                <MetricCard
                    loading={isFetchingMetrics || isFetchingPreviousMetrics}
                    metric={totalClickthroughs}
                    label="Total Clicks"
                    tooltip="Total number of clicks on an advertisement for the selected time period"
                />
            </Grid>

            <Grid item xs={4}>
                <MetricCard
                    loading={isFetchingAmcReachAndFrequencyList || isFetchingPreviousAmcReachAndFrequencyList}
                    metric={totalDailyReach}
                    label="Household Reach"
                    tooltip="Total number of households exposed to an advertisement"
                />
            </Grid>

            <Grid item xs={4}>
                <MetricCard
                    loading={isFetchingAmcReachAndFrequencyList || isFetchingPreviousAmcReachAndFrequencyList}
                    metric={totalImpressionFrequency}
                    label="Avg. Household Frequency"
                    tooltip="Avg. number of times a household saw advertisements across all media types and channels"
                    inverseColor={true}
                />
            </Grid>
        </Grid>
    );
}
