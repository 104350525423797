import { Alert, AlertColor, Snackbar } from '@mui/material';

import { createContext, useContext, useState, ReactElement } from 'react';

export interface GlobalInfoMessage {
    severity?: AlertColor;
    message: JSX.Element | string | null;
}

export const emptyMessage: GlobalInfoMessage = {
    message: null,
};

export type InfoMessageContextType = {
    infoMessage: GlobalInfoMessage;
    showMessage: (alertColor: AlertColor, message: string) => void;
    clearMessage: () => void;
};

export const InfoMessageContext = createContext<InfoMessageContextType>({
    infoMessage: emptyMessage,
    showMessage: () => {},
    clearMessage: () => {},
});

export function InfoMessageProvider(props: { children: ReactElement }) {
    const [infoMessage, setInfoMessage] = useState<GlobalInfoMessage>({
        message: null,
    });

    function showMessage(alertColor: AlertColor, message: string) {
        setInfoMessage({ message: message, severity: alertColor });
    }

    function clearMessage(): void {
        setInfoMessage({ message: null });
    }

    const contextValue = {
        infoMessage: infoMessage,
        showMessage: (alertColor: AlertColor, message: string) => showMessage(alertColor, message),
        clearMessage: clearMessage,
    };

    return <InfoMessageContext.Provider value={contextValue}>{props.children}</InfoMessageContext.Provider>;
}

export function GlobalInfoMessageComponent() {
    const { infoMessage, clearMessage } = useContext(InfoMessageContext);

    if (infoMessage.message) {
        return (
            <Snackbar
                open={infoMessage.message !== null}
                autoHideDuration={infoMessage.severity === 'success' ? 5000 : 60000}
                onClose={() => clearMessage()}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert onClose={() => clearMessage()} severity={infoMessage.severity} sx={{ width: '100%' }}>
                    {infoMessage.message}
                </Alert>
            </Snackbar>
        );
    } else {
        return null;
    }
}
