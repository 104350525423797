import ApiService from '../../ApiService';
import { ChangeEvent, useEffect, useState } from 'react';
import { Permission } from '../../types/User';
import { Checkbox, Chip, MenuItem } from '@mui/material';
import { CDTextField } from '../../components/CDTextField';

export interface PermissionSelectProps {
    userType: string | undefined;
    permissions: Permission[];
    onChange(permissions: Permission[]): void;
}

export default function PermissionSelectComponent(props: PermissionSelectProps) {
    const [permissionList, setPermissionList] = useState<Permission[]>([]);

    useEffect(() => {
        if (props.userType) {
            getPermissions(props.userType);
        }
    }, [props.userType]);

    function getPermissions(userType: string) {
        ApiService.getPermissions(userType)
            .then((response) => {
                setPermissionList(response.data);
            })
            .catch(() => {});
    }

    function removePermission(id: number): void {
        let permissions = props.permissions.filter((p) => p.id !== id);
        props.onChange(permissions);
    }

    function handlePermissionChange(event: ChangeEvent<HTMLInputElement>) {
        let id = +event.target.value;
        const permission = permissionList.filter((p) => p.id === id)[0];
        const hasPermission = props.permissions.filter((p) => p.id === id);
        if (hasPermission.length === 0) {
            props.permissions.push(permission);
            props.onChange(props.permissions);
        } else {
            removePermission(id);
        }
    }

    return (
        <>
            <CDTextField
                label="Add Permission"
                fullWidth
                select
                value={[]}
                SelectProps={{
                    multiple: true,
                }}
                onChange={handlePermissionChange}
            >
                {permissionList.map((permission) => (
                    <MenuItem key={permission.id} value={permission.id}>
                        <Checkbox checked={props.permissions.find((p) => p.id === permission.id) !== undefined} />
                        {permission.description}
                    </MenuItem>
                ))}
            </CDTextField>
            {props.permissions.map((permission) => {
                return (
                    <Chip
                        sx={{ margin: '5px' }}
                        onDelete={() => removePermission(permission.id)}
                        key={'chip-' + permission.id}
                        color="info"
                        label={permission.description}
                        variant="filled"
                    ></Chip>
                );
            })}
        </>
    );
}
