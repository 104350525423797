import AppBreadcrumbs from '../../../components/AppBreadcrumbs';
import Column from '../../../components/Column';
import Heading from '../../../components/Heading';
import { ChangeEvent, useEffect, useState } from 'react';
import { Button, IconButton, InputAdornment, TextField } from '@mui/material';
import ApiService from '../../../ApiService';
import { AddOutlined, ClearOutlined, SearchOutlined } from '@mui/icons-material';
import { GridRowParams } from '@mui/x-data-grid';
import { GridToolbarContainer } from '../../../components/DataGrid';
import { AmcInstance } from '../../../types/AmcInstance';
import { useNavigate } from 'react-router-dom';
import AmcInstanceCreateFormDialog from './AmcInstanceCreateFormDialog';
import CDGrid from '../../../components/CDGrid';

export default function AmcInstanceListPage() {
    const navigate = useNavigate();
    const [amcInstances, setAmcInstances] = useState<AmcInstance[]>([]);
    const [allAmcInstances, setAllAmcInstances] = useState<AmcInstance[]>([]);
    const [searchText, setSearchText] = useState<string>('');
    const [amcInstanceFormDialogOpen, setAmcInstanceFormDialogOpen] = useState<boolean>(false);

    function refresh() {
        ApiService.getAmcInstances().then((response) => {
            setAmcInstances(response.data);
            setAllAmcInstances(response.data);
        });
    }

    useEffect(() => {
        refresh();
    }, []);

    function handleEdit(params: GridRowParams) {
        navigate(String(params.row.id), {
            state: { ...params.row, isNew: false },
        });
    }

    function clearSearch() {
        setSearchText('');
        setAmcInstances(allAmcInstances);
    }

    function search(event: ChangeEvent<HTMLInputElement>) {
        event.preventDefault();
        setSearchText(event.target.value);
        const filteredRows = allAmcInstances.filter((row: AmcInstance) => {
            return (
                row.name.toLowerCase().indexOf(event.target.value.toLowerCase()) > -1 ||
                row.instanceName.toLowerCase().indexOf(event.target.value.toLowerCase()) > -1
            );
        });
        setAmcInstances(filteredRows);
    }

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <TextField
                    inputRef={(input) => input && input.focus()}
                    id="searchText"
                    value={searchText}
                    label="Instance Name Search"
                    onChange={search}
                    size="small"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchOutlined color="action" fontSize="small" />
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton size="small" onClick={() => clearSearch()}>
                                    <ClearOutlined fontSize="small" />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />

                <div style={{ flexGrow: 1 }} />

                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        setAmcInstanceFormDialogOpen(true);
                    }}
                    startIcon={<AddOutlined />}
                >
                    Add
                </Button>
            </GridToolbarContainer>
        );
    }

    return (
        <Column sx={{ height: '100%' }}>
            <Column gap={1}>
                <AppBreadcrumbs items={[]} />

                <Heading>AMC Instances</Heading>
            </Column>

            <Column gap={2} sx={{ height: '100%' }}>
                <CDGrid
                    components={{ Toolbar: CustomToolbar }}
                    columns={[
                        {
                            width: 100,
                            field: 'id',
                            headerName: 'Instance ID',
                            renderCell: (params) => (
                                <Button
                                    onClick={() => {
                                        handleEdit(params.value);
                                    }}
                                >
                                    {params.value}
                                </Button>
                            ),
                        },
                        {
                            width: 360,
                            field: 'name',
                            headerName: 'Name',
                        },
                        {
                            width: 400,
                            field: 'instanceName',
                            headerName: 'Instance Name',
                        },
                        {
                            width: 400,
                            field: 'status',
                            headerName: 'Status',
                            flex: 1,
                        },
                    ]}
                    autoHeight={false}
                    autoPageSize={true}
                    rows={amcInstances === null ? [] : amcInstances}
                    onRowClick={handleEdit}
                />
            </Column>

            {amcInstanceFormDialogOpen && (
                <AmcInstanceCreateFormDialog
                    open={amcInstanceFormDialogOpen}
                    onClose={() => {
                        refresh();
                        setAmcInstanceFormDialogOpen(false);
                    }}
                />
            )}
        </Column>
    );
}
