import { AxiosResponse } from 'axios';
import { useCallback, useState } from 'react';

import { AmcReachAndFrequency, AmcReachAndFrequencyCriteria } from '../types/AmcReachAndFrequency';
import ApiService from '../ApiService';

export interface AmcReachAndFrequencyListState {
    amcReachAndFrequencyList: AmcReachAndFrequency[];
    setAmcReachAndFrequencyList: (amcReachAndFrequencyList: AmcReachAndFrequency[]) => void;
    fetchAmcReachAndFrequencyList: (
        criteria: AmcReachAndFrequencyCriteria
    ) => Promise<AxiosResponse<AmcReachAndFrequency[]>>;
    isFetchingAmcReachAndFrequencyList: boolean;
}

export function useAmcReachAndFrequencyList(): AmcReachAndFrequencyListState {
    const [amcReachAndFrequencyList, setAmcReachAndFrequencyList] = useState<AmcReachAndFrequency[]>([]);
    const [isFetchingAmcReachAndFrequencyList, setIsFetchingAmcReachAndFrequencyList] = useState<boolean>(false);

    const fetchAmcReachAndFrequencyList = useCallback((criteria: AmcReachAndFrequencyCriteria) => {
        const response = ApiService.getAmcReachAndFrequencyList(criteria);

        setIsFetchingAmcReachAndFrequencyList(true);

        response.then((result) => {
            setIsFetchingAmcReachAndFrequencyList(false);
            setAmcReachAndFrequencyList(result.data);
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
        return response;
    }, []);

    return {
        amcReachAndFrequencyList,
        setAmcReachAndFrequencyList,
        fetchAmcReachAndFrequencyList,
        isFetchingAmcReachAndFrequencyList,
    };
}
