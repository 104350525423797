import { Stack, Toolbar } from '@mui/material';
import { Link } from 'react-router-dom';
import { useContext } from 'react';
import { AgencyContext } from '../App';
import NotificationsButton from './NotificationsButton';
import AccessGuard from './AccessGuard';
import { USER_GROUP_ADMIN } from '../types/User';
import UserMenu from './UserMenu';

export interface CDToolBarProps {
    logout: Function;
    toggleMenu: Function;
    showMenu: Function;
}

const CDToolbar = function (props: CDToolBarProps) {
    const agency = useContext(AgencyContext);

    return (
        <Toolbar
            disableGutters
            className="App-toolbar"
            sx={{
                height: '64px',
                color: 'rgba(255, 255, 255, 0.7)',
                // paddingLeft: '22px',
                paddingRight: '22px',
                borderBottom: '1px solid #4D66A64D',
            }}
        >
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={3}
                sx={{
                    width: '100%',
                }}
            >
                <div
                    style={{
                        borderRight: '1px solid #4D66A64D',
                    }}
                >
                    {!agency ||
                        (!agency.logoUrl && (
                            <Link
                                to="campaignManager"
                                style={{
                                    height: '64px',
                                    width: '88px',
                                    paddingLeft: '22px',
                                    paddingRight: '22px',
                                    display: 'flex',
                                    alignContent: 'center',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <img src="/Logomark.png" style={{ height: '40px', width: '40px' }} />
                            </Link>
                        ))}
                    {agency && agency.logoUrl && (
                        <img
                            style={{
                                margin: '0px 20px',
                                height: '40px',
                                width: '130px',
                            }}
                            alt="cognitionads.com"
                            src={agency && agency.logoUrl ? agency?.logoUrl : '/logo_mark.png'}
                        />
                    )}
                </div>

                <div style={{ flexGrow: 1 }} />

                <Stack direction="row" gap={1}>
                    <AccessGuard accessGroup={USER_GROUP_ADMIN}>
                        <NotificationsButton />
                    </AccessGuard>

                    <UserMenu toolbarProps={props} />
                </Stack>

                {/*<AccessGuard accessGroup={USER_GROUP_ADMIN}>*/}
                {/*    <BuildInfoComponent />*/}
                {/*</AccessGuard>*/}
            </Stack>
        </Toolbar>
    );
};

export default CDToolbar;
