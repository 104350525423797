import { SectionHeading } from '../../../components/SectionHeading';
import { CDTextField } from '../../../components/CDTextField';
import { CardContent, CardHeader, InputAdornment, Link as MuiLink } from '@mui/material';
import { InsertLinkOutlined } from '@mui/icons-material';
import CreativeAssetsComponent from './CreativeAssetsComponent';
import Column from '../../../components/Column';
import { CampaignCard } from './CampaignCard';
import { Campaign } from '../../../types/Campaign';

export default function StepCreativeAssets(props: {
    campaign: Campaign;
    disabled: boolean;
    onChange(value: Partial<Campaign>): void;
    setFiles(files: File[]): void;
    MAX_FILE_SIZE: number;
}) {
    const { campaign, setFiles, onChange, disabled, MAX_FILE_SIZE } = props;

    const centerContent = {
        width: '100%',
        maxWidth: '800px',
        minWidth: '620px',
        marginLeft: 'auto',
        marginRight: 'auto',
    };

    return (
        <>
            <CampaignCard sx={centerContent}>
                <CardHeader title="Creative Assets" titleTypographyProps={{ variant: 'h5' }}></CardHeader>
                <CardContent>
                    <Column sx={centerContent}>
                        <CDTextField
                            name="creativeAssetLink"
                            label="Creative Assets Link"
                            value={campaign.creativeAssetLink ?? ''}
                            required={false}
                            variant="outlined"
                            fullWidth={true}
                            disabled={disabled}
                            onChange={(event) =>
                                onChange({
                                    creativeAssetLink: event.target.value,
                                })
                            }
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="start">
                                        {campaign.creativeAssetLink &&
                                            campaign.creativeAssetLink.toLowerCase().indexOf('https://') > -1 && (
                                                <MuiLink href={campaign.creativeAssetLink} target="new">
                                                    <InsertLinkOutlined />
                                                </MuiLink>
                                            )}
                                    </InputAdornment>
                                ),
                            }}
                        />

                        <SectionHeading
                            divider={true}
                            subheading="Upload Customer Logos (640x480), Value Prop Logos (480x240), and other supporting graphics"
                        >
                            File Attachments
                        </SectionHeading>
                        <CreativeAssetsComponent
                            viewOnly={disabled}
                            campaign={campaign}
                            onFilesChanged={(files) => setFiles(files)}
                            maxFileSize={MAX_FILE_SIZE}
                        />

                        <SectionHeading divider={true}>Additional Information</SectionHeading>

                        <CDTextField
                            name="landingPage"
                            label="Landing Page"
                            value={campaign.landingPage ?? ''}
                            variant="outlined"
                            required={false}
                            disabled={disabled}
                            placeholder="For Static and Acquisition Campaigns"
                            onChange={(event) => onChange({ landingPage: event.target.value })}
                        />

                        <CDTextField
                            name="callToActions"
                            label="Call to Actions"
                            helperText="Specific CTA on ads, button actions to track on site, etc."
                            value={campaign.callToActions ?? ''}
                            variant="outlined"
                            required={false}
                            disabled={disabled}
                            multiline
                            rows={3}
                            onChange={(event) => onChange({ callToActions: event.target.value })}
                        />
                    </Column>
                </CardContent>
            </CampaignCard>
        </>
    );
}
