import { createContext } from 'react';
import { Dealer } from './types/Dealer';

export class AdvertiserContextValue {
    dealer: Dealer | null = null;
    agencyId: number | null = null;
}

export const AdvertiserContext = createContext({
    advertiserContext: new AdvertiserContextValue(),
    setDealer: (dealer: Dealer | null) => {},
    setAdvertiserContext: (dealer: Dealer | null, agencyId: number | null) => {},
});
