import AppBreadcrumbs from '../../components/AppBreadcrumbs';
import Column from '../../components/Column';
import Heading from '../../components/Heading';
import DealerImportSites from '../dealers/DealerImportSites';

export default function ImportListPage() {
    return (
        <Column sx={{ height: '100%' }}>
            <Column gap={1}>
                <AppBreadcrumbs items={[]} />

                <Heading>Import Manager</Heading>
            </Column>

            <Column gap={2} sx={{ height: '100%' }}>
                <DealerImportSites />
            </Column>
        </Column>
    );
}
