import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { Box, Divider, Grid, Chip } from '@mui/material';

import { IFilterItem } from './SalesInsights';
import { Fragment, useState } from 'react';
import { removeItemAtIndex } from './utils';
import Column from '../../components/Column';

import { useMemo } from 'react';

export default function ActiveFilterDialog({
    open,
    setOpen,
    filters,
    setFilters,
    setApplyFilters,
}: {
    open: boolean;
    setOpen: (open: boolean) => void;
    filters: IFilterItem[];
    setFilters: (filters: IFilterItem[]) => void;
    setApplyFilters: (filters: IFilterItem[]) => void;
}) {
    const [activeFilters, setActiveFilters] = useState<IFilterItem[]>([...filters]);

    const handleClose = () => {
        setOpen(false);
        setActiveFilters(filters);
    };

    const handleSave = () => {
        setOpen(false);
        setFilters(JSON.parse(JSON.stringify(activeFilters)));
        setApplyFilters(JSON.parse(JSON.stringify(activeFilters)));
    };

    const GetAllChildrenFiltersChip = ({ items, signature }: { items: IFilterItem[]; signature: number[] }) => {
        return (
            <>
                {items.map((item: IFilterItem, index: number) => {
                    const itemSignature: number[] = [...signature, index];
                    return (
                        <Fragment key={item.id}>
                            {!item?.children && (
                                <Chip
                                    size="small"
                                    label={item.label}
                                    onDelete={() => {
                                        const prev = [...activeFilters];
                                        setActiveFilters(removeItemAtIndex(prev, itemSignature || []));
                                    }}
                                />
                            )}
                            {item?.children && item?.children?.length > 0 && (
                                <GetAllChildrenFiltersChip signature={itemSignature} items={item.children} />
                            )}
                        </Fragment>
                    );
                })}
            </>
        );
    };

    const filledActiveFilterCount = useMemo((): number => {
        return activeFilters.filter((activeFilter: IFilterItem) => {
            return activeFilter?.children?.length ? true : false;
        }).length;
    }, [activeFilters]);

    return (
        <Dialog
            fullWidth
            maxWidth="md"
            onClose={handleClose}
            aria-labelledby="insights-active-filter-dialog-title"
            open={open}
            PaperProps={{
                sx: {
                    minWidth: '400px',
                },
            }}
        >
            <DialogTitle id="insights-active-filter-dialog-title" sx={{ m: 0, p: 2 }}>
                Active Filters
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent dividers sx={{ p: 3 }}>
                <Column gap={2}>
                    {activeFilters?.map((filter, index) => {
                        const isNotLastFilter: boolean = filledActiveFilterCount > index;
                        return filter?.children && filter?.children?.length > 0 ? (
                            <Fragment key={filter.id}>
                                {filter.id === 'make_model' && (
                                    <Column key={filter.id} gap={2}>
                                        <Typography variant="caption" color="text.primary">
                                            Make & Modal{' '}
                                            <Typography variant="caption" color="text.secondary">
                                                (Deleting <strong>'Make'</strong> will remove all corresponding modals)
                                            </Typography>
                                        </Typography>
                                        <Grid container spacing={2}>
                                            <Grid item xs={3}>
                                                <Typography
                                                    variant="body2"
                                                    color="text.primary"
                                                    sx={{ fontWeight: 500 }}
                                                >
                                                    Make
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={9}>
                                                <Typography
                                                    variant="body2"
                                                    color="text.primary"
                                                    sx={{ fontWeight: 500 }}
                                                >
                                                    Model
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Divider />
                                            </Grid>
                                            {filter?.children?.map((child1, subIndex) => {
                                                const childSignature: number[] = [index, subIndex];
                                                return child1?.children && child1?.children?.length > 0 ? (
                                                    <Fragment key={child1.id}>
                                                        <Grid item xs={3}>
                                                            <Chip
                                                                size="small"
                                                                label={child1.label}
                                                                onDelete={() => {
                                                                    const prev = [...activeFilters];
                                                                    setActiveFilters(
                                                                        removeItemAtIndex(prev, childSignature || [])
                                                                    );
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={9}>
                                                            <Box
                                                                sx={{
                                                                    display: 'flex',
                                                                    flexWrap: 'wrap',
                                                                    alignItems: 'center',
                                                                    gap: 1,
                                                                }}
                                                            >
                                                                <GetAllChildrenFiltersChip
                                                                    signature={childSignature}
                                                                    items={child1.children}
                                                                />
                                                            </Box>
                                                        </Grid>
                                                    </Fragment>
                                                ) : null;
                                            })}
                                        </Grid>
                                    </Column>
                                )}
                                {filter.id !== 'make_model' && (
                                    <Column key={filter.id} gap={2}>
                                        <Typography variant="caption" color="text.primary">
                                            {filter.label}
                                        </Typography>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexWrap: 'wrap',
                                                alignItems: 'center',
                                                gap: 1,
                                            }}
                                        >
                                            <GetAllChildrenFiltersChip signature={[index]} items={filter.children} />
                                        </Box>
                                    </Column>
                                )}

                                {isNotLastFilter && <Divider />}
                            </Fragment>
                        ) : null;
                    })}
                </Column>
            </DialogContent>
            <DialogActions sx={{ px: 3, py: 2 }}>
                <Button autoFocus variant="outlined" onClick={handleClose}>
                    Cancel
                </Button>
                <Button variant="contained" onClick={handleSave}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
}
