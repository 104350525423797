import {
    Alert,
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    MenuItem,
    Stack,
    TextField,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { CloseOutlined, SendOutlined } from '@mui/icons-material';
import ApiService from '../../ApiService';
import { DataGrid, GRID_CHECKBOX_SELECTION_COL_DEF, GridSelectionModel } from '@mui/x-data-grid';
import Utils from '../../components/Utils';
import { Workflow } from '../../types/Workflow';
import { Campaign } from '../../types/Campaign';
import CampaignStatusComponent from '../campaignManagerV2/CampaignStatusComponent';

export interface SendToAMCDialogProps {
    open: boolean;
    dealerId: number;
    onClose(workflowId: number | null, campaignIds: number[]): void;
    onCancel(): void;
}

export default function SendToAMCDialog(props: SendToAMCDialogProps) {
    const [workflows, setWorkflows] = useState<Workflow[]>([]);
    const [workflowId, setWorkflowId] = useState<number | null>(null);
    const [campaigns, setCampaigns] = useState<Campaign[]>([]);
    const [campaignIds, setCampaignIds] = useState<number[]>([]);
    const [bypassCampaignSelection, setBypassCampaignSelection] = useState(false);

    useEffect(() => {
        getWorkflows();
        getCampaigns(props.dealerId);
    }, [props.dealerId]);

    function getCampaigns(dealerId: number) {
        ApiService.getCampaignsForDealer(dealerId)
            .then((response) => {
                setCampaigns(response.data);
            })
            .catch(() => {
                setCampaigns([]);
            });
    }

    function getWorkflows() {
        ApiService.getWorkflows()
            .then((response) => {
                setWorkflows(response.data);
            })
            .catch(() => {
                setWorkflows([]);
            });
    }

    function cancel() {
        props.onCancel();
    }

    function sendToAmc() {
        props.onClose(workflowId, campaignIds);
    }

    function selectWorkflow(workflowId: number) {
        setWorkflowId(workflowId);
    }

    return (
        <Dialog maxWidth="md" fullWidth={true} open={props.open} onClose={cancel}>
            <DialogTitle>
                Send To AMC
                <Divider />
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <TextField
                        sx={{ mt: '10px', mb: '10px', width: '450px' }}
                        label="Select a Workflow"
                        select
                        required={true}
                        size="small"
                        SelectProps={{ native: false }}
                        variant="outlined"
                        onChange={(event) => {
                            selectWorkflow(Number(event.target.value));
                        }}
                    >
                        {workflows.map((workflow) => {
                            return (
                                <MenuItem key={workflow.id} value={workflow.id}>
                                    {workflow.workflowId}
                                </MenuItem>
                            );
                        })}
                    </TextField>

                    <Box
                        sx={{
                            '& .row': {
                                cursor: 'pointer',
                            },
                        }}
                    >
                        {campaigns.length === 0 && (
                            <>
                                <Alert severity="warning">
                                    No Campaigns found for this Advertiser
                                    <br />
                                    <Checkbox
                                        disableRipple
                                        checked={bypassCampaignSelection}
                                        onChange={() => setBypassCampaignSelection(!bypassCampaignSelection)}
                                    />
                                    Submit for Attribution only with no Budget or Cost
                                </Alert>
                            </>
                        )}
                        {campaigns.length > 0 && (
                            <div style={{ height: '200px', width: '100%' }}>
                                <b>Select Campaigns</b> <i>(to calculate Total Ad Budget)</i>
                                <DataGrid
                                    autoHeight
                                    rows={campaigns}
                                    pageSize={10}
                                    checkboxSelection={true}
                                    disableSelectionOnClick={true}
                                    onSelectionModelChange={(selectionModel: GridSelectionModel) => {
                                        // @ts-ignore
                                        setCampaignIds(selectionModel);
                                    }}
                                    columns={[
                                        {
                                            width: 100,
                                            ...GRID_CHECKBOX_SELECTION_COL_DEF,
                                            headerClassName: 'grid-header',
                                        },
                                        {
                                            width: 110,
                                            field: 'deliveryStatus',
                                            headerName: 'Status',
                                            align: 'center',
                                            headerClassName: 'grid-header',
                                            disableColumnMenu: false,
                                            hideSortIcons: false,
                                            renderCell: (params) => {
                                                let value = params.value;
                                                return <CampaignStatusComponent deliveryStatus={value} />;
                                            },
                                        },
                                        {
                                            width: 105,
                                            field: 'startDate',
                                            headerName: 'Start Date',
                                            headerClassName: 'grid-header',
                                            disableColumnMenu: false,
                                            hideSortIcons: false,
                                            valueGetter: (params) => {
                                                return Utils.formatDate(params.value);
                                            },
                                        },
                                        {
                                            width: 105,
                                            field: 'endDate',
                                            headerName: 'End Date',
                                            headerClassName: 'grid-header',
                                            disableColumnMenu: false,
                                            hideSortIcons: false,
                                            valueGetter: (params) => {
                                                return Utils.formatDate(params.value);
                                            },
                                        },
                                        {
                                            width: 160,
                                            field: 'poNumber',
                                            headerName: 'PO Number',
                                            headerClassName: 'grid-header',
                                        },
                                        {
                                            width: 170,
                                            field: 'dspCampaignId',
                                            headerName: 'Campaign Id',
                                            headerClassName: 'grid-header',
                                        },
                                        {
                                            width: 170,
                                            field: 'campaignName',
                                            headerName: 'Campaign Name',
                                            headerClassName: 'grid-header',
                                        },
                                    ]}
                                />
                            </div>
                        )}
                    </Box>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Stack direction="row" spacing={3}>
                    <Button variant="outlined" startIcon={<CloseOutlined />} color="error" onClick={cancel}>
                        Cancel
                    </Button>
                    <Button
                        color="primary"
                        variant="contained"
                        disabled={!workflowId || (!bypassCampaignSelection && campaignIds.length === 0)}
                        onClick={sendToAmc}
                        startIcon={<SendOutlined />}
                    >
                        Send to AMC
                    </Button>
                </Stack>
            </DialogActions>
        </Dialog>
    );
}
