import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@mui/material';
import {
    DateRangePicker,
    defaultStaticRanges,
    Range,
    RangeKeyDict,
} from 'react-date-range';
import { useState } from 'react';
import { CloseOutlined, DirectionsRunOutlined } from '@mui/icons-material';

interface WorkflowExecuteDialogProps {
    open: boolean;
    onClose: Function;
}

export default function AmcWorkflowExecuteDialog(
    props: WorkflowExecuteDialogProps
) {
    const [dateRange, setDateRange] = useState<Range>({
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
    });

    function handleClose(dateRange: Range | null) {
        props.onClose(dateRange);
    }

    function handleDateChange(range: RangeKeyDict) {
        setDateRange(range['selection'] as Range);
    }

    return (
        <div>
            <Dialog
                open={props.open}
                //onClose={props.onClose}
            >
                <DialogTitle>Run Workflow</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <DateRangePicker
                            onChange={handleDateChange}
                            moveRangeOnFirstSelection={false}
                            months={1}
                            staticRanges={defaultStaticRanges}
                            inputRanges={[]}
                            ranges={[dateRange]}
                            direction="horizontal"
                        />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => handleClose(dateRange)}
                        variant="contained"
                        color="warning"
                        startIcon={<DirectionsRunOutlined />}
                    >
                        Run
                    </Button>
                    <Button
                        onClick={() => handleClose(null)}
                        variant="outlined"
                        color="error"
                        startIcon={<CloseOutlined />}
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
