export let OemOfferStatus = {
    NEW: 'New',
    UPDATED: 'Updated',
    NO_ADVERTISER: 'No Advertiser',
    READY: 'Ready',
    VIDEO_REQUESTED: 'Video Requested',
    VIDEO_RECEIVED: 'Video Received',
    VIDEO_ERROR: 'Video Error',
    COMPLETE: 'Complete',
};
