import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import { useTheme } from '@mui/material';

export function CDChart(props: {
    chartOptions?: ApexOptions;
    categories?: string[];
    chartSeries?: number[];
    series?: ApexAxisChartSeries;
    label?: string;
    yAxisMin?: number;
    height?: string;
    width?: string;
}) {
    const options = props.chartOptions ? props.chartOptions : {};
    const theme = useTheme();

    let markerSize = 0;
    if (props.series && props.series.length > 0 && props.series[0].data.length === 1) {
        markerSize = 6;
    }

    const chartOptions: ApexOptions = {
        chart: {
            dropShadow: {
                enabled: false,
                top: 1,
                left: 1,
                blur: 2,
                opacity: 0.2,
            },
            toolbar: {
                show: true,
                tools: {
                    download: false,
                    selection: false,
                    zoom: false,
                    zoomin: false,
                    zoomout: false,
                    pan: false,
                    reset: false,
                    customIcons: [],
                },
            },
            background: 'transparent',
        },
        title: {
            text: props.label,
            align: 'left',
        },
        colors: [theme.palette.info.dark, theme.palette.success.dark],
        theme: {
            mode: theme.palette.mode,
        },
        dataLabels: {
            enabled: false,
        },
        grid: {
            show: false,
        },
        stroke: {
            curve: 'smooth',
            width: 2,
        },
        markers: {
            strokeWidth: 0.4,
            size: markerSize,
        },
        fill: {
            type: 'gradient',
            gradient: {
                shade: 'light',
                type: 'horizontal',
                shadeIntensity: 0.9,
                inverseColors: false,
                opacityFrom: 0.1,
                opacityTo: 0.6,
                stops: [0, 100],
            },
        },
        yaxis: {
            min: props.yAxisMin,
            opposite: false,
            labels: {
                formatter(value: number): string | string[] {
                    let v = Number(value);
                    let suffix = '';
                    if (v > 1000000) {
                        v = v / 1000000;
                        suffix = ' M';
                    } else if (v > 1000) {
                        v = v / 1000;
                        suffix = ' K';
                    }
                    return v.toLocaleString() + suffix;
                },
            },
        },
        xaxis: {
            type: 'datetime',
            labels: {
                show: true,
                datetimeFormatter: {
                    month: 'MMM',
                    day: 'MMM dd',
                },
            },
            categories: props.categories ? props.categories : [],
        },
        ...options,
    };

    if (props.chartSeries) {
        return (
            <>
                <ReactApexChart
                    type="area"
                    options={chartOptions}
                    series={[{ name: props.label, data: props.chartSeries }]}
                />
            </>
        );
    }

    return (
        <ReactApexChart
            type="area"
            options={chartOptions}
            series={props.series}
            height={props.height}
            width={props.width}
        />
    );
}
