import { useState } from 'react';
import { Close as CloseIcon } from '@mui/icons-material';
import { Button, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import WorkflowForm from './WorkflowForm';
import { Workflow } from '../../../types/Workflow';
import { StyledDialog } from '../../../components/StyledDialog';

type WorkflowCreateFormDialogProps = {
    open: boolean;
    onClose: () => void;
};

export default function WorkflowCreateFormDialog(props: WorkflowCreateFormDialogProps) {
    const [open, setOpen] = useState<boolean>(props.open);
    const [save, setSave] = useState<boolean>(false);
    const [validated, setValidated] = useState<boolean>(false);

    const handleClose = () => {
        setOpen(false);
        setSave(false);

        props.onClose();
    };

    const handleSave = (workflow: Workflow) => {
        handleClose();
    };

    const handleValidate = (_validated: boolean) => {
        setValidated(_validated);
    };

    const handleClickSave = () => {
        setSave(true);
    };

    return (
        <>
            <StyledDialog maxWidth="md" onClose={handleClose} open={open}>
                <DialogTitle sx={{ m: 0, p: 2 }}>Add AMC Global Workflow</DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers sx={{ minWidth: 868 }}>
                    <WorkflowForm
                        size="sm"
                        hideActions={true}
                        workflow={{
                            id: 0,
                            workflowId: '',
                            sqlQuery: '',
                            description: '',
                        }}
                        onSave={handleSave}
                        onValidate={handleValidate}
                        save={save}
                    />
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" color="error" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        autoFocus
                        disabled={validated === false}
                        onClick={handleClickSave}
                    >
                        Save
                    </Button>
                </DialogActions>
            </StyledDialog>
        </>
    );
}
