import { Box, Grid } from '@mui/material';

const AdTagPreviewSection = ({ size }: { size?: string | null }) => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sx={{ display: size === 'All' || size === '728x90' ? 'block' : 'none' }}>
                <Box
                    sx={{ display: size === 'All' || size === '728x90' ? 'flex' : 'none', justifyContent: 'center' }}
                    className="ad-tag"
                    data-width="728"
                    data-height="90"
                    data-src="https://servedby.flashtalking.com/imp/8/243952;8507915;201;jsiframe;AmazonUS;728x90/?imageType=gif&ftDestID=39271728&ft_width=728&ft_height=90&click=&ftOBA=1&ftExpTrack=&gdpr=${GDPR}&gdpr_consent=${GDPR_CONSENT_78}&us_privacy=${US_PRIVACY}&ft_custom=[%tp_AdvertiserID%]&ft_c1=[%tp_LineID%]&ft_keyword=[%tp_AdvertiserID%]&cachebuster=[CACHEBUSTER]"
                    data-href="https://servedby.flashtalking.com/click/8/243952;8507915;0;209;0/?gdpr=${GDPR}&gdpr_consent=${GDPR_CONSENT_78}&us_privacy=${US_PRIVACY}&ft_width=728&ft_height=90&url=39271728"
                    data-img-src="https://servedby.flashtalking.com/imp/8/243952;8507915;205;gif;AmazonUS;728x90/?gdpr=${GDPR}&gdpr_consent=${GDPR_CONSENT_78}&us_privacy=${US_PRIVACY}"
                />
            </Grid>

            <Grid item xs={12} sm={6} md={4} sx={{ display: size === 'All' || size === '300x250' ? 'block' : 'none' }}>
                <Box
                    sx={{ display: size === 'All' || size === '300x250' ? 'flex' : 'none', justifyContent: 'center' }}
                    className="ad-tag"
                    data-width="300"
                    data-height="250"
                    data-src="https://servedby.flashtalking.com/imp/8/243952;8507913;201;jsiframe;AmazonUS;300x250/?imageType=gif&ftDestID=39271300&ft_width=300&ft_height=250&click=&ftOBA=1&ftExpTrack=&gdpr=${GDPR}&gdpr_consent=${GDPR_CONSENT_78}&us_privacy=${US_PRIVACY}&ft_custom=[%tp_AdvertiserID%]&ft_c1=[%tp_LineID%]&ft_keyword=[%tp_AdvertiserID%]&cachebuster=[CACHEBUSTER]"
                    data-href="https://servedby.flashtalking.com/click/8/243952;8507913;0;209;0/?gdpr=${GDPR}&gdpr_consent=${GDPR_CONSENT_78}&us_privacy=${US_PRIVACY}&ft_width=300&ft_height=250&url=39271300"
                    data-img-src="https://servedby.flashtalking.com/imp/8/243952;8507913;205;gif;AmazonUS;300x250/?gdpr=${GDPR}&gdpr_consent=${GDPR_CONSENT_78}&us_privacy=${US_PRIVACY}"
                />
            </Grid>

            <Grid item xs={12} sm={6} md={4} sx={{ display: size === 'All' || size === '160x600' ? 'block' : 'none' }}>
                <Box
                    sx={{ display: size === 'All' || size === '160x600' ? 'flex' : 'none', justifyContent: 'center' }}
                    className="ad-tag"
                    data-width="160"
                    data-height="600"
                    data-src="https://servedby.flashtalking.com/imp/8/243952;8507912;201;jsiframe;AmazonUS;160x600/?imageType=gif&ftDestID=39271160&ft_width=160&ft_height=600&click=&ftOBA=1&ftExpTrack=&gdpr=${GDPR}&gdpr_consent=${GDPR_CONSENT_78}&us_privacy=${US_PRIVACY}&ft_custom=[%tp_AdvertiserID%]&ft_c1=[%tp_LineID%]&ft_keyword=[%tp_AdvertiserID%]&cachebuster=[CACHEBUSTER]"
                    data-href="https://servedby.flashtalking.com/click/8/243952;8507912;0;209;0/?gdpr=${GDPR}&gdpr_consent=${GDPR_CONSENT_78}&us_privacy=${US_PRIVACY}&ft_width=160&ft_height=600&url=39271160"
                    data-img-src="https://servedby.flashtalking.com/imp/8/243952;8507912;205;gif;AmazonUS;160x600/?gdpr=${GDPR}&gdpr_consent=${GDPR_CONSENT_78}&us_privacy=${US_PRIVACY}"
                />
            </Grid>

            <Grid item xs={12} sm={8} md={4} sx={{ display: size === 'All' || size === '300x600' ? 'block' : 'none' }}>
                <Box
                    sx={{ display: size === 'All' || size === '300x600' ? 'flex' : 'none', justifyContent: 'center' }}
                    className="ad-tag"
                    data-width="300"
                    data-height="600"
                    data-src="https://servedby.flashtalking.com/imp/8/243952;8507914;201;jsiframe;AmazonUS;300x600/?imageType=gif&ftDestID=39271300&ft_width=300&ft_height=600&click=&ftOBA=1&ftExpTrack=&gdpr=${GDPR}&gdpr_consent=${GDPR_CONSENT_78}&us_privacy=${US_PRIVACY}&ft_custom=[%tp_AdvertiserID%]&ft_c1=[%tp_LineID%]&ft_keyword=[%tp_AdvertiserID%]&cachebuster=[CACHEBUSTER]"
                    data-href="https://servedby.flashtalking.com/click/8/243952;8507914;0;209;0/?gdpr=${GDPR}&gdpr_consent=${GDPR_CONSENT_78}&us_privacy=${US_PRIVACY}&ft_width=300&ft_height=600&url=39271300"
                    data-img-src="https://servedby.flashtalking.com/imp/8/243952;8507914;205;gif;AmazonUS;300x600/?gdpr=${GDPR}&gdpr_consent=${GDPR_CONSENT_78}&us_privacy=${US_PRIVACY}"
                />
            </Grid>
        </Grid>
    );
};

export default AdTagPreviewSection;
