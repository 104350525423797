import { Alert, AlertColor, Snackbar } from '@mui/material';

import React from 'react';

export interface InfoMessageProps {
    severity?: AlertColor;
    message: JSX.Element | string | null;
    onClose?: (event: React.SyntheticEvent<any>) => void;
}

export function InfoMessage(props: InfoMessageProps) {
    function onClose(event: any) {
        if (props.onClose) {
            props.onClose(event);
        }
    }

    return (
        <Snackbar
            open={props.message !== null}
            autoHideDuration={props.severity === 'success' ? 6000 : 60000}
            onClose={onClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
            <Alert
                className={'alert-' + props.severity}
                onClose={onClose}
                severity={props.severity}
                sx={{ padding: '10px', width: '100%' }}
            >
                {props.message}
            </Alert>
        </Snackbar>
    );
}
