import { isValidElement, useMemo } from 'react';
import { deepmerge } from '@mui/utils';
import { Box, Card, CardContent, CardHeader, Tooltip, Typography } from '@mui/material';
import { HelpOutlineOutlined as HelpOutlineOutlinedIcon } from '@mui/icons-material';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import Column from '../Column';
import Printable from '../Printable';
import Row from '../Row';
import SkeletonBasicBarChart from './SkeletonBasicBarChart';
import theme from '../../theme';
import Utils from '../Utils';

export default function BasicBarChartCard(props: {
    label: string | null | undefined | React.ReactElement;
    tooltip?: string;
    chartOptions?: ApexOptions;
    chartSeries?: ApexAxisChartSeries | number[] | any;
    noDataOverlay?: React.ReactElement;
    loading?: boolean;
}) {
    const { label, tooltip, chartOptions, chartSeries, noDataOverlay, loading = false } = props;

    // const [chartXAxisMax, setChartXAxisMax] = useState<number | undefined>();

    const chartXAxisMax = useMemo((): number | undefined => {
        let max: number | undefined;

        if (chartSeries && chartSeries.length > 0) {
            if (chartSeries[0]?.data) {
                max = Math.max(...chartSeries[0].data);
                max = Math.ceil(max);
                max = max * 2;
            }
        }

        return max;
    }, [chartSeries]);

    const defaultChartOptions: ApexOptions = {
        colors: [theme.palette.primary.main],
        chart: {
            type: 'bar',
            // height: 300,
            // offsetX: 0,
            toolbar: {
                show: false,
            },
        },
        dataLabels: {
            enabled: true,
            textAnchor: 'start',
            offsetX: 75,
            offsetY: 0,
            style: {
                colors: [theme.palette.text.secondary],
                fontFamily: theme.typography.fontFamily,
                fontSize: '12px',
                fontWeight: 400,
            },
            formatter: (value, opts) => {
                return Utils.formatCurrency(value as any, 2) as string;
            },
        },
        grid: {
            show: false,
        },
        plotOptions: {
            bar: {
                borderRadius: 4,
                borderRadiusApplication: 'end',
                horizontal: true,
                dataLabels: {
                    position: 'top',
                },
                // columnWidth: '50%',
                barHeight: '40%',
            },
        },
        tooltip: {
            y: {
                formatter: (value) => {
                    return Utils.formatCurrency(value as any, 2) as string;
                },
            },
        },
        xaxis: {
            max: chartXAxisMax,
            labels: {
                formatter: (value) => {
                    return Utils.formatCurrency(value as any, 2) as string;
                },
                style: {
                    colors: [theme.palette.text.secondary],
                    fontFamily: theme.typography.fontFamily,
                    fontSize: '12px',
                    fontWeight: 400,
                },
            },
        },
        yaxis: {
            labels: {
                maxWidth: 240,
                style: {
                    colors: [theme.palette.text.secondary],
                    fontFamily: theme.typography.fontFamily,
                    fontSize: '12px',
                    fontWeight: 400,
                },
            },
        },
    };

    const hasData: boolean | undefined = useMemo(() => {
        // if (chartSeries?.data?.length) {
        //     return true;
        // }

        return chartSeries && chartSeries.length > 0 ? true : false;
    }, [chartSeries]);

    const hasNoDataOverlay: boolean | undefined = useMemo(() => {
        return noDataOverlay !== null && noDataOverlay !== undefined;
    }, [noDataOverlay]);

    // const boxHeight: number = 280;
    const boxHeight: number = 270;

    return (
        <Card variant="outlined">
            <CardHeader
                title={
                    <Row gap={0.5} alignItems="center">
                        {isValidElement(label) ? (
                            label
                        ) : (
                            <Typography variant="body1" color="text.secondary">
                                {label}
                            </Typography>
                        )}

                        <Printable show={false}>
                            {tooltip ? (
                                <Tooltip title={tooltip}>
                                    <HelpOutlineOutlinedIcon color="action" sx={{ fontSize: 16 }} />
                                </Tooltip>
                            ) : (
                                ''
                            )}
                        </Printable>
                    </Row>
                }
                sx={{
                    borderBottom: 1,
                    borderColor: 'divider',
                }}
            />
            <CardContent sx={{ height: 325 }}>
                {loading ? (
                    <SkeletonBasicBarChart />
                ) : (
                    <>
                        {hasData === true && (
                            <Box
                                sx={
                                    {
                                        // display: hasData === true ? 'block' : 'none',
                                    }
                                }
                            >
                                <ReactApexChart
                                    type="bar"
                                    options={deepmerge(defaultChartOptions, chartOptions)}
                                    series={chartSeries}
                                    height={boxHeight}
                                    // width={500}
                                />
                            </Box>
                        )}

                        {hasData === false && (
                            <>
                                {hasNoDataOverlay === true && <>{noDataOverlay}</>}

                                {hasNoDataOverlay === false && (
                                    <Column alignItems="center" justifyContent="center" sx={{ height: boxHeight + 2 }}>
                                        <Typography variant="body2">No data available</Typography>
                                    </Column>
                                )}
                            </>
                        )}
                    </>
                )}
            </CardContent>
        </Card>
    );
}
