import { useEffect, useMemo, useState } from 'react';
import {
    Autocomplete,
    Box,
    Checkbox,
    InputAdornment,
    List,
    ListItemButton,
    ListItem,
    ListItemIcon,
    ListItemText,
    TextField,
    Typography,
} from '@mui/material';
import {
    CheckBox as CheckBoxIcon,
    CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
    Search as SearchIcon,
} from '@mui/icons-material';
import { alpha } from '@mui/material';
import { createFilterOptions } from '@mui/material/Autocomplete';

import { IFilterItem } from './SalesInsights';
import { States } from './utils';
import Column from '../../components/Column';

const filterOptions = createFilterOptions({
    ignoreCase: true,
    // matchFrom: "start",
    limit: States.length,
});

type GeographyAutocompleteListProps = {
    id: string;
    setFilters: (filters: IFilterItem[]) => void;
    items: IFilterItem[];
    filters: IFilterItem[];
};

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function GeographyAutocompleteList(props: GeographyAutocompleteListProps) {
    const { id, setFilters, filters, items } = props;

    const filterItemIndex: number = filters.findIndex((_filterItem: IFilterItem) => _filterItem.id === id);
    const filterItem = filterItemIndex > 0 ? filters[filterItemIndex] : null;

    const [selectedOptions, setSelectedOptions] = useState<any[]>([]);
    const [value, setValue] = useState<any | null>(null);

    const handleToggle = (index: number): void => {
        selectedOptions.splice(index, 1);
        setSelectedOptions([...selectedOptions]);
    };

    useEffect(() => {
        setSelectedOptions(filterItem?.children ?? []);
    }, [filterItem]);

    useEffect(() => {
        const newFilters = filters?.map((oldFilterItem) => {
            const newFilterItem = oldFilterItem;
            if (filterItem?.id === newFilterItem.id) {
                newFilterItem.children = selectedOptions.map((option: any) => {
                    return {
                        id: option.id,
                        label: option.label,
                    };
                });
            }
            return newFilterItem;
        });
        setFilters(newFilters);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedOptions]);

    const options = useMemo(() => {
        if (selectedOptions.length > 0) {
            return items.filter((state: IFilterItem) => {
                return selectedOptions.findIndex((option: IFilterItem) => option?.id === state?.id) === -1;
            });
        }
        return items;
    }, [items, selectedOptions]);

    return (
        <Box>
            <Box sx={{ px: 2, py: 1.5 }}>
                <Autocomplete
                    value={value}
                    filterOptions={filterOptions}
                    options={options}
                    disableCloseOnSelect
                    clearOnBlur={true}
                    clearOnEscape={true}
                    getOptionLabel={(option: any) => option.label}
                    freeSolo={true}
                    isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
                    renderInput={(params) => (
                        <TextField
                            variant="outlined"
                            label="Search City or State"
                            {...params}
                            size="small"
                            InputProps={{
                                ...params?.InputProps,
                                startAdornment: (
                                    <InputAdornment
                                        position="start"
                                        sx={{
                                            color: alpha('#000000', 0.56),
                                        }}
                                    >
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    )}
                    renderOption={(props, option: any, { selected }) => {
                        const { key, ...optionProps } = props;

                        return (
                            <li key={key} {...optionProps}>
                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                />
                                <Column gap={0}>
                                    <Typography variant="body2" color="text.secondary">
                                        {option.label}
                                    </Typography>
                                    <Typography variant="caption" textTransform="uppercase">
                                        {option?.type}
                                    </Typography>
                                </Column>
                            </li>
                        );
                    }}
                    onChange={(event: any, newValue: any | null, reason: any, details?: any) => {
                        const newSelectedOptions = [...selectedOptions, ...[newValue]];

                        if (reason === 'selectOption') {
                            setSelectedOptions(newSelectedOptions);
                        }

                        setValue(newValue);
                    }}
                />
            </Box>

            {selectedOptions && selectedOptions.length > 0 && (
                <List
                    sx={{
                        width: '100%',
                        maxHeight: '200px',
                        overflowY: 'auto',
                        mt: 1,
                    }}
                >
                    {selectedOptions?.map((value: any, index: number) => {
                        const labelId = `checkbox-list-label-${value?.id}`;

                        return (
                            <ListItem key={value.id} disablePadding alignItems="flex-start">
                                <ListItemButton
                                    role={undefined}
                                    onClick={() => handleToggle(index)}
                                    dense
                                    sx={{
                                        alignItems: 'flex-start',
                                        paddingLeft: 3.5,
                                    }}
                                >
                                    <ListItemIcon sx={{ minWidth: 40, mt: 1 }}>
                                        <Checkbox
                                            edge="start"
                                            // checked={filterItem?.children?.some((item) => item.id === value.id)}
                                            defaultChecked
                                            tabIndex={-1}
                                            inputProps={{ 'aria-labelledby': labelId }}
                                            sx={{
                                                '& .MuiSvgIcon-root': {
                                                    color: 'unset',
                                                },
                                                padding: 0,
                                            }}
                                        />
                                    </ListItemIcon>
                                    <ListItemText
                                        id={labelId}
                                        primary={value.label}
                                        secondary={value.type}
                                        secondaryTypographyProps={{
                                            variant: 'caption',
                                            textTransform: 'uppercase',
                                        }}
                                        sx={{ color: 'text.secondary' }}
                                    />
                                </ListItemButton>
                            </ListItem>
                        );
                    })}
                </List>
            )}
        </Box>
    );
}
