import { MenuItem } from '@mui/material';
import { PropsWithChildren, useState } from 'react';
import theme, { navBackground } from '../../theme';
import Menu from '@mui/material/Menu';
import NavigationItem, { NavigationItemProps } from './NavigationItem';
import { useNavigate } from 'react-router-dom';
import AccessGuard from '../AccessGuard';

type NavigationMenuProps = {
    name: string;
    hasActiveItem: boolean;
    onNavigate(name: string): void;
    icon: React.ReactElement;
    items?: NavigationItemProps[];
};

export default function NavigationMenu(props: PropsWithChildren<NavigationMenuProps>) {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const navigate = useNavigate();

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (link: string) => {
        setAnchorEl(null);
        if (link !== '') {
            navigate(link);
        }
    };

    return (
        <>
            <NavigationItem
                label={props.name}
                to=""
                hasActiveItem={props.hasActiveItem}
                icon={props.icon}
                onClick={handleClick}
                onNavigate={(name) => {}}
            />

            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={() => handleClose('')}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                sx={{
                    zIndex: 1600,
                    '& .MuiMenu-paper': {
                        color: theme.palette.primary.contrastText,
                        backgroundColor: navBackground,
                        borderWidth: '1px',
                        borderColor: theme.palette.action.selected,
                        borderStyle: 'solid',
                        minWidth: '176px',
                    },
                    mt: '18px',
                    ml: '-13px',
                }}
            >
                {props.items?.map((i) => {
                    return (
                        <AccessGuard key={i.label} accessGroup={i.accessGroup} accessAgency={i.accessAgency}>
                            <MenuItem
                                key={i.label}
                                onClick={() => {
                                    i.onNavigate(props.name);
                                    handleClose(i.to);
                                }}
                            >
                                {i.label}
                            </MenuItem>
                        </AccessGuard>
                    );
                })}
            </Menu>
        </>
    );
}
