import { ChangeEvent, useEffect, useState } from 'react';
import ApiService from '../../ApiService';
import { MenuItem, TextField } from '@mui/material';
import { DspEntity } from '../../types/DspEntity';

export interface DspEntityProps {
    disabled?: boolean;
    dspEntityId: number | undefined;
    onSelectDspEntity(dspEntityId: number): void;
    error?: boolean;
    variant?: 'filled' | 'outlined';
    shrinkLabel?: boolean;
}

export default function DspEntityComponent({
    disabled,
    dspEntityId,
    error,
    onSelectDspEntity,
    shrinkLabel,
    variant = 'outlined',
}: DspEntityProps) {
    const [dspEntities, setDspEntities] = useState<DspEntity[] | null>(null);

    useEffect(() => {
        ApiService.getDspEntities()
            .then((response) => {
                setDspEntities(response.data);
            })
            .catch(() => {});
    }, []);

    function handleChange(event: ChangeEvent<HTMLInputElement>) {
        const id = Number(event.target.value);
        onSelectDspEntity(id);
    }

    return (
        <>
            {dspEntities && (
                <div id="dspEntity" style={{ width: '100%' }}>
                    <TextField
                        variant={variant}
                        fullWidth
                        size="small"
                        label={disabled ? 'DSP Entity' : 'Select a DSP Entity'}
                        name="dspEntityId"
                        select
                        disabled={disabled}
                        required={!disabled}
                        value={dspEntityId != null ? String(dspEntityId) : ''}
                        error={error}
                        helperText={error ? 'DSP Entity is required.' : ''}
                        onChange={handleChange}
                        SelectProps={{ native: false }}
                        InputLabelProps={{ shrink: shrinkLabel }}
                    >
                        {dspEntities.map((dspEntity) => {
                            return (
                                <MenuItem key={String(dspEntity.id)} value={String(dspEntity.id)}>
                                    {dspEntity.entityName}
                                </MenuItem>
                            );
                        })}
                    </TextField>
                </div>
            )}
        </>
    );
}
