import { Card, Stack, Typography } from '@mui/material';

export default function MetricCard(props: { metric: JSX.Element; label: string }) {
    const { metric, label } = props;

    return (
        <Card variant="outlined">
            <Stack direction="column" style={{ alignItems: 'center' }}>
                <Typography sx={{ mt: 1 }} variant="h6">
                    {label}
                </Typography>
                {metric}
            </Stack>
        </Card>
    );
}
