import Typography from '@mui/material/Typography';
import { Divider } from '@mui/material';

export function SectionHeading(props: {
    children: string | React.ReactElement;
    subheading?: string;
    divider?: boolean;
}) {
    const { children, subheading, divider } = props;

    return (
        <div>
            <Typography variant="h6">{children}</Typography>
            {subheading && (
                <Typography variant="body2" color="text.secondary">
                    {subheading}
                </Typography>
            )}
            {divider && <Divider />}
        </div>
    );
}
