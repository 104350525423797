import { useContext } from 'react';
import { CircularProgress, Grid } from '@mui/material';
import { Alert } from '@mui/material';
import { AgencyContext } from '../App';

export interface AppLoadingProps {
    systemError: boolean;
}

export default function AppLoading(props: AppLoadingProps) {
    const agency = useContext(AgencyContext);
    return (
        <>
            {props.systemError && (
                <Alert severity="error">
                    {agency?.name ? agency.name : '[cognition] '} is currently
                    unavailable.
                </Alert>
            )}
            <div>
                <Grid
                    container
                    spacing={1}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    style={{ minHeight: '100vh' }}
                >
                    <Grid item xs={12}>
                        <img
                            alt={agency?.name}
                            src={
                                agency && agency.logoUrl
                                    ? agency.logoUrl
                                    : '/logo.png'
                            }
                            style={{ height: '32px' }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <CircularProgress color="info" />
                    </Grid>
                </Grid>
            </div>
        </>
    );
}
