import { createContext, useContext, useState } from 'react';
import { GridColumnVisibilityModel } from '@mui/x-data-grid/hooks/features/columns/gridColumnsInterfaces';

export class CampaignManagerPageContextValue {
    campaignGridColumnVisibility: GridColumnVisibilityModel = {};
    setCampaignGridColumnVisibility: Function = (campaignGridColumnVisibility: GridColumnVisibilityModel) => {};
}

export const CampaignManagerPageContext = createContext<CampaignManagerPageContextValue>(
    new CampaignManagerPageContextValue()
);

export function useCampaignManagerPageContext() {
    return useContext(CampaignManagerPageContext);
}

export type CampaignManagerPageProps = {};

export default function useCampaignManagerPage() {
    const [campaignGridColumnVisibility, setCampaignGridColumnVisibility] = useState<GridColumnVisibilityModel>({});

    return {
        campaignGridColumnVisibility,
        setCampaignGridColumnVisibility,
    };
}
