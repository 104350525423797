import { Grid } from '@mui/material';

import Column from '../../../../../components/Column';
import theme from '../../../../../theme';
import { useReportingDashboardPageContext } from '../../../../../hooks/useReportingDashboardPage';

import PerformanceAverageCPMBasicBarChartCard from '../Metrics/PerformanceAverageCPMBasicBarChartCard';
import PerformanceAverageCPMByStrategyTypeBasicBarChartCard from '../Metrics/PerformanceAverageCPMByStrategyTypeBasicBarChartCard';
import PerformanceByStrategyTypeGroupedBarChartCard from '../Metrics/PerformanceByStrategyTypeGroupedBarChartCard';
import PerformanceGroupedBarChartCard from '../Metrics/PerformanceGroupedBarChartCard';
import PerformanceInvestmentByStrategyTypePieChartCard from '../Metrics/PerformanceInvestmentByStrategyTypePieChartCard';
import PerformanceInvestmentPieChartCard from '../Metrics/PerformanceInvestmentPieChartCard';

export default function PerformanceChartView() {
    const { isFetchingPerformances, reportingCriteria } = useReportingDashboardPageContext();

    return (
        <Column gap={3}>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    {reportingCriteria?.mediaType !== '' ? (
                        <PerformanceInvestmentByStrategyTypePieChartCard loading={isFetchingPerformances} />
                    ) : (
                        <PerformanceInvestmentPieChartCard loading={isFetchingPerformances} />
                    )}
                </Grid>

                <Grid item xs={6}>
                    {reportingCriteria?.mediaType !== '' ? (
                        <PerformanceAverageCPMByStrategyTypeBasicBarChartCard loading={isFetchingPerformances} />
                    ) : (
                        <PerformanceAverageCPMBasicBarChartCard loading={isFetchingPerformances} />
                    )}
                </Grid>
            </Grid>

            <Grid item xs={12} sx={{ mt: theme.spacing(1) }}>
                {reportingCriteria?.mediaType !== '' ? (
                    <PerformanceByStrategyTypeGroupedBarChartCard loading={isFetchingPerformances} />
                ) : (
                    <PerformanceGroupedBarChartCard loading={isFetchingPerformances} />
                )}
            </Grid>
        </Column>
    );
}
