import { SyntheticEvent, useCallback, useEffect, useRef, useState } from 'react';
import {
    Autocomplete,
    AutocompleteProps,
    Checkbox,
    Chip,
    Paper,
    TextField,
    TextFieldProps,
    Typography,
} from '@mui/material';
import { CheckBox as CheckBoxIcon, CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon } from '@mui/icons-material';

import { Dealer } from '../types/Dealer';
import ApiService from '../ApiService';

// interface AutocompleteOption {
//     id: number;
//     name: string;
//     label: string;
//     value: string;
// }

export type DealerAutocompleteProps = {
    agencies?: number[];
    onFetch?: (dealers: Dealer[]) => void;
    onFetching?: () => void;

    value?: number[];
    error?: boolean;
    label?: string;
    required?: boolean;
    variant?: TextFieldProps['variant'];
    TextFieldProps?: TextFieldProps;
} & Omit<AutocompleteProps<Dealer, true, false, false>, 'renderInput' | 'options' | 'value'>;

export default function DealerAutocomplete(props: DealerAutocompleteProps) {
    const { agencies = [], onFetch, onFetching, value = [], ...autocompleteProps } = props;
    const { onChange } = props;

    const [isFocused, setIsFocused] = useState<boolean>(false);
    const [dealers, setDealers] = useState<Dealer[]>([]);
    const [selectedDealers, setSelectedDealers] = useState<Dealer[]>([]);
    const $agencies = useRef<number[]>([]);

    const updateDealers = useCallback(
        (newDealers: Dealer[]) => {
            const newlyAddedAgencies = agencies.filter((agencyId) => !$agencies.current.includes(agencyId));
            const newlyRemovedAgencies = $agencies.current.filter((agencyId) => !agencies.includes(agencyId));

            const retainedDealers = selectedDealers.filter(
                (dealer) => !newlyRemovedAgencies.includes(dealer.agencyId as number)
            );
            const newAgencyDealers = newDealers.filter(
                (dealer) => newlyAddedAgencies.includes(dealer.agencyId as number) && dealer.activeStatus
            );

            const updatedDealers = [...retainedDealers, ...newAgencyDealers].filter(
                (dealer, index, self) => self.findIndex((d) => d.id === dealer.id) === index
            );

            $agencies.current = agencies;

            if (agencies.length > 0) {
                const event = new Event('selectOption') as unknown as SyntheticEvent<Element, Event>;
                onChange?.(event, updatedDealers, 'selectOption');
            } else if ($agencies.current.length > 0) {
                const event = new Event('clear') as unknown as SyntheticEvent<Element, Event>;
                onChange?.(event, [], 'clear');
            }
        },
        [agencies, selectedDealers, onChange]
    );

    const fetchDealers = useCallback(() => {
        if (onFetching) {
            onFetching();
        }

        ApiService.getDealerList({ agencyIds: agencies, page: 1, pageSize: -1 })
            .then((response) => {
                if (onFetch) {
                    onFetch(response.data);
                }

                const newDealers = response.data.filter((dealer: { activeStatus: boolean }) => dealer.activeStatus);

                setDealers(newDealers);

                updateDealers(newDealers);
            })
            .catch();
    }, [agencies]);

    const getOptionId = (dealer: Dealer): number => dealer.id as number;

    const getOptionLabel = (dealer: Dealer): string => dealer.dealerName;

    useEffect(() => {
        const newDealers: Dealer[] = dealers.filter((dealer: Dealer) => value.includes(dealer.id as number));

        setSelectedDealers(newDealers);
    }, [value, dealers]);

    useEffect(() => {
        fetchDealers();
    }, [fetchDealers]);

    return (
        <Autocomplete
            multiple
            size="small"
            fullWidth={false}
            disableCloseOnSelect={true}
            value={selectedDealers}
            options={dealers}
            getOptionLabel={(option) => getOptionLabel(option)}
            isOptionEqualToValue={(option, value) => option?.id === value?.id}
            renderTags={(tagValue, getTagProps) => {
                if (isFocused) {
                    return <></>;
                }

                const tagValueCount: number = tagValue.length;

                const getChipLabel = (tagValue: any): string => {
                    return `${tagValueCount} Selected`;
                };

                if (tagValueCount > 1) {
                    const index = 0;
                    const { key, ...tagProps } = getTagProps({ index });
                    return (
                        <Chip
                            key={key}
                            label={getChipLabel(tagValue)}
                            size="small"
                            {...tagProps}
                            onDelete={() => {
                                const event = new Event('clear') as unknown as SyntheticEvent<Element, Event>;

                                if (props.onChange) {
                                    props.onChange(event, [], 'clear');
                                }
                            }}
                        />
                    );
                }

                return (
                    <Typography
                        sx={{
                            px: (theme) => theme.spacing(1),
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                        }}
                    >
                        {tagValueCount > 0 ? getOptionLabel(tagValue[0]) : ''}
                    </Typography>
                );
            }}
            renderOption={(props, option, { selected }) => {
                const { ...optionProps } = props;
                return (
                    <li {...optionProps} key={getOptionId(option)}>
                        <Checkbox
                            icon={<CheckBoxOutlineBlankIcon color="action" />}
                            checkedIcon={<CheckBoxIcon color="primary" />}
                            style={{ marginRight: 8 }}
                            checked={selected}
                        />
                        {getOptionLabel(option)}
                    </li>
                );
            }}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            renderInput={(inputProps) => (
                <TextField
                    error={props.error}
                    label={props.label ?? 'Select an Advertiser'}
                    variant={props.variant ?? 'outlined'}
                    required={props.required !== undefined ? props.required : !props.disabled}
                    {...inputProps}
                    {...props.TextFieldProps}
                />
            )}
            PaperComponent={(paperComponentProps) => (
                <Paper
                    {...paperComponentProps}
                    sx={{
                        boxShadow: 8,
                        borderRadius: '8px',
                    }}
                />
            )}
            sx={{
                '& .MuiAutocomplete-popupIndicator': {
                    color: 'action.active',
                },
                '& .MuiAutocomplete-inputRoot': {
                    height: '2.5rem',
                    overflow: 'hidden',
                },
            }}
            {...autocompleteProps}
        />
    );
}
