import { OemOfferMedia } from '../../types/OemOffer';
import { Link } from '@mui/material';

export default function OfferMediaLink(props: { offerMedia: OemOfferMedia; fullName?: boolean }) {
    const { offerMedia, fullName } = props;
    if (!offerMedia) {
        return null;
    }
    const fileName = offerMedia.fileName ? offerMedia.fileName : offerMedia.s3Url;
    return (
        <Link target="new" href={offerMedia.s3Url} onClick={(event) => event.stopPropagation()}>
            {fullName && fileName}
            {!fullName && '...' + fileName.substring(fileName.length - 10)}
        </Link>
    );
}
