import { useEffect, useState } from 'react';
import {
    DataGridPro,
    GridRenderCellParams,
    useGridApiContext,
    GridColDef,
    GridRowsProp,
    DataGridProProps,
    useGridSelector,
    gridFilteredDescendantCountLookupSelector,
    GridRowModel,
} from '@mui/x-data-grid-pro';
import Box, { BoxProps } from '@mui/material/Box';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import axios, { CancelTokenSource } from 'axios';

import ApiService from '../../ApiService';

function CustomGridTreeDataGroupingCell(props: GridRenderCellParams) {
    const { id, field, rowNode, value } = props;
    const apiRef = useGridApiContext();
    const filteredDescendantCountLookup = useGridSelector(apiRef, gridFilteredDescendantCountLookupSelector);
    const filteredDescendantCount = filteredDescendantCountLookup[rowNode.id] ?? 0;

    const handleClick: BoxProps['onClick'] = (event) => {
        if (rowNode.type !== 'group') {
            return;
        }

        apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded);
        apiRef.current.setCellFocus(id, field);
        event.stopPropagation();
    };

    const isTreeNode = rowNode && 'childrenExpanded' in rowNode;
    const isExpanded = isTreeNode ? rowNode.childrenExpanded : false;

    return (
        <Box sx={{ ml: rowNode.depth * 4 }}>
            <div>
                {filteredDescendantCount > 0 ? (
                    <Box
                        onClick={handleClick}
                        tabIndex={-1}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        {!isExpanded ? <KeyboardArrowRightIcon /> : <KeyboardArrowDownIcon />}
                        {value} ({filteredDescendantCount})
                    </Box>
                ) : (
                    <span />
                )}
            </div>
        </Box>
    );
}
const columns: GridColDef[] = [
    { field: 'model', headerName: 'Model', minWidth: 200, flex: 1 },
    {
        field: 'count',
        headerName: 'Vehicle Count',
        type: 'number',
        minWidth: 150,
        flex: 1,
    },
];

const getTreeDataPath: DataGridProProps['getTreeDataPath'] = (row) => row.hierarchy;
const groupingColDef: DataGridProProps['groupingColDef'] = {
    headerName: 'Make',
    sortable: true,
    flex: 1,
    renderCell: (params) => <CustomGridTreeDataGroupingCell {...params} />,
};

export default function TreeDataGrid({
    setResultsCount,
    dbFilters = '',
}: {
    setResultsCount: (count: number) => void;
    dbFilters?: string;
}) {
    const [rows, setRows] = useState<GridRowsProp>([]);
    const [isFetchingRows, setIsFetchingRows] = useState<boolean>(false);
    const [cancelTokenSource, setCancelTokenSource] = useState<CancelTokenSource | null>(null);

    useEffect(() => {
        if (cancelTokenSource) {
            cancelTokenSource.cancel('Operation canceled due to new request.');
        }

        const newCancelTokenSource: CancelTokenSource = axios.CancelToken.source();
        setCancelTokenSource(newCancelTokenSource);

        setIsFetchingRows(true);

        ApiService.getPolkSales(dbFilters, {
            cancelToken: newCancelTokenSource.token,
        })
            .then((response) => {
                const treeRows: GridRowModel[] = [];
                if (response?.data?.length) {
                    let id = 0;
                    let rowTotalCount = 0;
                    let previewMake = '';
                    response?.data?.map((item, index) => {
                        id++;
                        if (id === 1) previewMake = item.make;
                        if (previewMake !== item.make) {
                            treeRows.push({
                                hierarchy: [previewMake],
                                count: rowTotalCount,
                                id: id,
                            });

                            rowTotalCount = 0;
                            previewMake = item.make;
                            id++;
                        }

                        rowTotalCount += item.count;
                        treeRows.push({
                            hierarchy: [item.make, item.model],
                            model: item.model,
                            count: item.count,
                            id: id,
                        });

                        if (index === response.data.length - 1) {
                            id++;
                            treeRows.push({
                                hierarchy: [item.make],
                                count: rowTotalCount,
                                id: id,
                            });

                            rowTotalCount = 0;
                            id = 0;
                            previewMake = '';
                        }

                        return item;
                    });
                }
                setResultsCount(treeRows.length);
                setRows(treeRows);
            })
            .catch((error) => {
                setRows([]);
            })
            .finally(() => {
                setIsFetchingRows(false);
            });

        return () => {
            if (cancelTokenSource) {
                cancelTokenSource.cancel('Component unmounted or criteria changed.');
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dbFilters]);

    const [paginationModel, setPaginationModel] = useState({
        pageSize: 20,
        page: 0,
    });

    return (
        <DataGridPro
            initialState={{
                sorting: {
                    sortModel: [{ field: 'count', sort: 'desc' }],
                },
            }}
            loading={isFetchingRows}
            treeData
            rows={rows}
            columns={columns}
            getTreeDataPath={getTreeDataPath}
            groupingColDef={groupingColDef}
            pagination
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            pageSizeOptions={[10, 20, 50]}
            sx={{
                overflow: 'hidden',
            }}
        />
    );
}
