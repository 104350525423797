import QuickSightDashboard from '../../components/QuickSightDashboard';
import AppBreadcrumbs from '../../components/AppBreadcrumbs';
import Column from '../../components/Column';
import Heading from '../../components/Heading';

export default function OperationsDashboardPage() {
    return (
        <Column sx={{ height: '100%' }}>
            <Column gap={1}>
                <AppBreadcrumbs items={[]} />

                <Heading>Operations Dashboard</Heading>
            </Column>

            <Column gap={2} sx={{ height: '100%' }}>
                <QuickSightDashboard dashboardId="2c09b926-aaad-4dd9-96a1-36f7e36b5a8f" />
            </Column>
        </Column>
    );
}
