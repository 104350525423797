import AppBreadcrumbs from '../../../components/AppBreadcrumbs';
import Column from '../../../components/Column';
import Heading from '../../../components/Heading';
import { useEffect, useState } from 'react';
import { Alert, Button } from '@mui/material';
import { AddOutlined } from '@mui/icons-material';
import { GridRowParams } from '@mui/x-data-grid';
import { GridToolbarContainer } from '../../../components/DataGrid';
import ApiService from '../../../ApiService';
import { Workflow } from '../../../types/Workflow';
import { useNavigate } from 'react-router-dom';
import WorkflowCreateFormDialog from './WorkflowCreateFormDialog';
import CDGrid from '../../../components/CDGrid';

export default function WorkflowsPage() {
    const navigate = useNavigate();
    const [workflows, setWorkflows] = useState<Workflow[] | null>([]);
    const [workflowFormDialogOpen, setWorkflowFormDialogOpen] = useState<boolean>(false);

    useEffect(() => {
        getWorkflows();
    }, []);

    function getWorkflows() {
        ApiService.getWorkflows()
            .then((response) => {
                setWorkflows(response.data);
            })
            .catch(() => {
                setWorkflows([]);
            });
    }

    function refresh() {
        getWorkflows();
    }

    function handleEditWorkflow(params: GridRowParams) {
        if (params && params.row) {
            navigate('' + params.row.id, {
                state: { ...params.row, isNew: false },
            });
        }
    }

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <Alert severity="info">
                    Global Workflows applied to all Instances before Workflow Execution &nbsp;
                </Alert>

                <div style={{ flexGrow: 1 }} />
                <Button
                    sx={{ marginTop: '5px', marginBottom: '10px' }}
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        setWorkflowFormDialogOpen(true);
                    }}
                    startIcon={<AddOutlined />}
                >
                    Add
                </Button>
            </GridToolbarContainer>
        );
    }

    return (
        <Column sx={{ height: '100%' }}>
            <Column gap={1}>
                <AppBreadcrumbs items={[]} />

                <Heading>AMC Global Workflows</Heading>
            </Column>

            <Column gap={2}>
                <CDGrid
                    components={{ Toolbar: CustomToolbar }}
                    getRowId={(row) => row.workflowId}
                    columns={[
                        {
                            width: 400,
                            field: 'workflowId',
                            headerName: 'Workflow ID',
                            headerClassName: 'grid-header',
                            renderCell: (params) => (
                                <Button
                                    onClick={() => {
                                        handleEditWorkflow(params.value);
                                    }}
                                >
                                    {params.value}
                                </Button>
                            ),
                        },
                        {
                            width: 200,
                            field: 'sqlQuery',
                            headerName: 'SQL',
                            headerClassName: 'grid-header',
                            flex: 1,
                        },
                    ]}
                    rows={workflows === null ? [] : workflows}
                    onRowClick={handleEditWorkflow}
                    pageSize={10}
                    rowsPerPageOptions={[10]}
                />
            </Column>

            {workflowFormDialogOpen && (
                <WorkflowCreateFormDialog
                    open={workflowFormDialogOpen}
                    onClose={() => {
                        refresh();
                        setWorkflowFormDialogOpen(false);
                    }}
                />
            )}
        </Column>
    );
}
