import { useContext, useEffect, useRef, useState } from 'react';
import { Badge, IconButton, Tooltip } from '@mui/material';
import { Notifications } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import ApiService from '../ApiService';
import { UserContext } from '../App';
import theme from '../theme';
import { navItemBackgroundHover } from '../theme';

export default function NotificationsButton() {
    const anchorRef = useRef<HTMLButtonElement | null>(null);
    const navigate = useNavigate();
    const [unread, setUnread] = useState<number>(0);
    const { userContext } = useContext(UserContext);

    function handleOpenPopover() {
        navigate('/jobStatus');
    }

    useEffect(() => {
        if (userContext.isAdmin()) {
            getFailedJobCount();
        }
    }, [userContext]);

    function getFailedJobCount() {
        ApiService.getFailedJobCount()
            .then((response) => {
                setUnread(response.data);
            })
            .catch(() => {});
    }

    return (
        <>
            <Tooltip title="Job Status">
                <IconButton
                    ref={anchorRef}
                    sx={{
                        color: theme.palette.primary.contrastText,
                        fontSize: 15,

                        '&:hover': {
                            backgroundColor: navItemBackgroundHover,
                        },
                    }}
                    onClick={handleOpenPopover}
                >
                    <Badge color="error" badgeContent={unread}>
                        <Notifications color="inherit" fontSize="medium" />
                    </Badge>
                </IconButton>
            </Tooltip>
        </>
    );
}
