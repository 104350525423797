import { Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, Tooltip } from '@mui/material';
import { useState } from 'react';
import { Campaign } from '../../../types/Campaign';
import CampaignSummaryV2 from './CampaignSummaryV2';
import CDButton, { CDDefaultButton } from '../../../components/CDButton';
import { CloseOutlined } from '@mui/icons-material';

export default function CampaignSummaryDialog(props: {
    campaign: Campaign;
    onModify(): void;
    allowModify: boolean;
    onClose(): void;
}) {
    const { campaign, onModify, allowModify, onClose } = props;
    const [open, setOpen] = useState(true);

    return (
        <>
            <Dialog
                maxWidth="md"
                open={open}
                fullWidth
                onClose={() => {
                    setOpen(false);
                    onClose();
                }}
            >
                <DialogTitle component="span" sx={{ padding: '8px 16px' }}>
                    <span>Campaign Summary</span>
                    <Divider sx={{ marginTop: '6px' }} />
                </DialogTitle>

                <Tooltip title="Close and return to Campaign Creation">
                    <IconButton
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 4,
                        }}
                        onClick={onClose}
                        size="small"
                    >
                        <CloseOutlined />
                    </IconButton>
                </Tooltip>

                <DialogContent>
                    <span style={{ margin: '0px' }}>
                        <CampaignSummaryV2
                            showDuplicates={false}
                            campaign={campaign}
                            reloadCampaign={() => {}}
                            dealer={null}
                            allowEdit={false}
                        />
                    </span>
                </DialogContent>
                <DialogActions sx={{ padding: '14px' }}>
                    <CDButton onClick={onClose} color="inherit">
                        {allowModify ? 'Cancel' : 'Close'}
                    </CDButton>
                    <div style={{ width: '16px' }} />
                    {allowModify && (
                        <CDDefaultButton tooltip="Modify this Campaign" onClick={onModify}>
                            Modify
                        </CDDefaultButton>
                    )}
                    <div style={{ width: '8px' }} />
                </DialogActions>
            </Dialog>
        </>
    );
}
