import Button, { ButtonProps } from '@mui/material/Button';
import { forwardRef } from 'react';
import { Link, LinkProps } from 'react-router-dom';

export type LinkButtonProps = ButtonProps & {
    linkProps?: Omit<LinkProps, 'to'>;
};

const LinkBehavior = forwardRef<any, LinkButtonProps>((props, ref) => {
    const { href, linkProps, ...buttonProps } = props;
    const restLinkProps = buttonProps as Omit<LinkProps, 'to'>;
    return <Link ref={ref} to={href ?? ''} {...linkProps} {...restLinkProps} />;
});

/**
 * A Button that works as a React Router Link
 */
export function LinkButton(props: LinkButtonProps) {
    return <Button LinkComponent={LinkBehavior} {...props} />;
}
