import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { CloseOutlined } from '@mui/icons-material';

export interface ConfirmDialogProps {
    title: string;
    text?: string;
    okText?: string;
    onOk(): void;
    okIcon?: React.ReactNode;
    okColor?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
    cancelText?: string;
    onCancel(): void;
    cancelIcon?: React.ReactNode;
    cancelColor?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
    children?: JSX.Element;
}

export default function ConfirmDialog(props: ConfirmDialogProps) {
    const { cancelIcon, cancelColor, cancelText } = props;

    return (
        <Dialog open={true} onClose={props.onCancel}>
            <DialogTitle>{props.title}</DialogTitle>
            <DialogContent>
                <DialogContentText>{props.text}</DialogContentText>
                {props.children}
            </DialogContent>
            <DialogActions>
                <Button
                    variant="outlined"
                    startIcon={cancelIcon || cancelIcon === null ? cancelIcon : <CloseOutlined />}
                    color={cancelColor ?? 'error'}
                    onClick={props.onCancel}
                >
                    {cancelText ? cancelText : 'Cancel'}
                </Button>
                <Button
                    variant="contained"
                    color={props.okColor ? props.okColor : 'primary'}
                    startIcon={props.okIcon}
                    onClick={props.onOk}
                    autoFocus
                >
                    {props.okText ? props.okText : 'Ok'}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
