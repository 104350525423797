import { Campaign } from '../../../types/Campaign';
import CampaignSummaryV2 from '../campaignEdit/CampaignSummaryV2';

export default function SummaryTab(props: { campaign: Campaign; reloadCampaign(): void }) {
    const { campaign, reloadCampaign } = props;

    return (
        <>
            <CampaignSummaryV2
                allowEdit={false}
                showDuplicates={true}
                campaign={campaign}
                reloadCampaign={reloadCampaign}
                dealer={campaign.dealer ? campaign.dealer : null}
            />
        </>
    );
}
