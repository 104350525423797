import { useMemo, useState, Fragment, useEffect } from 'react';
import { Box, Chip, Typography, Button } from '@mui/material';
import moment from 'moment';

import { SectionHeading } from '../../components/SectionHeading';
import Filters from './Filters';
import ActiveFilterDialog from './ActiveFilterDialog';
import TreeDataGrid from './TreeDataGrid';
import CardList from './CardList';
import SelectMonth from './SelectMonth';
import Row from '../../components/Row';
import { getDefaultDates, removeItemAtIndex } from './utils';
export interface IFilterItem {
    id: string;
    label: string;
    children?: IFilterItem[];
    hasSubCategory?: boolean;
    hasSearchbox?: boolean;
    isDropdown?: boolean;
    type?: string;
}

export const FilterList: IFilterItem[] = [
    // {
    //     id: 'platform_users',
    //     label: 'Platform Users',
    //     children: [],
    // },
    {
        id: 'purchase_type',
        label: 'Purchase Type',
        children: [],
    },
    {
        id: 'make_model',
        label: 'Make & Model',
        children: [],
        hasSubCategory: true,
        hasSearchbox: true,
    },
    {
        id: 'fuel_type',
        label: 'Fuel Type',
        children: [],
    },
    {
        id: 'segments',
        label: 'Segments',
        children: [],
        hasSearchbox: true,
    },
    {
        id: 'geography',
        label: 'Geography',
        children: [],
    },
    {
        id: 'month',
        label: 'Month',
        children: [],
        isDropdown: true,
    },
];

const SalesInsights = () => {
    const { startDate: defaultStartDate, endDate: defaultEndDate } = getDefaultDates();
    const [showAll, setShowAll] = useState(false);
    const [filters, setFilters] = useState<IFilterItem[]>(JSON.parse(JSON.stringify(FilterList)));
    const [applyFilters, setApplyFilters] = useState<IFilterItem[]>(JSON.parse(JSON.stringify(FilterList)));
    const [resultCount, setResultsCount] = useState(0);
    const [dbFilters, setDbFilters] = useState('');
    useEffect(() => {
        let newDbFilters = '?';
        filters?.forEach((filter) => {
            if (filter.id === 'purchase_type' && filter?.children?.length)
                newDbFilters += '&newusedind=' + filter?.children?.map((item) => item.id) || '';
            if (filter.id === 'fuel_type' && filter?.children?.length)
                newDbFilters += '&fueldesc=' + filter?.children?.map((item) => item.id) || '';
            if (filter.id === 'segments' && filter?.children?.length)
                newDbFilters += '&segmentdescription=' + filter?.children?.map((item) => item.id) || '';
            if (filter.id === 'geography' && filter?.children?.length)
                newDbFilters += '&locations=' + filter?.children?.map((item) => item.id) || '';
            if (filter.id === 'month') {
                if (filter?.children?.length === 1) {
                    const ids = filter?.children?.[0]?.id?.split('@');
                    if (!ids?.length) return;
                    newDbFilters += '&startDate=' + ids?.[0] || '';
                    newDbFilters += '&endDate=' + ids?.[1] || '';
                } else {
                    newDbFilters += '&startDate=' + moment(defaultStartDate).format('YYYY-MM-DD');
                    newDbFilters += '&endDate=' + moment(defaultEndDate).format('YYYY-MM-DD');
                }
            }
            if (filter.id === 'make_model' && filter?.children?.length) {
                let db_make = '&make=' + filter?.children?.map((item) => item.id) || '';
                let db_model = '&model=';
                filter?.children?.forEach((i) => {
                    db_model += i?.children?.map((item) => item.id?.split('@')?.[1]) || '';
                });
                newDbFilters += db_make;
                newDbFilters += db_model;
            }
        });
        newDbFilters = newDbFilters.replace('?&', '?');
        setDbFilters(newDbFilters === '?' ? '' : newDbFilters);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [applyFilters]);

    const monthFilterIndex = useMemo((): number => {
        return FilterList.findIndex((filterItem: IFilterItem) => filterItem.id === 'month');
    }, []);

    const GetAllChildrenFiltersChip = ({ items, signature }: { items: IFilterItem[]; signature: number[] }) => {
        return (
            <>
                {items.map((item: IFilterItem, index: number) => {
                    const itemSignature: number[] = [...signature, index];
                    return (
                        <Fragment key={item.id}>
                            {!item?.children && (
                                <Chip
                                    size="small"
                                    label={item.label}
                                    onDelete={() => {
                                        const newFilters = removeItemAtIndex([...applyFilters], itemSignature || []);
                                        setFilters(JSON.parse(JSON.stringify(newFilters)));
                                        setApplyFilters(JSON.parse(JSON.stringify(newFilters)));
                                    }}
                                />
                            )}
                            {item?.children && item?.children?.length > 0 && (
                                <GetAllChildrenFiltersChip signature={itemSignature} items={item.children} />
                            )}
                        </Fragment>
                    );
                })}
            </>
        );
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}>
                <Row justifyContent="space-between" alignItems="center" spacing={2} sx={{ px: 3, py: 2 }}>
                    <Box>
                        <Row gap={2} alignItems="center">
                            <SectionHeading subheading={''} divider={false}>
                                <Row gap={1} alignItems="center">
                                    <Typography variant="h6">Insights</Typography>
                                </Row>
                            </SectionHeading>
                        </Row>
                    </Box>
                </Row>
            </Box>

            <Box>
                <Row justifyContent="space-between" alignItems="center" spacing={2} sx={{ px: 3, py: 2 }}>
                    <SelectMonth setFilters={setFilters} id={monthFilterIndex} filters={filters} />
                </Row>
            </Box>

            <Box
                sx={{
                    display: 'flex',
                    gap: 2,
                    px: 3,
                }}
            >
                <Filters setFilters={setFilters} filters={filters} setApplyFilters={setApplyFilters} />
                <Box sx={{ width: '100%' }}>
                    <Box
                        sx={{
                            display: 'flex',
                            gap: 2,
                            alignItems: 'center',
                            flexWrap: 'wrap',
                        }}
                    >
                        <Typography variant="subtitle2" color="text.primary">
                            {resultCount} results
                        </Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                gap: 1,
                                alignItems: 'center',
                                flexWrap: 'wrap',
                                '& .MuiChip-root:nth-of-type(n+5)': {
                                    display: 'none',
                                },
                            }}
                            className="chiplist"
                        >
                            <GetAllChildrenFiltersChip signature={[]} items={applyFilters} />
                            <Button
                                size="small"
                                sx={{
                                    display: 'none',
                                    '.chiplist:has(.MuiChip-root:nth-of-type(n+5)) &.MuiButton-root': {
                                        display: 'block',
                                    },
                                }}
                                onClick={() => setShowAll(true)}
                            >
                                Show All
                            </Button>
                        </Box>
                    </Box>

                    <CardList dbFilters={dbFilters} />

                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                            minHeight: 400,
                            // height: 'calc(100vh - 400px)',
                        }}
                    >
                        <TreeDataGrid setResultsCount={setResultsCount} dbFilters={dbFilters} />
                    </Box>
                </Box>
            </Box>
            {showAll && (
                <ActiveFilterDialog
                    open={showAll}
                    setApplyFilters={setApplyFilters}
                    setOpen={setShowAll}
                    filters={filters}
                    setFilters={setFilters}
                />
            )}
        </Box>
    );
};

export default SalesInsights;
