import { Box, Button, IconButton, InputLabel, List, ListItem, ListItemText, styled, Typography } from '@mui/material';
import { AttachFileOutlined, CloseOutlined, DownloadOutlined } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import ApiService from '../../../ApiService';
import Utils from '../../../components/Utils';
import { Campaign } from '../../../types/Campaign';

export default function CreativeAssetsComponent(props: {
    campaign: Campaign;
    maxFileSize: number;
    onFilesChanged(files: File[]): void;
    viewOnly: boolean;
}) {
    const { campaign, onFilesChanged, maxFileSize, viewOnly } = props;
    const [files, setFiles] = useState<File[]>([]);
    const [existingFiles, setExistingFiles] = useState<File[]>([]);

    useEffect(() => {
        if (campaign.campaignId) {
            getFileList(campaign.campaignId, campaign.changeRequestId);
        }
    }, [campaign]);

    function getFileList(id: number, changeRequestId?: number) {
        ApiService.getCampaignCreatives(id, changeRequestId)
            .then((response) => {
                let fileList: any = [];
                response.data.forEach((f) => {
                    fileList.push({ name: f });
                });
                setExistingFiles(fileList);
            })
            .catch();
    }

    function removeFile(file: File) {
        let d = files.filter((f) => f.name !== file.name);
        setFiles(d);
        onFilesChanged(d);
    }

    function downloadFile(file: string) {
        // @ts-ignore
        ApiService.getCampaignCreative(campaign.campaignId, file)
            .then((response) => {
                Utils.downloadFile(response, 'application/octetstream');
            })
            .catch(() => {});
    }

    function onFileChange(event: any) {
        const fileList = [...files];
        fileList.push.apply(fileList, event.target.files);
        setFiles(fileList);
        onFilesChanged(fileList);
    }

    return (
        <Box>
            {files.length + existingFiles.length > 0 && (
                <List disablePadding sx={{ width: '100%' }} dense>
                    {files.map((f) => (
                        <FileItem
                            key={`${f.name}:${f.size}`}
                            f={f}
                            removeFile={removeFile}
                            downloadFile={downloadFile}
                            maxFileSize={maxFileSize}
                        />
                    ))}

                    {existingFiles.map((f) => (
                        <FileItem
                            complete
                            key={`${f.name}:${f.size}`}
                            f={f}
                            removeFile={removeFile}
                            downloadFile={downloadFile}
                            maxFileSize={maxFileSize}
                        />
                    ))}
                </List>
            )}

            {!viewOnly && (
                <InputLabel htmlFor="btn-uploadRequest" style={{ display: 'inline' }}>
                    <input
                        id="btn-uploadRequest"
                        name="btn-uploadRequest"
                        type="file"
                        multiple
                        style={{ display: 'none' }}
                        onChange={onFileChange}
                    />
                    <Button variant="contained" color="secondary" component="span" startIcon={<AttachFileOutlined />}>
                        Select Files
                    </Button>
                </InputLabel>
            )}
        </Box>
    );
}

const InfoItem = styled(ListItem)(({ theme }) => ({
    paddingLeft: 0,
    marginBottom: theme.spacing(3),
}));

function FileItem(props: {
    f: File;
    complete?: boolean;
    removeFile: (f: File) => void;
    downloadFile: (f: string) => void;
    maxFileSize: number;
}) {
    const { f, complete, removeFile, downloadFile, maxFileSize } = props;

    return (
        <InfoItem>
            {!f.size && (
                <Button
                    sx={{ textDecoration: 'underline' }}
                    color="info"
                    onClick={() => downloadFile(f.name)}
                    startIcon={<DownloadOutlined />}
                >
                    {f.name}
                </Button>
            )}

            {f.size && (
                <ListItemText
                    primary={f.name}
                    secondary={
                        f.size > maxFileSize ? (
                            <Typography color="error">
                                The maximum file upload size is {maxFileSize / (1024 * 1024)} MB. Larger files such as
                                video clips should be delivered via a File Sharing tool like Dropbox.
                            </Typography>
                        ) : (
                            <>
                                {formatFileSize(f.size)}
                                {complete && (
                                    <>
                                        {' • '}
                                        <Typography color="success.light" component="span" variant="body2">
                                            Complete
                                        </Typography>
                                    </>
                                )}
                            </>
                        )
                    }
                    secondaryTypographyProps={{
                        variant: 'body2',
                    }}
                ></ListItemText>
            )}

            {f.size && (
                <IconButton onClick={() => removeFile(f)}>
                    <CloseOutlined />
                </IconButton>
            )}
        </InfoItem>
    );
}

function formatFileSize(sizeInBytes: number): string {
    const mb = sizeInBytes / (1024 * 1024);
    if (mb > 1) {
        return `${mb.toFixed(1)}mb`;
    }

    const kb = sizeInBytes / 1024;
    if (kb > 1) {
        return `${Math.round(kb)}kb`;
    }

    return `${sizeInBytes}`;
}
