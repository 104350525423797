import { useContext } from 'react';

import { InfoMessageContext } from '../components/GlobalInfoMessage';

export function useInfoMessage() {
    const { infoMessage, showMessage, clearMessage } = useContext(InfoMessageContext);

    return {
        infoMessage: infoMessage,

        // Alias
        showMessage: showMessage,
        clearMessage: clearMessage,

        showInfoMessage: showMessage,
        clearInfoMessage: clearMessage,
    };
}
