import { useEffect, useState } from 'react';
import { Close as CloseIcon } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import AgencyFirstPartyDealerForm from './AgencyFirstPartyDealerForm';
import { Agency, AgencyFirstPartyDealer } from '../../types/Agency';

type AgencyFirstPartyDealerDialogProps = {
    agency: Agency;
    agencyFirstPartyDealer: AgencyFirstPartyDealer | null;
    open: boolean;
    onClose: () => void;
    onSave?: (agencyFirstPartyDealer: AgencyFirstPartyDealer, agency: Agency) => void;
};

export default function AgencyFirstPartyDealerDialog(props: AgencyFirstPartyDealerDialogProps) {
    const { agency } = props;

    const [open, setOpen] = useState<boolean>(props.open);
    const [save, setSave] = useState<boolean>(false);
    const [validated, setValidated] = useState<boolean>(false);

    const [agencyFirstPartyDealer, setAgencyFirstPartyDealer] = useState<AgencyFirstPartyDealer>({
        id: 0,
        agencyId: agency.id,
        dealerId: 0,
        dealerCode: '',
    });

    useEffect(() => {
        if (props.agencyFirstPartyDealer) {
            setAgencyFirstPartyDealer(props.agencyFirstPartyDealer);
        }
    }, []);

    const handleClose = () => {
        setOpen(false);
        setSave(false);

        props.onClose();
    };

    const handleSave = (_agencyFirstPartyDealer: AgencyFirstPartyDealer, agency: Agency) => {
        if (props.onSave) {
            props.onSave(_agencyFirstPartyDealer, agency);
        }
        handleClose();
    };

    const handleValidate = (_validated: boolean) => {
        setValidated(_validated);
    };

    const handleClickSave = () => {
        setSave(true);
    };

    return (
        <>
            <Dialog fullWidth={true} maxWidth="md" onClose={handleClose} open={open}>
                <DialogTitle sx={{ m: 0, p: 2 }}>
                    {agencyFirstPartyDealer?.id ? 'Edit Agency First-Party Dealer' : 'Add Agency First-Party Dealer'}
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 12,
                        top: 12,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers sx={{ px: 3, py: 4 }}>
                    <AgencyFirstPartyDealerForm
                        size="sm"
                        hideActions={true}
                        agency={agency}
                        agencyFirstPartyDealer={agencyFirstPartyDealer}
                        onSave={handleSave}
                        onValidate={handleValidate}
                        save={save}
                    />
                </DialogContent>
                <DialogActions sx={{ px: 3, py: 2 }}>
                    <Button variant="outlined" color="error" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        autoFocus
                        disabled={validated === false}
                        onClick={handleClickSave}
                    >
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
