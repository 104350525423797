import { Autocomplete, Paper, TextFieldProps } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import ApiService from '../ApiService';
import { Dealer } from '../types/Dealer';
import { CDTextField, CDTextFieldProps } from './CDTextField';
import { getStyles } from './ToolbarStyles';
import { UserContext } from '../App';

export interface SelectDealerProps {
    disabled?: boolean;
    dealerId: number | undefined | null;
    agencyId?: number | null;
    error?: boolean;
    dealerSelected(dealerId: number | null, dealer: Dealer | null): void;
    dealerDefaultSelect?: boolean;
    variant?: TextFieldProps['variant'];
    placeholder?: TextFieldProps['placeholder'];
    label?: string;
    required?: boolean;
    isHeader?: boolean;
    TextFieldProps?: CDTextFieldProps;
}

export default function DealerSelector(props: SelectDealerProps) {
    const { dealerSelected, dealerDefaultSelect, TextFieldProps } = props;

    const [dealers, setDealers] = useState<Dealer[] | null>(null);
    const [selectedDealer, setSelectedDealer] = useState<Dealer | null>(null);
    const [dealerDefaultSelected, setDealerDefaultSelected] = useState<boolean>(false);
    const { userContext } = useContext(UserContext);

    useEffect(() => {
        if (props.dealerId && dealers) {
            let dealer = dealers.find((d) => {
                return d.id === props.dealerId;
            });
            setSelectedDealer(dealer ?? null);
        } else {
            if (dealerDefaultSelect === true && dealerDefaultSelected === false && dealers && !selectedDealer) {
                let dealer = dealers[0];
                setSelectedDealer(dealer);
                dealerSelected(dealer?.id ?? null, dealer);
                setDealerDefaultSelected(true);
            } else {
                setSelectedDealer(null);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.dealerId, dealers]);

    useEffect(() => {
        if (props.agencyId) {
            ApiService.getAgencyDealers(props.agencyId)
                .then((response) => {
                    const activeDealers = response.data.filter((dealer: Dealer) => dealer.activeStatus);
                    setDealers(activeDealers);
                    if (!props.isHeader && activeDealers.length === 1) {
                        setSelectedDealer(activeDealers[0]);
                        dealerSelected(activeDealers[0].id ? activeDealers[0].id : null, activeDealers[0]);
                    }
                })
                .catch(() => {});
        } else {
            ApiService.getAssignedDealers()
                .then((response) => {
                    const activeDealers = response.data.filter((dealer: Dealer) => dealer.activeStatus);
                    setDealers(activeDealers);
                })
                .catch(() => {});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.agencyId, userContext, props.isHeader]);

    function setDealerId(id: string, dealer: Dealer | null) {
        if (id === 'undefined') {
            props.dealerSelected(null, null);
            setSelectedDealer(null);
        } else {
            props.dealerSelected(Number(id), dealer);
        }
    }

    function dealerChange(_event: any, newValue: Dealer | null) {
        setDealerId(String(newValue?.id), newValue);
    }

    if (!userContext.isAdmin() && dealers && dealers?.length < 2) {
        return null;
    }

    const sxProps = getStyles(props.isHeader);

    return (
        <>
            <Autocomplete
                sx={sxProps}
                options={dealers ? dealers : []}
                getOptionLabel={(option) => option.dealerName}
                renderOption={(props, option) => (
                    <li {...props} key={option.id}>
                        {option.dealerName}
                    </li>
                )}
                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                size="small"
                fullWidth={false}
                value={selectedDealer}
                disabled={props.disabled || !dealers || (!props.isHeader && dealers.length === 1)}
                onChange={dealerChange}
                renderInput={(params) => (
                    <CDTextField
                        color={props.isHeader ? 'secondary' : 'primary'}
                        error={props.error}
                        shrinkLabel={false}
                        required={props.required !== undefined ? props.required : !props.disabled}
                        variant={props.variant ? props.variant : 'outlined'}
                        {...params}
                        label={
                            selectedDealer
                                ? ''
                                : props.disabled
                                ? 'Advertiser'
                                : props.label !== undefined
                                ? props.label
                                : 'Select an Existing Advertiser'
                        }
                        placeholder={props.placeholder}
                        {...TextFieldProps}
                    />
                )}
                PaperComponent={(props) => (
                    <Paper
                        {...props}
                        sx={{
                            boxShadow: 8,
                            borderRadius: '8px',
                        }}
                    />
                )}
            />
        </>
    );
}
