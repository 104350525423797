import { ReleaseNotesDialog } from './ReleaseNotesDialog';
import { useEffect, useState } from 'react';
import ApiService from '../../ApiService';
import { ReleaseNote } from '../../types/ReleaseNote';

export function ReleaseNotesDisplay() {
    const [releaseNote, setReleaseNote] = useState<ReleaseNote | null>(null);

    useEffect(() => {
        if (localStorage.getItem('showReleaseNotes') === 'true') {
            ApiService.getLastPublishedReleaseNote().then((response) => {
                const releaseNote = response.data;
                if (releaseNote) {
                    if (releaseNote?.content) {
                        setReleaseNote(releaseNote);
                        localStorage.setItem(
                            'showReleaseNotes',
                            releaseNote.appVersion
                        );
                    }
                } else {
                    localStorage.setItem('showReleaseNotes', 'false');
                }
            });
        }
    }, []);

    if (releaseNote) {
        return (
            <ReleaseNotesDialog
                onClose={() => setReleaseNote(null)}
                releaseNote={releaseNote}
            />
        );
    } else {
        return null;
    }
}
