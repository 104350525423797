import { DataSetValue } from '../../types/DataSetValue';
import CDGrid from '../../components/CDGrid';
import Utils from '../../components/Utils';

export default function SalesDataMAIDDataGrid(props: { rows: DataSetValue[] }) {
    return (
        <CDGrid
            variant="contained"
            autoHeight={true}
            headerHeight={75}
            rowHeight={75}
            columns={[
                {
                    width: 320,
                    field: 'maid',
                    headerName: 'MAID',
                },
                {
                    width: 120,
                    field: 'saleDate',
                    headerName: 'Date of Visit',
                    valueGetter: (params) => {
                        return Utils.formatDate(params.value);
                    },
                },
                {
                    width: 120,
                    field: 'deviceType',
                    headerName: 'Device Type',
                },
                {
                    width: 180,
                    field: 'segmentId',
                    headerName: 'Segment ID',
                },
            ]}
            rows={props.rows}
        />
    );
}
