export const ATTRIBUTION_EXPORT_METHOD_DOWNLOAD = 'download';
export const ATTRIBUTION_EXPORT_METHOD_EMAIL = 'email';

export type AttributionExportMethod =
    | typeof ATTRIBUTION_EXPORT_METHOD_DOWNLOAD
    | typeof ATTRIBUTION_EXPORT_METHOD_EMAIL;

export const ATTRIBUTION_EXPORT_TYPE_CSV = 'CSV';
export const ATTRIBUTION_EXPORT_TYPE_PDF = 'PDF';

export type AttributionExportType = typeof ATTRIBUTION_EXPORT_TYPE_CSV | typeof ATTRIBUTION_EXPORT_TYPE_PDF;

export class AttributionExport {
    exportMethod: AttributionExportMethod = ATTRIBUTION_EXPORT_METHOD_DOWNLOAD;
    exportType: AttributionExportType = ATTRIBUTION_EXPORT_TYPE_PDF;

    attributionType: 'Polk' | 'Advertiser' = 'Polk';
    attributionDate?: Date | undefined;
    dealerId: number | undefined = 0;

    file?: Blob;
}
