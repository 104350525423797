import { usePrintContext } from '../hooks/usePrint';

export type PrintableProps = {
    children: React.ReactElement | string | null | undefined;
    show: boolean;
};

export default function Printable(props: PrintableProps): JSX.Element {
    const { children, show } = props;

    const { print } = usePrintContext();

    // Elements are hidden in print mode only
    if (print === false && show === false) {
        return <>{children}</>;
    }

    // Elements are visible in print mode only
    if (print === true && show === true) {
        return <>{children}</>;
    }

    return <></>;
}
