import { useEffect, useState } from 'react';
import { Box, Button, ButtonGroup, Tooltip } from '@mui/material';
import {
    InsightsOutlined as InsightsOutlinedIcon,
    TableChartOutlined as TableChartOutlinedIcon,
} from '@mui/icons-material';

import { SectionHeading } from '../../components/SectionHeading';
import Column from '../../components/Column';
import Printable from '../../components/Printable';
import Row from '../../components/Row';
import Utils from '../../components/Utils';

import AdvertiserMatchbackCampaignChartView from './AdvertiserMatchbackCampaignChartView';
import AdvertiserMatchbackCampaignTableView from './AdvertiserMatchbackCampaignTableView';

type AdvertiserMatchbackViewsProps = {
    heading: React.ReactElement | string | null | undefined;
};

export default function AdvertiserMatchbackViews(props: AdvertiserMatchbackViewsProps) {
    const { heading } = props;

    const [view, setView] = useState<'chart' | 'table'>(() => {
        return Utils.localStorage('attribution.advertiserView', 'chart');
    });

    useEffect(() => {
        localStorage.setItem('attribution.advertiserView', view);
    }, [view]);

    const getButtonClassName = (currentView: string): string => {
        return currentView === view ? 'MuiButton-selected' : '';
    };

    return (
        <Column gap={3} sx={{ mt: 1.5 }}>
            <Box>
                <Row justifyContent="space-between" alignItems="center" spacing={2}>
                    <Column gap={0.5}>
                        {heading && (
                            <Row gap={0.5} alignItems="center">
                                <SectionHeading divider={false}>{heading}</SectionHeading>
                            </Row>
                        )}
                    </Column>

                    <Printable show={false}>
                        <Row width="auto">
                            <ButtonGroup
                                color="inherit"
                                variant="outlined"
                                size="large"
                                sx={{
                                    '& .MuiButton-root': {
                                        borderColor: (theme) => theme.palette.divider,
                                        height: 40,
                                        width: 40,
                                    },
                                    '& .MuiButton-root:hover': {
                                        borderColor: (theme) => theme.palette.action.selected,
                                    },
                                    '& .MuiButton-root .MuiSvgIcon-root': {
                                        color: (theme) => theme.palette.action.disabled,
                                    },
                                    '& .MuiButton-selected .MuiSvgIcon-root': {
                                        color: (theme) => theme.palette.text.primary,
                                    },
                                    '& .MuiButtonGroup-grouped:not(:last-of-type):hover': {
                                        borderRightColor: (theme) => theme.palette.divider,
                                    },
                                }}
                            >
                                <Tooltip title="Chart View">
                                    <Button onClick={() => setView('chart')} className={getButtonClassName('chart')}>
                                        <InsightsOutlinedIcon />
                                    </Button>
                                </Tooltip>

                                <Tooltip title="Table View">
                                    <Button onClick={() => setView('table')} className={getButtonClassName('table')}>
                                        <TableChartOutlinedIcon />
                                    </Button>
                                </Tooltip>
                            </ButtonGroup>
                        </Row>
                    </Printable>
                </Row>
            </Box>

            <Box>
                {view === 'chart' && <AdvertiserMatchbackCampaignChartView />}
                {view === 'table' && <AdvertiserMatchbackCampaignTableView />}
            </Box>
        </Column>
    );
}
