import { Checkbox, Divider, Grid, InputAdornment, Tooltip } from '@mui/material';
import { AttachMoneyOutlined, CancelOutlined, EditOutlined, PercentOutlined, SaveOutlined } from '@mui/icons-material';
import { ChangeEvent, FormEvent, useState } from 'react';
import { CampaignFee } from '../../../types/Campaign';
import CDCard from '../../../components/CDCard';
import { CDTextField } from '../../../components/CDTextField';
import SummaryText from '../SummaryText';
import AccessGuard from '../../../components/AccessGuard';
import { USER_GROUP_ADMIN } from '../../../types/User';
import CDButton from '../../../components/CDButton';
import Row from '../../../components/Row';
import FixedVariableFeeComponent from '../../agency/FixedVariableFeeComponent';

export default function CampaignFeesComponent(props: {
    campaignFee: CampaignFee;
    editMode: boolean;
    allowEditToggle: boolean;
    agencyView?: boolean;
    onChange(campaignFee: CampaignFee): void;
}) {
    const { onChange, allowEditToggle, agencyView } = props;
    const [campaignFee, setCampaignFee] = useState(props.campaignFee);
    const [editMode, setEditMode] = useState(props.editMode);

    function handleChange(event: ChangeEvent<HTMLInputElement>) {
        const field = event.target.name;
        let value: number | string | boolean | null = event.target.value;

        if (field.startsWith('include')) {
            value = event.target.checked;
        }

        let updatedFees = { ...campaignFee, [field]: value };
        setCampaignFee(updatedFees);
        if (!allowEditToggle && editMode) {
            onChange(updatedFees);
        }
    }

    function feeChange(updatedFees: CampaignFee) {
        setCampaignFee(updatedFees);
        if (!allowEditToggle && editMode) {
            onChange(updatedFees);
        }
    }

    function handleSubmit(event: FormEvent<HTMLFormElement>) {
        event.preventDefault();
        if (campaignFee.agencyFixedCPM && !campaignFee.cognitionCPM) {
            return;
        }
        onChange(campaignFee);
        setEditMode(!editMode);
    }

    return (
        <>
            <CDCard>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={2} sx={{ pt: 2 }} alignItems="center">
                        {agencyView && (
                            <>
                                <Grid item xs={12}>
                                    <SummaryText label="Agency Fees" value="" />
                                    <Divider />
                                </Grid>

                                <FixedVariableFeeComponent
                                    label="Video"
                                    allowVariable={true}
                                    fixedFee={campaignFee.videoFixedCPM}
                                    variableFee={campaignFee.videoAgencyFee}
                                    incrementalCPM={campaignFee.videoIncrementalCPM}
                                    editMode={editMode}
                                    onChange={(fixed, variable, incremental) => {
                                        feeChange({
                                            ...campaignFee,
                                            videoFixedCPM: fixed,
                                            videoAgencyFee: variable,
                                            videoIncrementalCPM: incremental,
                                        });
                                    }}
                                />

                                <Grid item xs={3} />

                                <FixedVariableFeeComponent
                                    label="Display"
                                    allowVariable={true}
                                    fixedFee={campaignFee.displayFixedCPM}
                                    variableFee={campaignFee.displayAgencyFee}
                                    incrementalCPM={campaignFee.displayIncrementalCPM}
                                    editMode={editMode}
                                    onChange={(fixed, variable, incremental) => {
                                        feeChange({
                                            ...campaignFee,
                                            displayFixedCPM: fixed,
                                            displayAgencyFee: variable,
                                            displayIncrementalCPM: incremental,
                                        });
                                    }}
                                />

                                <Grid item xs={3} />

                                <FixedVariableFeeComponent
                                    label="Audio"
                                    allowVariable={true}
                                    fixedFee={campaignFee.audioFixedCPM}
                                    variableFee={campaignFee.audioAgencyFee}
                                    incrementalCPM={campaignFee.audioIncrementalCPM}
                                    editMode={editMode}
                                    onChange={(fixed, variable, incremental) => {
                                        feeChange({
                                            ...campaignFee,
                                            audioFixedCPM: fixed,
                                            audioAgencyFee: variable,
                                            audioIncrementalCPM: incremental,
                                        });
                                    }}
                                />

                                <Grid item xs={3} />
                            </>
                        )}

                        {!agencyView && (
                            <>
                                <Grid item xs={12}>
                                    <SummaryText label="Agency Fees" value="" />
                                    <Divider />
                                </Grid>

                                <Grid item xs={3}>
                                    {campaignFee.agencyFixedCPM ? 'Fixed CPM' : 'Agency Fee'}
                                </Grid>
                                <Grid item xs={2}>
                                    {(!!campaignFee.agencyFixedCPM && (
                                        <>
                                            <CDTextField
                                                name="agencyFixedCPM"
                                                value={campaignFee.agencyFixedCPM}
                                                fullWidth
                                                onChange={handleChange}
                                                disabled={!editMode}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <AttachMoneyOutlined
                                                                fontSize="small"
                                                                color={editMode ? 'primary' : 'action'}
                                                            />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                inputProps={{ inputMode: 'text', pattern: '[0-9.]{1,5}' }}
                                            />
                                        </>
                                    )) || (
                                        <CDTextField
                                            name="agencyFee"
                                            value={campaignFee.agencyFee}
                                            fullWidth
                                            onChange={handleChange}
                                            disabled={!editMode}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <PercentOutlined
                                                            fontSize="small"
                                                            color={editMode ? 'primary' : 'action'}
                                                        />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            inputProps={{ inputMode: 'text', pattern: '[0-9.]{1,5}' }}
                                        />
                                    )}
                                </Grid>

                                <Grid item xs={7}>
                                    <Row>
                                        <div style={{ flex: 1 }} />
                                        {allowEditToggle && !editMode && (
                                            <CDButton
                                                startIcon={<EditOutlined />}
                                                onClick={() => setEditMode(!editMode)}
                                            >
                                                Edit
                                            </CDButton>
                                        )}
                                        {allowEditToggle && editMode && (
                                            <>
                                                <CDButton
                                                    startIcon={<CancelOutlined color="error" />}
                                                    onClick={() => {
                                                        setCampaignFee(props.campaignFee);
                                                        setEditMode(!editMode);
                                                    }}
                                                >
                                                    Cancel
                                                </CDButton>
                                                <CDButton type="submit" startIcon={<SaveOutlined />} onClick={() => {}}>
                                                    Save
                                                </CDButton>
                                            </>
                                        )}
                                    </Row>
                                </Grid>
                                {!!campaignFee.agencyFixedCPM && (
                                    <>
                                        <Grid item xs={3}>
                                            Agency Incremental CPM
                                        </Grid>
                                        <Grid item xs={2}>
                                            <CDTextField
                                                name="agencyIncrementalCPM"
                                                value={campaignFee.agencyIncrementalCPM}
                                                fullWidth
                                                onChange={handleChange}
                                                disabled={!editMode}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <AttachMoneyOutlined
                                                                fontSize="small"
                                                                color={editMode ? 'primary' : 'action'}
                                                            />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                inputProps={{ inputMode: 'text', pattern: '[0-9.]{1,5}' }}
                                            />
                                        </Grid>
                                        <Grid item xs={5} />

                                        <Grid item xs={3}>
                                            DSP CPM Cost
                                        </Grid>
                                        <Grid item xs={2}>
                                            <CDTextField
                                                name="cognitionCPM"
                                                value={campaignFee.cognitionCPM}
                                                fullWidth
                                                required={true}
                                                onChange={handleChange}
                                                disabled={!editMode}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <AttachMoneyOutlined
                                                                fontSize="small"
                                                                color={editMode ? 'primary' : 'action'}
                                                            />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                inputProps={{ inputMode: 'text', pattern: '[0-9.]{1,5}' }}
                                            />
                                        </Grid>
                                    </>
                                )}
                            </>
                        )}

                        <Grid item xs={12} />

                        <Grid item xs={12}>
                            <SummaryText label="Cognition Digital Fee Structure" value="" />
                            <Divider />
                        </Grid>

                        <Grid item xs={3}>
                            CD Platform Fee
                        </Grid>
                        <Grid item xs={2}>
                            <CDTextField
                                name="cdPlatformFee"
                                value={campaignFee.cdPlatformFee}
                                fullWidth
                                onChange={handleChange}
                                disabled={!editMode}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <AttachMoneyOutlined
                                                fontSize="small"
                                                color={editMode ? 'primary' : 'action'}
                                            />
                                        </InputAdornment>
                                    ),
                                }}
                                inputProps={{ inputMode: 'text', pattern: '[0-9]*' }}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            {editMode && (
                                <Tooltip title="Include in Spend / Budget">
                                    <span>
                                        <Checkbox
                                            name="includeCdPlatformFee"
                                            checked={campaignFee.includeCdPlatformFee}
                                            onChange={handleChange}
                                            disabled={!editMode}
                                        />
                                    </span>
                                </Tooltip>
                            )}
                            {!editMode && (
                                <span>
                                    {(campaignFee.includeCdPlatformFee ? 'Included in' : ' Excluded from ') +
                                        ' Spend / Budget'}
                                </span>
                            )}
                        </Grid>
                        <Grid item xs={4} />

                        <AccessGuard accessGroup={USER_GROUP_ADMIN}>
                            <>
                                <Grid item xs={3}>
                                    Amazon Passthrough Upcharge
                                </Grid>
                                <Grid item xs={2}>
                                    <CDTextField
                                        name="amazonPassthroughUpcharge"
                                        value={campaignFee.amazonPassthroughUpcharge}
                                        fullWidth
                                        onChange={handleChange}
                                        disabled={!editMode}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <PercentOutlined
                                                        fontSize="small"
                                                        color={editMode ? 'primary' : 'action'}
                                                    />
                                                </InputAdornment>
                                            ),
                                        }}
                                        inputProps={{ inputMode: 'text', pattern: '[0-9.]{1,5}' }}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    {editMode && (
                                        <Tooltip title="Include in Spend / Budget">
                                            <span>
                                                <Checkbox
                                                    name="includeAmazonPassthroughUpcharge"
                                                    checked={campaignFee.includeAmazonPassthroughUpcharge}
                                                    onChange={handleChange}
                                                    disabled={!editMode}
                                                />
                                            </span>
                                        </Tooltip>
                                    )}
                                    {!editMode && campaignFee.amazonPassthroughUpcharge && (
                                        <span>
                                            {(campaignFee.includeAmazonPassthroughUpcharge
                                                ? 'Included in'
                                                : ' Excluded from ') + ' Spend / Budget'}
                                        </span>
                                    )}
                                </Grid>
                                <Grid item xs={3} />

                                <Grid item xs={3}>
                                    CD Managed Service
                                </Grid>
                                <Grid item xs={2}>
                                    <CDTextField
                                        name="cdManagedService"
                                        value={campaignFee.cdManagedService}
                                        fullWidth
                                        onChange={handleChange}
                                        disabled={!editMode}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <PercentOutlined
                                                        fontSize="small"
                                                        color={editMode ? 'primary' : 'action'}
                                                    />
                                                </InputAdornment>
                                            ),
                                        }}
                                        inputProps={{ inputMode: 'text', pattern: '[0-9.]{1,5}' }}
                                    />
                                </Grid>
                                <Grid item xs={7}></Grid>

                                <Grid item xs={3}>
                                    {editMode
                                        ? 'Other Fee'
                                        : campaignFee.otherFeeLabel
                                        ? campaignFee.otherFeeLabel
                                        : 'Other Fee'}
                                </Grid>

                                <Grid item xs={2}>
                                    <CDTextField
                                        name="otherFee"
                                        value={campaignFee.otherFee}
                                        fullWidth
                                        onChange={handleChange}
                                        disabled={!editMode}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <AttachMoneyOutlined
                                                        fontSize="small"
                                                        color={editMode ? 'primary' : 'action'}
                                                    />
                                                </InputAdornment>
                                            ),
                                        }}
                                        inputProps={{ inputMode: 'text', pattern: '[0-9]*' }}
                                    />
                                </Grid>
                                {editMode && (
                                    <>
                                        <Grid item xs={1}>
                                            {editMode && (
                                                <Tooltip title="Include in Spend / Budget">
                                                    <span>
                                                        <Checkbox
                                                            name="includeOtherFee"
                                                            checked={campaignFee.includeOtherFee}
                                                            onChange={handleChange}
                                                            disabled={!editMode}
                                                        />
                                                    </span>
                                                </Tooltip>
                                            )}
                                        </Grid>
                                        <Grid item xs={2}>
                                            {editMode && 'Other Fee Label'}
                                        </Grid>
                                        <Grid item xs={3}>
                                            {editMode && (
                                                <CDTextField
                                                    name="otherFeeLabel"
                                                    value={campaignFee.otherFeeLabel}
                                                    fullWidth
                                                    onChange={handleChange}
                                                    disabled={!editMode}
                                                    inputProps={{
                                                        maxLength: 50,
                                                    }}
                                                />
                                            )}
                                        </Grid>
                                        <Grid item xs={1} />
                                    </>
                                )}
                                {!editMode && campaignFee.otherFee && (
                                    <>
                                        <Grid item xs={5}>
                                            <span>
                                                {(campaignFee.includeOtherFee ? 'Included in' : ' Excluded from ') +
                                                    ' Spend / Budget'}
                                            </span>
                                        </Grid>
                                    </>
                                )}
                            </>
                        </AccessGuard>
                    </Grid>
                </form>
            </CDCard>
        </>
    );
}
