import { Box, IconButton, InputAdornment, TextField } from '@mui/material';
import CDGrid from '../../components/CDGrid';
import { ChangeEvent, useEffect, useState } from 'react';
import { GridColumns, GridColumnGroupingModel, GridValueFormatterParams } from '@mui/x-data-grid';
import { DealerPolkAudience } from '../../types/DealerPolkAudience';
import Row from '../../components/Row';
import { ClearOutlined, SearchOutlined } from '@mui/icons-material';
import Utils from '../../components/Utils';

export default function PolkAudienceDealerGrid(props: { dealers: DealerPolkAudience[] | null }) {
    const { dealers } = props;

    const [rows, setRows] = useState<DealerPolkAudience[] | null>(null);
    const [allRows, setAllRows] = useState<DealerPolkAudience[]>([]);
    const [page, setPage] = useState(
        // Use a callback function so the local storage call only runs once.
        () => Number(localStorage.getItem('polkAudienceGridPage'))
    );
    const [searchText, setSearchText] = useState<string>(
        // Use a callback function so the local storage call only runs once.
        () => Utils.localStorage('polkAudienceSearch', '')
    );

    useEffect(() => {
        const initialSearchText = Utils.localStorage('polkAudienceSearch', '');

        setRows(dealers);
        setAllRows(dealers === null ? [] : dealers);
        filterRows(initialSearchText, dealers === null ? [] : dealers);
    }, [dealers]);

    let numberOfNewCarsExpected: number | undefined = 0;
    let numberOfUsedCarsExpected: number | undefined = 0;
    let numberOfEVCarsExpected: number | undefined = 0;
    // let numberOfFinanceCarsExpected: number | undefined = 0;
    if (rows && rows.length > 0) {
        let firstDealer = rows[0];
        numberOfNewCarsExpected = firstDealer.totalNewCarsExpected;
        numberOfUsedCarsExpected = firstDealer.totalUsedCarsExpected;
        numberOfEVCarsExpected = firstDealer.totalEVCarsExpected;
        // numberOfFinanceCarsExpected = firstDealer.totalFinanceCarsExpected;
    }

    function onPageChange(page: number) {
        setPage(page);
        localStorage.setItem('dealerGridPage', String(page));
    }

    function clearSearch() {
        setSearchText('');
        filterRows('', allRows);
        localStorage.setItem('polkAudienceSearch', '');
    }

    function search(event: ChangeEvent<HTMLInputElement>) {
        event.preventDefault();
        setSearchText(event.target.value);
        filterRows(event.target.value, allRows);
        localStorage.setItem('polkAudienceSearch', event.target.value);
    }

    function filterRows(searchText: string, allRows: DealerPolkAudience[]) {
        const filteredRows = allRows.filter((row: DealerPolkAudience) => {
            return (
                row.dealerName.toLowerCase().indexOf(searchText.toLowerCase()) > -1 ||
                row.agencyName.toLowerCase().indexOf(searchText.toLowerCase()) > -1
            );
        });
        setRows(filteredRows);
    }

    function GridToolbar() {
        return (
            <Row justifyContent="space-between" sx={{ mt: 2, mb: 3 }}>
                <TextField
                    inputRef={(input) => input && input.focus()}
                    id="searchText"
                    value={searchText}
                    size="small"
                    label="Search"
                    onChange={search}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchOutlined fontSize="small" />
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={() => clearSearch()} edge="end">
                                    <ClearOutlined fontSize="small" />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </Row>
        );
    }

    const columnGroupingModel: GridColumnGroupingModel = [
        {
            groupId: 'New Car Audiences',
            headerName: 'New Car Audiences',
            headerClassName: 'cd--new-car-audiences',
            headerAlign: 'center',
            children: [{ field: 'newCarsDateUploaded' }, { field: 'totalNewCarsUploaded' }],
        },
        {
            groupId: 'Used Car Audiences',
            headerName: 'Used Car Audiences',
            headerClassName: 'cd--used-car-audiences',
            headerAlign: 'center',
            children: [{ field: 'usedCarsDateUploaded' }, { field: 'totalUsedCarsUploaded' }],
        },
        {
            groupId: 'EV Audiences',
            headerName: 'EV Audiences',
            headerClassName: 'cd--ev-audiences',
            headerAlign: 'center',
            children: [{ field: 'evCarsDateUploaded' }, { field: 'totalEVCarsUploaded' }],
        },
        {
            groupId: 'Finance Audiences',
            headerName: 'Finance Audiences',
            headerClassName: 'cd--finance-audiences',
            headerAlign: 'center',
            children: [{ field: 'financeCarsDateUploaded' }, { field: 'totalFinanceCarsUploaded' }],
        },
    ];

    const columns: GridColumns = [
        {
            width: 200,
            field: 'agencyName',
            headerName: 'Agency Name',
            flex: 2,
        },
        {
            width: 200,
            field: 'dealerName',
            headerName: 'Advertiser Name',
            flex: 2,
        },
        // {
        //     field: 'status',
        //     headerName: 'Status',
        //     headerAlign: 'center',
        //     align: 'center',
        //     filterable: false,
        //     flex: 1,
        //     renderCell: (params: GridRenderCellParams) => {
        //         const row = params.row;
        //         let status: 'Queued' | 'Processing' | 'Completed';
        //         let statusColor: 'primary' | 'success' | 'warning';

        //         status = getDealerPolkAudienceStatus(row);

        //         switch (status) {
        //             case 'Completed':
        //                 statusColor = 'success';
        //                 break;

        //             case 'Processing':
        //                 statusColor = 'warning';
        //                 break;

        //             case 'Queued':
        //                 statusColor = 'primary';
        //                 break;

        //             default:
        //                 statusColor = 'primary';
        //                 break;
        //         }

        //         return <Chip variant="filled" color={statusColor} label={status}></Chip>;
        //     },
        // },
        {
            field: 'newCarsDateUploaded',
            headerName: 'Completion Date',
            description: 'New Car Completion Date',
            headerAlign: 'center',
            align: 'center',
            filterable: false,
            flex: 1,
            valueFormatter: (params: GridValueFormatterParams) => {
                return params.value == null ? ' ' : new Date((params.value as number) * 1000).toLocaleDateString();
            },
        },
        {
            field: 'totalNewCarsUploaded',
            headerName: 'Count',
            description: 'New Cars Count',
            headerAlign: 'center',
            align: 'center',
            flex: 1,
            valueFormatter: (params: GridValueFormatterParams) => {
                return params.value === 0 ? ' ' : params.value + '/' + numberOfNewCarsExpected;
            },
        },
        {
            field: 'usedCarsDateUploaded',
            headerName: 'Completion Date',
            description: 'Used Car Completion Date',
            headerAlign: 'center',
            align: 'center',
            flex: 1,
            valueFormatter: (params: GridValueFormatterParams) => {
                return params.value == null ? ' ' : new Date((params.value as number) * 1000).toLocaleDateString();
            },
        },
        {
            field: 'totalUsedCarsUploaded',
            headerName: 'Count',
            description: 'Used Cars Count',
            headerAlign: 'center',
            align: 'center',
            flex: 1,
            valueFormatter: (params: GridValueFormatterParams) => {
                return params.value === 0 ? ' ' : params.value + '/' + numberOfUsedCarsExpected;
            },
        },
        {
            field: 'evCarsDateUploaded',
            headerName: 'Completion Date',
            description: 'EV Car Completion Date',
            headerAlign: 'center',
            align: 'center',
            flex: 1,
            valueFormatter: (params: GridValueFormatterParams) => {
                return params.value == null ? ' ' : new Date((params.value as number) * 1000).toLocaleDateString();
            },
        },
        {
            field: 'totalEVCarsUploaded',
            headerName: 'Count',
            description: 'EV Cars Count',
            headerAlign: 'center',
            align: 'center',
            flex: 1,
            valueFormatter: (params: GridValueFormatterParams) => {
                return params.value === 0 ? ' ' : params.value + '/' + numberOfEVCarsExpected;
            },
        },
        // {
        //     field: 'financeCarsDateUploaded',
        //     headerName: 'Completion Date',
        //     description: 'Finance Car Completion Date',
        //     headerAlign: 'center',
        //     align: 'center',
        //     flex: 1,
        //     valueFormatter: (params: GridValueFormatterParams) => {
        //         return params.value == null
        //             ? ' '
        //             : new Date((params.value as number) * 1000).toLocaleDateString();
        //     },
        // },
        // {
        //     field: 'totalFinanceCarsUploaded',
        //     headerName: 'Count',
        //     description: 'Finance Cars Count',
        //     headerAlign: 'center',
        //     align: 'center',
        //     flex: 1,
        //     valueFormatter: (params: GridValueFormatterParams) => {
        //         return params.value === 0 ? ' ' : params.value + '/' + numberOfFinanceCarsExpected;
        //     },
        // },
    ];

    return (
        <Box
            sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <GridToolbar />
            <CDGrid
                page={page}
                rowHeight={70}
                experimentalFeatures={{ columnGrouping: true }}
                columnGroupingModel={columnGroupingModel}
                columns={columns}
                rows={rows === null ? [] : rows}
                loading={rows === null}
                autoHeight={false}
                autoPageSize={true}
                onPageChange={onPageChange}
            />
        </Box>
    );
}
