import { AmcAudience } from '../../../types/AmcAudiences';
import { PeopleOutlined } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';

export default function AmcAudienceIcon(props: {
    amcAudience: AmcAudience | null;
}) {
    const { amcAudience } = props;

    interface StatusColorType {
        [key: string]: 'success' | 'warning' | 'error';
    }

    const audienceStatusColors: StatusColorType = {
        Success: 'success',
        Pending: 'warning',
        Error: 'error',
    };

    type MapType = {
        [id: string]: string;
    };
    const statusTooltip: MapType = {
        Success: 'Data Set is available for use in a Campaign',
        Pending:
            'Data Set is being created - not available for use in a Campaign yet',
        Error: amcAudience?.statusReason
            ? amcAudience.statusReason
            : 'Unknown error - Data Set could not be created.',
    };
    if (amcAudience?.statusMeaning) {
        let color = audienceStatusColors[amcAudience.statusMeaning];

        return (
            <Tooltip title={statusTooltip[amcAudience.statusMeaning]}>
                <IconButton onClick={() => {}}>
                    <PeopleOutlined color={color} />
                </IconButton>
            </Tooltip>
        );
    } else {
        return null;
    }
}
