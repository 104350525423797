import {
    AlertColor,
    Button,
    FormControlLabel,
    Grid,
    Switch,
    InputAdornment,
    InputLabel,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import { CloudUploadOutlined, Filter as FilterIcon } from '@mui/icons-material';
import { Dealer } from '../../types/Dealer';
import { ChangeEvent, useCallback, useState, useEffect } from 'react';
import ApiService from '../../ApiService';
import CDColorPicker from '../../components/CDColorPicker';
import ImagePreviewDialog from '../../components/ImagePreviewDialog';
import Row from '../../components/Row';

export type DealerUpdateCallback = (name: string, changes: Partial<Dealer>) => void;

export type DealerCreativeAssetsProps = {
    dealer: Dealer;
    // Indicates a partial update of a dealer. The changes object
    // contains only the attributes that changed.
    onDealerUpdate: DealerUpdateCallback;
    className?: string;
    variant?: 'filled' | 'outlined';
    showImagePreviews?: boolean;
    shrinkTextFieldLabels?: boolean;
    onMessage?: (alertLevel: AlertColor, message: string) => void;
};

function ImagePreview({ imageUrl }: { imageUrl: string | null | undefined }) {
    const [open, setOpen] = useState<boolean>(false);

    if (!imageUrl) {
        return <></>;
    }

    return (
        <Row alignItems="center">
            {/*<img
                style={{
                    cursor: 'pointer',
                    height: '40px',
                }}
                src={imageUrl}
                alt=""
                onClick={() => {
                    setOpen(true);
                }}
            />*/}
            <Button
                variant="outlined"
                size="medium"
                startIcon={<FilterIcon />}
                onClick={() => {
                    setOpen(true);
                }}
                sx={{ height: '40px' }}
            >
                Preview
            </Button>
            <ImagePreviewDialog
                open={open}
                imageUrl={imageUrl}
                onClose={() => {
                    setOpen(false);
                }}
            />
        </Row>
    );
}

function DefaultAdImageUrlField({
    name,
    label,
    helperText,
    onChange,
    onFileChange,
    variant,
    value,
    shrinkTextFieldLabels,
    tooltip,
}: {
    name: string;
    label: string;
    helperText: string;
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
    onFileChange: (event: ChangeEvent<HTMLInputElement>) => void;
    variant?: 'filled' | 'outlined';
    value: string;
    shrinkTextFieldLabels?: boolean | undefined;
    tooltip: string;
}) {
    return (
        <TextField
            name={name}
            fullWidth={true}
            size="small"
            label={label}
            helperText={helperText}
            onChange={onChange}
            variant={variant}
            value={value}
            InputLabelProps={{ shrink: shrinkTextFieldLabels }}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <InputLabel htmlFor={`btn-upload-${name}`} sx={{ display: 'flex' }}>
                            <input
                                id={`btn-upload-${name}`}
                                name={`btn-upload-${name}`}
                                style={{
                                    display: 'none',
                                }}
                                type="file"
                                accept=".png,.jpg"
                                onChange={onFileChange}
                            />
                            <Tooltip title={tooltip}>
                                <CloudUploadOutlined
                                    color="primary"
                                    sx={{
                                        cursor: 'pointer',
                                    }}
                                />
                            </Tooltip>
                        </InputLabel>
                    </InputAdornment>
                ),
            }}
        />
    );
}

export default function DealerCreativeAssets({
    className,
    dealer,
    onDealerUpdate,
    onMessage,
    variant = 'outlined',
    showImagePreviews = true,
    shrinkTextFieldLabels,
}: DealerCreativeAssetsProps) {
    const CTA_TEXT_DEFAULT_VALUE = 'Shop Inventory';
    const CTA_TEXT_SECONDARY_DEFAULT_VALUE = 'Learn More';
    const CTA_BUTTON_DEFAULT_COLOR = '#000000';
    const CTA_BUTTON_SECONDARY_DEFAULT_COLOR = '#FF0000';

    const setDefaultValues = useCallback(() => {
        if (dealer.ctaText === undefined || null) {
            onDealerUpdate('ctaText', { ctaText: CTA_TEXT_DEFAULT_VALUE });
        }
        if (!dealer.ctaTextSecondary === undefined || null) {
            onDealerUpdate('ctaTextSecondary', { ctaTextSecondary: CTA_TEXT_SECONDARY_DEFAULT_VALUE });
        }
        if (!dealer.ctaButtonColor === undefined || null) {
            onDealerUpdate('ctaButtonColor', { ctaButtonColor: CTA_BUTTON_DEFAULT_COLOR });
        }
        if (!dealer.ctaButtonColorSecondary === undefined || null) {
            onDealerUpdate('ctaButtonColorSecondary', { ctaButtonColorSecondary: CTA_BUTTON_SECONDARY_DEFAULT_COLOR });
        }
    }, [
        dealer.ctaText,
        dealer.ctaTextSecondary,
        dealer.ctaButtonColor,
        dealer.ctaButtonColorSecondary,
        onDealerUpdate,
    ]);

    const handleFieldChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            const {
                target: { name, value },
            } = event;

            if (name === 'showTrim') {
                onDealerUpdate(name, { [name]: event.target.checked });
            } else {
                onDealerUpdate(name, { [name]: value });
            }
        },
        [onDealerUpdate]
    );

    const handleCTAColorChange = (color: string | null) => {
        if (color !== '' && color !== dealer.ctaButtonColor) {
            onDealerUpdate('ctaButtonColor', { ctaButtonColor: color ?? undefined });
        }
    };

    const handleSecondaryCTAColorChange = (color: string | null) => {
        if (color !== '' && color !== dealer.ctaButtonColorSecondary) {
            onDealerUpdate('ctaButtonColorSecondary', { ctaButtonColorSecondary: color ?? undefined });
        }
    };

    const createFileInputChangeHandler = (fileType: string) => {
        return (event: ChangeEvent<HTMLInputElement>) => {
            const files = event.target.files;
            if (files?.length) {
                const file = files[0];
                const formData = new FormData();
                formData.append('fileType', fileType);
                formData.append('file', file, file.name);
                ApiService.uploadDealerImage(formData)
                    .then((response) => {
                        if (fileType === 'logo') {
                            fileType = 'logoUrl';
                        }
                        onDealerUpdate(fileType, { [fileType]: response.data });
                        if (onMessage) {
                            onMessage('success', 'Upload succeded.');
                        }
                    })
                    .catch(() => {});
            }
        };
    };

    const urlFieldGridSpan = showImagePreviews ? 9 : 12;

    useEffect(() => {
        setDefaultValues();
    }, [setDefaultValues]);

    return (
        <Grid container spacing={2} className={className}>
            <Grid item xs={urlFieldGridSpan}>
                <TextField
                    name="logoUrl"
                    fullWidth={true}
                    size="small"
                    label="Logo URL"
                    helperText="Must be 600 x 188 pixels"
                    onChange={handleFieldChange}
                    variant={variant}
                    value={dealer.logoUrl ?? ''}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <InputLabel htmlFor="btn-uploadLogo" sx={{ display: 'flex' }}>
                                    <input
                                        id="btn-uploadLogo"
                                        name="btn-uploadLogo"
                                        style={{
                                            display: 'none',
                                        }}
                                        type="file"
                                        accept=".png,.jpg"
                                        onChange={createFileInputChangeHandler('logo')}
                                    />
                                    <Tooltip title="Upload Advertiser Logo image">
                                        <CloudUploadOutlined
                                            color="primary"
                                            sx={{
                                                cursor: 'pointer',
                                            }}
                                        />
                                    </Tooltip>
                                </InputLabel>
                            </InputAdornment>
                        ),
                    }}
                    InputLabelProps={{ shrink: shrinkTextFieldLabels }}
                />
            </Grid>

            {showImagePreviews && (
                <Grid item xs={3}>
                    <ImagePreview imageUrl={dealer.logoUrl} />
                </Grid>
            )}

            <Grid item xs={urlFieldGridSpan}>
                <TextField
                    name="defaultBadge"
                    fullWidth={true}
                    size="small"
                    label="Default Badge URL"
                    onChange={handleFieldChange}
                    helperText="Must be 520 x 140 pixels"
                    variant={variant}
                    value={dealer.defaultBadge ?? ''}
                    InputLabelProps={{ shrink: shrinkTextFieldLabels }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <InputLabel htmlFor="btn-uploadBadge" sx={{ display: 'flex' }}>
                                    <input
                                        id="btn-uploadBadge"
                                        name="btn-uploadBadge"
                                        style={{
                                            display: 'none',
                                        }}
                                        type="file"
                                        accept=".png,.jpg"
                                        onChange={createFileInputChangeHandler('defaultBadge')}
                                    />
                                    <Tooltip title="Upload Default Badge image">
                                        <CloudUploadOutlined
                                            color="primary"
                                            sx={{
                                                cursor: 'pointer',
                                            }}
                                        />
                                    </Tooltip>
                                </InputLabel>
                            </InputAdornment>
                        ),
                    }}
                />
            </Grid>
            {showImagePreviews && (
                <Grid item xs={3}>
                    <ImagePreview imageUrl={dealer.defaultBadge} />
                </Grid>
            )}

            <Grid item xs={urlFieldGridSpan}>
                <TextField
                    name="fixedOpsBadge"
                    fullWidth={true}
                    size="small"
                    label="Fixed Ops Badge URL (Vertical)"
                    onChange={handleFieldChange}
                    helperText="Must be 800 x 320 pixels"
                    variant={variant}
                    value={dealer.fixedOpsBadge ?? ''}
                    InputLabelProps={{ shrink: shrinkTextFieldLabels }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <InputLabel htmlFor="btn-uploadFixedOpsBadge" sx={{ display: 'flex' }}>
                                    <input
                                        id="btn-uploadFixedOpsBadge"
                                        name="btn-uploadFixedOpsBadge"
                                        style={{
                                            display: 'none',
                                        }}
                                        type="file"
                                        accept=".png,.jpg"
                                        onChange={createFileInputChangeHandler('fixedOpsBadge')}
                                    />
                                    <Tooltip title="Upload FixedOps Badge image">
                                        <CloudUploadOutlined
                                            color="primary"
                                            sx={{
                                                cursor: 'pointer',
                                            }}
                                        />
                                    </Tooltip>
                                </InputLabel>
                            </InputAdornment>
                        ),
                    }}
                />
            </Grid>
            {showImagePreviews && (
                <Grid item xs={3}>
                    <ImagePreview imageUrl={dealer.fixedOpsBadge} />
                </Grid>
            )}

            <Grid item xs={12}>
                <Typography variant="h6">Default Ad Images</Typography>
            </Grid>

            <Grid item xs={urlFieldGridSpan}>
                <DefaultAdImageUrlField
                    name="defaultAdImageUrl"
                    label="Default Ad Image URL (300 x 250)"
                    helperText="Must be 300 x 250 pixels"
                    onChange={handleFieldChange}
                    onFileChange={createFileInputChangeHandler('defaultAdImageUrl')}
                    variant={variant}
                    value={dealer.defaultAdImageUrl ?? ''}
                    shrinkTextFieldLabels={shrinkTextFieldLabels}
                    tooltip={'Upload Default Ad Image'}
                />
            </Grid>
            {showImagePreviews && (
                <Grid item xs={3}>
                    <ImagePreview imageUrl={dealer.defaultAdImageUrl} />
                </Grid>
            )}

            <Grid item xs={urlFieldGridSpan}>
                <DefaultAdImageUrlField
                    name="defaultAdImageUrl300x600"
                    label="Default Ad Image URL (300 x 600)"
                    helperText="Must be 300 x 600 pixels"
                    onChange={handleFieldChange}
                    onFileChange={createFileInputChangeHandler('defaultAdImageUrl300x600')}
                    variant={variant}
                    value={dealer.defaultAdImageUrl300x600 ?? ''}
                    shrinkTextFieldLabels={shrinkTextFieldLabels}
                    tooltip={'Upload Default Ad Image'}
                />
            </Grid>
            {showImagePreviews && (
                <Grid item xs={3}>
                    <ImagePreview imageUrl={dealer.defaultAdImageUrl300x600} />
                </Grid>
            )}

            <Grid item xs={urlFieldGridSpan}>
                <DefaultAdImageUrlField
                    name="defaultAdImageUrl160x600"
                    label="Default Ad Image URL (160 x 600)"
                    helperText="Must be 160 x 600 pixels"
                    onChange={handleFieldChange}
                    onFileChange={createFileInputChangeHandler('defaultAdImageUrl160x600')}
                    variant={variant}
                    value={dealer.defaultAdImageUrl160x600 ?? ''}
                    shrinkTextFieldLabels={shrinkTextFieldLabels}
                    tooltip={'Upload Default Ad Image'}
                />
            </Grid>
            {showImagePreviews && (
                <Grid item xs={3}>
                    <ImagePreview imageUrl={dealer.defaultAdImageUrl160x600} />
                </Grid>
            )}

            <Grid item xs={urlFieldGridSpan}>
                <DefaultAdImageUrlField
                    name="defaultAdImageUrl728x90"
                    label="Default Ad Image URL (728 x 90)"
                    helperText="Must be 728 x 90 pixels"
                    onChange={handleFieldChange}
                    onFileChange={createFileInputChangeHandler('defaultAdImageUrl728x90')}
                    variant={variant}
                    value={dealer.defaultAdImageUrl728x90 ?? ''}
                    shrinkTextFieldLabels={shrinkTextFieldLabels}
                    tooltip={'Upload Default Ad Image'}
                />
            </Grid>
            {showImagePreviews && (
                <Grid item xs={3}>
                    <ImagePreview imageUrl={dealer.defaultAdImageUrl728x90} />
                </Grid>
            )}

            <Grid item xs={12}>
                <Typography variant="h6">CTA Button Settings</Typography>
            </Grid>

            <Grid item xs={5}>
                <TextField
                    name="ctaText"
                    label="CTA Text"
                    value={dealer.ctaText ?? CTA_TEXT_DEFAULT_VALUE}
                    fullWidth={true}
                    size="small"
                    variant={variant}
                    onChange={handleFieldChange}
                    InputLabelProps={{ shrink: shrinkTextFieldLabels }}
                />
            </Grid>

            <Grid item xs={4}>
                <CDColorPicker
                    name="ctaButtonColor"
                    label="CTA Button Color"
                    value={dealer.ctaButtonColor ?? CTA_BUTTON_DEFAULT_COLOR}
                    onChange={handleCTAColorChange}
                />
            </Grid>

            <Grid item xs={5}>
                <TextField
                    name="ctaTextSecondary"
                    label="Secondary CTA Text"
                    value={dealer.ctaTextSecondary ?? CTA_TEXT_SECONDARY_DEFAULT_VALUE}
                    fullWidth={true}
                    size="small"
                    variant={variant}
                    onChange={handleFieldChange}
                    InputLabelProps={{ shrink: shrinkTextFieldLabels }}
                />
            </Grid>

            <Grid item xs={4}>
                <CDColorPicker
                    name="ctaButtonColorSecondary"
                    label="Secondary CTA Button Color"
                    value={dealer.ctaButtonColorSecondary ?? CTA_BUTTON_SECONDARY_DEFAULT_COLOR}
                    onChange={handleSecondaryCTAColorChange}
                />
            </Grid>

            <Grid item xs={urlFieldGridSpan}>
                <FormControlLabel
                    control={
                        <>
                            <Switch checked={!!dealer.showTrim} onChange={handleFieldChange} name="showTrim" />
                        </>
                    }
                    label="Display Trim in Template"
                />
            </Grid>

            <Grid item xs={12} sx={{ mb: 1 }} />
        </Grid>
    );
}
