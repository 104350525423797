import { Campaign, CampaignChangeRequest } from '../../../types/Campaign';
import { useEffect, useState } from 'react';
import ApiService from '../../../ApiService';
import CDGrid from '../../../components/CDGrid';
import { GridColumns } from '@mui/x-data-grid';
import Utils from '../../../components/Utils';
import { Box, Chip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CDButton from '../../../components/CDButton';

export default function ChangeRequestTab(props: { campaign: Campaign }) {
    const { campaign } = props;
    const navigate = useNavigate();

    const [campaignChangeRequests, setCampaignChangeRequests] = useState<CampaignChangeRequest[]>([]);

    useEffect(() => {
        if (campaign.id) {
            ApiService.getCampaignChangeRequests(campaign.id).then((response) => {
                setCampaignChangeRequests(response.data);
            });
        }
    }, [campaign]);

    const columns: GridColumns = [
        {
            field: 'createdDate',
            headerName: 'Created',
            width: 100,
            renderCell: (params) => {
                return Utils.formatDate(params.value);
            },
        },
        {
            field: 'createdByUser',
            headerName: 'Created By',
            width: 130,
            flex: 1,
            valueGetter: (params) => {
                return params.value.name;
            },
        },
        {
            field: 'status',
            headerName: 'Status',
            width: 120,
            renderCell: (params) => {
                return (
                    <Chip
                        size="small"
                        label={params.value}
                        color={params.value === 'Pending' ? 'warning' : 'success'}
                    />
                );
            },
        },
        {
            field: 'dealer',
            headerName: 'Advertiser',
            width: 160,
            flex: 2,
            valueGetter: (params) => {
                return params.value.dealerName;
            },
        },
        {
            field: 'budget',
            headerName: 'Budget',
            align: 'right',
            headerAlign: 'right',
            flex: 1,
            cellClassName: (params) => {
                if (campaign.budget !== params.value) {
                    return 'modified';
                } else {
                    return '';
                }
            },
            renderCell: (params) => {
                return Utils.formatCurrency(params.value);
            },
        },
        {
            field: 'impressionGoal',
            headerName: 'Impressions',
            align: 'right',
            headerAlign: 'right',
            flex: 1,
            cellClassName: (params) => {
                if (campaign.impressionGoal !== params.value) {
                    return 'modified';
                } else {
                    return '';
                }
            },
            renderCell: (params) => {
                return Utils.formatNumber(params.value);
            },
        },
        {
            field: 'startDate',
            headerName: 'Start Date',
            flex: 1,
            renderCell: (params) => {
                return Utils.formatDate(params.value);
            },
        },
        {
            field: 'endDate',
            headerName: 'End Date',
            flex: 1,
            renderCell: (params) => {
                return Utils.formatDate(params.value);
            },
        },
        {
            field: '-',
            headerName: 'Actions',
            headerAlign: 'center',
            flex: 1,
            renderCell: (params) => {
                return (
                    <CDButton
                        variant="text"
                        onClick={() => {
                            navigate('/campaign/' + params.row.id, {
                                state: {
                                    isChangeRequest: true,
                                    campaignId: campaign.id,
                                    changeRequestId: params.row.id,
                                },
                            });
                        }}
                    >
                        {params.row.status === 'Pending' ? 'View / Modify' : 'View'}
                    </CDButton>
                );
            },
        },
    ];

    return (
        <>
            <Box
                sx={{
                    '& .modified': {
                        fontWeight: 'bold',
                    },
                }}
            >
                <CDGrid
                    // onRowClick={(params) => {
                    //     navigate('/campaign/' + params.row.id, {
                    //         state: {
                    //             isChangeRequest: true,
                    //             campaignId: campaign.id,
                    //             changeRequestId: params.row.id,
                    //         },
                    //     });
                    // }}
                    columns={columns}
                    rows={campaignChangeRequests}
                />
            </Box>
        </>
    );
}
