import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

type BusyOverlayProps = {
    container: HTMLElement | undefined;
    visible?: boolean;
};

type RenderState = 'hidden' | 'showing' | 'visible' | 'hiding';

export default function BusyOverlay(props: BusyOverlayProps) {
    const [state, setState] = useState<RenderState>('hidden');

    useEffect(() => {
        if (props.visible) {
            setState('showing');
        } else {
            setState('hiding');
        }
    }, [props.visible]);

    useEffect(() => {
        if (state === 'showing') {
            const timer = setTimeout(() => {
                setState('visible');
            }, 50);
            return () => {
                clearTimeout(timer);
                setState('visible');
            };
        } else if (state === 'hiding') {
            const timer = setTimeout(() => {
                setState('hidden');
            }, 500);
            return () => {
                clearTimeout(timer);
                setState('hidden');
            };
        }
    }, [state]);

    const className = ['zipcode-overlay'];

    if (state === 'visible') {
        className.push('zipcode-overlay__visible');
    }

    return props.container && state !== 'hidden'
        ? createPortal(
              <div className={className.join(' ')}>
                  <div className="zipcode-spinner" />
              </div>,
              props.container
          )
        : null;
}
