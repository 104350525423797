import { SyntheticEvent, useEffect, useMemo, useState } from 'react';
import { Autocomplete, AutocompleteProps, InputLabelProps, TextField, TextFieldProps } from '@mui/material';
import { createFilterOptions } from '@mui/material/Autocomplete';

export interface GeographyStateOption {
    inputValue?: string;
    code: string;
    name: string;
}

export const GeographyStates: Record<string, GeographyStateOption[]> = {
    CA: [
        { name: 'Alberta', code: 'AB' },
        { name: 'British Columbia', code: 'BC' },
        { name: 'Manitoba', code: 'MB' },
        { name: 'New Brunswick', code: 'NB' },
        { name: 'Newfoundland and Labrador', code: 'NL' },
        { name: 'Northwest Territories', code: 'NT' },
        { name: 'Nova Scotia', code: 'NS' },
        { name: 'Nunavut', code: 'NU' },
        { name: 'Ontario', code: 'ON' },
        { name: 'Prince Edward Island', code: 'PE' },
        { name: 'Quebec', code: 'QC' },
        { name: 'Saskatchewan', code: 'SK' },
        { name: 'Yukon Territory', code: 'YT' },
    ],
    US: [
        { name: 'Alabama', code: 'AL' },
        { name: 'Alaska', code: 'AK' },
        { name: 'Arizona', code: 'AZ' },
        { name: 'Arkansas', code: 'AR' },
        { name: 'California', code: 'CA' },
        { name: 'Colorado', code: 'CO' },
        { name: 'Connecticut', code: 'CT' },
        { name: 'Delaware', code: 'DE' },
        { name: 'District of Columbia', code: 'DC' },
        { name: 'Florida', code: 'FL' },
        { name: 'Georgia', code: 'GA' },
        { name: 'Hawaii', code: 'HI' },
        { name: 'Idaho', code: 'ID' },
        { name: 'Illinois', code: 'IL' },
        { name: 'Indiana', code: 'IN' },
        { name: 'Iowa', code: 'IA' },
        { name: 'Kansas', code: 'KS' },
        { name: 'Kentucky', code: 'KY' },
        { name: 'Louisiana', code: 'LA' },
        { name: 'Maine', code: 'ME' },
        { name: 'Maryland', code: 'MD' },
        { name: 'Massachusetts', code: 'MA' },
        { name: 'Michigan', code: 'MI' },
        { name: 'Minnesota', code: 'MN' },
        { name: 'Mississippi', code: 'MS' },
        { name: 'Missouri', code: 'MO' },
        { name: 'Montana', code: 'MT' },
        { name: 'Nebraska', code: 'NE' },
        { name: 'Nevada', code: 'NV' },
        { name: 'New Hampshire', code: 'NH' },
        { name: 'New Jersey', code: 'NJ' },
        { name: 'New Mexico', code: 'NM' },
        { name: 'New York', code: 'NY' },
        { name: 'North Carolina', code: 'NC' },
        { name: 'North Dakota', code: 'ND' },
        { name: 'Ohio', code: 'OH' },
        { name: 'Oklahoma', code: 'OK' },
        { name: 'Oregon', code: 'OR' },
        { name: 'Pennsylvania', code: 'PA' },
        { name: 'Puerto Rico', code: 'PR' },
        { name: 'Rhode Island', code: 'RI' },
        { name: 'South Carolina', code: 'SC' },
        { name: 'South Dakota', code: 'SD' },
        { name: 'Tennessee', code: 'TN' },
        { name: 'Texas', code: 'TX' },
        { name: 'Utah', code: 'UT' },
        { name: 'Vermont', code: 'VT' },
        { name: 'Virginia', code: 'VA' },
        { name: 'Washington', code: 'WA' },
        { name: 'West Virginia', code: 'WV' },
        { name: 'Wisconsin', code: 'WI' },
        { name: 'Wyoming', code: 'WY' },
    ],
};

const filter = createFilterOptions<GeographyStateOption>();

export type GeographyStateFieldProps = {
    countryCode?: string;

    label?: string;
    name?: string;
    variant?: TextFieldProps['variant'];
    InputLabelProps?: InputLabelProps;
    disableInputValue?: boolean;
} & Partial<AutocompleteProps<any, false, false, true>>;

export default function GeographyStateField(props: GeographyStateFieldProps) {
    const {
        countryCode = 'US',

        label = 'State',
        name = 'state',
        variant = 'outlined',
        InputLabelProps,
        disableInputValue = true,
        ...autocompleteProps
    } = props;

    const geographyStates = useMemo(() => {
        if (countryCode in GeographyStates) {
            return GeographyStates[countryCode];
        }
        return [];
    }, [countryCode]);

    const [value, setValue] = useState<any | null>(null);

    useEffect(() => {
        let newValueOption: GeographyStateOption | null = {
            code: '',
            name: '',
        };

        if (typeof props.value === 'string') {
            const existingValue: GeographyStateOption | undefined = geographyStates.find(
                (state: GeographyStateOption) => state.code === props.value
            );

            if (existingValue) {
                newValueOption = existingValue;
            } else {
                newValueOption = {
                    name: props.value,
                    code: props.value,
                };
            }
        } else {
            newValueOption = props.value;
        }

        setValue(newValueOption);
    }, [props.value]);

    return (
        <Autocomplete
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            {...autocompleteProps}
            freeSolo
            options={geographyStates}
            value={value}
            onChange={(event: SyntheticEvent, newValue: any, reason: any, details?: any) => {
                let newValueOption: GeographyStateOption = {
                    code: '',
                    name: '',
                };

                if (typeof newValue === 'string') {
                    newValueOption = {
                        name: newValue,
                        code: newValue,
                    };
                } else if (newValue && newValue.inputValue) {
                    if (!disableInputValue) {
                        // Create a new value from the user input
                        newValueOption = {
                            name: newValue.inputValue,
                            code: newValue.inputValue,
                        };
                    }
                } else {
                    newValueOption = newValue;
                }

                setValue(newValueOption);

                if (props?.onChange) {
                    props.onChange(event, newValueOption, reason, details);
                }
            }}
            filterOptions={(options, params) => {
                const filtered = filter(options, {
                    ...params,
                    getOptionLabel: (option) => {
                        // Value selected with enter, right from the input
                        if (typeof option === 'string') {
                            return option;
                        }
                        // Add "xxx" option created dynamically
                        if (option.inputValue) {
                            return option.inputValue;
                        }
                        // Regular option
                        return `${option.name} - ${option.code}`;
                    },
                });

                if (!disableInputValue) {
                    let { inputValue } = params;
                    inputValue = inputValue.toUpperCase();
                    // Suggest the creation of a new value
                    const isExisting: boolean =
                        options.some((option) => inputValue === option.name) ||
                        options.some((option) => inputValue === option.code);
                    if (inputValue !== '' && !isExisting) {
                        filtered.push({
                            inputValue,
                            name: `Add "${inputValue}"`,
                            code: inputValue,
                        });
                    }
                }

                return filtered;
            }}
            getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === 'string') {
                    return option;
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                    return option.inputValue;
                }
                // Regular option
                return option.code;
            }}
            renderOption={(props, option) => {
                const { key, ...optionProps } = props;
                return (
                    <li key={key} {...optionProps}>
                        {option.name}
                    </li>
                );
            }}
            renderInput={(params) => (
                <TextField {...params} label={label} name={name} variant={variant} InputLabelProps={InputLabelProps} />
            )}
        />
    );
}
