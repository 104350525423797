import { useMemo } from 'react';
import { Box, Card, CardContent, CircularProgress, Typography } from '@mui/material';

import Column from '../../../../../components/Column';

import { useReportingDashboardPageContext } from '../../../../../hooks/useReportingDashboardPage';
import ReportingPerformanceTable from '../Tables/ReportingPerformanceTable';

export default function ReportingPerformanceTableView() {
    const { isFetchingPerformances, reportingCriteria, performances } = useReportingDashboardPageContext();

    const hasData = useMemo((): boolean => {
        return performances && performances.length > 0 ? true : false;
    }, [performances]);

    const boxHeight: number = 270;

    if (!reportingCriteria) {
        return <></>;
    }

    return (
        <Column gap={3}>
            <Box>
                {isFetchingPerformances ? (
                    <Card variant="outlined">
                        <CardContent>
                            <Column
                                direction="column"
                                alignItems="center"
                                justifyContent="center"
                                sx={{ height: boxHeight + 2 }}
                            >
                                <CircularProgress />
                            </Column>
                        </CardContent>
                    </Card>
                ) : (
                    <>
                        {hasData === true && (
                            <ReportingPerformanceTable
                                reportingCriteria={reportingCriteria}
                                performances={performances}
                            />
                        )}
                        {hasData === false && (
                            <Card variant="outlined">
                                <CardContent>
                                    <Column
                                        direction="column"
                                        alignItems="center"
                                        justifyContent="center"
                                        sx={{ height: boxHeight + 2 }}
                                    >
                                        <Typography variant="body2">No data available</Typography>
                                    </Column>
                                </CardContent>
                            </Card>
                        )}
                    </>
                )}
            </Box>
        </Column>
    );
}
