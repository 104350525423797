import { MouseEvent, useMemo, useState } from 'react';
import { styled, alpha } from '@mui/material/styles';
import { Box, Button, ButtonProps, Menu as BaseMenu, MenuProps, MenuItem, Typography } from '@mui/material';
import { ArrowDropDown as ArrowDropDownIcon } from '@mui/icons-material';
import { CSVLink } from 'react-csv';

import {
    ATTRIBUTION_EXPORT_METHOD_DOWNLOAD,
    ATTRIBUTION_EXPORT_METHOD_EMAIL,
    // ATTRIBUTION_EXPORT_TYPE_CSV,
    ATTRIBUTION_EXPORT_TYPE_PDF,
    AttributionExport,
} from '../../types/AttributionExport';

import { TABS, useAttributionPageContext } from '../../hooks/useAttributionPage';
import { useAdvertiserAttributionCsvExporter, usePolkAttributionCsvExporter } from '../../hooks/useAttributionExport';
import useAttributionExport from '../../hooks/useAttributionExport';
import AttributionExportDialog from './AttributionExportDialog';

const Menu = styled((props: MenuProps) => (
    <BaseMenu
        elevation={4}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: 0,
        minWidth: 120,
        color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
            },
        },
    },
}));

export default function AttributionExportMenuButton(props: ButtonProps) {
    const { tab, attributionType, dealer, attributionDate } = useAttributionPageContext();
    const { generateExportFilename } = useAttributionExport();
    const advertiserCsvExporter = useAdvertiserAttributionCsvExporter();
    const polkCsvExporter = usePolkAttributionCsvExporter();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const [attributionExport, setAttributionExport] = useState<AttributionExport>({
        exportMethod: ATTRIBUTION_EXPORT_METHOD_DOWNLOAD,
        exportType: ATTRIBUTION_EXPORT_TYPE_PDF,

        dealerId: dealer ? dealer.id : 0,
        attributionDate: attributionDate,
        attributionType: attributionType,
    });
    const [exportDialogOpen, setExportDialogOpen] = useState<boolean>(false);

    const filename: string = useMemo(() => {
        let filename = '';

        switch (tab) {
            case TABS.POLK_REPORTED_SALES:
                filename = generateExportFilename('{id}-polk-attribution.csv');
                break;

            case TABS.ADVERTISER_REPORTED_SALES:
                filename = generateExportFilename('{id}-advertiser-attribution.csv');
                break;

            default:
                filename = generateExportFilename('{id}-attribution.csv');
                break;
        }

        return filename;
    }, [generateExportFilename, tab]);

    const handleClick = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const getCsvData = () => {
        let rows = [];

        if (tab === TABS.ADVERTISER_REPORTED_SALES) {
            rows = advertiserCsvExporter.getCsvData();
        } else {
            rows = polkCsvExporter.getCsvData();
        }

        return rows;
    };

    return (
        <div>
            <Button
                id="attribution-export-menu-button"
                aria-controls={open ? 'attribution-export-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                variant="contained"
                disableElevation
                onClick={handleClick}
                endIcon={<ArrowDropDownIcon />}
                size="large"
                sx={{ height: 40 }}
                {...props}
            >
                Export
            </Button>
            <Menu
                id="attribution-export-menu"
                MenuListProps={{
                    'aria-labelledby': 'attribution-export-menu-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                sx={{ width: 300 }}
            >
                <Box
                    sx={{
                        px: 2,
                        py: 1,
                        mb: 1,
                        width: 300,
                    }}
                >
                    <Typography
                        variant="subtitle1"
                        sx={{
                            fontWeight: (theme) => theme.typography.fontWeightBold,
                        }}
                    >
                        {tab === TABS.POLK_REPORTED_SALES && <>Polk Reported Sales</>}
                        {tab === TABS.ADVERTISER_REPORTED_SALES && <>Advertiser Reported Sales</>}
                    </Typography>
                </Box>
                <MenuItem
                    disableRipple
                    onClick={() => {
                        setAttributionExport({
                            ...attributionExport,
                            exportMethod: ATTRIBUTION_EXPORT_METHOD_DOWNLOAD,
                            exportType: ATTRIBUTION_EXPORT_TYPE_PDF,
                        });
                        setExportDialogOpen(true);
                    }}
                >
                    PDF
                </MenuItem>
                <MenuItem disableRipple>
                    <CSVLink
                        data={getCsvData()}
                        filename={filename}
                        style={{
                            display: 'inline-block',
                            width: '100%',
                            textDecoration: 'none',
                        }}
                    >
                        CSV
                    </CSVLink>
                </MenuItem>
                <MenuItem
                    disableRipple
                    onClick={() => {
                        setAttributionExport({
                            ...attributionExport,
                            exportMethod: ATTRIBUTION_EXPORT_METHOD_EMAIL,
                            exportType: ATTRIBUTION_EXPORT_TYPE_PDF,
                        });
                        setExportDialogOpen(true);
                    }}
                >
                    Email
                </MenuItem>
            </Menu>

            <AttributionExportDialog
                open={exportDialogOpen}
                onClose={() => {
                    setExportDialogOpen(false);
                }}
                attributionExport={attributionExport}
            />
        </div>
    );
}
