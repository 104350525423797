import { useEffect, useMemo, useState } from 'react';
import { Grid } from '@mui/material';
import moment from 'moment-timezone';

import { AttributionPerformance } from '../../types/CampaignPerformance';
import ApiService from '../../ApiService';

import { Metric } from '../../components/Metrics/MetricCard';
import { setupMetricChange } from '../../components/Metrics/MetricCard';
import { useAttributionPageContext } from '../../hooks/useAttributionPage';
import { useAmcReachAndFrequencyList } from '../../hooks/useAmcReachAndFrequencyList';
import MetricCard from '../../components/Metrics/MetricCard';

export default function AdvertiserMatchbackMetrics() {
    const {
        dealer,
        attributionDate,
        performances,
        previousPerformances,
        attributionMetrics,
        setAttributionMetrics,
        isFetchingPerformances,
        isFetchingPreviousPerformances,
    } = useAttributionPageContext();

    const [attributionPerformances, setAttributionPerformances] = useState<AttributionPerformance[]>([]);
    const [previousAttributionPerformances, setPreviousAttributionPerformances] = useState<AttributionPerformance[]>(
        []
    );

    const [isFetchingAttributionPerformances, setIsFetchingAttributionPerformances] = useState<boolean>(false);
    const [isFetchingPreviousAttributionPerformances, setIsFetchingPreviousAttributionPerformances] =
        useState<boolean>(false);

    const { amcReachAndFrequencyList, fetchAmcReachAndFrequencyList, isFetchingAmcReachAndFrequencyList } =
        useAmcReachAndFrequencyList();

    const {
        amcReachAndFrequencyList: previousAmcReachAndFrequencyList,
        fetchAmcReachAndFrequencyList: fetchPreviousAmcReachAndFrequencyList,
        isFetchingAmcReachAndFrequencyList: isFetchingPreviousAmcReachAndFrequencyList,
    } = useAmcReachAndFrequencyList();

    function getSumTotalCampaignPerformanceMetric(_metrics: any[], key: string): number {
        return _metrics.map((_metric: any) => _metric?.[key] ?? 0).reduce((_total, _subtotal) => _total + _subtotal, 0);
    }

    const getMetricByAttributionPerformance = (
        target: string,
        format: 'number' | 'dollar' | 'decimal' | 'percent'
    ): Metric => {
        let _metric: Metric = {
            format: format,
            value: 0,
        };

        if (attributionPerformances && attributionPerformances.length) {
            _metric.value = attributionPerformances
                .map((o: AttributionPerformance) => o[target as keyof AttributionPerformance] as number)
                .reduce((a, b) => a + b, 0);
        }

        if (previousAttributionPerformances && previousAttributionPerformances.length) {
            _metric.previousValue = previousAttributionPerformances
                .map((o: AttributionPerformance) => o[target as keyof AttributionPerformance] as number)
                .reduce((a, b) => a + b, 0);
        }

        _metric = setupMetricChange(_metric);

        return _metric;
    };

    const getMetricByCampaignPerformance = (
        target: string,
        format: 'number' | 'dollar' | 'decimal' | 'percent'
    ): Metric => {
        let _metric: Metric = {
            format: format,
            value: 0,
        };

        if (performances.length > 0) {
            _metric.value = getSumTotalCampaignPerformanceMetric(performances, target);
        }

        if (previousPerformances.length > 0) {
            _metric.previousValue = getSumTotalCampaignPerformanceMetric(previousPerformances, target);
        }

        _metric = setupMetricChange(_metric);

        return _metric;
    };

    const totalSales = useMemo(() => {
        return getMetricByAttributionPerformance('totalSales', 'number');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [attributionPerformances, previousAttributionPerformances]);

    const attributedSales = useMemo(() => {
        return getMetricByAttributionPerformance('totalAdExposedSales', 'number');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [attributionPerformances, previousAttributionPerformances]);

    const totalSpend = useMemo(() => {
        return getMetricByCampaignPerformance('totalCost', 'dollar');
        // return getMetricByAttributionPerformance('totalInvestment', 'dollar');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [performances, previousPerformances]);
    //[attributionPerformances, previousAttributionPerformances]);

    const costPerSold = useMemo(() => {
        return getMetricByAttributionPerformance('costPerAdExposedSale', 'dollar');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [attributionPerformances, previousAttributionPerformances]);

    const totalImpressions = useMemo(() => {
        return getMetricByCampaignPerformance('impressions', 'number');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [performances, previousPerformances]);

    const totalClickthroughs = useMemo(() => {
        return getMetricByCampaignPerformance('clickthroughs', 'number');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [performances, previousPerformances]);

    const totalDailyReach: Metric = useMemo(() => {
        let _metric: Metric = {
            format: 'number',
            value: 0,
        };

        if (amcReachAndFrequencyList.length > 0) {
            _metric.value = amcReachAndFrequencyList
                .map((amcReachAndFrequency: any) => amcReachAndFrequency?.reach as number)
                .reduce((a: number, b: number) => a + b, 0);
        }

        if (previousAmcReachAndFrequencyList.length > 0) {
            _metric.previousValue = previousAmcReachAndFrequencyList
                .map((amcReachAndFrequency: any) => amcReachAndFrequency?.reach as number)
                .reduce((a: number, b: number) => a + b, 0);
        }

        _metric = setupMetricChange(_metric);

        return _metric;
    }, [amcReachAndFrequencyList, previousAmcReachAndFrequencyList]);

    const totalImpressionFrequency: Metric = useMemo(() => {
        let _metric: Metric = {
            format: 'number',
            value: 0,
        };

        if (amcReachAndFrequencyList.length > 0) {
            _metric.value = amcReachAndFrequencyList
                .map((amcReachAndFrequency: any) => amcReachAndFrequency?.frequency as number)
                .reduce((a: number, b: number) => a + b, 0);
            _metric.value = Math.round(_metric.value / amcReachAndFrequencyList.length);
        }

        if (previousAmcReachAndFrequencyList.length > 0) {
            _metric.previousValue = previousAmcReachAndFrequencyList
                .map((amcReachAndFrequency: any) => amcReachAndFrequency?.frequency as number)
                .reduce((a: number, b: number) => a + b, 0);
            _metric.previousValue = Math.round(_metric.previousValue / previousAmcReachAndFrequencyList.length);
        }

        _metric = setupMetricChange(_metric);

        return _metric;
    }, [amcReachAndFrequencyList, previousAmcReachAndFrequencyList]);

    const totalSpendMetricLabel: string = useMemo(() => {
        if (attributionDate) {
            return `${moment(attributionDate).format('MMMM')} Investment`;
        }

        return 'Total Investment';
    }, [attributionDate]);

    const costPerSoldTooltip: string = useMemo(() => {
        if (attributionDate) {
            return '{month} Investment divided by Total Ad Exposed Sales'.replace(
                '{month}',
                moment(attributionDate).format('MMMM')
            );
        }

        return 'Total Investment divided by Total Ad Exposed Sales';
    }, [attributionDate]);

    useEffect(() => {
        const dealerId = dealer?.id ?? 0;

        if (dealerId > 0 && attributionDate) {
            const startDate: Date = moment(attributionDate).startOf('month').toDate();
            const endDate: Date = moment(attributionDate).endOf('month').toDate();

            const previousStartDate: Date = moment
                .utc(moment(attributionDate).subtract(1, 'month').format('YYYY-MM-DD HH:mm:ss'))
                .toDate();
            const previousEndDate: Date = moment
                .utc(moment(attributionDate).subtract(1, 'month').endOf('month').format('YYYY-MM-DD HH:mm:ss'))
                .toDate();

            setIsFetchingAttributionPerformances(true);
            setIsFetchingPreviousAttributionPerformances(true);

            ApiService.getAttributionPerformanceList({
                dealerId: dealerId,
                dataSet: 'Sales',
                startDate: startDate,
                endDate: endDate,
            }).then((response) => {
                setIsFetchingAttributionPerformances(false);
                setAttributionPerformances(response.data);
            });

            ApiService.getAttributionPerformanceList({
                dealerId: dealerId,
                dataSet: 'Sales',
                startDate: previousStartDate,
                endDate: previousEndDate,
            }).then((response) => {
                setIsFetchingPreviousAttributionPerformances(false);
                setPreviousAttributionPerformances(response.data);
            });

            fetchAmcReachAndFrequencyList({
                dealerId: dealerId,
                campaignName: 'Global',
                startDate: startDate,
                endDate: endDate,
            });

            fetchPreviousAmcReachAndFrequencyList({
                dealerId: dealerId,
                campaignName: 'Global',
                startDate: previousStartDate,
                endDate: previousEndDate,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dealer, attributionDate]);

    useEffect(() => {
        setAttributionMetrics({
            ...attributionMetrics,
            ...{
                totalSales,
                attributedSales,
                totalSpend,
                costPerSold,
                totalImpressions,
                totalClickthroughs,
                totalImpressionFrequency,
                totalDailyReach,
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        totalSales,
        attributedSales,
        totalSpend,
        costPerSold,
        totalImpressions,
        totalClickthroughs,
        totalImpressionFrequency,
        totalDailyReach,
    ]);

    return (
        <Grid container spacing={2}>
            <Grid item xs={3}>
                <MetricCard
                    loading={isFetchingAttributionPerformances || isFetchingPreviousAttributionPerformances}
                    metric={totalSales}
                    label="Total Sales"
                    tooltip="Total Advertiser Sales as reported by Advertiser"
                />
            </Grid>

            <Grid item xs={3}>
                <MetricCard
                    loading={isFetchingAttributionPerformances || isFetchingPreviousAttributionPerformances}
                    metric={attributedSales}
                    label="Total Ad Exposed Sales"
                    tooltip="Total households that were exposed to an advertisement and made a purchase for the selected time period"
                />
            </Grid>

            <Grid item xs={3}>
                <MetricCard
                    loading={isFetchingPerformances || isFetchingPreviousPerformances}
                    metric={totalSpend}
                    label={totalSpendMetricLabel}
                    tooltip="Total advertiser ad spend for the selected time period"
                />
            </Grid>

            <Grid item xs={3}>
                <MetricCard
                    loading={isFetchingAttributionPerformances || isFetchingPreviousAttributionPerformances}
                    metric={costPerSold}
                    label="Cost Per Sale"
                    tooltip={costPerSoldTooltip}
                    inverseColor={true}
                />
            </Grid>

            <Grid item xs={3}>
                <MetricCard
                    loading={isFetchingPerformances || isFetchingPreviousPerformances}
                    metric={totalImpressions}
                    label="Total Exposures"
                    tooltip="Total number of ad exposures or impressions for the selected advertiser for the selected time period"
                />
            </Grid>

            <Grid item xs={3}>
                <MetricCard
                    loading={isFetchingPerformances || isFetchingPreviousPerformances}
                    metric={totalClickthroughs}
                    label="Total Clicks"
                    tooltip="Total number of clicks on an advertisement for the selected time period"
                />
            </Grid>

            <Grid item xs={3}>
                <MetricCard
                    loading={isFetchingAmcReachAndFrequencyList || isFetchingPreviousAmcReachAndFrequencyList}
                    metric={totalDailyReach}
                    label="Household Reach"
                    tooltip="Total number of households exposed to an advertisement"
                />
            </Grid>

            <Grid item xs={3}>
                <MetricCard
                    loading={isFetchingAmcReachAndFrequencyList || isFetchingPreviousAmcReachAndFrequencyList}
                    metric={totalImpressionFrequency}
                    label="Avg. Household Frequency"
                    tooltip="Avg. number of times a household saw advertisements across all media types and channels"
                    inverseColor={true}
                />
            </Grid>
        </Grid>
    );
}
