import { useEffect, useState } from 'react';
import { Dashboard } from '../types/Dashboard';
import ApiService from '../ApiService';
import { FormControl, MenuItem, TextField } from '@mui/material';

export interface QuickSightDashboardSelectorProps {
    label: string;
    dashboardId: string | null;
    onChange(dashboardId: string | null): void;
}
export default function QuickSightDashboardSelector(props: QuickSightDashboardSelectorProps) {
    const [dashboards, setDashboards] = useState<Dashboard[] | null>(null);

    useEffect(() => {
        ApiService.getQuickSightDashboards().then((response) => {
            setDashboards(response.data);
        });
    }, []);

    return (
        <>
            <FormControl style={{ width: '100%' }}>
                {dashboards && (
                    <TextField
                        label={props.label}
                        select
                        size="small"
                        SelectProps={{ native: false }}
                        variant="outlined"
                        value={props.dashboardId || ''}
                        InputLabelProps={{ shrink: true }}
                        disabled={dashboards.length === 1}
                        onChange={(event) => {
                            props.onChange(event.target.value);
                        }}
                    >
                        {dashboards.map((dashboard) => {
                            return (
                                <MenuItem key={dashboard.dashboardId} value={dashboard.dashboardId}>
                                    {dashboard.dashboardName}
                                </MenuItem>
                            );
                        })}
                    </TextField>
                )}
            </FormControl>
        </>
    );
}
