import { useMemo } from 'react';
import { Typography } from '@mui/material';
import { ApexOptions } from 'apexcharts';

import { Performance } from '../../../../../types/CampaignPerformance';
import Row from '../../../../../components/Row';

import { useReportingDashboardPageContext } from '../../../../../hooks/useReportingDashboardPage';
import GroupedBarChartCard from '../../../../../components/Metrics/GroupedBarChartCard';

type PerformanceGroupedBarChartCardProps = {
    loading?: boolean;
};

export default function PerformanceGroupedBarChartCard(props: PerformanceGroupedBarChartCardProps) {
    const { loading = false } = props;
    const { performances, hasDisplayMediaType, hasVideoMediaType, hasAudioMediaType } =
        useReportingDashboardPageContext();

    const mediaTypes = useMemo((): string[] => {
        return ['Display', 'Video', 'Audio'].filter((_mediaType: string) => {
            switch (_mediaType) {
                case 'Display':
                    return hasDisplayMediaType;
                case 'Video':
                    return hasVideoMediaType;
                case 'Audio':
                    return hasAudioMediaType;
            }
            return false;
        });
    }, [hasDisplayMediaType, hasVideoMediaType, hasAudioMediaType]);

    const filteredPerformances = useMemo(() => {
        return performances;
    }, [performances]);

    const filteredMediaTypes = useMemo(() => {
        return mediaTypes.filter((_mediaType: string, _index: number) => {
            const total: number = filteredPerformances
                .filter((p: Performance) => p.mediaType === _mediaType)
                .map((p: Performance) => {
                    const total: number = p?.impressions + p?.dailyReach + p?.clickthroughs + p?.offAmazonConversions;
                    return total;
                })
                .reduce((total: number, value: number) => total + value, 0);

            return total > 0;
        });
    }, [filteredPerformances, mediaTypes]);

    const seriesData = useMemo(() => {
        if (filteredPerformances && filteredPerformances.length) {
            return [
                {
                    name: 'Exposures',
                    data: filteredMediaTypes.map((m: string) => {
                        return filteredPerformances
                            .filter((p: Performance) => p.mediaType === m)
                            .map((p: Performance) => p?.impressions)
                            .reduce((total: number, value: number) => total + value, 0);
                    }),
                },
                {
                    name: 'Household Reach',
                    data: filteredMediaTypes.map((m: string) => {
                        return filteredPerformances
                            .filter((p: Performance) => p.mediaType === m)
                            .map((p: Performance) => p?.dailyReach)
                            .reduce((total: number, value: number) => total + value, 0);
                    }),
                },
                {
                    name: 'Clicks',
                    data: filteredMediaTypes.map((m: string) => {
                        return filteredPerformances
                            .filter((p: Performance) => p.mediaType === m)
                            .map((p: Performance) => p?.clickthroughs)
                            .reduce((total: number, value: number) => total + value, 0);
                    }),
                },
                {
                    name: 'Conversions',
                    data: filteredMediaTypes.map((m: string) => {
                        return filteredPerformances
                            .filter((p: Performance) => p.mediaType === m)
                            .map((p: Performance) => p?.offAmazonConversions)
                            .reduce((total: number, value: number) => total + value, 0);
                    }),
                },
            ];
        }

        return [];
    }, [filteredPerformances, filteredMediaTypes]);

    const seriesDataLabels = useMemo(() => {
        return filteredMediaTypes;
    }, [filteredMediaTypes]);

    const seriesOptions = useMemo((): ApexOptions => {
        return {
            legend: {
                offsetY: 12,
                position: 'top',
            },
            xaxis: {
                categories: seriesDataLabels,
            },
        };
    }, [seriesDataLabels]);

    return (
        <GroupedBarChartCard
            loading={loading}
            label={
                <Row gap={1} alignItems="center" sx={{ mr: 0.5 }} width="auto">
                    <Typography variant="body1" color="text.primary">
                        Performance Metrics by Media Type
                    </Typography>
                </Row>
            }
            tooltip="An event as defined by advertiser, by Media Type."
            chartOptions={seriesOptions}
            chartSeries={seriesData}
        />
    );
}
