import moment from 'moment-timezone';

import { Performance } from '../types/CampaignPerformance';
import Utils from '../components/Utils';

import { useReportingDashboardPageContext } from './useReportingDashboardPage';

export function useMediaReportingPerformanceCsvExporter() {
    const { attributionMetrics, attributionPerformances, performances } = useReportingDashboardPageContext();

    const getCsvData = () => {
        let rows = [];

        rows.push(
            ...[
                ['Reporting Analytics - Overall Performance'],
                ['Total Investment', 'Total Exposures', 'Average CPM', 'Household Reach'],
                [
                    Utils.formatValue(attributionMetrics?.totalSpend?.value, 'dollar'),
                    Utils.formatValue(attributionMetrics?.totalImpressions?.value),
                    Utils.formatValue(attributionMetrics?.averageCPM?.value, 'dollar'),
                    Utils.formatValue(attributionMetrics?.totalDailyReach?.value),
                ],
                [
                    // An empty row.
                ],
            ]
        );

        // rows.push([
        //     'Advertiser',
        //     'Total Sales',
        //     'Total Investment',
        // ]);

        if (false && attributionPerformances.length) {
            rows.push(
                ...attributionPerformances.map((attributionPerformance: any) => {
                    return [
                        attributionPerformance.dealerId,
                        attributionPerformance.totalSales,
                        attributionPerformance.totalInvestment,
                    ];
                })
            );
        }

        if (performances.length > 0) {
            rows.push(
                ...[
                    [
                        // An empty row.
                    ],
                    ['Campaign Performance'],
                    [
                        'Agency',
                        'Advertiser',
                        'Campaign',
                        'Media Type',
                        'Total Investment',
                        'Total Exposure',
                        'Average CPM',
                        'Household Reach',
                        'Avg. Viewability',
                        'Total Clicks',
                        'Avg. CTR',
                        'Conversions',
                    ],
                ]
            );

            rows.push(
                ...performances.map((performance: Performance) => {
                    return [
                        performance.agencyName,
                        performance.dealerName,
                        performance.campaignName,
                        performance.mediaType,
                        Utils.formatValue(performance.totalCost, 'dollar'),
                        Utils.formatValue(performance.impressions),
                        Utils.formatValue(performance.CPM, 'dollar'),
                        Utils.formatValue(performance.dailyReach),
                        Utils.formatValue(performance.viewabilityRate, 'percent'),
                        Utils.formatValue(performance.clickthroughs),
                        Utils.formatValue(performance.CTR, 'percent'),
                        Utils.formatValue(performance.offAmazonConversions),
                    ];
                })
            );
        }

        return rows;
    };

    return { getCsvData };
}

export default function useReportingPerformanceExport() {
    const { reportingCriteria = null } = useReportingDashboardPageContext();

    const generateExportFilename = (format = '{id}'): string => {
        let keywords: string[] = [];

        if (reportingCriteria) {
            if (reportingCriteria.startDate) {
                keywords.push(moment(reportingCriteria.startDate).format('MMMM DD YYYY'));
            }

            if (reportingCriteria.endDate) {
                keywords.push(moment(reportingCriteria.endDate).format('MMMM DD YYYY'));
            }
        }

        return format.replace('{id}', keywords.map((_keyword) => _keyword.replaceAll(' ', '').toLowerCase()).join('-'));
    };

    return {
        generateExportFilename,
    };
}
