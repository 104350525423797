import CDGrid from './CDGrid';
import { Box, Card, CardContent, CardHeader, Chip, Divider, IconButton, Modal, Stack, Tooltip } from '@mui/material';
import { useEffect, useState } from 'react';
import Utils from './Utils';
import { SizmekAd } from '../types/Sizmek';
import ApiService from '../ApiService';
import moment from 'moment';
import { CloseOutlined, PhotoCameraOutlined, VideoCameraBackOutlined } from '@mui/icons-material';

export interface AdvertiserAdComponentProps {
    dealerId?: number;
    disabled?: boolean;
}

export default function AdvertiserAdComponentOld(props: AdvertiserAdComponentProps) {
    const [ads, setAds] = useState<SizmekAd[]>([]);
    const [ad, setAd] = useState<SizmekAd>();
    const [servingPath, setServingPath] = useState<string | null>();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (props.dealerId) {
            setLoading(true);
            ApiService.getDealerAds(props.dealerId)
                .then((response) => {
                    setAds(response.data);
                    setLoading(false);
                })
                .catch(() => {});
        }
    }, [props.dealerId]);

    function showImage(ad: SizmekAd): void {
        setAd(ad);
        setServingPath('x');
    }

    function showVideo(ad: SizmekAd): void {
        let assetId = ad.adAsset.assetId;
        if (ad.adAsset.variant) {
            assetId = ad.adAsset.variant.assetId;
        }
        if (props.dealerId) {
            ApiService.getAsset(props.dealerId, assetId)
                .then((response) => {
                    setAd(ad);
                    setServingPath(response.data.servingPath);
                })
                .catch(() => {});
        }
    }

    return (
        <>
            {servingPath && ad && (
                <Modal open={servingPath.length > 0} onClose={() => setServingPath(null)}>
                    <Card
                        elevation={4}
                        sx={{
                            position: 'absolute' as 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                        }}
                    >
                        <CardHeader
                            title={
                                <>
                                    <Stack direction="row" sx={{ alignItems: 'center' }}>
                                        <span>{ad.name}</span>
                                        <span style={{ flex: 1 }} />
                                        <IconButton>
                                            <CloseOutlined onClick={() => setServingPath(null)} />
                                        </IconButton>
                                    </Stack>
                                    <Divider />
                                </>
                            }
                            titleTypographyProps={{ variant: 'h6' }}
                        ></CardHeader>
                        <CardContent
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignContent: 'center',
                                alignItems: 'center',
                                minHeight: 400,
                                minWidth: 600,
                            }}
                        >
                            <Box
                                sx={{
                                    width: ad.adAsset.width > 1024 ? ad.adAsset.width * 0.5 : ad.adAsset.width + 20,
                                    height: ad.adAsset.width > 1024 ? ad.adAsset.height * 0.5 : ad.adAsset.height + 20,
                                    bgcolor: 'background.paper',
                                    border: '2px solid #000',
                                    boxShadow: 24,
                                    p: 0,
                                }}
                            >
                                {ad?.adAsset.mediaType === 'VIDEO' && (
                                    <video width="100%" height="100%" autoPlay controls>
                                        <source src={servingPath} type="video/mp4" />
                                    </video>
                                )}
                                {ad?.adAsset.mediaType === 'IMAGE' && (
                                    <iframe
                                        title="Ad Preview"
                                        style={{
                                            border: '0',
                                            width: ad.adAsset.width + 32,
                                            height: ad.adAsset.height + 25,
                                        }}
                                        src={
                                            '/iframe.html?tag=https%3A%2F%2Fplatform.sizmek.com%2Fpreview%2FServing%2FadServer.bs%3Fcn%3Ddisplay%26c%3D28%26ord%3D%5Btimestamp%5D%26pr%3D1%26isTestMode%3D%26%26isResponsive%3Dfalse%26ai%3D' +
                                            ad.masterAdId
                                        }
                                    ></iframe>
                                )}
                            </Box>
                        </CardContent>
                    </Card>
                </Modal>
            )}
            <CDGrid
                loading={loading}
                initialState={{
                    columns: {
                        columnVisibilityModel: {
                            description: false,
                            createdOn: false,
                        },
                    },
                }}
                disabled={props.disabled}
                pageSize={20}
                columns={[
                    {
                        width: 80,
                        field: 'defaultImage',
                        headerName: 'Preview',
                        headerAlign: 'center',
                        align: 'center',
                        renderCell: (params) => {
                            let ad = params.row.adAsset;
                            if (ad.variant) {
                                ad = ad.variant;
                            }
                            if (ad.width > 1024) {
                                ad.width = ad.width * 0.5;
                                ad.height = ad.height * 0.5;
                            }
                            if (ad.mediaType === 'VIDEO') {
                                return (
                                    <Tooltip placement="right-start" title="Click to Preview">
                                        <IconButton onClick={() => showVideo(params.row)}>
                                            <VideoCameraBackOutlined />
                                        </IconButton>
                                    </Tooltip>
                                );
                            } else {
                                return (
                                    <Tooltip placement="right-start" title="Click to Preview">
                                        <IconButton onClick={() => showVideo(params.row)}>
                                            <PhotoCameraOutlined onClick={() => showImage(params.row)} />
                                        </IconButton>
                                    </Tooltip>
                                );
                            }
                        },
                    },
                    {
                        width: 270,
                        field: 'name',
                        headerName: 'Name',
                        flex: 2,
                        renderCell: (params) => {
                            return (
                                <Tooltip title={params.value}>
                                    <div
                                        style={{
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                        }}
                                    >
                                        {params.value}
                                    </div>
                                </Tooltip>
                            );
                        },
                    },
                    {
                        width: 120,
                        field: 'adStatus',
                        headerName: 'Status',
                        headerAlign: 'center',
                        align: 'center',
                        valueGetter: (params) => {
                            return Utils.convertCase(params.value);
                        },
                        renderCell: (params) => {
                            if (params.value === 'Live' || params.value === 'Published') {
                                return <Chip color="success" label={params.value} variant="filled"></Chip>;
                            } else {
                                return <span>{params.value}</span>;
                            }
                        },
                    },
                    {
                        width: 130,
                        field: 'adFormatName',
                        flex: 1,
                        headerName: 'Format',
                    },
                    {
                        width: 105,
                        field: 'id',
                        flex: 1,
                        headerName: 'Dimensions',
                        valueGetter: (params) => {
                            if (params.row.defaultImage) {
                                return params.row.defaultImage.width + 'x' + params.row.defaultImage.height;
                            } else {
                                return params.row.adAsset.width + 'x' + params.row.adAsset.height;
                            }
                        },
                    },
                    {
                        width: 200,
                        field: 'description',
                        headerName: 'Description',
                        flex: 1,
                        renderCell: (params) => {
                            return (
                                <Tooltip title={params.value}>
                                    <div
                                        style={{
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                        }}
                                    >
                                        {params.value}
                                    </div>
                                </Tooltip>
                            );
                        },
                    },
                    {
                        width: 150,
                        field: 'createdOn',
                        hide: true,
                        headerName: 'Created On',
                        valueGetter: (params) => {
                            return moment(new Date(params.value)).format('MM/DD/YY h:mm a');
                        },
                    },
                    {
                        width: 140,
                        field: 'lastUpdateOn',
                        flex: 1,
                        headerName: 'Updated On',
                        valueGetter: (params) => {
                            return moment(new Date(params.value)).format('MM/DD/YY h:mm a');
                        },
                    },
                ]}
                rows={ads}
            />
        </>
    );
}
