// import { Helmet } from 'react-helmet';

import TrialfireScript from './scripts/TrialfireScript';
import { TrialfireIdentifyUserScript } from './scripts/TrialfireScript';

export default function AppHead() {
    return (
        <>
            <TrialfireScript />
            <TrialfireIdentifyUserScript />
        </>
    );
}
