import { Tooltip, Typography } from '@mui/material';
import { LaunchOutlined } from '@mui/icons-material';
import Row from './Row';

export default function CDLink(props: { linkText?: string; modified?: boolean }) {
    const { linkText, modified } = props;

    return (
        <Typography
            component="div"
            color="primary"
            sx={{
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
            }}
        >
            {linkText && linkText.startsWith('http') && (
                <Row>
                    <Tooltip title={linkText} placement="top-start">
                        <a target="new" href={linkText} color="primary">
                            <Row gap={1}>
                                View <LaunchOutlined sx={{ marginTop: '2px' }} fontSize="small" color="primary" />
                            </Row>
                        </a>
                    </Tooltip>
                    {modified && <span style={{ fontStyle: 'italic' }}>*Modified</span>}
                </Row>
            )}
            {linkText && !linkText.startsWith('http') && <span>{linkText}</span>}
        </Typography>
    );
}
