import { MouseEvent, useEffect, useState } from 'react';
import { InputAdornment, Popover, PopoverProps, TextFieldProps } from '@mui/material';
import { HexColorPicker } from 'react-colorful';
import { CDTextField } from '../components/CDTextField';
import theme from '../theme';

type CDColorPickerProps = {
    name: string;
    label: string;
    value: string | null;
    onChange: (newValue: string | null) => void;
    TextFieldProps?: TextFieldProps;
    PopoverProps?: PopoverProps;
};

export default function CDColorPicker(props: CDColorPickerProps) {
    const { onChange, value } = props;

    const [color, setColor] = useState<string>('');
    const [popoverAnchorEl, setPopoverAnchorEl] = useState<HTMLElement | null>(null);
    const [popoverOpen, setPopoverOpen] = useState<boolean>(false);

    useEffect(() => {
        setColor(value ?? '');
    }, []);

    useEffect(() => {
        onChange(color);
    }, [color]);

    return (
        <>
            <CDTextField
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <div
                                className="MuiInputColorPicker-root"
                                style={{
                                    backgroundColor: color ?? '#eeeeee',
                                    borderRadius: '8px',
                                    borderStyle: 'solid',
                                    borderWidth: '1px',
                                    display: 'block',
                                    cursor: 'pointer',
                                    height: '24px',
                                    width: '24px',
                                }}
                                onClick={(event: MouseEvent<HTMLElement>) => {
                                    setPopoverAnchorEl(event.currentTarget);
                                    setPopoverOpen(!popoverOpen);
                                }}
                            ></div>
                        </InputAdornment>
                    ),
                }}
                {...props.TextFieldProps}
                name={props.name}
                label={props.label}
                value={color}
                onChange={(event) => {
                    setColor(event.target.value);
                }}
                sx={{
                    '&': {
                        width: '100%',
                    },
                    '& .MuiInputColorPicker-root': {
                        borderColor: theme.palette.divider,
                    },
                    '&:hover .MuiInputColorPicker-root': {
                        borderColor: theme.palette.action.active,
                    },
                    '& .Mui-focused .MuiInputColorPicker-root': {
                        borderColor: theme.palette.primary.main,
                    },
                }}
            />
            <Popover
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                {...props.PopoverProps}
                anchorEl={popoverAnchorEl}
                open={popoverOpen}
                onClose={(event) => {
                    setPopoverAnchorEl(null);
                    setPopoverOpen(false);
                }}
                sx={{
                    '& .MuiPaper-root': {
                        overflow: 'hidden',
                    },
                }}
            >
                <HexColorPicker
                    color={color}
                    onChange={(color) => {
                        if (color.includes('NaN')) {
                            color = '#ffffff';
                        }

                        setColor(color);
                    }}
                />
            </Popover>
        </>
    );
}
