import {
    Box,
    // Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';

import { Performance } from '../../types/CampaignPerformance';
import { Table, TableCellText, TablePrimaryCell } from '../../components/Metrics/MetricTable';
import Row from '../../components/Row';
import Utils from '../../components/Utils';

import { useAttributionPageContext } from '../../hooks/useAttributionPage';
import { calculatePerformanceValue } from '../../hooks/useCampaignPerformance';

export type PerformanceListTableProps = { performances: Performance[] };

export function DisplayPerformanceListTable({ performances }: PerformanceListTableProps) {
    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Campaign</TableCell>
                    <TableCell>Spend</TableCell>
                    <TableCell>Avg. CPM</TableCell>
                    <TableCell>Impressions</TableCell>
                    <TableCell>Reach</TableCell>
                    <TableCell>Clicks</TableCell>
                    <TableCell>
                        <TableCellText tooltip="Conversions as reported by the DSP">Conversions</TableCellText>
                    </TableCell>
                </TableRow>
            </TableHead>

            <TableBody>
                {performances.map((performance: Performance, index: number) => (
                    <TableRow key={index}>
                        <TablePrimaryCell>{performance.campaignName}</TablePrimaryCell>
                        <TableCell>{Utils.formatValue(performance.totalCost, 'dollar')}</TableCell>
                        <TableCell>{Utils.formatValue(performance.CPM, 'dollar')}</TableCell>
                        <TableCell>{Utils.formatValue(performance.impressions)}</TableCell>
                        <TableCell>{Utils.formatValue(performance.dailyReach)}</TableCell>
                        <TableCell>{Utils.formatValue(performance.clickthroughs)}</TableCell>
                        <TableCell>{Utils.formatValue(performance.offAmazonConversions)}</TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
}

export function VideoPerformanceListTable({ performances }: PerformanceListTableProps) {
    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Campaign</TableCell>
                    <TableCell>Spend</TableCell>
                    <TableCell>Avg. CPM</TableCell>
                    <TableCell>Impressions</TableCell>
                    <TableCell>Reach</TableCell>
                    <TableCell>
                        <TableCellText tooltip="Estimated Cost per Completed View">eCPCV</TableCellText>
                    </TableCell>
                    <TableCell>
                        <TableCellText tooltip="Conversions as reported by the DSP">Conversions</TableCellText>
                    </TableCell>
                </TableRow>
            </TableHead>

            <TableBody>
                {performances.map((performance: Performance, index: number) => (
                    <TableRow key={index}>
                        <TablePrimaryCell>{performance.campaignName}</TablePrimaryCell>
                        <TableCell>{Utils.formatValue(performance.totalCost, 'dollar')}</TableCell>
                        <TableCell>{Utils.formatValue(performance.CPM, 'dollar')}</TableCell>
                        <TableCell>{Utils.formatValue(performance.impressions)}</TableCell>
                        <TableCell>{Utils.formatValue(performance.dailyReach)}</TableCell>
                        <TableCell>
                            {Utils.formatValue(calculatePerformanceValue(performance, 'eCPCV'), 'dollar')}
                        </TableCell>
                        <TableCell>{Utils.formatValue(performance.offAmazonConversions)}</TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
}

export function AudioPerformanceListTable({ performances }: PerformanceListTableProps) {
    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Campaign</TableCell>
                    <TableCell>Spend</TableCell>
                    <TableCell>Avg. CPM</TableCell>
                    <TableCell>Impressions</TableCell>
                    <TableCell>Reach</TableCell>
                    <TableCell>Clicks</TableCell>
                    <TableCell>
                        <TableCellText tooltip="Conversions as reported by the DSP">Conversions</TableCellText>
                    </TableCell>
                </TableRow>
            </TableHead>

            <TableBody>
                {performances.map((performance: Performance, index: number) => (
                    <TableRow key={index}>
                        <TablePrimaryCell>{performance.campaignName}</TablePrimaryCell>
                        <TableCell>{Utils.formatValue(performance.totalCost, 'dollar')}</TableCell>
                        <TableCell>{Utils.formatValue(performance.CPM, 'dollar')}</TableCell>
                        <TableCell>{Utils.formatValue(performance.impressions)}</TableCell>
                        <TableCell>{Utils.formatValue(performance.dailyReach)}</TableCell>
                        <TableCell>{Utils.formatValue(performance.clickthroughs)}</TableCell>
                        <TableCell>{Utils.formatValue(performance.offAmazonConversions)}</TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
}

export default function AdvertiserMatchbackCampaignTable() {
    const { getPerformances, getPerformanceMediaTypes } = useAttributionPageContext();

    const mediaTypes: string[] = getPerformanceMediaTypes();

    return (
        <TableContainer
            sx={{
                borderCollapse: 'inherit',
                borderColor: (theme) => theme.palette.grey[300],
                borderStyle: 'solid',
                borderWidth: 1,
                borderRadius: '8px',
                overflow: 'hidden',
            }}
        >
            {mediaTypes &&
                mediaTypes.length > 0 &&
                mediaTypes.map((mediaType) => (
                    <Box key={mediaType}>
                        <Row
                            sx={{
                                backgroundColor: (theme) => theme.palette.action.hover,
                                padding: (theme) => theme.spacing(2),
                            }}
                        >
                            <Typography variant="subtitle2" color="text.primary">
                                {mediaType}
                            </Typography>
                        </Row>
                        {mediaType === 'Display' && (
                            <DisplayPerformanceListTable performances={getPerformances(mediaType)} />
                        )}
                        {mediaType === 'Video' && (
                            <VideoPerformanceListTable performances={getPerformances(mediaType)} />
                        )}
                        {mediaType === 'Audio' && (
                            <AudioPerformanceListTable performances={getPerformances(mediaType)} />
                        )}
                    </Box>
                ))}
        </TableContainer>
    );
}
