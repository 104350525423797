import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import ApiService from '../../ApiService';
import { MenuItem, TextField } from '@mui/material';
import { SasAccount } from '../../types/SasAccount';

export interface SasAccountProps {
    disabled?: boolean;
    sasAccountId: number | undefined;
    onSelectSasAccount(accountId: number): void;
    error?: boolean;
    variant?: 'filled' | 'outlined';
    shrinkLabel?: boolean;
}

export default function SasAccountComponent({
    disabled,
    error,
    onSelectSasAccount,
    sasAccountId,
    shrinkLabel,
    variant = 'outlined',
}: SasAccountProps) {
    const [accounts, setAccounts] = useState<SasAccount[]>([]);
    const onSelectSasAccountRef = useRef(onSelectSasAccount);

    // onSelectSasAccount is being set in the parent component as an inline function which will
    // trigger too many updates/side effects.  Use a ref to isolate those changes until the parent
    // components can be refactored.
    useEffect(() => {
        onSelectSasAccountRef.current = onSelectSasAccount;
    }, [onSelectSasAccount]);

    useEffect(() => {
        ApiService.getSasAccounts()
            .then((response) => {
                setAccounts(response.data);
                if (response.data.length === 1) {
                    onSelectSasAccountRef.current(response.data[0].id);
                }
            })
            .catch(() => {});
    }, []);

    const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        const id = Number(event.target.value);
        onSelectSasAccountRef.current(id);
    }, []);

    return (
        <>
            <div id="sasAccount" style={{ width: '100%' }}>
                <TextField
                    variant={variant}
                    fullWidth
                    size="small"
                    label={disabled ? 'Ad Server Account' : 'Select Ad Server Account'}
                    name="accountId"
                    select
                    disabled={disabled}
                    required={!disabled}
                    value={sasAccountId != null ? String(sasAccountId) : ''}
                    error={error}
                    helperText={error ? 'Account is required.' : ''}
                    onChange={handleChange}
                    SelectProps={{ native: false }}
                    InputLabelProps={{ shrink: shrinkLabel }}
                >
                    {accounts.map((account) => {
                        return (
                            <MenuItem key={String(account.id)} value={String(account.id)}>
                                {account.accountName}
                            </MenuItem>
                        );
                    })}
                </TextField>
            </div>
        </>
    );
}
